export const pathologies = [
  {
    codigo: "020000000",
    nombre: "CÁNCER",
    nombre_cronico: "Cáncer",
  },
  {
    codigo: "040300E16",
    nombre: "OTROS TRASTORNOS DE LA SECRECION INTERNA DEL PANCREAS",
    nombre_cronico: "Resistencia a la Insulina",
  },
  {
    codigo: "040100E07",
    nombre: "HIPOTIROIDISMO /HIPERTIROIDISMO",
    nombre_cronico: "Hipotiroidismo /hipertiroidismo",
  },
  {
    codigo: "040500000",
    nombre: "DESNUTRICIÓN",
    nombre_cronico: "Desnutrición",
  },
  {
    codigo: "040800E79",
    nombre: "TRASTORNOS DEL METABOLISMO DE LAS PURINAS Y DE LAS PIRIMIDINAS",
    nombre_cronico: "Hiperuricemia",
  },
  {
    codigo: "040800E78",
    nombre:
      "TRASTORNOS DEL METABOLISMO DE LAS LIPOPROTEINAS Y OTRAS LIPIDEMIAS",
    nombre_cronico: "Dislipidemia",
  },
  {
    codigo: "040800000",
    nombre: "SÍNDROME METABÓLICO",
    nombre_cronico: "Síndrome metabólico",
  },
  {
    codigo: "040800E84",
    nombre: "FIBROSIS QUÍSTICA",
    nombre_cronico: "Fibrosis quística",
  },
  {
    codigo: "090700000",
    nombre: "ACCIDENTE CEREBRO VASCULAR (ACV)",
    nombre_cronico: "Accidente cerebro vascular (acv)",
  },
  {
    codigo: "090300I10",
    nombre: "HIPERTENSIÓN ARTERIAL",
    nombre_cronico: "Hipertensión arterial",
  },
  {
    codigo: "030100D53",
    nombre: "ANEMIA",
    nombre_cronico: "Anemia",
  },
  {
    codigo: "060300G20",
    nombre: "MAL DE PARKINSON",
    nombre_cronico: "Mal de parkinson",
  },
  {
    codigo: "060500G35",
    nombre: "ESCLEROSIS MÚLTIPLE",
    nombre_cronico: "Esclerosis múltiple",
  },
  {
    codigo: "060600G43",
    nombre: "MIGRAÑA",
    nombre_cronico: "Migraña",
  },
  {
    codigo: "060400G30",
    nombre: "MAL DE ALZHEIMER",
    nombre_cronico: "Mal de alzheimer",
  },
  {
    codigo: "060600G40",
    nombre: "EPILEPSIA",
    nombre_cronico: "Epilepsia",
  },
  {
    codigo: "070100H04",
    nombre: "TRASTORNOS DEL APARATO LAGRIMAL",
    nombre_cronico: "Síndrome del Ojo Seco",
  },
  {
    codigo: "070600H40",
    nombre: "GLAUCOMA",
    nombre_cronico: "Glaucoma",
  },
  {
    codigo: "080300H83",
    nombre: "VERTIGO /LABERINTITIS",
    nombre_cronico: "Vertigo /laberintitis",
  },
  {
    codigo: "080400H90",
    nombre: "HIPOACUSIA",
    nombre_cronico: "Hipoacusia",
  },
  {
    codigo: "090800I73",
    nombre: "OTRAS ENFERMEDADES VASCULARES PERIFERICAS",
    nombre_cronico: "Enfermedad Arterial Periférica",
  },
  {
    codigo: "090400I25",
    nombre: "CARDIOPATÍA ISQUÉMICA",
    nombre_cronico: "Cardiopatía isquémica",
  },
  {
    codigo: "090600I40",
    nombre: "MIOCARDITIS AGUDA",
    nombre_cronico: "Miocarditis Infecciosa",
  },
  {
    codigo: "090600I49",
    nombre: "ARRITMIA CARDIACA",
    nombre_cronico: "Arritmia cardiaca",
  },
  {
    codigo: "090900I84",
    nombre: "HEMORROIDES",
    nombre_cronico: "Hemorroides",
  },
  {
    codigo: "120300000",
    nombre: "DERMATITIS CRÓNICA",
    nombre_cronico: "Dermatitis crónica",
  },
  {
    codigo: "100500J44",
    nombre: "ENFERMEDAD PULMONAR OBSTRUCTIVA (EPOC)",
    nombre_cronico: "Enfermedad pulmonar obstructiva (epoc)",
  },
  {
    codigo: "100500J45",
    nombre: "ASMA",
    nombre_cronico: "Asma",
  },
  {
    codigo: "090900I87",
    nombre: "INSUFICIENCIA VENOSA",
    nombre_cronico: "Insuficiencia venosa",
  },
  {
    codigo: "100400J34",
    nombre: "HIPERTROFIA DE CORNETES Y/O TRASTORNO DE ADENOIDES",
    nombre_cronico: "Hipertrofia de cornetes y/o trastorno de adenoides",
  },
  {
    codigo: "110200K29",
    nombre: "GASTRITIS CRÓNICA",
    nombre_cronico: "Gastritis crónica",
  },
  {
    codigo: "110600K58",
    nombre: "SINDROME COLON IRRITABLE",
    nombre_cronico: "Sindrome colon irritable",
  },
  {
    codigo: "110600K57",
    nombre: "DIVERTICULITIS",
    nombre_cronico: "Diverticulitis",
  },
  {
    codigo: "110800K70",
    nombre: "ESTEATOSIS HEPÁTICA  (HIGADO GRASO)",
    nombre_cronico: "Esteatosis hepática  (higado graso)",
  },
  {
    codigo: "110500K50",
    nombre: "ENFERMEDAD DE CROHN",
    nombre_cronico: "Enfermedad de crohn",
  },
  {
    codigo: "120500L50",
    nombre: "URTICARIA",
    nombre_cronico: "Urticaria",
  },
  {
    codigo: "140300N19",
    nombre: "INSUFICIENCIA RENAL",
    nombre_cronico: "Insuficiencia renal",
  },
  {
    codigo: "120800L93",
    nombre: "LUPUS",
    nombre_cronico: "Lupus",
  },
  {
    codigo: "130102M06",
    nombre: "ARTRITIS",
    nombre_cronico: "Artritis",
  },
  {
    codigo: "130200M35",
    nombre: "SÍNDROME DE SJÖGREN / SINDROME DEL OJO SECO",
    nombre_cronico: "Síndrome de Sjögren",
  },
  {
    codigo: "130104M22",
    nombre: "CONDROMALACIA ROTULIANA",
    nombre_cronico: "Condromalacia rotuliana",
  },
  {
    codigo: "130103M19",
    nombre: "ARTROSIS",
    nombre_cronico: "Artrosis",
  },
  {
    codigo: "130501M85",
    nombre: "OTROS TRASTORNOS DE LA DENSIDAD Y DE LA ESTRUCTURA OSEAS",
    nombre_cronico: "Osteoporosis/osteopenia",
  },
  {
    codigo: "141000N83",
    nombre:
      "TRASTORNOS NO INFLAMATORIOS DEL OVARIO, DE LA TROMPA DE FALOPIO Y DEL LIGAMENTO ANCHO",
    nombre_cronico: "Quiste simple de ovarios",
  },
  {
    codigo: "180900R73",
    nombre: "NIVEL ELEVADO DE GLUCOSA EN SANGRE",
    nombre_cronico: "Intolerancia a Carbohidratos/Prediabetes",
  },
  {
    codigo: "120400L40",
    nombre: "PSORIASIS",
    nombre_cronico: "Psoriasis",
  },
  {
    codigo: "040200E10",
    nombre: "DIABETES MELITUS",
    nombre_cronico: "Diabetes melitus",
  },
  {
    codigo: "090800I74",
    nombre: "TROMBOSIS",
    nombre_cronico: "Trombosis",
  },
  {
    codigo: "140700N40",
    nombre: "HIPERPLASIA DE LA PROSTATA",
    nombre_cronico: "Hiperplasia de la prostata",
  },
  {
    codigo: "140600N39",
    nombre: "CÓLICO NEFRÍTICO / CÁLCULO RENAL /LITIASIS RENAL",
    nombre_cronico: "Cólico nefrítico / cálculo renal /litiasis renal",
  },
  {
    codigo: "220100U09",
    nombre: "CONDICIÓN DE SALUD POSTERIOR A COVID-19",
    nombre_cronico: "Condición de salud posterior a covid-19",
  },
  {
    codigo: "100400J30",
    nombre: "RINITIS CRÓNICA O ALÉRGICA",
    nombre_cronico: "Rinitis crónica o alérgica",
  },
  {
    codigo: "040400E28",
    nombre: "SINDROME DE OVARIO POLIQUISTICO / OVARIOS POLIQUISTICOS",
    nombre_cronico: "Sindrome de ovario poliquistico / ovarios poliquisticos",
  },
  {
    codigo: "100400J35",
    nombre: "ENFERMEDADES CRONICAS DE LAS AMIGDALAS Y DE LAS ADENOIDES",
    nombre_cronico: "Enfermedades cronicas de las amigdalas y de las adenoides",
  },
  {
    codigo: "130200M34",
    nombre: "ESCLEROSIS SISTÉMICA",
    nombre_cronico: "Esclerosis sistémica",
  },
  {
    codigo: "020300D36",
    nombre: "TUMOR BENIGNO DE OTROS SITIOS Y DE LOS NO ESPECIFICADOS",
    nombre_cronico:
      "Lesión ocupante de espacio (LOE)/tumores benignos/Neoplasias benignas",
  },
  {
    codigo: "060800G62",
    nombre: "OTRAS POLINEUROPATIAS",
    nombre_cronico: "Neuropatías /Polineuropatías sin especificar",
  },
]