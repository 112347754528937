import { 
  getPrincipiosActivosCategorias as getPActivosCategorias, 
  getPrincipioActivoCategoria as getPActivosACategoria,
  deletePrincipioActivoCategoria as deletePrincipioActivoACategoria,
  editPrincipioActivoCategoria as editPrincipioActivoACategoria,
  createPrincipioActivoCategoria as createPrincipioActivoACategoria } from "../../../helpers/backend_helper";

import {  apiError, principiosActivosCategoriasSuccess, principioActivoCategoriaSuccess, principiosActivosCategoriaDeleteSuccess, reset_principiosActivosCategoria_flag,} from "./reducer";

export const getPrincipiosActivosCat = () => async (dispatch) => {
  //console.log("THUNK -- getPrincipiosActivosCat action started");
  
  try {
    //console.log("Thunk -- getPrincipiosActivosCateg called");
    
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      //console.log("Calling getPActivosCategorias");
      response = getPActivosCategorias();
    }

    //console.log("API response:", response);
    
    //console.log("Awaiting response");
    var data = await response;
    //console.log("Data received:", data);

    if (data) {
      //console.log("Dispatching success action");
      dispatch(principiosActivosCategoriasSuccess(data));
    }
  } catch (error) {
    console.error("Error in getPrincipiosActivosCat:", error);
    dispatch(apiError(error));
  }
};


export const deletePrincipioActivoCategoria = (id) => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = deletePrincipioActivoACategoria(id);
    }
    var data = await response;
    if (data) {
      dispatch(principiosActivosCategoriaDeleteSuccess(data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const editPrincipioActivoCategoria = (principioActivo) => async (dispatch) => {
  try {
   
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = editPrincipioActivoACategoria(principioActivo);
    }
    var data = await response;
    if (data) {
      dispatch(principioActivoCategoriaSuccess(data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const createPrincipioActivoCategoria = (principioActivo) => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = createPrincipioActivoACategoria(principioActivo);
    }
    var data = await response;  

    if (data) {
      dispatch(principioActivoCategoriaSuccess(data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const getPrincipioActivoCategoria = (id) => async (dispatch) => {
  try {
    
    
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = getPActivosACategoria(id);
    }
    var data = await response;
    
    if (data) {
      dispatch(principioActivoCategoriaSuccess(data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const resetPrincipiosActivosCategoriaFlag = () => async (dispatch) => {
  try {
    const response = dispatch(reset_principiosActivosCategoria_flag());
    return response;
  } catch (error) {
    dispatch(apiError(error));
  }
};
