import React, { useState, useEffect } from "react";
import { createSelector } from "reselect";
import { useSelector, useDispatch } from "react-redux";
import {
  editSolicitud,
  createMessage,
  addComentario,
  addDelivery,
} from "../../../slices/thunks";
import {
  Col,
  Row,
  Input,
  Label,
  Button,
  Form,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import InformacionDelivery from "./InformacionDelivery";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Delivery = ({ usuarios, solicitud, farmacias }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [responsable, setResponsable] = useState("");
  const [fecha, setFecha] = useState("");
  const [fechaEntrega, setFechaEntrega] = useState("");
  const [hora, setHora] = useState("");
  const [showDelivery, setShowDelivery] = useState(false);
  const [coordenadas, setCoordenadas] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [formErrors, setFormErrors] = useState({
    responsable: false,
    fecha: false,
    hora: false,
  });
  const [loadingx, setLoadingx] = useState(false);

  const createBlobUrl = (base64Data) => {
    const parts = base64Data.split(";base64,");
    const contentType = parts[0].split(":")[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    const blob = new Blob([uInt8Array], { type: contentType });
    return URL.createObjectURL(blob);
  };

  const abrirImagen = (base64URL) => {
    const blobUrl = createBlobUrl(base64URL);
    window.open(blobUrl, "_blank");
  };
  const selectLayoutState = (state) => state;
  const pageData = createSelector(selectLayoutState, (state) => ({
    siscotel: state.Siscotel.siscotel,
    errorFacturacion: state.Siscotel.error,
    loading: state.Siscotel.loading,
    errorMsgFacturacion: state.Siscotel.errorMsg,
    colorMsg: state.Siscotel.colorMsg,
    exito: state.Siscotel.exito,
  }));

  const {
    siscotel,
    errorFacturacion,
    loading,
    errorMsgFacturacion,
    colorMsg,
    exito,
  } = useSelector(pageData);

  useEffect(() => {
    if (solicitud && solicitud.coordenadas) {
      setCoordenadas(true);
    }
    if (solicitud && solicitud.delivery && solicitud.delivery.length > 0) {
      setShowDelivery(true);
      // Tomamos el último delivery registrado (asumiendo que el más reciente está al final)
      const ultimoDelivery = solicitud.delivery[solicitud.delivery.length - 1];
      let fechaAjustada = ultimoDelivery.fecha;

      // Verificar si la fecha termina con 'Z' y eliminarla si está presente
      if (fechaAjustada.endsWith("Z")) {
        fechaAjustada = fechaAjustada.slice(0, -1);
      }

      setFecha(fechaAjustada);

      if (ultimoDelivery.fechaEntrega) {
        fechaAjustada = ultimoDelivery.fechaEntrega;

        if (fechaAjustada.endsWith("Z")) {
          fechaAjustada = fechaAjustada.slice(0, -1);
        }

        setFechaEntrega(fechaAjustada);
      }
    }
  }, [solicitud]);

  useEffect(() => {
    if (siscotel && siscotel.status == 200 && loadingx) {
      const fechaEntrega = crearFechaHora(fecha, hora);
      let fechaAjustada = fechaEntrega;

      if (fechaAjustada.endsWith("Z")) {
        fechaAjustada = fechaAjustada.slice(0, -1);
      }

      const now = new Date();
      const creado = new Date(
        now.getTime() - now.getTimezoneOffset() * 60000
      ).toISOString();

      const comentario = {
        tipo: "Enviado",
        mensaje:
          "Datos del Delivery Responsable: " +
          responsable.nombre +
          " " +
          responsable.apellido +
          " Fecha: " +
          formatDate(fechaAjustada),
        estatus: true,
        adjunto: null,
        creado: creado,
      };

      const delivery = {
        servicioEntrega: responsable,
        estatus: "En Transito",
        fecha: fechaEntrega,
      };

      // Función asincrónica para ejecutar las acciones secuencialmente
      const updateSequentially = async () => {
        try {
          // Primero actualizar el delivery
          await dispatch(
            addDelivery({
              identificador: String(solicitud.identificador),
              delivery,
            })
          );
          
          // Luego actualizar el comentario
          await dispatch(
            addComentario({
              identificador: String(solicitud.identificador),
              comentario,
            })
          );
          
          toast.success("Delivery registrado exitosamente", {});
        } catch (error) {
          console.error("Error al actualizar:", error);
          toast.error("Error al registrar el delivery");
        } finally {
          setLoadingx(false);
        }
      };
      
      // Ejecutar la función asincrónica
      updateSequentially();
    }
  }, [dispatch, siscotel, loadingx]);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString("es-ES", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true, // Esto cambia a formato 12 horas con AM/PM
    });
  };

  const formatDate2 = (dateString) => {
    // Verificar el formato de la fecha
    const isZuluTime = dateString.endsWith("Z");
    const date = new Date(dateString);

    return date.toLocaleString("es-ES", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      // Si es Zulu time (termina en Z), usar UTC, si no, usar America/Bogota
      timeZone: isZuluTime ? "UTC" : "America/Caracas",
    });
  };

  function crearFechaHora(fecha, hora) {
    // Asumiendo que fecha está en formato 'YYYY-MM-DD' y hora en formato 'HH:mm'
    const [year, month, day] = fecha.split("-");
    const [hours, minutes] = hora.split(":");

    // Crear la fecha en la zona horaria de Venezuela (UTC-4)
    const fechaHoraVenezuela = new Date(
      Date.UTC(year, month - 1, day, hours, minutes)
    );

    fechaHoraVenezuela.setHours(fechaHoraVenezuela.getHours()); // Ajustar a UTC-4

    // Formatear la fecha a ISO string
    return fechaHoraVenezuela.toISOString();
  }

  const deliveryUsers =
    usuarios && usuarios[0] && farmacias
      ? usuarios[0].filter((persona) => {
          // Verificar que el usuario sea Delivery y esté activo
          const isDeliveryAndActive =
            persona.activo === true && persona.perfil === "Delivery";

          // Si el usuario tiene una farmacia asignada, verificar que coincida con el codigoProveedor
          if (isDeliveryAndActive && persona.farmacia) {
            const farmaciaUsuario = farmacias.find(
              (farmacia) => farmacia.id === persona.farmacia
            );

            return (
              farmaciaUsuario &&
              farmaciaUsuario.codigo_myh === solicitud.codigoProveedor
            );
          }

          return false;
        })
      : [];

  function handleResponsale(e) {
    if (usuarios && usuarios[0]) {
      const selectedUser = usuarios[0].find(
        (user) => user.id === e.target.value
      );
      if (selectedUser) {
        setResponsable(selectedUser);
        setFormErrors({ ...formErrors, responsable: false });
      }
    }
  }

  function handleFecha(e) {
    setFecha(e.target.value);
    setFormErrors({ ...formErrors, fecha: false });
  }

  function handleHora(e) {
    setHora(e.target.value);
    setFormErrors({ ...formErrors, hora: false });
  }

  function handleSubmitDelivery() {
    setLoadingx(true);
    // Objeto para almacenar los estados de error
    const errors = {
      responsable: !responsable,
      fecha: !fecha,
      hora: !hora,
    };

    // Actualizar el estado de los errores
    setFormErrors(errors);

    // Si hay algún error, detener la ejecución
    if (Object.values(errors).some((error) => error)) {
      setShowErrorMessage(true);
      setTimeout(() => setShowErrorMessage(false), 3000); // Ocultar después de 3 segundos
      return;
    }

    const fechaEntrega = crearFechaHora(fecha, hora);
    let fechaAjustada = fechaEntrega;

    if (fechaAjustada.endsWith("Z")) {
      fechaAjustada = fechaAjustada.slice(0, -1);
    }

    const message = {
      IdentificadorEvento: solicitud.identificador,
      Tipo: "Mensaje",
      Mensaje:
        "Datos del Delivery Responsable: " +
        responsable.nombre +
        " " +
        responsable.apellido +
        " - Fecha: " +
        formatDate(fechaAjustada),
      Adjunto: "",
      CodigoProveedor: solicitud.codigoProveedor,
    };

    dispatch(createMessage(message));
  }

  function handleResponsale(e) {
    if (usuarios && usuarios[0]) {
      const selectedUser = usuarios[0].find(
        (user) => user.id === e.target.value
      );
      if (selectedUser) {
        setResponsable(selectedUser);
      }
    }
  }

  function handleFecha(e) {
    setFecha(e.target.value);
  }

  function handleHora(e) {
    setHora(e.target.value);
  }

  const editarSolicitudDelivery = () => {
    setShowDelivery(false);
  };

  /**
   * Renderiza una tabla con la información de delivery de una solicitud.
   * @param {{solicitud: object, onEdit: function}} props
   * @prop {object} solicitud La solicitud que se va a renderizar.
   * @prop {function} onEdit Función que se llama cuando se hace click en la acción de editar.
   * @returns {ReactElement} Un ReactElement que representa la tabla con la información de delivery.
   */
  const DeliveryTable = ({ solicitud, onEdit }) => {
    if (!solicitud || !solicitud.delivery || solicitud.delivery.length === 0) {
      return (
        <div className="text-center p-4 bg-light rounded">
          <i className="ri-information-line fs-24 text-muted mb-2 d-block"></i>
          <p className="mb-0">No hay información de delivery disponible.</p>
        </div>
      );
    }

    return (
      <>
        {solicitud.estatus === "Por Reprogramar" && (
          <div className="text-end col-lg-12 mb-3">
            <Button
              onClick={onEdit}
              className="btn btn-primary btn-sm text-nowrap"
            >
              <i className="ri-user-follow-line me-1" />
              Nuevo Responsable
            </Button>
          </div>
        )}

        <div className="table-responsive">
          <table className="table table-hover align-middle">
            <thead className="table-light">
              <tr>
                <th scope="col" className="fw-semibold">
                  Responsable
                </th>
                <th className="fw-semibold">Teléfono</th>
                <th className="fw-semibold">Estatus</th>
                <th className="fw-semibold">Observación</th>
                <th className="fw-semibold">Fecha - Hora</th>
              </tr>
            </thead>
            <tbody>
              {solicitud.delivery.map((delivery, index) => (
                <tr key={index}>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-xs me-2 flex-shrink-0">
                        <div className="avatar-title bg-primary-subtle text-primary rounded-circle">
                          {(delivery.servicioEntrega?.nombre?.[0] || "") +
                            (delivery.servicioEntrega?.apellido?.[0] || "")}
                        </div>
                      </div>
                      <div>
                        <span className="fw-medium">
                          {`${delivery.servicioEntrega?.nombre || ""} ${
                            delivery.servicioEntrega?.apellido || ""
                          }`}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    {delivery.servicioEntrega?.telefono ? (
                      <div className="d-flex align-items-center">
                        <i className="ri-phone-line text-muted me-1"></i>
                        <span>{delivery.servicioEntrega.telefono}</span>
                      </div>
                    ) : (
                      <span className="text-muted">-</span>
                    )}
                  </td>
                  <td>
                    {delivery.estatus ? (
                      <span
                        className={`badge ${getStatusBadgeClass(
                          delivery.estatus
                        )}`}
                      >
                        {delivery.estatus}
                      </span>
                    ) : (
                      <span className="text-muted">-</span>
                    )}
                  </td>
                  <td>
                    {delivery.observacion ? (
                      <span className="text-wrap" style={{ maxWidth: "200px" }}>
                        {delivery.observacion}
                      </span>
                    ) : (
                      <span className="text-muted">-</span>
                    )}
                  </td>
                  <td>
                    {delivery?.fecha ? (
                      <div className="d-flex align-items-center">
                        <i className="ri-calendar-line text-muted me-1"></i>
                        <span>{formatDate2(delivery.fecha)}</span>
                      </div>
                    ) : (
                      <span className="text-muted">No disponible</span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  // Función auxiliar para determinar la clase de la insignia según el estado
  const getStatusBadgeClass = (status) => {
    switch (status) {
      case "Pendiente":
        return "bg-warning-subtle text-warning";
      case "En Transito":
        return "bg-info-subtle text-info";
      case "Entregado":
        return "bg-success-subtle text-success";
      case "Cancelado":
        return "bg-danger-subtle text-danger";
      case "Por Reprogramar":
        return "bg-secondary-subtle text-secondary";
      default:
        return "bg-primary-subtle text-primary";
    }
  };

  /**
   * Renderiza una tabla con la información de delivery de una solicitud.
   * @param {{solicitud: object, onEdit: function}} props
   * @prop {object} solicitud La solicitud que se va a renderizar.
   * @prop {function} onEdit Función que se llama cuando se hace click en la acción de editar.
   * @returns {ReactElement} Un ReactElement que representa la tabla con la información de delivery.
   */
  const DeliveryEntregaTable = ({ solicitud }) => {
    // Obtener el último delivery (asumiendo que es el más reciente)
    const ultimoDelivery = solicitud.delivery[solicitud.delivery.length - 1];

    return (
      <div className="table-responsive">
        <table className="table table-hover align-middle">
          <thead className="table-light">
            <tr>
              <th scope="col" className="fw-semibold">
                Fecha - Hora
              </th>
              <th className="fw-semibold">Distancia (Km)</th>
              <th className="fw-semibold">Costo Entrega (Bs)</th>
              <th className="fw-semibold">Costo Entrega ($)</th>
              <th className="fw-semibold">Lugar de Entrega</th>
              <th className="fw-semibold">Comprobante</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {ultimoDelivery?.fechaEntrega ? (
                  <span className="text-body-emphasis">
                    {formatDate(ultimoDelivery.fechaEntrega)}
                  </span>
                ) : (
                  <span className="text-muted">No disponible</span>
                )}
              </td>
              <td>
                {ultimoDelivery.distancia_real ? (
                  <span className="badge bg-info-subtle text-info fs-12 px-2">
                    {ultimoDelivery.distancia_real} km
                  </span>
                ) : (
                  <span className="text-muted">-</span>
                )}
              </td>
              <td>
                {ultimoDelivery.costoEntrega ? (
                  <span className="fw-medium">
                    {ultimoDelivery.costoEntregaBs}
                  </span>
                ) : (
                  <span className="text-muted">-</span>
                )}
              </td>
              <td>
                {ultimoDelivery.costoEntrega ? (
                  <span className="fw-medium">
                    {ultimoDelivery.costoEntrega}
                  </span>
                ) : (
                  <span className="text-muted">-</span>
                )}
              </td>

              <td id="d-date">
                {ultimoDelivery.ubicacionEntrega ? (
                  <div>
                    <a
                      href={`https://www.google.com/maps/search/?api=1&query=${ultimoDelivery.ubicacionEntrega[0]},${ultimoDelivery.ubicacionEntrega[1]}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-sm btn-soft-primary"
                    >
                      <i className="ri-map-pin-line me-1"></i>
                      {`${ultimoDelivery.ubicacionEntrega[0].toFixed(
                        6
                      )}, ${ultimoDelivery.ubicacionEntrega[1].toFixed(6)}`}
                    </a>
                  </div>
                ) : (
                  <span className="text-muted">-</span>
                )}
              </td>

              <td>
                {ultimoDelivery.reciboEntrega ? (
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      abrirImagen(ultimoDelivery.reciboEntrega);
                    }}
                    className="btn btn-sm btn-soft-info"
                  >
                    <i className="ri-file-text-line me-1"></i>
                    Ver Comprobante
                  </a>
                ) : (
                  <span className="badge bg-danger-subtle text-danger">
                    No disponible
                  </span>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <React.Fragment>
      <InformacionDelivery solicitud={solicitud} farmacias={farmacias} />

      {coordenadas && !showDelivery ? (
        <Card className="border-0 shadow-sm">
          <CardHeader>
            <div className="d-flex align-items-center">
              <i className="ri-truck-line me-1 text-primary me-2 fs-20"></i>
              <h5 className="card-title mb-0">Información Delivery:</h5>
            </div>
          </CardHeader>

          <CardBody className="p-4">
            <div id="farme__divTabla__delivery"> </div>

            {showErrorMessage && (
              <div
                className="alert alert-danger d-flex align-items-center"
                role="alert"
              >
                <i className="ri-error-warning-line me-2 fs-16"></i>
                <div>Por favor complete todos los campos obligatorios</div>
              </div>
            )}

            <Form
              id="delivery-parte1-form"
              className="form-steps"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmitDelivery();
              }}
            >
              <div className="bg-light p-3 rounded mb-4">
                <Row>
                  <Col>
                    <div className="mb-3">
                      <Label
                        className="form-label fw-medium"
                        htmlFor="gen-info-responsable-input"
                      >
                        Responsable <span className="text-danger">*</span>
                      </Label>
                      <select
                        className={`form-select ${
                          formErrors.responsable ? "is-invalid" : ""
                        }`}
                        id="t-status"
                        data-choices
                        data-choices-search-false
                        aria-label="Default select"
                        required
                        onChange={handleResponsale}
                      >
                        <option value="">Seleccione un responsable</option>
                        {deliveryUsers.map((persona) => (
                          <option key={persona.id} value={persona.id}>
                            {persona.nombre} {persona.apellido}
                          </option>
                        ))}
                      </select>
                      {formErrors.responsable && (
                        <div className="invalid-feedback">
                          Por favor seleccione un responsable
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label
                        className="form-label fw-medium"
                        htmlFor="gen-info-fecha-input"
                      >
                        Fecha para la entrega{" "}
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="date"
                        className={`form-control ${
                          formErrors.fecha ? "is-invalid" : ""
                        }`}
                        required
                        min={new Date().toISOString().split("T")[0]}
                        onChange={(e) => {
                          handleFecha(e);
                          setFormErrors({ ...formErrors, fecha: false });
                        }}
                      />
                      {formErrors.fecha && (
                        <div className="invalid-feedback">
                          Por favor seleccione una fecha
                        </div>
                      )}
                    </div>
                  </Col>

                  <Col lg={4}>
                    <div className="mb-3">
                      <Label
                        className="form-label fw-medium"
                        htmlFor="gen-info-hora-input"
                      >
                        Hora de la entrega{" "}
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        required
                        type="time"
                        className={`form-control ${
                          formErrors.hora ? "is-invalid" : ""
                        }`}
                        id="exampleInputtime"
                        onChange={(e) => {
                          handleHora(e);
                          setFormErrors({ ...formErrors, hora: false });
                        }}
                      />
                      {formErrors.hora && (
                        <div className="invalid-feedback">
                          Por favor seleccione una hora
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>

              <Row>
                <Col lg={12}>
                  <div className="mb-3 mt-4 text-end">
                    {solicitud.estatus !== "Cancelado" && (
                      <Button
                        color="success"
                        className="btn btn-lg px-4"
                        disabled={loadingx}
                        onClick={() => {
                          handleSubmitDelivery();
                        }}
                      >
                        {loadingx ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm me-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Registrando...
                          </>
                        ) : (
                          <>
                            <i className="ri-truck-line align-middle fs-16 me-2"></i>
                            Registrar Delivery
                          </>
                        )}
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      ) : null}

      {showDelivery ? (
        <>
          <Card className="border-0 shadow-sm">
            <CardHeader>
              <div className="d-flex align-items-center">
                <i className="ri-truck-line me-1 text-primary me-2 fs-20"></i>
                <h5 className="card-title mb-0">Información Delivery:</h5>
              </div>
            </CardHeader>

            <CardBody className="p-4">
              <DeliveryTable
                solicitud={solicitud}
                onEdit={editarSolicitudDelivery}
              />
            </CardBody>
          </Card>
        </>
      ) : null}

      {showDelivery && solicitud.estatus == "Entregado" ? (
        <>
          <Card className="border-0 shadow-sm">
            <CardHeader>
              <div className="d-flex align-items-center">
                <i className="ri-check-double-line me-1 text-primary me-2 fs-20"></i>
                <h5 className="card-title mb-0">
                  Información de la Entrega del Delivery:
                </h5>
              </div>
            </CardHeader>

            <CardBody className="p-4">
              <DeliveryEntregaTable solicitud={solicitud} />
            </CardBody>
          </Card>
        </>
      ) : null}
    </React.Fragment>
  );
};

export default Delivery;
