import React from "react";
import { Container, Row, Col, Card, CardBody, Alert } from "reactstrap";
import Crear from "./Crear";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { DatosSesion } from "../../../Components/Common/DatosSesion";

const PlanillaEstrategicaCrear = () => {
  document.title = "Crear Planilla Estrategica | Farme";
  const perfil = DatosSesion("perfil");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {perfil === "Administrador" ||
          perfil === "Farmabien" ||
          perfil === "MyHealth" ? (
            <>
              <BreadCrumb
                title="Crear Planilla Estrategica"
                pageTitle="Listar Planilla Estrategica"
                url="/planilla-estrategica"
              />
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <Crear />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            <Alert color="danger">
              No tienes permisos para ver esta página.
            </Alert>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PlanillaEstrategicaCrear;
