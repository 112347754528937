import React, { useState, useEffect, useMemo } from "react";
import { Alert, Row, Col } from "reactstrap";
import { UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";
import TableContainer from "../../../Components/Common/TableContainer";
import { format, parse } from "date-fns";

// Función auxiliar para obtener el último delivery
const getLastDelivery = (solicitud) => {
  if (!solicitud.delivery || !Array.isArray(solicitud.delivery)) {
    return null;
  }
  return solicitud.delivery.length > 0
    ? solicitud.delivery[solicitud.delivery.length - 1]
    : null;
};

// Cambiamos la exportación a default
const Lista = ({
  solicitudes,
  error,
  errorMsg,
  colorMsg,
  setSolicitudesEntregar,
}) => {
  const [selectedSolicitudes, setSelectedSolicitudes] = useState({});

  const getSelectedSolicitudes = () => {
    return Object.entries(selectedSolicitudes)
      .filter(([_, isSelected]) => isSelected)
      .map(([id, _]) => id);
  };

  const handleSolicitudesEntregar = () => {
    try {
      const selectedIds = getSelectedSolicitudes();
      /* console.log("IDs seleccionados:", selectedIds); */

      const selectedSolicitudesDetails = solicitudes.filter((sol) =>
        selectedIds.includes(sol.id.toString())
      );

      /* console.log(
        "Solicitudes seleccionadas detalle completo:",
        JSON.stringify(selectedSolicitudesDetails, null, 2)
      ); */

      if (selectedSolicitudesDetails.length === 0) {
        console.warn("No hay solicitudes seleccionadas");
        return;
      }

      // Verificar que cada solicitud tenga los datos necesarios
      const solicitudesValidadas = selectedSolicitudesDetails.map(
        (solicitud) => {
          // Asegurarse de que la solicitud tenga un ID válido
          if (!solicitud.id) {
            console.error("Solicitud sin ID:", solicitud);
            throw new Error("Solicitud inválida: falta ID");
          }

          // Verificar otros campos necesarios
          if (!solicitud.identificador) {
            console.error("Solicitud sin identificador:", solicitud);
            throw new Error("Solicitud inválida: falta identificador");
          }

          return solicitud;
        }
      );

      // Enviar las solicitudes validadas al componente padre
      if (setSolicitudesEntregar) {
        //console.log("Enviando solicitudes validadas:", solicitudesValidadas);
        setSolicitudesEntregar(solicitudesValidadas);
      }
    } catch (error) {
      console.error("Error al procesar solicitudes:", error);
      // Aquí podrías mostrar un mensaje de error al usuario
    }
  };

  const columns = useMemo(
    () => [
      {
        id: "selection",
        header: ({ table }) => (
          <input
            type="checkbox"
            checked={table.getIsAllRowsSelected()}
            onChange={(e) => {
              table.toggleAllRowsSelected(e.target.checked);
              const newSelectedSolicitudes = {};
              table.getRowModel().rows.forEach((row) => {
                newSelectedSolicitudes[row.original.id] = e.target.checked;
              });
              setSelectedSolicitudes(newSelectedSolicitudes);
            }}
            title="Seleccionar todas las filas"
          />
        ),
        cell: ({ row }) => (
          <input
            type="checkbox"
            checked={row.getIsSelected()}
            onChange={(e) => {
              row.toggleSelected(e.target.checked);
              setSelectedSolicitudes((prev) => ({
                ...prev,
                [row.original.id]: e.target.checked,
              }));
              /* console.log(
                "Selección de fila:",
                row.original.id,
                e.target.checked
              ); */
            }}
          />
        ),
        enableColumnFilter: false,
        enableSorting: false,
      },

      {
        header: "Solicitud",
        accessorKey: "identificador",
        enableColumnFilter: false,
      },
      {
        header: "Recibe",
        accessorKey: "recibe",
        enableColumnFilter: false,
      },
      {
        header: "Teléfono",
        accessorKey: "telefono",
        enableColumnFilter: false,
      },
      {
        header: "Dirección",
        accessorKey: "direccion",
        enableColumnFilter: false,
        cell: ({ row }) => {
          const direccion = row.original.direccion;
          const coordenadas = row.original.coordenadas;

          if (Array.isArray(coordenadas) && coordenadas.length === 2) {
            const [lat, lng] = coordenadas;
            const mapsUrl = `https://www.google.com/maps?q=${lat},${lng}`;

            return (
              <a href={mapsUrl} target="_blank" rel="noopener noreferrer">
                {direccion}
              </a>
            );
          }
          return direccion;
        },
      },
      {
        header: "Distancia",
        accessorKey: "delivery",
        enableColumnFilter: false,
        cell: ({ row }) => {
          const delivery = row.original.delivery;
          if (Array.isArray(delivery) && delivery.length > 0) {
            // Mostrar la distancia del último delivery
            return delivery[delivery.length - 1].distancia;
          }
          return delivery?.distancia || "";
        },
      },
      {
        header: "Nombre",
        accessorKey: "delivery",
        enableColumnFilter: false,
        cell: ({ row }) => {
          const delivery = row.original.delivery;
          if (Array.isArray(delivery) && delivery.length > 0) {
            return delivery[delivery.length - 1].servicioEntrega?.nombre || "";
          }
          return delivery?.servicioEntrega?.nombre || "";
        },
      },

      {
        header: "Apellido",
        accessorKey: "delivery",
        enableColumnFilter: false,
        cell: ({ row }) => {
          const delivery = row.original.delivery;
          if (Array.isArray(delivery) && delivery.length > 0) {
            return (
              delivery[delivery.length - 1].servicioEntrega?.apellido || ""
            );
          }
          return delivery?.servicioEntrega?.apellido || "";
        },
      },
      {
        header: "Fecha y Hora",
        accessorKey: "delivery",
        enableColumnFilter: false,
        cell: ({ row }) => {
          const delivery = row.original.delivery;
          const fecha =
            Array.isArray(delivery) && delivery.length > 0
              ? delivery[delivery.length - 1].fecha
              : delivery?.fecha;

          if (!fecha) return "";

          try {
            
            const formatDate2 = (dateString) => {
              // Verificar el formato de la fecha
              const isZuluTime = dateString.endsWith("Z");
              const date = new Date(dateString);
          
              return date.toLocaleString("es-ES", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
                // Si es Zulu time (termina en Z), usar UTC, si no, usar America/Bogota
                timeZone: isZuluTime ? "UTC" : "America/Caracas",
              });
            };
            return (
              <div>
                {formatDate2(fecha)}

              </div>
            );
          } catch (error) {
            console.error("Error al formatear la fecha:", error);
            return fecha;
          }
        },
      },
      {
        header: "Acción",
        accessorKey: "accion",
        enableColumnFilter: false,
        cell: (cell) => {
          const rowId = cell.row.original.id;
          return (
            <div className="d-flex gap-3">
              <Link to={`/solicitud/ver/${rowId}/`} className="text-success">
                <i className="mdi mdi-pencil font-size-28" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Editar
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      {errorMsg && errorMsg ? <Alert color={colorMsg}> {error} </Alert> : null}

      <Row>
        <Col>
          <button
            id="solicitudesEntregadas"
            className="btn btn-success float-end mt-2 mb-3"
            onClick={handleSolicitudesEntregar}
            disabled={getSelectedSolicitudes().length === 0}
          >
            Solicitudes a Entregar ({getSelectedSolicitudes().length})
          </button>
        </Col>
      </Row>

      <TableContainer
        columns={columns}
        data={solicitudes || []}
        isGlobalFilter={false}
        customPageSize={1000}
        SearchPlaceholder="Buscar..."
        divClass="table-responsive"
        tableClass="align-middle table-nowrap"
        theadClass="table-light text-muted"
        enableRowSelection={true}
        enableMultiRowSelection={true}
        onRowSelectionChange={(updatedSelection) => {
          setSelectedSolicitudes(updatedSelection);
          /* console.log("Selección actualizada:", updatedSelection); */
        }}
        state={{ rowSelection: selectedSolicitudes }}
      />
    </React.Fragment>
  );
};

// Exportación por defecto
export default Lista;
