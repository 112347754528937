import React from 'react';

//import Scss
import './assets/scss/themes.scss';

//imoprt Route
import Route from './Routes';

// Importar componente de notificaciones de autenticación
import AuthNotifier from './Components/AuthNotifier';

function App() {
  return (
    <React.Fragment>
      <Route />
      {/* Componente global de notificaciones de autenticación */}
      <AuthNotifier />
    </React.Fragment>
  );
}

export default App;
