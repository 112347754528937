import React, { useMemo, useState, useEffect, useRef } from "react";
import { Row } from "reactstrap";
import {
  getSolicitudes,
  resetSolicitudFlag,
  getFarmacias,
} from "../../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { Link } from "react-router-dom";
import TableContainerNoPagination from "../../../Components/Common/TableContainerNoPagination";
import { PorcentajeAvance } from "./ColSolicitudes";
import { DatosSesion } from "../../../Components/Common/DatosSesion";

const ListaSolicitudes = (props) => {
  const dispatch = useDispatch();
  const selectLayoutState = (state) => state;

  // Estado para la farmacia actual y loading
  const [farmaciaActual, setFarmaciaActual] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [tipoSolicitud, setTipoSolicitud] = useState("");
  const [estatus, setEstatus] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  // Agregar estados para la paginación
  const [pageSize, setPageSize] = useState(20);
  const [pageIndex, setPageIndex] = useState(0);

  // Definir las opciones de los filtros
  const tiposSolicitud = [
    { value: "Aguda", label: "Aguda" },
    { value: "Cronica", label: "Crónica" },
  ];

  const estatusSolicitud = [
    { value: "Nueva Solicitud", label: "Nueva Solicitud" },
    { value: "Por Entregar", label: "Por Entregar" },
    { value: "En Transito", label: "En Tránsito" },
    { value: "Entregado", label: "Entregado" },
    { value: "Cancelada", label: "Cancelada" },
    { value: "Por Renovar", label: "Por Renovar" },
    { value: "Por Reprogramar", label: "Por Reprogramar" },
  ];

  const pageData = createSelector(selectLayoutState, (state) => ({
    solicitudes: state.Solicitudes.solicitudes,
    error: state.Solicitudes.error,
    loading: state.Solicitudes.loading,
    errorMsg: state.Solicitudes.errorMsg,
    colorMsg: state.Solicitudes.colorMsg,
    exito: state.Solicitudes.exito,
  }));

  const farmaciasPageData = createSelector(selectLayoutState, (state) => ({
    farmacias: state.Farmacias.farmacias,
    loadingFarmacias: state.Farmacias.loading,
  }));

  const { solicitudes, error, loading, errorMsg, colorMsg, exito } =
    useSelector(pageData);

  const { farmacias, loadingFarmacias } = useSelector(farmaciasPageData);

  // Obtener datos de sesión
  const perfil = DatosSesion("perfil");
  const farmaciaId = DatosSesion("farmacia");

  // Efecto para cargar las farmacias
  useEffect(() => {
    dispatch(getFarmacias());
  }, [dispatch]);

  // Efecto para establecer la farmacia actual cuando se tengan los datos
  useEffect(() => {
    if (farmaciaId && farmacias && farmacias[0]) {
      const farmaciaEncontrada = farmacias[0].find(
        (farmacia) => farmacia.id === farmaciaId
      );
      setFarmaciaActual(farmaciaEncontrada);
    }
  }, [farmacias, farmaciaId]);

  // Elimina searchQuery de las dependencias del useEffect
  useEffect(() => {
    //setIsLoading(true);

    let queryParams = [];

    // Si el usuario es Administrador o MyH+, obtener todas las solicitudes
    if (perfil === "Administrador" || perfil === "MyH+") {
      queryParams.push(`estatus=Nueva Solicitud`);
      const finalQuery = queryParams.join("&");
      dispatch(getSolicitudes(finalQuery)).finally(() => setIsLoading(false));
    }
    // Si es otro perfil y tiene farmacia actual, filtrar por código de proveedor
    else if (farmaciaActual) {
      queryParams.push(`estatus=Nueva Solicitud`);
      queryParams.push(`codProveedor=${farmaciaActual.codigo_myh}`);
      const finalQuery = queryParams.join("&");
      dispatch(getSolicitudes(finalQuery)).finally(() => setIsLoading(false));
    }
    // Si no cumple ninguna condición anterior, quitar el loading
    else {
      setIsLoading(false);
    }
  }, [dispatch, farmaciaActual, farmaciaId, perfil, tipoSolicitud, estatus]); // Removido searchQuery

  const columns = useMemo(
    () => [
      {
        header: "ID",
        cell: (cell) => {
          return <span className="fw-semibold">{cell.getValue()}</span>;
        },
        accessorKey: "identificador",
        enableColumnFilter: false,
      },
      /*       {
        header: "Detalle",
        accessorKey: "detalle",
        enableColumnFilter: false,
      }, */

      {
        header: "Tipo",
        accessorKey: "tipo",
        enableColumnFilter: false,
        cell: (cell) => {
          const tipo = cell.getValue();
          // Verificar si estatus es null o undefined
          if (!tipo) {
            return <span>-</span>;
          }
          
          let badgeClass = "";
          let colorClass = "";
      
          switch (tipo) {
            case "Aguda":
              badgeClass = "Aguda";
              colorClass = "bg-danger-subtle text-danger";
              break;
            case "Cronica":
              badgeClass = "Crónica";
              colorClass = "bg-success-subtle text-success";
              break;
            default:
              colorClass = "bg-info-subtle text-info";
          }
      
          return (
            <span className={`badge ${colorClass}`}>
              {badgeClass || tipo}
            </span>
          );
        },
      },

      {
        header: "Solicitante",
        accessorKey: "solicitante",
        enableColumnFilter: false,
      },
      {
        header: "Cédula",
        accessorKey: "solicitanteCedula",
        enableColumnFilter: false,
      },

      {
        header: "Fecha / Hora",
        accessorKey: "creado",
        enableColumnFilter: false,
        cell: (cell) => {
          const fecha = new Date(cell.getValue());
          return fecha.toLocaleString("es-ES", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          });
        },
      },
      {
        header: "Cod. Farmacia",
        accessorKey: "codigoProveedor",
        enableColumnFilter: false,
        cell: (cell) => {
          const codigoProveedor = cell.getValue();
          // Verificar si estatus es null o undefined
          if (!codigoProveedor) {
            return <span>-</span>;
          }
          
          let badgeClass = "";
          let colorClass = "";
      
          switch (codigoProveedor) {
            case "00077":
              badgeClass = "Caracas";
              colorClass = "bg-primary-subtle text-primary";
              break;
            default:
              badgeClass = "Lara";
              colorClass = "bg-warning-subtle text-warning";
          }
      
          return (
            <span className={`badge ${colorClass}`}>
              {codigoProveedor} - {badgeClass}
            </span>
          );
        },
      },

      {
        header: "Prioridad",
        accessorKey: "prioridad",
        enableColumnFilter: false,
        cell: (cell) => {
          const prioridad = cell.getValue();
          // Verificar si estatus es null o undefined
          if (!prioridad) {
            return <span>-</span>;
          }
          let badgeClass = "";

          switch (prioridad.toLowerCase()) {
            case "alta":
              badgeClass = "danger";
              break;
            case "media":
              badgeClass = "warning";
              break;
            case "baja":
              badgeClass = "success";
              break;
            default:
              badgeClass = "info";
          }

          return (
            <span
              className={`badge bg-${badgeClass}-subtle text-${badgeClass}`}
            >
              {prioridad}
            </span>
          );
        },
      },

      {
        header: "Estado",
        accessorKey: "estatus",
        enableColumnFilter: false,
        cell: (cell) => {
          const estatus = cell.getValue();
          // Verificar si estatus es null o undefined
          if (!estatus) {
            return <span>-</span>;
          }
          let badgeClass = "";

          switch (estatus.toLowerCase()) {
            case "entregado":
              badgeClass = "success";
              break;
            case "pendiente":
              badgeClass = "warning";
              break;
            case "cancelado":
              badgeClass = "danger";
              break;
            default:
              badgeClass = "info";
          }

          return (
            <span
              className={`badge bg-${badgeClass}-subtle text-${badgeClass}`}
            >
              {estatus}
            </span>
          );
        },
      },
      {
        header: "Porcentaje de Avance",
        accessorKey: "porcentaje_avance",
        enableColumnFilter: false,
        cell: (cell) => {
          return <PorcentajeAvance {...cell} />;
        },
      },
      {
        header: "Acción",
        accessorKey: "accion",
        enableColumnFilter: false,
        cell: (cell) => {
          const rowId = cell.row.original.id;
          return (
            <div className="d-flex gap-3">
              <Link
                to={`/solicitud/ver/${rowId}`}
                className="text-primary d-inline-block"
              >
                <i className="ri-eye-fill fs-16"></i>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const resetFilters = () => {
    setSearchQuery("");
    setTipoSolicitud("");
    setEstatus("");
    setIsLoading(true);
    // Inicializar queryParams como un array vacío
    let queryParams = [];

    // Realizar la búsqueda inicial según el perfil
    if (perfil === "Administrador" || perfil === "MyH+") {
      queryParams.push(`estatus=Nueva Solicitud`);
      const finalQuery =
        queryParams.length > 0
          ? `${queryParams.join("&")}`
          : "";

      dispatch(getSolicitudes(finalQuery)).finally(() => setIsLoading(false));
    } else if (farmaciaActual) {
      queryParams.push(`estatus=Nueva Solicitud`);
      queryParams.push(`codProveedor=${farmaciaActual.codigo_myh}`);
      const finalQuery = queryParams.join("&");
      dispatch(getSolicitudes(finalQuery)).finally(() => setIsLoading(false));
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
  };

  // Y en el input:
  // En el componente TableContainer, necesitaremos pasar los controles de filtro como children
  return (
    <React.Fragment>
      {isLoading ? (
        <div className="text-center">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Cargando...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="card">
            <div className="card-body">
              <div className="row g-3 align-items-center mb-4">
                <div className="col-auto">
                  <div className="search-box">
                    <input
                      id="buscar"
                      type="text"
                      className="form-control search"
                      placeholder="Buscar ..."
                      value={searchQuery}
                      onChange={handleSearchChange}
                      autoComplete="off"
                    />

                    <i
                      className="ri-search-line search-icon"
                      style={{ pointerEvents: "none" }}
                    ></i>
                  </div>
                </div>
                <div className="col-auto">
                  <select
                    className="form-select"
                    value={tipoSolicitud}
                    onChange={(e) => setTipoSolicitud(e.target.value)}
                    style={{ minWidth: "250px" }}
                  >
                    <option value="">Tipo de Solicitud</option>
                    {tiposSolicitud.map((tipo) => (
                      <option key={tipo.value} value={tipo.value}>
                        {tipo.label}
                      </option>
                    ))}
                  </select>
                </div>
                {/* <div className="col-auto">
                  <select
                    className="form-select"
                    value={estatus}
                    onChange={(e) => setEstatus(e.target.value)}
                    style={{ minWidth: "250px" }}
                  >
                    <option value="">Estatus</option>
                    {estatusSolicitud.map((status) => (
                      <option key={status.value} value={status.value}>
                        {status.label}
                      </option>
                    ))}
                  </select>
                </div> */}
                <div className="col-auto">
                  <button
                    className="btn btn-primary me-2"
                    onClick={() => {
                      // Aquí implementaremos la lógica de filtrado
                      setIsLoading(true);
                      let queryParams = [];

                      // Agregar el parámetro de búsqueda si existe
                      if (searchQuery.trim()) {
                        queryParams.push(
                          `query=${encodeURIComponent(searchQuery.trim())}`
                        );
                      }

                      if (estatus) {
                        queryParams.push(
                          `estatus=${encodeURIComponent(estatus)}`
                        );
                      }

                      if (tipoSolicitud) {
                        queryParams.push(
                          `tipo=${encodeURIComponent(tipoSolicitud)}`
                        );
                      }

                      if (perfil === "Administrador" || perfil === "MyH+") {
                        queryParams.push(`estatus=Nueva Solicitud`);
                        const finalQuery =
                          queryParams.length > 0
                            ? `${queryParams.join("&")}`
                            : "";

                        dispatch(getSolicitudes(finalQuery)).finally(() =>
                          setIsLoading(false)
                        );
                      } else if (farmaciaActual) {
                        queryParams.push(
                          `codProveedor=${farmaciaActual.codigo_myh}`
                        );
                        queryParams.push(`estatus=Nueva Solicitud`);

                        const finalQuery = queryParams.join("&");
                        dispatch(getSolicitudes(finalQuery)).finally(() =>
                          setIsLoading(false)
                        );
                      }
                    }}
                  >
                    <i className="ri-filter-line align-bottom me-1"></i> Filtrar
                  </button>
                  <button
                    className={`btn btn-secondary ${
                      searchQuery || tipoSolicitud || estatus
                        ? "btn-warning"
                        : "btn-secondary"
                    }`}
                    onClick={resetFilters}
                  >
                    <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                    Reiniciar
                  </button>
                </div>
              </div>

              {/* Selector de registros por página */}
              <div className="d-flex align-items-center mb-3">
                <div className="me-2">Registros por página:</div>
                <select 
                  className="form-select form-select-sm" 
                  style={{ width: "70px" }}
                  value={pageSize}
                  onChange={(e) => {
                    const newSize = Number(e.target.value);
                    setPageSize(newSize);
                    setPageIndex(0);
                    const queryParams = [];
                    // Mantener los filtros existentes
                    if (estatus) queryParams.push(`estatus=${encodeURIComponent(estatus)}`);
                    // Usar farmaciaActual en lugar de codProveedor
                    if (farmaciaActual && farmaciaActual.codigo_myh) queryParams.push(`codProveedor=${encodeURIComponent(farmaciaActual.codigo_myh)}`);
                    if (tipoSolicitud) queryParams.push(`tipo=${encodeURIComponent(tipoSolicitud)}`);
                    if (searchQuery.trim()) queryParams.push(`query=${encodeURIComponent(searchQuery.trim())}`);
                    // Añadir parámetros de paginación
                    queryParams.push(`limit=${newSize}`);
                    queryParams.push(`offset=0`);
                    const finalQuery = queryParams.join("&");
                    //console.log("Cambiando tamaño de página:", finalQuery);
                    setIsLoading(true);
                    dispatch(getSolicitudes(finalQuery)).finally(() => setIsLoading(false));
                  }}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>

              {/* Usamos el nuevo componente TableContainerNoPagination que no incluye paginador */}
              <TableContainerNoPagination
                columns={columns || []}
                data={Array.isArray(solicitudes[0]) ? solicitudes[0] : []}
                isGlobalFilter={false}
                customPageSize={pageSize}
                divClass="table-responsive"
              />

              {/* Paginador personalizado */}
              <div className="d-flex justify-content-between align-items-center mt-3">
                <div>
                  Mostrando {pageIndex * pageSize + 1} a {Math.min((pageIndex + 1) * pageSize, solicitudes[1] || 0)} de {solicitudes[1] || 0} registros
                </div>
                <div>
                  <ul className="pagination mb-0">
                    <li className={`page-item ${pageIndex === 0 ? 'disabled' : ''}`}>
                      <button 
                        className="page-link" 
                        onClick={() => {
                          if (pageIndex > 0) {
                            setPageIndex(0);
                            const queryParams = [];
                            if (estatus) queryParams.push(`estatus=${encodeURIComponent(estatus)}`);
                            if (farmaciaActual && farmaciaActual.codigo_myh) queryParams.push(`codProveedor=${encodeURIComponent(farmaciaActual.codigo_myh)}`);
                            if (tipoSolicitud) queryParams.push(`tipo=${encodeURIComponent(tipoSolicitud)}`);
                            if (searchQuery.trim()) queryParams.push(`query=${encodeURIComponent(searchQuery.trim())}`);
                            queryParams.push(`limit=${pageSize}`);
                            queryParams.push(`offset=0`);
                            const finalQuery = queryParams.join("&");
                            //console.log("Cambiando a primera página:", finalQuery);
                            setIsLoading(true);
                            dispatch(getSolicitudes(finalQuery)).finally(() => setIsLoading(false));
                          }
                        }}
                      >
                        &laquo;
                      </button>
                    </li>
                    <li className={`page-item ${pageIndex === 0 ? 'disabled' : ''}`}>
                      <button 
                        className="page-link" 
                        onClick={() => {
                          if (pageIndex > 0) {
                            const newPage = pageIndex - 1;
                            setPageIndex(newPage);
                            const queryParams = [];
                            if (estatus) queryParams.push(`estatus=${encodeURIComponent(estatus)}`);
                            if (farmaciaActual && farmaciaActual.codigo_myh) queryParams.push(`codProveedor=${encodeURIComponent(farmaciaActual.codigo_myh)}`);
                            if (tipoSolicitud) queryParams.push(`tipo=${encodeURIComponent(tipoSolicitud)}`);
                            if (searchQuery.trim()) queryParams.push(`query=${encodeURIComponent(searchQuery.trim())}`);
                            queryParams.push(`limit=${pageSize}`);
                            queryParams.push(`offset=${newPage * pageSize}`);
                            const finalQuery = queryParams.join("&");
                            //console.log("Cambiando a página anterior:", finalQuery);
                            setIsLoading(true);
                            dispatch(getSolicitudes(finalQuery)).finally(() => setIsLoading(false));
                          }
                        }}
                      >
                        &lt;
                      </button>
                    </li>
                    
                    {/* Generar botones de página limitados */}
                    {(() => {
                      const maxPages = Math.ceil((solicitudes[1] || 0) / pageSize);
                      const maxPagesToShow = 5;
                      let startPage = Math.max(0, pageIndex - Math.floor(maxPagesToShow / 2));
                      let endPage = Math.min(maxPages - 1, startPage + maxPagesToShow - 1);
                      
                      if (endPage - startPage + 1 < maxPagesToShow) {
                        startPage = Math.max(0, endPage - maxPagesToShow + 1);
                      }
                      
                      const pages = [];
                      for (let i = startPage; i <= endPage; i++) {
                        pages.push(
                          <li key={i} className={`page-item ${pageIndex === i ? 'active' : ''}`}>
                            <button 
                              className="page-link" 
                              onClick={() => {
                                setPageIndex(i);
                                const queryParams = [];
                                if (estatus) queryParams.push(`estatus=${encodeURIComponent(estatus)}`);
                                if (farmaciaActual && farmaciaActual.codigo_myh) queryParams.push(`codProveedor=${encodeURIComponent(farmaciaActual.codigo_myh)}`);
                                if (tipoSolicitud) queryParams.push(`tipo=${encodeURIComponent(tipoSolicitud)}`);
                                if (searchQuery.trim()) queryParams.push(`query=${encodeURIComponent(searchQuery.trim())}`);
                                queryParams.push(`limit=${pageSize}`);
                                queryParams.push(`offset=${i * pageSize}`);
                                const finalQuery = queryParams.join("&");
                                //("Cambiando a página:", i+1, "Query:", finalQuery);
                                setIsLoading(true);
                                dispatch(getSolicitudes(finalQuery)).finally(() => setIsLoading(false));
                              }}
                            >
                              {i + 1}
                            </button>
                          </li>
                        );
                      }
                      return pages;
                    })()}
                    
                    <li className={`page-item ${pageIndex === Math.ceil((solicitudes[1] || 0) / pageSize) - 1 ? 'disabled' : ''}`}>
                      <button 
                        className="page-link" 
                        onClick={() => {
                          if (pageIndex < Math.ceil((solicitudes[1] || 0) / pageSize) - 1) {
                            const newPage = pageIndex + 1;
                            setPageIndex(newPage);
                            const queryParams = [];
                            if (estatus) queryParams.push(`estatus=${encodeURIComponent(estatus)}`);
                            if (farmaciaActual && farmaciaActual.codigo_myh) queryParams.push(`codProveedor=${encodeURIComponent(farmaciaActual.codigo_myh)}`);
                            if (tipoSolicitud) queryParams.push(`tipo=${encodeURIComponent(tipoSolicitud)}`);
                            if (searchQuery.trim()) queryParams.push(`query=${encodeURIComponent(searchQuery.trim())}`);
                            queryParams.push(`limit=${pageSize}`);
                            queryParams.push(`offset=${newPage * pageSize}`);
                            const finalQuery = queryParams.join("&");
                            //console.log("Cambiando a página siguiente:", finalQuery);
                            setIsLoading(true);
                            dispatch(getSolicitudes(finalQuery)).finally(() => setIsLoading(false));
                          }
                        }}
                      >
                        &gt;
                      </button>
                    </li>
                    <li className={`page-item ${pageIndex === Math.ceil((solicitudes[1] || 0) / pageSize) - 1 ? 'disabled' : ''}`}>
                      <button 
                        className="page-link" 
                        onClick={() => {
                          if (pageIndex < Math.ceil((solicitudes[1] || 0) / pageSize) - 1) {
                            const newPage = Math.ceil((solicitudes[1] || 0) / pageSize) - 1;
                            setPageIndex(newPage);
                            const queryParams = [];
                            if (estatus) queryParams.push(`estatus=${encodeURIComponent(estatus)}`);
                            if (farmaciaActual && farmaciaActual.codigo_myh) queryParams.push(`codProveedor=${encodeURIComponent(farmaciaActual.codigo_myh)}`);
                            if (tipoSolicitud) queryParams.push(`tipo=${encodeURIComponent(tipoSolicitud)}`);
                            if (searchQuery.trim()) queryParams.push(`query=${encodeURIComponent(searchQuery.trim())}`);
                            queryParams.push(`limit=${pageSize}`);
                            queryParams.push(`offset=${newPage * pageSize}`);
                            const finalQuery = queryParams.join("&");
                            //console.log("Cambiando a última página:", finalQuery);
                            setIsLoading(true);
                            dispatch(getSolicitudes(finalQuery)).finally(() => setIsLoading(false));
                          }
                        }}
                      >
                        &raquo;
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export { ListaSolicitudes };
