/**
 * Servicio para manejar el almacenamiento seguro de tokens
 * Proporciona una capa de abstracción para almacenar tokens en cookies o localStorage
 */

// Implementación de fallback para js-cookie
const CookieService = {
  get: (key) => {
    try {
      return localStorage.getItem(key);
    } catch (e) {
      console.error('Error al obtener de localStorage:', e);
      return null;
    }
  },
  set: (key, value, options) => {
    try {
      localStorage.setItem(key, value);
      return true;
    } catch (e) {
      console.error('Error al guardar en localStorage:', e);
      return false;
    }
  },
  remove: (key) => {
    try {
      localStorage.removeItem(key);
      return true;
    } catch (e) {
      console.error('Error al eliminar de localStorage:', e);
      return false;
    }
  }
};

// Nombres de las claves de almacenamiento
const STORAGE_KEYS = {
  ACCESS_TOKEN: 'auth_token',
  REFRESH_TOKEN: 'refresh_token',
  TOKEN_EXPIRY: 'token_expiry',
  AUTH_USER: 'authUser'
};

// Verificar si estamos en producción
const isProduction = process.env.NODE_ENV === 'production';

/**
 * Almacena un token en el medio de almacenamiento adecuado
 * @param {string} key - Clave de almacenamiento
 * @param {string} value - Valor a almacenar
 * @param {number} expiresInDays - Días hasta la expiración (solo para cookies)
 */
export const setToken = (key, value, expiresInDays = 1) => {
  CookieService.set(key, value);
};

/**
 * Obtiene un token del medio de almacenamiento adecuado
 * @param {string} key - Clave de almacenamiento
 * @returns {string|null} - Valor almacenado o null si no existe
 */
export const getToken = (key) => {
  return CookieService.get(key) || null;
};

/**
 * Elimina un token del medio de almacenamiento adecuado
 * @param {string} key - Clave de almacenamiento
 */
export const removeToken = (key) => {
  CookieService.remove(key);
};

/**
 * Almacena el token de acceso
 * @param {string} token - Token de acceso
 */
export const setAccessToken = (token) => {
  setToken(STORAGE_KEYS.ACCESS_TOKEN, token);
};

/**
 * Obtiene el token de acceso
 * @returns {string|null} - Token de acceso o null si no existe
 */
export const getAccessToken = () => {
  return getToken(STORAGE_KEYS.ACCESS_TOKEN);
};

/**
 * Almacena el token de refresco
 * @param {string} token - Token de refresco
 */
export const setRefreshToken = (token) => {
  setToken(STORAGE_KEYS.REFRESH_TOKEN, token);
};

/**
 * Obtiene el token de refresco
 * @returns {string|null} - Token de refresco o null si no existe
 */
export const getRefreshToken = () => {
  return getToken(STORAGE_KEYS.REFRESH_TOKEN);
};

/**
 * Almacena la fecha de expiración del token
 * @param {number} expiryTime - Timestamp de expiración
 */
export const setTokenExpiry = (expiryTime) => {
  setToken(STORAGE_KEYS.TOKEN_EXPIRY, expiryTime.toString());
};

/**
 * Obtiene la fecha de expiración del token
 * @returns {number|null} - Timestamp de expiración o null si no existe
 */
export const getTokenExpiry = () => {
  const expiry = getToken(STORAGE_KEYS.TOKEN_EXPIRY);
  return expiry ? parseInt(expiry) : null;
};

/**
 * Almacena la información del usuario autenticado
 * @param {Object} user - Información del usuario
 */
export const setAuthUser = (user) => {
  setToken(STORAGE_KEYS.AUTH_USER, JSON.stringify(user));
};

/**
 * Obtiene la información del usuario autenticado
 * @returns {Object|null} - Información del usuario o null si no existe
 */
export const getAuthUser = () => {
  const user = getToken(STORAGE_KEYS.AUTH_USER);
  try {
    return user ? JSON.parse(user) : null;
  } catch (error) {
    console.error('Error al parsear authUser:', error);
    return null;
  }
};

/**
 * Elimina todos los tokens de autenticación
 */
export const clearAuthTokens = () => {
  removeToken(STORAGE_KEYS.ACCESS_TOKEN);
  removeToken(STORAGE_KEYS.REFRESH_TOKEN);
  removeToken(STORAGE_KEYS.TOKEN_EXPIRY);
  // No eliminamos AUTH_USER para mantener la información del usuario
};

/**
 * Verifica si el token está por expirar
 * @param {number} thresholdMinutes - Minutos antes de la expiración para considerar que está por expirar
 * @returns {boolean} - true si el token está por expirar, false en caso contrario
 */
export const isTokenExpiring = (thresholdMinutes = 5) => {
  const expiry = getTokenExpiry();
  if (!expiry) return false;
  
  const now = new Date().getTime();
  const thresholdMs = thresholdMinutes * 60 * 1000;
  
  return now + thresholdMs >= expiry;
};

export default {
  setAccessToken,
  getAccessToken,
  setRefreshToken,
  getRefreshToken,
  setTokenExpiry,
  getTokenExpiry,
  setAuthUser,
  getAuthUser,
  clearAuthTokens,
  isTokenExpiring,
  STORAGE_KEYS
};
