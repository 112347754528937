import React, { useEffect, useState, useCallback } from "react";
import { Card, CardBody, Row, Col, Collapse, Button, Spinner, Progress } from "reactstrap";

/**
 * Componente para mostrar estadísticas de ruta
 * @param {Object} props - Propiedades del componente
 * @returns {JSX.Element} - Componente de estadísticas de ruta
 */
const RouteStats = ({ stats, loading = false }) => {
  // Estado local para manejar las estadísticas
  const [localStats, setLocalStats] = useState({
    total: 0,
    entregadas: 0,
    canceladas: 0,
    pendientes: 0,
    distancia_total: 0,
    tiempo_estimado: 0,
    procesadas: 0 // Nuevo campo para el total de solicitudes procesadas
  });


  //console.log("stats", stats);
  

  // Actualizar estadísticas locales cuando cambian las props
  useEffect(() => {
    if (stats) {
      // Crear una copia profunda para evitar mutaciones
      const newStats = JSON.parse(JSON.stringify(stats));
      
      // Asegurarse de que todos los valores numéricos sean válidos
      const safeStats = {
        total: Number(newStats.total) || 0,
        entregadas: Number(newStats.entregadas) || 0,
        canceladas: Number(newStats.canceladas) || 0,
        pendientes: Number(newStats.pendientes) || 0,
        distancia_total: Number(newStats.distancia_total) || 0,
        tiempo_estimado: Number(newStats.tiempo_estimado) || 0,
        procesadas: Number(newStats.procesadas) || 0 // Asegurar que procesadas sea un número válido
      };
      
      // Verificar que las estadísticas son consistentes
      if (safeStats.total > 0) {
        // Si el total no coincide con la suma de las partes, recalcular pendientes
        const totalCalculado = safeStats.entregadas + safeStats.canceladas + safeStats.pendientes;
        if (totalCalculado !== safeStats.total) {
          safeStats.pendientes = Math.max(0, safeStats.total - safeStats.entregadas - safeStats.canceladas);
        }
        
        // Si no tenemos procesadas, calcularlas
        if (safeStats.procesadas === 0) {
          safeStats.procesadas = safeStats.entregadas + safeStats.canceladas;
        }
      }
      
      // Forzar la actualización de las estadísticas locales
      setLocalStats(safeStats);
    }
  }, [stats]);
  
  // Calcular el progreso de la ruta de manera memoizada
  const calcularProgreso = useCallback(() => {
    const { total, entregadas, canceladas } = localStats;
    
    // Si no hay solicitudes, el progreso es 0%
    if (!total || total === 0) return 0;
    
    // Calcular el progreso como porcentaje de solicitudes completadas (entregadas o canceladas)
    return Math.round(((entregadas + canceladas) / total) * 100);
  }, [localStats]);

  // Formatear tiempo (convertir segundos a formato horas:minutos)
  const formatearTiempo = useCallback((segundos) => {
    if (!segundos || segundos === 0) return "0h 0m";
    
    // Convertir segundos a minutos
    const minutos = Math.round(Number(segundos) / 60);
    
    // Calcular horas y minutos
    const horas = Math.floor(minutos / 60);
    const minutosRestantes = Math.round(minutos % 60);
    
    return `${horas}h ${minutosRestantes}m`;
  }, []);

  // Calcular el color del progreso
  const colorProgreso = useCallback(() => {
    const progreso = calcularProgreso();
    
    if (progreso < 30) return "danger";
    if (progreso < 70) return "warning";
    return "success";
  }, [calcularProgreso]);

  // Valor de progreso calculado
  const progresoActual = calcularProgreso();

  return (
    <Card className="mb-4 border-0 shadow-sm">
      <CardBody>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h5 className="mb-0">Estadísticas de Ruta</h5>
          {loading ? (
            <Spinner size="sm" color="primary" />
          ) : (
            <>
             {/* <span className="badge bg-primary-subtle text-primary">
              {progresoActual}% Completado
            </span> */}
            {""}
            
            </>
           
          )}
        </div>

        {/* Barra de progreso */}
        <div className="mb-4">
          {/* <Progress
            value={progresoActual}
            color={colorProgreso()}
            className="progress-lg"
            animated={loading}
          /> */}
        </div>

        {/* Estadísticas en tarjetas */}
        <Row>
          {/* <Col xs="6" md="3" className="mb-3">
            <div className="border rounded p-3 text-center">
              <h3 className="mb-1">{localStats.total}</h3>
              <p className="text-muted mb-0">Total Paradas</p>
            </div>
          </Col> */}
          <Col xs="6" md="6" className="mb-3">
            <div className="border rounded p-3 text-center bg-soft-success">
              <h3 className="mb-1">{localStats.entregadas}</h3>
              <p className="text-muted mb-0">Entregadas</p>
            </div>
          </Col>
          <Col xs="6" md="6" className="mb-3">
            <div className="border rounded p-3 text-center bg-soft-danger">
              <h3 className="mb-1">{localStats.canceladas}</h3>
              <p className="text-muted mb-0">Canceladas</p>
            </div>
          </Col>
         {/*  <Col xs="6" md="3" className="mb-3">
            <div className="border rounded p-3 text-center bg-soft-warning">
              <h3 className="mb-1">{localStats.pendientes}</h3>
              <p className="text-muted mb-0">Pendientes</p>
            </div>
          </Col> */}
        </Row>

        {/* Información adicional */}
        <Row className="mt-2">
          <Col xs="6">
            <div className="d-flex align-items-center">
              <div className="avatar-sm me-3">
                <div className="avatar-title bg-light rounded-circle">
                  <i className="mdi mdi-map-marker-distance fs-20 text-primary"></i>
                </div>
              </div>
              <div>
                <p className="text-muted mb-0">Distancia Total</p>
                <h5 className="mb-0">{Number(localStats.distancia_total).toFixed(1)} km</h5>
              </div>
            </div>
          </Col>
          <Col xs="6">
            <div className="d-flex align-items-center">
              <div className="avatar-sm me-3">
                <div className="avatar-title bg-light rounded-circle">
                  <i className="mdi mdi-clock-outline fs-20 text-primary"></i>
                </div>
              </div>
              <div>
                <p className="text-muted mb-0">Tiempo Estimado</p>
                <h5 className="mb-0">{formatearTiempo(localStats.tiempo_estimado)}</h5>
              </div>
            </div>
          </Col>
        </Row>

        {/* Información de procesadas */}
       {/*  <Row className="mt-3">
          <Col xs="12">
            <div className="border rounded p-3 text-center bg-soft-info">
              <h5 className="mb-0">Total entregas procesadas: <span className="fw-bold">{localStats.procesadas}</span></h5>
            </div>
          </Col>
        </Row> */}
      </CardBody>
    </Card>
  );
};

export default RouteStats;