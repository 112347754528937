import { createAsyncThunk } from "@reduxjs/toolkit";

//Include Both Helper File with needed methods
import {
  postJwtLogin,
  postJwtForgetPwd,
  postJwtLogout,
} from "../../../helpers/fakebackend_helper";

import {
  loginSuccess,
  logoutUserSuccess,
  logoutUserRequest,
  logoutUserFail,
  apiError,
  reset_login_flag,
  refreshTokenSuccess,
} from "./reducer";

import { refreshAuthToken } from "../../../helpers/api_helper";
import AuthNotifications from "../../../helpers/auth_notifications";
import SecureStorage from "../../../helpers/secure_storage";

export const loginUser = createAsyncThunk(
  "auth/login",
  async (user, { dispatch, rejectWithValue }) => {
    try {
      const response = await postJwtLogin({
        username: user.email,
        password: user.password,
      });
      
      dispatch(loginSuccess(response));
      
      // Redirigir a la página de inicio
      window.location.href = "/dashboard";
      
      return response;
    } catch (error) {
      const errorMessage = error.message || "Error de inicio de sesión";
      dispatch(apiError(errorMessage));
      return rejectWithValue(errorMessage);
    }
  }
);

export const logoutUser = () => async (dispatch) => {
  try {
    dispatch(logoutUserRequest());
    
    try {
      // Intentar hacer logout en el servidor
      const response = await postJwtLogout();
      
      // Si llegamos aquí, el logout fue exitoso o se hizo un logout local
      if (response && response.localOnly) {       
        AuthNotifications.notify(
          AuthNotifications.EVENTS.LOGOUT_SUCCESS,
          AuthNotifications.TYPES.WARNING,
          response.message
        );
      } else {        
        AuthNotifications.notify(
          AuthNotifications.EVENTS.LOGOUT_SUCCESS,
          AuthNotifications.TYPES.SUCCESS,
          "Logout exitoso en el servidor"
        );
      }
    } catch (error) {
      console.error("Error al hacer logout en el servidor:", error);
      
      // Aún así, hacemos logout local
      SecureStorage.clearAuthTokens();
      
      AuthNotifications.notify(
        AuthNotifications.EVENTS.LOGOUT_FAILED,
        AuthNotifications.TYPES.ERROR,
        "Error al hacer logout en el servidor, pero se realizó logout local"
      );
    }
    
    dispatch(logoutUserSuccess());
    
    // Redirigir al login
    window.location.href = "/login";
  } catch (error) {
    const errorMessage = error.message || "Error al cerrar sesión";
    dispatch(logoutUserFail(errorMessage));
  }
};

export const resetLoginFlag = () => async (dispatch) => {
  dispatch(reset_login_flag());
};

export const forgetPassword = createAsyncThunk(
  "auth/forgotpwd",
  async (user, { dispatch, rejectWithValue }) => {
    try {
      const response = await postJwtForgetPwd(user);
      return response;
    } catch (error) {
      const errorMessage = error.message || "Error al recuperar contraseña";
      dispatch(apiError(errorMessage));
      return rejectWithValue(errorMessage);
    }
  }
);

export const refreshToken = createAsyncThunk(
  "auth/refreshtoken",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const result = await refreshAuthToken();
      
      if (result.success) {
        dispatch(refreshTokenSuccess());
        return result;
      } else {
        throw new Error(result.message || "Error al refrescar el token");
      }
    } catch (error) {
      const errorMessage = error.message || "Error al refrescar el token";
      dispatch(apiError(errorMessage));
      return rejectWithValue(errorMessage);
    }
  }
);

export const getProfile = createAsyncThunk(
  "auth/profile",
  async (_, { rejectWithValue }) => {
    try {
      const user = SecureStorage.getAuthUser();
      if (!user) {
        throw new Error("No hay información de usuario disponible");
      }
      return user;
    } catch (error) {
      const errorMessage = error.message || "Error al obtener perfil";
      return rejectWithValue(errorMessage);
    }
  }
);
