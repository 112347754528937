import React, { useEffect, useState } from "react";
import { createFarmacia, resetFarmaciasFlag } from "../../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { Col, Row, Input, Label, Form, Alert, Spinner } from "reactstrap";
import Select from "react-select";
import { Navigate } from "react-router-dom";

const CrearFarmacia = (props) => {
  const dispatch = useDispatch();

  const selectLayoutState = (state) => state;

  const pageData = createSelector(selectLayoutState, (state) => ({
    farmacia: state.Farmacias.farmacia,
    error: state.Farmacias.error,
    loading: state.Farmacias.loading,
    errorMsg: state.Farmacias.errorMsg,
    colorMsg: state.Farmacias.colorMsg,
    exito: state.Farmacias.exito,
  }));

  // Inside your component
  const { farmacia, error, loading, errorMsg, colorMsg, exito } =
    useSelector(pageData);

  const [nombre, setNombre] = useState(null);
  const [codigo_farmabien, setCodigoFarmabien] = useState(null);
  const [codigo_myh, setCodigoMyh] = useState(null);
  const [direccion, setDireccion] = useState(null);
  const [coordenadas, setCoordenadas] = useState(null);


  const [redireccionar, setRedireccionar] = useState(false);
  const [loadingx, setLoadingx] = useState(false);

  useEffect(() => {
    if (errorMsg) {
      setTimeout(() => {
        dispatch(resetFarmaciasFlag());
        setLoadingx(false);
      }, 2000);
    }
  }, [dispatch, errorMsg]);

  useEffect(() => {
    if (exito) {
      setTimeout(() => {
        setRedireccionar(true);
      }, 2000);
    }
  }, [exito]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const obj = {
      nombre: nombre.trim(),
      codigo_farmabien: codigo_farmabien.trim(),
      codigo_myh: codigo_myh.trim(),
      direccion: direccion.trim(),
      coordenadas: coordenadas.trim(),
    };

    try {
      const response = await dispatch(createFarmacia(obj));
      if (response.error) {
        throw new Error(response.error.message);
      }
      setLoadingx(true);
    } catch (error) {
      console.error("Error al crear una Farmacia:", error);
      // Aquí puedes manejar el error, por ejemplo, mostrando un mensaje al usuario
    }
  };

  return (
    <>
      {redireccionar && <Navigate to="/farmacias" replace />}

      <React.Fragment>
        {errorMsg && errorMsg ? (
          <Alert color={colorMsg}> {error} </Alert>
        ) : null}

        <Form onSubmit={handleSubmit}>
          <Row lg={12} className="p-3">
            <Col xxl={6} md={6}>
              <div>
                <Label className="form-label" htmlFor="nombre">
                  Nombre <span className="text-danger">*</span>
                </Label>
              </div>
              <Input
                required
                minLength={3}
                maxLength={50}
                type="text"
                className="form-control"
                id="nombre"
                name="nombre"
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
              />
            </Col>
            <Col xxl={6} md={6}>
              <div>
                <Label className="form-label" htmlFor="codigo_farmabien">
                Codigo Farmabien <span className="text-danger">*</span>
                </Label>
              </div>
              <Input
                required
                minLength={3}
                maxLength={50}
                type="text"
                className="form-control"
                id="codigo_farmabien"
                name="codigo_farmabien"
                value={codigo_farmabien}
                onChange={(e) => setCodigoFarmabien(e.target.value)}
              />
            </Col>
            <Col xxl={6} md={6}>
              <div>
                <Label className="form-label" htmlFor="codigo_myh">
                Codigo MyH+ <span className="text-danger">*</span>
                </Label>
              </div>
              <Input
                required
                minLength={3}
                maxLength={50}
                type="text"
                className="form-control"
                id="codigo_myh"
                name="codigo_myh"
                value={codigo_myh}
                onChange={(e) => setCodigoMyh(e.target.value)}
              />
            </Col>

            <Col xxl={6} md={6}>
              <div>
                <Label className="form-label" htmlFor="coordenadas">
                Coordenadas <span className="text-danger">*</span>
                </Label>
              </div>
              <Input
                required
                minLength={3}
                maxLength={50}
                type="text"
                className="form-control"
                id="coordenadas"
                name="coordenadas"
                value={coordenadas}
                onChange={(e) => setCoordenadas(e.target.value)}
              />
            </Col>
            <Col xxl={12} md={12}>
              <div className="mb-3">
                <Label className="form-label" htmlFor="direccion">
                Direccion <span className="text-danger">*</span>
                </Label>
                <Input
                  type="textarea"
                  required
                  className="form-control"
                  id="direccion"
                  name="direccion"
                  value={direccion}
                  onChange={(e) => setDireccion(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <Row className="align-items-end">
            <Col lg={12}>
              <div className="mt-4 mb-3 text-end">
                <button
                  type="submit"
                  className="btn btn-success btn-label right ms-auto nexttab nexttab"
                >
                  {loadingx ? (
                    <Spinner size="sm" className="me-2">
                      {" "}
                      Loading...{" "}
                    </Spinner>
                  ) : null}
                  <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                  Crear Farmacia
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    </>
  );
};

export default CrearFarmacia;
