import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardHeader,
  Alert,
} from "reactstrap";
import { ListaSolicitudes } from "./ListaSolicitudes";
import { DatosSesion } from "../../../Components/Common/DatosSesion";
import Widget from "./Widgets";

const ListView = () => {
  document.title = "Lista de Solicitudes | Farme";
  const perfil = DatosSesion("perfil");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* <Row>
            <Widget />
          </Row> */}

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Solicitudes</h5>
                </CardHeader>
                <CardBody>
                  {perfil === "Administrador" ||
                  perfil === "Farmabien" ||
                  perfil === "MyHealth" ? (
                    <ListaSolicitudes perfil={perfil} />
                  ) : (
                    <Alert color="danger">
                      No tienes permisos para ver esta página.
                    </Alert>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ListView;
