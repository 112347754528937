import React, { useEffect, useState } from "react";
import { createLab, resetLaboratorioFlag } from "../../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { Col, Row, Input, Label, Form, Alert, Spinner } from "reactstrap";
import Select from "react-select";
import { Navigate } from "react-router-dom";

const CrearLaboratorio = (props) => {
  const dispatch = useDispatch();
  const selectLayoutState = (state) => state;
  const laboratorioPageData = createSelector(selectLayoutState, (state) => ({
    laboratorio: state.Laboratorios.laboratorio,
    error: state.Laboratorios.error,
    loading: state.Laboratorios.loading,
    errorMsg: state.Laboratorios.errorMsg,
    colorMsg: state.Laboratorios.colorMsg,
    exito: state.Laboratorios.exito,
  }));
  const { laboratorio, exito, error, loading, errorMsg, colorMsg } =
    useSelector(laboratorioPageData);
  const [nombre, setNombre] = useState("");
  const [origen, setOrigen] = useState("");
  const [transnacional, setTransnacional] = useState(false);
  const [activo, setActivo] = useState(false);
  const [calidadFarmacologica, setCalidadFarmacologica] = useState("");
  const [calificacionImagen, setCalificacionImagen] = useState("");
  const [redireccionar, setRedireccionar] = useState(false);
  const [loadingx, setLoadingx] = useState(false);

  useEffect(() => {
    if (errorMsg) {
      setTimeout(() => {
        dispatch(resetLaboratorioFlag());
        setLoadingx(false);
      }, 2000);
    }
  }, [dispatch, errorMsg]);

  useEffect(() => {
    if (exito) {
      setTimeout(() => {
        setRedireccionar(true);
      }, 2000);
    }
  }, [exito]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const lab = {
      nombre: nombre.trim(),
      //origen: origen.trim(),
      //transnacional: transnacional,
      calidadFarmacologica: calidadFarmacologica,
      calificacionImagen: calificacionImagen,
      activo: activo,
      creado: null,
      actualizado: null,
    };

    try {
      dispatch(createLab(lab));
      setLoadingx(true);
    } catch (error) {
      console.error("Error al crear laboratorio:", error);
      // Maneja el error aquí
    }
  };

  const opcionesFarmacologica = ["A", "B", "C", "D"];
  const opcionesPresentacion = ["A", "B", "C"];

  return (
    <>
      {redireccionar && <Navigate to="/laboratorios" replace />}

      <React.Fragment>
        {errorMsg && errorMsg ? (
          <Alert color={colorMsg}> {error} </Alert>
        ) : null}
        <Form onSubmit={handleSubmit}>
          <Row lg={12} className="p-3">
            <Col xxl={6} md={6}>
              <div>
                <Label className="form-label" htmlFor="nombre">
                  Nombre <span className="text-danger">*</span>
                </Label>
              </div>
              <Input
                required
                minLength={3}
                maxLength={50}
                type="text"
                className="form-control"
                id="nombre"
                name="nombre"
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
              />
            </Col>
           
            <Col xxl={6} md={6}>
              <div className="mb-3">
                <Label className="form-label" htmlFor="activo">
                  Activo <span className="text-danger">*</span>
                </Label>
                <Select
                  onChange={(selectedOption) => {
                    setActivo(selectedOption.value === "true");
                  }}
                  options={[
                    { value: "", label: "Seleccione una opción" },
                    { value: "true", label: "Si" },
                    { value: "false", label: "No" },
                  ]}
                  required
                  id="activo"
                  className="js-example-basic-single mb-0"
                  name="activo"
                />
              </div>
            </Col>
            <Col xxl={6} md={6}>
              <div className="mb-3">
                <Label className="form-label" htmlFor="calidad-farmacologica">
                  Calidad Farmacológica <span className="text-danger">*</span>
                </Label>
                <Select
                  onChange={(selectedOption) => {
                    setCalidadFarmacologica(selectedOption.value);
                  }}
                  options={opcionesFarmacologica.map((item) => ({
                    value: item,
                    label: item,
                  }))}
                  required
                  id="farmacologica"
                  className="js-example-basic-single mb-0"
                  name="farmacologia"
                />
              </div>
            </Col>
            <Col xxl={6} md={6}>
              <div className="mb-3">
                <Label className="form-label" htmlFor="calidad-presentacion">
                  Calidad Presentación <span className="text-danger">*</span>
                </Label>
                <Select
                  onChange={(selectedOption) => {
                    setCalificacionImagen(selectedOption.value);
                  }}
                  options={opcionesPresentacion.map((item) => ({
                    value: item,
                    label: item,
                  }))}
                  required
                  id="choices-single-default"
                  className="js-example-basic-single mb-0"
                  name="presentacion"
                />
              </div>
            </Col>
          </Row>
          <Row className="align-items-end">
            <Col lg={12}>
              <div className="mt-4 mb-3 text-end">
                <button
                  type="submit"
                  className="btn btn-success btn-label right ms-auto nexttab nexttab"
                >
                  {loadingx ? (
                    <Spinner size="sm" className="me-2">
                      {" "}
                      Loading...{" "}
                    </Spinner>
                  ) : null}
                  <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                  Crear Laboratorio
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    </>
  );
};

export default CrearLaboratorio;
