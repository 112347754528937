import {
  getUsuarios,
  getUsuario,
  deleteUsuario,
  editUsuario,
  createUsuario,
  resetPassword,
} from "../../../helpers/backend_helper";
import {
  apiError,
  usuariosSuccess,
  usuarioSuccess,
  usuariosDeleteSuccess,
  reset_usuario_flag,
} from "./reducer";

export const cambiarPassword = (usuario) => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = resetPassword(usuario);
    }

    var data = await response;    

    if (data) {
      const serializableData = {
        data: data.data,
        message: data.message,
        status: data.status,
      };
      dispatch(usuarioSuccess(serializableData));
    }

  } catch (error) {
    dispatch(apiError(error.message));
  }
};

export const getUsers = () => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = getUsuarios();
    }

    var data = await response;

    if (data) {
      const serializableData = {
        data: data.data,
        message: data.message,
        status: data.status,
      };
      dispatch(usuariosSuccess(serializableData));
    }
  } catch (error) {
    dispatch(apiError(error.message));
  }
};

export const getUser = (id) => async (dispatch) => {
  try {
    

    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = getUsuario(id);
    }
    var data = await response;

    if (data) {
      const serializableData = {
        data: data.data,
        message: data.message,
        status: data.status,
      };
      dispatch(usuarioSuccess(serializableData)); 
    }
  } catch (error) {

    dispatch(apiError(error.message));
  }
};

export const deleteUser = (id) => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = deleteUsuario(id);
    }
    var data = await response;
    if (data) {
      const serializableData = {
        data: data.data,
        message: data.message,
        status: data.status,
      };
      dispatch(usuariosDeleteSuccess(serializableData));
    }
  } catch (error) {
    dispatch(apiError(error.message));
  }
};

export const editUser = (usuario) => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = editUsuario(usuario);
    }
    var data = await response;
    if (data) {
      const serializableData = {
        data: data.data,
        message: data.message,
        status: data.status,
      };
      dispatch(usuarioSuccess(serializableData));
    }
  } catch (error) {
    dispatch(apiError(error.message));
  }
};

export const createUser = (usuario) => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = createUsuario(usuario);
    }
    var data = await response;

    if (data) {
      const serializableData = {
        data: data.data,
        message: data.message,
        status: data.status,
      };
      dispatch(usuarioSuccess(serializableData));
    }
  } catch (error) {


    dispatch(apiError(error.message));
  }
};

export const resetUsuarioFlag = () => async (dispatch) => {
  try {
    const response = dispatch(reset_usuario_flag());
    return response;
  } catch (error) {
    dispatch(apiError(error.message));
  }
};
