import React, { useEffect } from "react";
import { getFarmacia, resetFarmaciasFlag } from "../../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { useParams } from "react-router-dom";

// Importaciones de reacciones
import {  Container,  Row,  Col,  Card,  CardBody,  CardHeader,  Alert, } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import EditarFarmacia from "./Editar";

const Editar = () => {
  //console.log("Editar Farmacia  rendered");
  // Obtiene el ID desde la URL
  const { id } = useParams();
  //console.log("id desde la URL:", id);

  const dispatch = useDispatch();

  useEffect(() => {
    //console.log("useEffect de Editar Farmacia se ejecut ");
    dispatch(getFarmacia(id));
  }, [dispatch, id]);

  const selectLayoutState = (state) => state;
  
  const pageData = createSelector(selectLayoutState, (state) => ({
    farmacia: state.Farmacias.farmacia,
    error: state.Farmacias.error,
    loading: state.Farmacias.loading,
    errorMsg: state.Farmacias.errorMsg,
    colorMsg: state.Farmacias.colorMsg,
    exito: state.Farmacias.exito,
  }));

  // Inside your component
  const { farmacia, error, loading, errorMsg, colorMsg, exito } =
    useSelector(pageData);



  useEffect(() => {
    
    if (errorMsg) {
      setTimeout(() => {
        dispatch(resetFarmaciasFlag());
      }, 3000);
    }
  }, [dispatch, errorMsg]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Editar Farmacia"
            pageTitle="Listar Farmacias"
            url="/farmacias"
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="border-0">

                  {errorMsg && errorMsg ? (
                    <Alert color={colorMsg}> {error} </Alert>
                  ) : null}
                  
                </CardHeader>
                <CardBody>
                  {exito && (
                    <EditarFarmacia farmacia={farmacia}  />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Editar;
