import React, { useMemo } from "react";
import { Alert } from "reactstrap";
//redux`
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import CountUp from "react-countup";
import { Card, CardBody, Col } from "reactstrap";

// Formatear números grandes
const formatearNumeroGrande = (numero) => {
  if (numero === null || numero === undefined) return 0;
  
  // Si el número es menor a 1000, lo devolvemos tal cual
  if (numero < 1000) {
    return numero;
  }
  // Si el número es entre 1,000 y 999,999, lo formateamos en K (miles)
  else if (numero < 1000000) {
    return (numero / 1000).toFixed(1) + "K";
  }
  // Si el número es entre 1,000,000 y 999,999,999, lo formateamos en M (millones)
  else if (numero < 1000000000) {
    return (numero / 1000000).toFixed(1) + "M";
  }
  // Si el número es mayor a 1,000,000,000, lo formateamos en B (billones)
  else {
    return (numero / 1000000000).toFixed(1) + "B";
  }
};


const Widgets = () => {
  const selectLayoutState = (state) => state;

  const pageData = createSelector(selectLayoutState, (state) => ({
    kpis: state.Dashboard.kpis,
    loading: state.Dashboard.loading,
    errorMsg: state.Dashboard.errorMsg,
    error: state.Dashboard.error,
    colorMsg: state.Dashboard.colorMsg,
    exito: state.Dashboard.exito,
  }));

  // Inside your component
  const { kpis, loading, errorMsg, error, colorMsg, exito } =
    useSelector(pageData);

 
  // Configuración de los widgets basados en los KPIs
  const widgetsConfig = [
    {
      id: 1,
      key: "total_cotizaciones",
      label: "Total Cotillones",
      badgeClass: (kpis?.total_cotizaciones?.tendencia === "up") ? "success" : 
                  (kpis?.total_cotizaciones?.tendencia === "down") ? "danger" : "muted",
      badge: (kpis?.total_cotizaciones?.tendencia === "up") ? "ri-arrow-right-up-line" : 
             (kpis?.total_cotizaciones?.tendencia === "down") ? "ri-arrow-right-down-line" : "",
      percentage: kpis?.total_cotizaciones?.porcentaje_cambio || 0,
      counter: kpis?.total_cotizaciones?.valor || 0,
      formattedCounter: formatearNumeroGrande(kpis?.total_cotizaciones?.valor),
      useFormatted: kpis?.total_cotizaciones?.valor >= 100000,
      bgcolor: "success",
      icon: "bx bx-dollar-circle",
      decimals: 2,
      prefix: "Bs.",
      suffix: ""
    },
    {
      id: 2,
      key: "enf_agudas",
      label: "ENF. Agudas",
      badgeClass: (kpis?.enf_agudas?.tendencia === "up") ? "success" : 
                  (kpis?.enf_agudas?.tendencia === "down") ? "danger" : "muted",
      badge: (kpis?.enf_agudas?.tendencia === "up") ? "ri-arrow-right-up-line" : 
             (kpis?.enf_agudas?.tendencia === "down") ? "ri-arrow-right-down-line" : "",
      percentage: kpis?.enf_agudas?.porcentaje_cambio || 0,
      counter: kpis?.enf_agudas?.valor || 0,
      formattedCounter: formatearNumeroGrande(kpis?.enf_agudas?.valor),
      useFormatted: kpis?.enf_agudas?.valor >= 100000,
      bgcolor: "info",
      icon: "bx bx-shopping-bag",
      decimals: 2,
      prefix: "Bs.",
      suffix: ""
    },
    {
      id: 3,
      key: "enf_cronicas",
      label: "Enf. Crónicas",
      badgeClass: (kpis?.enf_cronicas?.tendencia === "up") ? "success" : 
                  (kpis?.enf_cronicas?.tendencia === "down") ? "danger" : "muted",
      badge: (kpis?.enf_cronicas?.tendencia === "up") ? "ri-arrow-right-up-line" : 
             (kpis?.enf_cronicas?.tendencia === "down") ? "ri-arrow-right-down-line" : "",
      percentage: kpis?.enf_cronicas?.porcentaje_cambio || 0,
      counter: kpis?.enf_cronicas?.valor || 0,
      formattedCounter: formatearNumeroGrande(kpis?.enf_cronicas?.valor),
      useFormatted: kpis?.enf_cronicas?.valor >= 100000,
      bgcolor: "warning",
      icon: "bx bx-user-circle",
      decimals: 2,
      prefix: "Bs.",
      suffix: ""
    },
    {
      id: 4,
      key: "calificacion",
      label: "Calificación",
      badgeClass: (kpis?.calificacion?.tendencia === "up") ? "success" : 
                  (kpis?.calificacion?.tendencia === "down") ? "danger" : "muted",
      badge: (kpis?.calificacion?.tendencia === "up") ? "ri-arrow-right-up-line" : 
             (kpis?.calificacion?.tendencia === "down") ? "ri-arrow-right-down-line" : "",
      percentage: kpis?.calificacion?.porcentaje_cambio || 0,
      counter: kpis?.calificacion?.valor || 0,
      formattedCounter: formatearNumeroGrande(kpis?.calificacion?.valor),
      useFormatted: kpis?.calificacion?.valor >= 100000,
      bgcolor: "primary",
      icon: "bx bx-star",
      decimals: 1,
      prefix: "",
      suffix: ""
    },
    {
      id: 5,
      key: "delivery",
      label: "Delivery",
      badgeClass: (kpis?.delivery?.tendencia === "up") ? "success" : 
                  (kpis?.delivery?.tendencia === "down") ? "danger" : "muted",
      badge: (kpis?.delivery?.tendencia === "up") ? "ri-arrow-right-up-line" : 
             (kpis?.delivery?.tendencia === "down") ? "ri-arrow-right-down-line" : "",
      percentage: kpis?.delivery?.porcentaje_cambio || 0,
      counter: kpis?.delivery?.valor || 0,
      formattedCounter: formatearNumeroGrande(kpis?.delivery?.valor),
      useFormatted: kpis?.delivery?.valor >= 100000,
      bgcolor: "danger",
      icon: "bx bx-cycling",
      decimals: 0,
      prefix: "",
      suffix: ""
    },
    {
      id: 6,
      key: "pareto",
      label: "80/20",
      badgeClass: (kpis?.pareto?.tendencia === "up") ? "success" : 
                  (kpis?.pareto?.tendencia === "down") ? "danger" : "muted",
      badge: (kpis?.pareto?.tendencia === "up") ? "ri-arrow-right-up-line" : 
             (kpis?.pareto?.tendencia === "down") ? "ri-arrow-right-down-line" : "",
      percentage: kpis?.pareto?.porcentaje_cambio || 0,
      counter: kpis?.pareto?.valor || 0,
      formattedCounter: formatearNumeroGrande(kpis?.pareto?.valor),
      useFormatted: kpis?.pareto?.valor >= 100000,
      bgcolor: "secondary",
      icon: "bx bx-chart",
      decimals: 2,
      prefix: "",
      suffix: "%"
    }
  ];

  return (
    <React.Fragment>
      {widgetsConfig.map((item, key) => (
        <Col xl={2} md={6} sm={4} key={key}>
          <Card className="card-animate">
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    {item.label}
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <h5 className={"fs-14 mb-0 text-" + item.badgeClass}>
                    {item.badge ? (
                      <i className={"fs-13 align-middle " + item.badge}></i>
                    ) : null}{" "}
                    {item.percentage} %
                  </h5>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-18 fw-semibold ff-secondary mb-4">
                    <span className="counter-value" data-target="559.25">
                      {item.useFormatted ? (
                        <span>{item.prefix}{item.formattedCounter}{item.suffix}</span>
                      ) : (
                        <CountUp
                          start={0}
                          prefix={item.prefix}
                          suffix={item.suffix}
                          separator="."
                          decimal=","
                          end={item.counter}
                          decimals={item.decimals}
                          duration={4}
                        />
                      )}
                    </span>
                  </h4>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span
                    className={
                      "avatar-title rounded fs-3 bg-" + item.bgcolor + "-subtle"
                    }
                  >
                    <i className={`text-${item.bgcolor} ${item.icon}`}></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </React.Fragment>
  );
};

export default Widgets;
