import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  diagnosticos: [],
  error: "", // for error message
  loading: false,
  errorMsg: false, // for error
  colorMsg: "",
  exito: false,
};

const diagnosticosSlice = createSlice({
  name: "diagnosticos",
  initialState,
  reducers: {
    apiError(state, action) {
      state.error = action.payload;
      state.loading = false;
      state.errorMsg = true;
      state.colorMsg = "danger";
    },

    diagnosticosSuccess(state, action) {
      state.diagnosticos = action.payload.data.data[0];
      state.loading = false;
      state.errorMsg = true;
      state.colorMsg = "success";
      state.error = action.payload.data.message;
      state.exito = true;
    },

    reset_diagnostico_flag(state) {
      state.success = false;
      state.error = "";
      state.loading = false;
      state.errorMsg = false;
      state.colorMsg = "";
    },
  },
});

export const {
  apiError,
  diagnosticosSuccess,
  reset_diagnostico_flag,
} = diagnosticosSlice.actions;

export default diagnosticosSlice.reducer;
