import { APIClient } from "./api_helper";
import * as url from "./url_helper";

const api = new APIClient();

// Diagnosticos
export const getDiagnosticos = () => api.get(url.BASE_DIAGNOSTICOS);

// Siscotel
export const createEventResult = (siscotel) =>
  api.post(url.BASE_SICOTEL + "event-result", siscotel);
export const createDocument = (siscotel) =>
  api.post(url.BASE_SICOTEL + "document", siscotel);

export const createMessage = (siscotel) => {
  return api.post(url.BASE_SICOTEL + "message", siscotel);
};

export const createCoverage = (siscotel) =>
  api.post(url.BASE_SICOTEL + "coverage", siscotel);

//Farmacias
export const getFarmacias = () => api.get(url.BASE_FARMACIAS);
export const getFarmacia = (id) => api.get(url.BASE_FARMACIAS + id);
export const deleteFarmacia = (id) => api.delete(url.BASE_FARMACIAS + id + "/");
export const editFarmacia = (farmacia) =>
  api.put(url.BASE_FARMACIAS + farmacia.id, farmacia);
export const createFarmacia = (farmacia) =>
  api.post(url.BASE_FARMACIAS, farmacia);

//Plantillas Mensajes
export const getPlantillasMensajes = () => api.get(url.BASE_PLANTILLASMENSAJES);
export const getPlantillaMensaje = (id) =>
  api.get(url.BASE_PLANTILLASMENSAJES + id);
export const deletePlantillaMensaje = (id) =>
  api.delete(url.BASE_PLANTILLASMENSAJES + id + "/");
export const editPlantillaMensaje = (plantillaMensaje) =>
  api.put(
    url.BASE_PLANTILLASMENSAJES + plantillaMensaje.id + "/",
    plantillaMensaje
  );
export const createPlantillaMensaje = (plantillaMensaje) =>
  api.post(url.BASE_PLANTILLASMENSAJES, plantillaMensaje);

// Laboratorios
export const getLaboratorios = () => api.get(url.BASE_LABORATORIOS);
export const getLaboratorio = (id) => api.get(url.BASE_LABORATORIOS + "/" + id);
export const deleteLaboratorio = (id) =>
  api.delete(url.BASE_LABORATORIOS + "/" + id);
export const editLaboratorio = (laboratorio) =>
  api.put(url.BASE_LABORATORIOS + "/" + laboratorio.id, laboratorio);
export const createLaboratorio = (laboratorio) =>
  api.post(url.BASE_LABORATORIOS, laboratorio);

// Principios Activos
export const getPrincipiosActivos = () => api.get(url.BASE_PRINCIPIOSACTIVOS);
export const getPrincipioActivo = (id) =>
  api.get(url.BASE_PRINCIPIOSACTIVOS + "/" + id);
export const deletePrincipioActivo = (id) =>
  api.delete(url.BASE_PRINCIPIOSACTIVOS + id);
export const editPrincipioActivo = (principioActivo) =>
  api.put(
    url.BASE_PRINCIPIOSACTIVOS + "/" + principioActivo.id,
    principioActivo
  );
export const createPrincipioActivo = (principioActivo) =>
  api.post(url.BASE_PRINCIPIOSACTIVOS, principioActivo);

// Principios Activos Categorias
export const getPrincipiosActivosCategorias = () =>
  api.get(url.BASE_PRINCIPIOSACTIVOSCATEGORIAS);
export const getPrincipioActivoCategoria = (id) =>
  api.get(url.BASE_PRINCIPIOSACTIVOSCATEGORIAS + "/" + id);
export const deletePrincipioActivoCategoria = (id) =>
  api.delete(url.BASE_PRINCIPIOSACTIVOSCATEGORIAS + "/" + id);
export const editPrincipioActivoCategoria = (principioActivo) =>
  api.put(
    url.BASE_PRINCIPIOSACTIVOSCATEGORIAS + "/" + principioActivo.id,
    principioActivo
  );
export const createPrincipioActivoCategoria = (principioActivo) =>
  api.post(url.BASE_PRINCIPIOSACTIVOSCATEGORIAS, principioActivo);

// Usuarios
export const getUsuarios = () => api.get(url.BASE_USUARIOS);
export const getUsuario = (id) => api.get(url.BASE_USUARIOS + "/" + id);
export const deleteUsuario = (id) => api.delete(url.BASE_USUARIOS + "/" + id);
export const editUsuario = (usuario) =>
  api.put(url.BASE_USUARIOS + "/" + usuario.id, usuario);
export const createUsuario = (usuario) => api.post(url.BASE_USUARIOS, usuario);
export const resetPassword = (usuario) =>
  api.post(url.BASE_USUARIOS + "/reset-password/", usuario);

// Servicios de Entrega
export const getServiciosDeEntregas = () =>
  api.get(url.BASE_SERVICIODEENTREGAS);
export const getServicioDeEntrega = (id) =>
  api.get(url.BASE_SERVICIODEENTREGAS + "/" + id);
export const deleteServicioDeEntrega = (id) =>
  api.delete(url.BASE_SERVICIODEENTREGAS + "/" + id);
export const editServicioDeEntrega = (ServicioDeEntrega) =>
  api.put(
    url.BASE_SERVICIODEENTREGAS + "/" + ServicioDeEntrega.id,
    ServicioDeEntrega
  );
export const createServicioDeEntrega = (ServicioDeEntrega) =>
  api.post(url.BASE_SERVICIODEENTREGAS, ServicioDeEntrega);

// Planilla Estratégica
export const getPlanillasEstrategicas = () =>
  api.get(url.BASE_PLANILLAESTRATEGICA);
export const getPlanillaEstrategica = (id) =>
  api.get(url.BASE_PLANILLAESTRATEGICA + "/" + id);
export const deletePlanillaEstrategica = (id) =>
  api.delete(url.BASE_PLANILLAESTRATEGICA + "/" + id);
export const editPlanillaEstrategica = (planillaEstrategica) =>
  api.put(
    url.BASE_PLANILLAESTRATEGICA + "/" + planillaEstrategica.id,
    planillaEstrategica
  );
export const createPlanillaEstrategica = (planillaEstrategica) =>
  api.post(url.BASE_PLANILLAESTRATEGICA, planillaEstrategica);

// Solicitudes
export const getsolicitudes = (query) =>
  api.get(url.BASE_SOLICITUDES + "/?" + query);
export const getsolicitud = (id) => api.get(url.BASE_SOLICITUDES + "/" + id);
export const deletesolicitud = (id) =>
  api.delete(url.BASE_SOLICITUDES + "/" + id);
export const editsolicitud = (solicitud) =>
  api.put(url.BASE_SOLICITUDES + "/" + solicitud.id + "/", solicitud);
export const createsolicitud = (solicitud) =>
  api.post(url.BASE_SOLICITUDES, solicitud);
export const medicamentos = (lista) =>
  api.post(url.BASE_SOLICITUDES + "/medicamentos/", lista);
export const rutaOptima = (data) =>
  api.post(url.BASE_SOLICITUDES + "/ruta-optima/", {
    solicitudes_ids: data.solicitudes_ids,
    ruta_id: data.ruta_id,
  });
export const solicitudesEntregar = (id_user) => {
  //console.log("Llamando a solicitudesEntregar con id_user:", id_user);
  //console.log("URL completa:", url.BASE_SOLICITUDES + "/solicitudes-entregar/" + id_user);
  return api.get(url.BASE_SOLICITUDES + "/solicitudes-entregar/" + id_user);
};
export const updateDeliverySolicitud = (solicitud) =>
  api.put(
    `${url.BASE_SOLICITUDES}/${solicitud.identificador}/delivery/`,
    solicitud
  );
  
export const agregarComentario = async (identificador, comentario) => {
  // Convertir identificador a string para asegurar consistencia
  const idString = String(identificador);

  try {
    const response = await api.post(
      url.BASE_SOLICITUDES + `/agregar-comentario/?identificador=${idString}`,
      comentario
    );
    return response;
  } catch (error) {
    // Si hay un error de respuesta, extraer los detalles
    if (error.response && error.response.data) {
      console.error("Detalles del error:", error.response.data);
      throw error.response.data;
    }
    // Para otros errores, lanzar un objeto serializable
    throw {
      status: error.response ? error.response.status : 500,
      message: error.message || "Error desconocido al agregar comentario",
      detail: error.response ? error.response.data : null,
    };
  }
};


export const agregarFacturacion = async (identificador, facturacion) => {
  // Convertir identificador a string para asegurar consistencia
  const idString = String(identificador);

  try {
    const response = await api.post(
      url.BASE_SOLICITUDES + `/agregar-facturacion/`,
      { 
        identificador: idString,
        facturacionArray: facturacion 
      }
    );
    return response;
  } catch (error) {
    // Si hay un error de respuesta, extraer los detalles
    if (error.response && error.response.data) {
      console.error("Detalles del error:", error.response.data);
      throw error.response.data;
    }
    // Para otros errores, lanzar un objeto serializable
    throw {
      status: error.response ? error.response.status : 500,
      message: error.message || "Error desconocido al agregar facturación",
      detail: error.response ? error.response.data : null,
    };
  }
};

export const agregarDelivery = async (identificador, delivery) => {
  // Convertir identificador a string para asegurar consistencia
  const idString = String(identificador);

  try {
    const response = await api.post(
      url.BASE_SOLICITUDES + `/agregar-delivery/?identificador=${idString}`,
      delivery
    );
    return response;
  } catch (error) {
    // Si hay un error de respuesta, extraer los detalles
    if (error.response && error.response.data) {
      console.error("Detalles del error:", error.response.data);
      throw error.response.data;
    }
    // Para otros errores, lanzar un objeto serializable
    throw {
      status: error.response ? error.response.status : 500,
      message: error.message || "Error desconocido al agregar facturación",
      detail: error.response ? error.response.data : null,
    };
  }
};

// Reportes
export const getSolicitudesPorTipo = (params) => {
  // Formatear las fechas correctamente
  const formattedParams = {
    tipo: params.tipo || "",
    farmacia: params.farmacia || "",
    estatus: params.estatus || "",
    fecha_inicio: params.fecha_inicio
      ? new Date(params.fecha_inicio).toISOString().split("T")[0]
      : "",
    fecha_fin: params.fecha_fin
      ? new Date(params.fecha_fin).toISOString().split("T")[0]
      : "",
  };

  const queryParams = new URLSearchParams(formattedParams).toString();
  return api.get(
    `${url.BASE_SOLICITUDES}/reportes/solicitudes-por-tipo/?${queryParams}`
  );
};

export const exportarSolicitudesPorTipo = (params) => {
  const queryParams = new URLSearchParams({
    tipo: params.tipo || "Todas",
    farmacia: params.farmacia || "",
    estatus: params.estatus || "",
    fecha_inicio: params.fecha_inicio,
    fecha_fin: params.fecha_fin,
  }).toString();

  return api.getBlob(
    `${url.BASE_SOLICITUDES}/reportes/solicitudes-por-tipo/exportar/?${queryParams}`
  );
};

export const downloadBlob = (blob, fileName) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
};

export const getFacturacionPorPeriodo = (params) => {
  // Formatear las fechas correctamente
  const formattedParams = {
    tipo: params.tipo || "",
    farmacia: params.farmacia || "",
    estatus: params.estatus || "",
    fecha_inicio: params.fecha_inicio
      ? new Date(params.fecha_inicio).toISOString().split("T")[0]
      : "",
    fecha_fin: params.fecha_fin
      ? new Date(params.fecha_fin).toISOString().split("T")[0]
      : "",
  };

  const queryParams = new URLSearchParams(formattedParams).toString();
  return api.get(
    `${url.BASE_SOLICITUDES}/reportes/facturacion-por-periodo/?${queryParams}`
  );
};

export const exportarFacturacionPorPeriodo = (params) => {
  const queryParams = new URLSearchParams({
    tipo: params.tipo || "Todas",
    farmacia: params.farmacia || "",
    estatus: params.estatus || "",
    fecha_inicio: params.fecha_inicio
      ? new Date(params.fecha_inicio).toISOString().split("T")[0]
      : "",
    fecha_fin: params.fecha_fin
      ? new Date(params.fecha_fin).toISOString().split("T")[0]
      : "",
  }).toString();

  return api.getBlob(
    `${url.BASE_SOLICITUDES}/reportes/facturacion-por-periodo/exportar/?${queryParams}`
  );
};

export const getSolicitudesFacturacion = (params) => {
  return api.get(
    `${url.BASE_SOLICITUDES}/verificarqr/${params.id}/${params.factura}`
  );
};

// Rutas
/* export const optimizarRuta = (data) => api.post("/rutas/optimizar", data); */

export const crearRuta = async (data) => {
  //console.log("Enviando solicitud para crear ruta con datos:", JSON.stringify(data));
  try {
    const response = await api.post("/rutas", data);
    //console.log("Respuesta exitosa al crear ruta:", response);
    return response;
  } catch (error) {
    console.error("Error al crear ruta:", error);
    // Rethrow para que el error se maneje en el nivel superior
    throw error;
  }
};

export const actualizarRuta = (datos) => {
  return api.put(`/rutas/${datos.id}`, datos);
};

export const actualizarEstadoSolicitudEnRuta = async (
  rutaId,
  solicitudId,
  datos
) => {
  try {
    // Verificar que los parámetros sean válidos
    if (!rutaId || !solicitudId) {
      console.error(
        "Parámetros inválidos para actualizarEstadoSolicitudEnRuta:",
        { rutaId, solicitudId, datos }
      );
      throw new Error(
        "Parámetros inválidos para actualizar estado de solicitud"
      );
    }

    if (typeof datos === "object" && datos !== null) {
      try {
        // Crear un objeto completo para el endpoint de solicitudes
        const solicitudUpdate = datos;
        // Construir la URL para el endpoint de solicitudes
        const url = `/solicitud/${datos.identificador}/delivery/`;
        // Realizar la solicitud PUT con el body completo
        const response = await api.put(url, solicitudUpdate);

        // También actualizar el estado en la ruta para mantener la consistencia
        try {
          const rutaUrl = `/rutas/${rutaId}/solicitud/${solicitudId}?estado=${
            datos.estatusDelivery === "Entregado" ? "ENTREGADO" : "CANCELADO"
          }`;
          const rutaResponse = await api.put(rutaUrl);
        } catch (rutaError) {
          console.error(
            "Error al actualizar el estado en la ruta, pero la solicitud se actualizó correctamente:",
            rutaError
          );
          // No lanzamos el error aquí, ya que la actualización principal (solicitud) fue exitosa
        }

        return { success: true, data: response };
      } catch (solicitudError) {
        console.error(
          "Error al actualizar la solicitud de delivery:",
          solicitudError
        );
      }
    } else {
      throw new Error(
        "Formato de datos inválido para actualizar estado de solicitud"
      );
    }
  } catch (error) {
    console.error("Error al actualizar estado de solicitud en ruta:", error);
    return {
      success: false,
      error:
        error.message || "Error desconocido al actualizar estado de solicitud",
      details: error,
    };
  }
};

export const finalizarRuta = async (rutaId) => {
  try {
    //console.log(`Llamando a endpoint para finalizar ruta: rutaId=${rutaId}`);

    // Verificar que el parámetro sea válido
    if (!rutaId) {
      console.error("Parámetro inválido para finalizarRuta:", { rutaId });
      throw new Error("Parámetro inválido para finalizar ruta");
    }

    // Construir la URL correctamente según el endpoint del backend
    // El endpoint en el backend es @router.put("/finalizar/{ruta_id}")
    // El router tiene el prefijo "/rutas"
    const url = `/rutas/finalizar/${rutaId}`;
    //console.log("URL completa para finalizar ruta:", url);

    const response = await api.put(url);
    //console.log("Respuesta del servidor para finalización de ruta:", response);
    return response;
  } catch (error) {
    console.error("Error al finalizar ruta:", error);
    throw error;
  }
};

export const obtenerRutaActiva = (motorizadoId) => {
  return api.get(`/rutas/activa/${motorizadoId}`);
};

export const obtenerEstadisticasRuta = (motorizadoId, params) =>
  api.get(`/rutas/estadisticas/${motorizadoId}`, { params });

//Dashboard
export const getKpis = (params) => {
  // Formatear las fechas correctamente
  const formattedParams = {
    fecha_inicio: params.fecha_inicio
      ? new Date(params.fecha_inicio).toISOString().split("T")[0]
      : "",
    fecha_fin: params.fecha_fin
      ? new Date(params.fecha_fin).toISOString().split("T")[0]
      : "",
    codigo_proveedor: params.codigo_proveedor || null,
  };

  const queryParams = new URLSearchParams(formattedParams).toString();

  return api.get(`/api/v1/dashboard/kpis?${queryParams}`);
};
