import React from "react";
import { Container, Row, Col, Card, CardBody, CardHeader, Alert } from "reactstrap";
import Crear from "./Crear";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { DatosSesion } from "../../../Components/Common/DatosSesion";

const PrincipioActivoCategoriaCrear = () => {
  document.title = "Crear Principio Activo Categoria | Farme";
  const perfil = DatosSesion("perfil");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {perfil === "Administrador" ||
          perfil === "Farmabien" ||
          perfil === "MyHealth" ? (
            <>
              <BreadCrumb
                title="Crear Principio Activo Categoria"
                pageTitle="Listar Principios Activos Categorias"
                url="/principiosactivoscategorias"
              />

              <Row>
                <Col lg={12}>
                  <Card>
                    <CardHeader>
                      <h5 className="card-title mb-0">
                        Principios Activos Categoria
                      </h5>
                    </CardHeader>
                    <CardBody>
                      <Crear />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            <Alert color="danger">
              No tienes permisos para ver esta página.
            </Alert>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PrincipioActivoCategoriaCrear;
