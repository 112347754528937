import React, { useEffect } from "react";
import {
  getPrincActivo,
  resetPrincipiosActivosFlag,
} from "../../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { useParams } from "react-router-dom";

// Importaciones de reacciones
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Alert,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import EditarPrincipioActivo from "./EditarPrincipioActivo";
import { DatosSesion } from "../../../Components/Common/DatosSesion";

const PrincipioActivoEditar = () => {
  const perfil = DatosSesion("perfil");
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPrincActivo(id));
  }, [dispatch, id]);

  const selectLayoutState = (state) => state;
  const principiosActivosPageData = createSelector(
    selectLayoutState,
    (state) => ({
      principioActivo: state.PrincipiosActivos.principioActivo,
      error: state.PrincipiosActivos.error,
      loading: state.PrincipiosActivos.loading,
      errorMsg: state.PrincipiosActivos.errorMsg,
      colorMsg: state.PrincipiosActivos.colorMsg,
      exito: state.PrincipiosActivos.exito,
    })
  );

  // Inside your component
  const { principioActivo, error, loading, errorMsg, colorMsg, exito } =
    useSelector(principiosActivosPageData);

  useEffect(() => {
    if (errorMsg) {
      setTimeout(() => {
        dispatch(resetPrincipiosActivosFlag());
      }, 3000);
    }
  }, [dispatch, errorMsg]);

  //OBTENER PRINCIPIOS ACTIVOS
  const selectLayoutStatePActivos = (state) => state;
  const pActivosPageData = createSelector(
    selectLayoutStatePActivos,
    (state) => ({
      principiosActivos: state.PrincipiosActivos.principiosActivos,
    })
  );

  // Inside your component
  const { principiosActivos } = useSelector(pActivosPageData);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {perfil === "Administrador" ||
          perfil === "Farmabien" ||
          perfil === "MyHealth" ? (
            <>
              <BreadCrumb
                title="Editar Principio Activo"
                pageTitle="Listar Principios Activos"
                url="/principiosactivos"
              />

              <Row>
                <Col lg={12}>
                  <Card>
                    <CardHeader className="border-0">
                      {errorMsg && errorMsg ? (
                        <Alert color={colorMsg}> {error} </Alert>
                      ) : null}
                    </CardHeader>
                    <CardBody>
                      {exito && (
                        <EditarPrincipioActivo
                          principioActivo={principioActivo}
                          principiosActivos={principiosActivos[0]}
                        />
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            <Alert color="danger">
              No tienes permisos para ver esta página.
            </Alert>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PrincipioActivoEditar;
