import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Alert,
  Spinner,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify"; // Importar toast para notificaciones

// Componentes
import SolicitudCard from "./components/SolicitudCard";
import FilterBar from "./components/FilterBar";
import RouteMap from "./components/RouteMap";
import RouteStats from "./components/RouteStats";
import ConfirmModal from "./components/ConfirmModal";
import CancelModal from "./components/CancelModal";

// Hooks
import useGeolocation from "./hooks/useGeolocation";

// Acciones
import {
  fetchSolicitudes,
  fetchRutaActiva,
  createRuta,
  updateDeliveryStatus,
  updateSolicitudEnRuta,
  filterSolicitudes,
  resetFilters,
  finalizarRuta as finalizarRutaEntrega, // Renombrar la acción para evitar conflictos
  resetRutaActiva,
} from "../store/actions";

// Importar createMessage desde slices/thunks
import { createMessage } from "../../../slices/thunks";

// Breadcrumbs
import BreadCrumb from "../../../Components/Common/BreadCrumb";

// Utilidades
import { DatosSesion } from "../../../Components/Common/DatosSesion";

// Helpers
import { rutaOptima } from "../../../helpers/backend_helper";

/**
 * Componente principal para listar y gestionar solicitudes de entrega
 * @returns {JSX.Element} - Componente de lista de solicitudes
 */
const ListaSolicitudes = () => {
  const dispatch = useDispatch();

  // Estados locales
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [selectedSolicitud, setSelectedSolicitud] = useState(null);
  const [rutaOptimizada, setRutaOptimizada] = useState(null); // Estado para almacenar la información de la ruta optimizada
  const [updateDeliveryLoading, setUpdateDeliveryLoading] = useState(false); // Estado para controlar la carga durante actualización de entregas
  const [mapUpdateKey, setMapUpdateKey] = useState(Date.now()); // Nuevo estado para forzar la actualización del mapa
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [ubicacion, setUbicacion] = useState(null); // Estado para almacenar la ubicación actual
  const [obteniendoUbicacion, setObteniendoUbicacion] = useState(false); // Estado para controlar la carga durante obtención de ubicación

  // Obtener estados del store
  const {
    solicitudes = [],
    solicitudesFiltradas = [],
    rutaActiva = null,
    loading: solicitudesLoading,
    error: storeError,
    filtros = { fechaInicio: null, fechaFin: null },
    createRutaLoading = false,
    updateDeliveryLoading: updateDeliveryLoadingRedux,
  } = useSelector((state) => state.Motorizado || {});

  // Obtener información del usuario
  const { user } = useSelector((state) => state.Login);

  // Estados del componente
  const [stats, setStats] = useState({
    total: 0,
    entregadas: 0,
    canceladas: 0,
    pendientes: 0,
    distancia_total: 0,
    tiempo_estimado: 0,
    procesadas: 0, // Nuevo campo: total de solicitudes procesadas
  });

  // Usar el hook de geolocalización
  const { getCurrentPosition } = useGeolocation();

  const determinarEstatus = (solicitud) => {
    if (solicitud.tipo === "Agudo") {
      return "Entregado";
    } else if (
      solicitud.tipo === "Cronica" &&
      solicitud.cronico?.fechaVigencia
    ) {
      // Obtener la fecha del próximo mes
      const fechaProximoMes = new Date();
      fechaProximoMes.setMonth(fechaProximoMes.getMonth() + 1);

      // Convertir la fecha de vigencia a objeto Date
      const fechaVigencia = new Date(solicitud.cronico.fechaVigencia);

      // Si el próximo mes es igual o inferior a la fecha de vigencia
      if (fechaProximoMes <= fechaVigencia) {
        return "Por Renovar";
      }
    }
    return "Entregado";
  };

  // Efecto para cargar datos iniciales
  useEffect(() => {
    // Obtener solicitudes y ruta activa al cargar el componente
    dispatch(fetchSolicitudes());
    dispatch(fetchRutaActiva());
  }, [dispatch]);


  
  // Efecto para actualizar estadísticas cuando cambia la ruta activa
  useEffect(() => {
    if (rutaActiva) {
      checkRouteCompletion(rutaActiva);

      // Si la ruta tiene ruta_optima, actualizar el estado de rutaOptimizada
      if (rutaActiva.ruta_optima) {
        setRutaOptimizada(rutaActiva.ruta_optima);
      }
    } else {
      // Resetear estadísticas si no hay ruta activa
      setStats({
        total: 0,
        entregadas: 0,
        canceladas: 0,
        pendientes: 0,
        distancia_total: 0,
        tiempo_estimado: 0,
        procesadas: 0,
      });
    }
  }, [rutaActiva]);

  // Efecto para actualizar estadísticas cuando cambian las solicitudes filtradas
  useEffect(() => {
    // Solo actualizar si no hay ruta activa o si hay filtros aplicados
    if (!rutaActiva && (filtros.fechaInicio || filtros.fechaFin)) {
      // Calcular estadísticas basadas en las solicitudes filtradas
      const solicitudesMostradas = solicitudesFiltradas || [];

      const total = solicitudesMostradas.length;
      const entregadas = solicitudesMostradas.filter(
        (s) => s.estatus === "Entregado" || s.estatusDelivery === "Entregada"
      ).length;
      const canceladas = solicitudesMostradas.filter(
        (s) =>
          s.estatus === "Cancelado" ||
          s.estatusDelivery === "Cancelada" ||
          s.estatus === "Por Reprogramar"
      ).length;
      const pendientes = total - entregadas - canceladas;

      setStats({
        total,
        entregadas,
        canceladas,
        pendientes,
        distancia_total: 0,
        tiempo_estimado: 0,
        procesadas: entregadas + canceladas,
      });
    }
  }, [rutaActiva, solicitudesFiltradas, filtros]);

  // Manejar cambios en los filtros
  const handleFilterApply = (newFilters) => {
    // Asegurarse de que los filtros sean serializables
    const serializableFilters = {
      fechaInicio: newFilters.fechaInicio,
      fechaFin: newFilters.fechaFin,
    };
    // Aplicar filtros a las solicitudes actuales sin recargarlas
    dispatch(filterSolicitudes(serializableFilters, solicitudes));
  };

  // Resetear filtros
  const handleFilterReset = () => {
    dispatch(resetFilters());
    // Recargar las solicitudes sin filtros
    dispatch(fetchSolicitudes());
  };

  // Generar ruta
  const handleGenerateRoute = async () => {
    try {
      // Usar el estado loading del store
      dispatch({ type: "SET_LOADING", payload: true });

      // Usar las solicitudes filtradas que ya están disponibles en el componente
      if (solicitudesFiltradas.length === 0) {
        toast.warning(
          "No hay solicitudes filtradas disponibles para generar una ruta"
        );
        return;
      }

      // Filtrar solicitudes con coordenadas válidas
      const validSolicitudes = solicitudesFiltradas.filter(
        (s) =>
          s.coordenadas &&
          Array.isArray(s.coordenadas) &&
          s.coordenadas.length >= 2
      );

      // Mostrar mensaje si no hay solicitudes válidas
      if (validSolicitudes.length === 0) {
        toast.warning(
          "No hay solicitudes con coordenadas válidas para generar una ruta"
        );
        return;
      }

      // Obtener la posición actual
      const position = await getCurrentPosition();

      if (!position) {
        toast.warning("No se pudo obtener tu ubicación actual");
        return;
      }

      // Verificar si hay solicitudes inválidas (sin coordenadas)
      const invalidSolicitudes = solicitudesFiltradas.filter(
        (s) =>
          !s.coordenadas ||
          !Array.isArray(s.coordenadas) ||
          s.coordenadas.length < 2
      );

      // Obtener el ID del motorizado
      const motorizadoId = DatosSesion("id");

      if (!motorizadoId) {
        toast.error("No se pudo obtener el ID del motorizado");
        return;
      }

      // Crear la ruta con las solicitudes válidas según la estructura correcta
      const rutaData = {
        solicitudes: validSolicitudes.map(
          (s) => s.id || s._id || s.identificador
        ),
        motorizado_id: motorizadoId,
        farmacia_id: DatosSesion("farmacia"),
        fecha_inicio: new Date().toISOString(),
      };

      const result = await dispatch(createRuta(rutaData));

      if (result && result.error) {
        toast.error(
          `Error al crear la ruta: ${
            result.error.message || "Error desconocido"
          }`
        );
        return;
      }

      // Obtener el ID de la ruta creada - manejar diferentes estructuras posibles
      let rutaId = null;

      // Caso 1: result.payload.data.id
      if (result?.payload?.data?.id) {
        rutaId = result.payload.data.id;
      }
      // Caso 2: result.data.id
      else if (result?.data?.id) {
        rutaId = result.data.id;
      }
      // Caso 3: result.id
      else if (result?.id) {
        rutaId = result.id;
      }
      // Caso 4: result.payload.id
      else if (result?.payload?.id) {
        rutaId = result.payload.id;
      }

      if (!rutaId) {
        console.error("No se pudo obtener el ID de la ruta creada");
        toast.warning("La ruta se creó pero no se pudo optimizar");
        // Recargar la ruta activa de todas formas
        dispatch(fetchRutaActiva());
        return;
      }

      // Obtener la ruta óptima usando el endpoint dedicado
      try {
        //console.log("Obteniendo ruta óptima para la ruta:", rutaId);
        const solicitudesIds = validSolicitudes.map(
          (s) => s.id || s._id || s.identificador
        );

        const rutaOptimaResponse = await rutaOptima({
          solicitudes_ids: solicitudesIds,
          ruta_id: rutaId,
        });

        // Verificar la estructura exacta de la respuesta
        if (rutaOptimaResponse && rutaOptimaResponse.data) {
          // La respuesta puede tener diferentes estructuras, intentamos manejarlas todas
          let infoRutaOptima = null;

          // Caso 1: {data: {...}, status_code: 200, message: "..."}
          if (
            rutaOptimaResponse.data.status_code === 200 &&
            rutaOptimaResponse.data.data
          ) {
            infoRutaOptima = rutaOptimaResponse.data.data;
          }
          // Caso 2: {data: {paradas: [...]}}
          else if (rutaOptimaResponse.data.paradas) {
            infoRutaOptima = rutaOptimaResponse.data;
          }
          // Caso 3: {data: {status_code: 200, message: "...", data: {...}}}
          else if (
            rutaOptimaResponse.data.status_code === 200 &&
            rutaOptimaResponse.data.message
          ) {
            // Si hay un mensaje de éxito pero no hay datos, consideramos que fue exitoso
            toast.success("Ruta optimizada correctamente");
            dispatch(fetchRutaActiva());
            return;
          }

          if (
            infoRutaOptima &&
            (infoRutaOptima.distancia_total || infoRutaOptima.paradas)
          ) {
            toast.success("Ruta optimizada correctamente");
          }

          // Verificar que la estructura de la ruta optimizada sea correcta
          if (infoRutaOptima.paradas && Array.isArray(infoRutaOptima.paradas)) {
            //console.log("Estructura de paradas en la ruta optimizada:", infoRutaOptima.paradas  );

            // Verificar que cada parada tenga la estructura correcta
            const paradasValidas = infoRutaOptima.paradas.every(
              (parada) =>
                parada.coordenadas &&
                Array.isArray(parada.coordenadas) &&
                parada.coordenadas.length >= 2
            );

            if (!paradasValidas) {
              console.warn(
                "Algunas paradas no tienen coordenadas válidas, intentando corregir..."
              );

              // Intentar corregir las paradas que no tienen coordenadas
              infoRutaOptima.paradas = infoRutaOptima.paradas.map((parada) => {
                // Si la parada ya tiene coordenadas válidas, devolverla sin cambios
                if (
                  parada.coordenadas &&
                  Array.isArray(parada.coordenadas) &&
                  parada.coordenadas.length >= 2
                ) {
                  return parada;
                }

                // Si la parada tiene lat y lng, crear coordenadas a partir de ellas
                if (parada.lat !== undefined && parada.lng !== undefined) {
                  ////console.log(`Corrigiendo parada con lat/lng: [${parada.lat}, ${parada.lng}]` );
                  return {
                    ...parada,
                    coordenadas: [parada.lat, parada.lng],
                  };
                }

                // Si la parada tiene latitud y longitud, crear coordenadas a partir de ellas
                if (
                  parada.latitud !== undefined &&
                  parada.longitud !== undefined
                ) {
                  ////console.log(`Corrigiendo parada con latitud/longitud: [${parada.latitud}, ${parada.longitud}]`);
                  return {
                    ...parada,
                    coordenadas: [parada.latitud, parada.longitud],
                  };
                }

                return parada;
              });
            }
          }

          // Actualizar el estado con la ruta optimizada
          ////console.log("Estableciendo ruta optimizada en el estado:", infoRutaOptima);
          setRutaOptimizada(infoRutaOptima);

          // Actualizar las estadísticas con los datos de la ruta óptima
          if (infoRutaOptima && infoRutaOptima.paradas) {
            // Calcular el total de paradas (excluyendo el origen)
            const totalParadas = infoRutaOptima.paradas
              ? infoRutaOptima.paradas.filter(
                  (parada) => parada.tipo === "destino"
                ).length
              : 0;

            // Asegurarse de que los valores de distancia y tiempo sean números
            const distancia_total = Number(infoRutaOptima.distancia_total) || 0;

            const tiempo_total = Number(infoRutaOptima.tiempo_total) || 0;

            // Actualizar las estadísticas
            setStats({
              total: totalParadas,
              entregadas: 0,
              canceladas: 0,
              pendientes: totalParadas,
              distancia_total,
              tiempo_estimado: tiempo_total,
              procesadas: 0,
            });
          } else {
            console.warn("Información de ruta incompleta");
            toast.warning(
              "La ruta se creó pero la información de optimización está incompleta"
            );
          }
        } else {
          console.error("Error al obtener información de ruta óptima");
          toast.error("La ruta se creó pero no se pudo optimizar");
        }
      } catch (error) {
        console.error("Error al obtener información de ruta óptima:", error);
        toast.warning("La ruta se creó pero ocurrió un error al optimizarla");
        setLoading(false);
      }

      // Recargar la ruta activa después de crearla y optimizarla
      dispatch(fetchRutaActiva());

      toast.success("Ruta de entrega generada con éxito");
    } catch (error) {
      console.error("Error al generar la ruta:", error);
      toast.error(
        `Error al generar la ruta: ${error.message || "Error desconocido"}`
      );
    } finally {
      // Asegurarse de que el estado de carga se restablezca
      dispatch({ type: "SET_LOADING", payload: false });
    }
  };

  // Abrir modal de confirmación
  const openConfirmModal = async (solicitud) => {
    try {
      setObteniendoUbicacion(true);
      setError(null);

      // Mostrar notificación de que se está obteniendo la ubicación
      toast.info("Obteniendo ubicación GPS...", { autoClose: 2000 });

      // Obtener la ubicación actual
      const posicion = await getCurrentPosition();
      setUbicacion(posicion);

      // Continuar con la apertura del modal
      setSelectedSolicitud(solicitud);
      setConfirmModalOpen(true);
    } catch (error) {
      console.error("Error al obtener la ubicación:", error);

      // Preguntar al usuario si desea continuar sin ubicación
      if (
        window.confirm(
          "No se pudo obtener la ubicación GPS. ¿Desea continuar con la entrega sin registrar la ubicación?"
        )
      ) {
        setUbicacion(null); // Establecer ubicación como null para indicar que no está disponible
        setSelectedSolicitud(solicitud);
        setConfirmModalOpen(true);
      } else {
        toast.error("Entrega cancelada. Active el GPS y reintente.");
        setError(
          "No se pudo obtener la ubicación. Por favor, active el GPS y reintente."
        );
      }
    } finally {
      setObteniendoUbicacion(false);
    }
  };

  // Cerrar modal de confirmación
  const closeConfirmModal = () => {
    setConfirmModalOpen(false);
    setSelectedSolicitud(null);
  };

  // Confirmar entrega
  const handleConfirmDelivery = async (data) => {
    try {
      setLoading(true);
      setError(null);

      const confirmData = {
        identificador: selectedSolicitud.identificador,
        estatus: determinarEstatus(selectedSolicitud),
        porcentaje_avance: 100,
        estatusDelivery: "Entregado",
        ruta_id: rutaActiva?.id,
        reciboEntrega: data.imagen,
        telefono: selectedSolicitud.telefono || "",
        observacion: data.observaciones || "",
      };

      // Solo agregar ubicación si está disponible
      if (ubicacion && ubicacion.latitude && ubicacion.longitude) {
        confirmData.ubicacionEntrega = [
          ubicacion.latitude,
          ubicacion.longitude,
        ];
      }

      // Cerrar el modal inmediatamente para mejorar la experiencia del usuario
      setConfirmModalOpen(false);
      
      // Mostrar mensaje de procesamiento
      toast.info("Procesando confirmación...");

      // Actualizar Ruta
      const response = await dispatch(updateSolicitudEnRuta(rutaActiva?.id, selectedSolicitud.id, confirmData));

      // Verificar si la respuesta es exitosa
      if (
        response &&
        // Nuevo formato de respuesta del backend helper
        (response.success === true ||
          // O formato de respuesta de Redux
          (response.type && response.type.includes("SUCCESS")))
      ) {
        toast.success("Entrega confirmada exitosamente");
        
        // Actualizar la lista de solicitudes y la ruta activa
        try {
          // Usar Promise.race con un timeout para evitar bloqueos
          await Promise.race([
            Promise.all([
              dispatch(fetchSolicitudes()),
              dispatch(fetchRutaActiva())
            ]),
            new Promise((_, reject) => 
              setTimeout(() => reject(new Error('Timeout al actualizar datos')), 5000)
            )
          ]);
        } catch (updateError) {
          console.warn("Error o timeout al actualizar datos:", updateError);
          toast.info("Los cambios se han guardado. Actualiza la página para ver los cambios más recientes.");
        }
      } else {
        // Mostrar mensaje de error específico si está disponible
        const errorMsg = response?.error || "Error al confirmar la entrega";
        console.error(
          "Error al confirmar la entrega. Detalles:",
          errorMsg,
          response
        );
        toast.error(errorMsg);
      }
    } catch (error) {
      console.error("Error al confirmar entrega:", error);
      setError(error.message || "Error al confirmar la entrega");
      toast.error("Error al confirmar la entrega");
    } finally {
      setLoading(false);
    }
  };

  // Abrir modal de cancelación
  const openCancelModal = (solicitud) => {
    setSelectedSolicitud(solicitud);
    setCancelModalOpen(true);
  };

  // Cancelar entrega
  const handleCancelDelivery = async (data) => {
    try {
      setLoading(true);
      setError(null);

      const cancelData = {
        identificador: selectedSolicitud.identificador,
        estatus: "Por Reprogramar",
        observacion: data.motivo || "Entrega cancelada",
        estatusDelivery: "Cancelado",
        porcentaje_avance: 60,
        telefono: selectedSolicitud.telefono || "",
        ruta_id: rutaActiva?.id, // Agregar el ID de la ruta
      };

      // Solo agregar ubicación si está disponible
      if (ubicacion && ubicacion.latitude && ubicacion.longitude) {
        cancelData.ubicacionEntrega = [
          ubicacion.latitude,
          ubicacion.longitude,
        ];
      } else {
        cancelData.ubicacionEntrega = null;
      }

      // Cerrar el modal inmediatamente para mejorar la experiencia del usuario
      setCancelModalOpen(false);
      
      // Mostrar mensaje de procesamiento
      toast.info("Procesando cancelación...");

      //Actualiza Solicitud y Ruta
      const response = await dispatch(
        updateSolicitudEnRuta(rutaActiva?.id, selectedSolicitud?.id, cancelData)
      );

      // Verificar si la respuesta es exitosa
      if (
        response &&
        // Nuevo formato de respuesta del backend helper
        (response.success === true ||
          // O formato de respuesta de Redux
          (response.type && response.type.includes("SUCCESS")))
      ) {
        toast.success("Entrega cancelada correctamente");
        
        // Actualizar la lista de solicitudes y la ruta activa
        try {
          // Usar Promise.race con un timeout para evitar bloqueos
          await Promise.race([
            Promise.all([
              dispatch(fetchSolicitudes()),
              dispatch(fetchRutaActiva())
            ]),
            new Promise((_, reject) => 
              setTimeout(() => reject(new Error('Timeout al actualizar datos')), 5000)
            )
          ]);
        } catch (updateError) {
          console.warn("Error o timeout al actualizar datos:", updateError);
          toast.info("Los cambios se han guardado. Actualiza la página para ver los cambios más recientes.");
        }
      } else {
        // Mostrar mensaje de error específico si está disponible
        const errorMsg = response?.error || "Error al cancelar la entrega";
        console.error(
          "Error al cancelar la entrega. Detalles:",
          errorMsg,
          response
        );
        toast.error(errorMsg);
      }
    } catch (error) {
      console.error("Error en handleCancelDelivery:", error);
      setError(error.message || "Error al cancelar la entrega");
      toast.error("Error al cancelar la entrega");
    } finally {
      setLoading(false);
    }
  };

  // Función para manejar la finalización de la ruta
  const handleFinalizarRuta = async () => {
    if (rutaActiva && rutaActiva.id) {
      try {
        // Mostrar estado de carga
        setLoading(true);

        // Llamar a la acción para finalizar la ruta
        const result = await dispatch(finalizarRutaEntrega(rutaActiva.id));

        // Verificar el resultado
        if (
          result &&
          (result.success || (result.type && result.type.includes("SUCCESS")))
        ) {
          //console.log("Ruta finalizada con éxito. Resultado:", result);

          // Mostrar notificación de éxito
          toast.success("Ruta finalizada con éxito");

          // Limpiar el estado local
          setStats({
            total: 0,
            entregadas: 0,
            canceladas: 0,
            pendientes: 0,
            distancia_total: 0,
            tiempo_estimado: 0,
            procesadas: 0,
          });

          // Actualizar la lista de solicitudes
          await dispatch(fetchSolicitudes());

          // Resetear la ruta activa en el estado de Redux
          await dispatch(resetRutaActiva());
        } else {
          console.error("Error al finalizar la ruta. Resultado:", result);
          const errorMsg = result?.error || "No se pudo finalizar la ruta";
          setError(errorMsg);
          toast.error(errorMsg);
        }
      } catch (error) {
        console.error("Error al finalizar la ruta:", error);
        setError(error.message || "Error al finalizar la ruta");
        toast.error("Error al finalizar la ruta");
      } finally {
        setLoading(false);
      }
    } else {
      console.error("No hay ruta activa para finalizar");
      setError("No hay ruta activa para finalizar");
      toast.error("No hay ruta activa para finalizar");
    }
  };

  // Calcular estadísticas de la ruta
  const calcularEstadisticas = () => {
    // Si hay una ruta activa, verificar su finalización
    if (rutaActiva) {
      checkRouteCompletion(rutaActiva);
    }

    // Devolver el estado actual de las estadísticas
    return stats;
  };

  // Función para verificar si todas las solicitudes han sido procesadas
  const checkRouteCompletion = (ruta) => {
    // Si no hay ruta activa, no hacer nada
    if (!ruta) {
      return;
    }

    //console.log("Verificando finalización de ruta:", ruta);

    // Calcular estadísticas basadas en la ruta proporcionada
    let totalSolicitudes = ruta.solicitudes ? ruta.solicitudes.length : 0;

    // Intentar obtener estadísticas de diferentes fuentes
    let entregadas = 0;
    let canceladas = 0;
    let pendientes = 0;

    // Prioridad 1: Usar estadísticas del backend si están disponibles
    if (ruta.estadisticas) {
      const { entregas_exitosas = 0, entregas_canceladas = 0 } =
        ruta.estadisticas || {};

      // Verificar que las estadísticas sean números válidos
      entregadas = Number(entregas_exitosas) || 0;
      canceladas = Number(entregas_canceladas) || 0;

      // Calcular pendientes basado en las solicitudes totales y las procesadas
      let totalRuta = 0;

      if (ruta.solicitudes && Array.isArray(ruta.solicitudes)) {
        totalRuta = ruta.solicitudes.length;
      }

      // Si tenemos un total de solicitudes, calcular pendientes como la diferencia
      if (totalRuta > 0) {
        pendientes = totalRuta - entregadas - canceladas;
        pendientes = Math.max(0, pendientes); // Asegurar que no sea negativo
        totalSolicitudes = totalRuta;
      } else {
        // Si no podemos determinar el total de solicitudes, usar el total calculado
        totalSolicitudes = entregadas + canceladas + pendientes;
      }
    } else {
      // Si no hay estadísticas del backend, intentar calcularlas manualmente
      // contando las solicitudes por estado
      if (ruta.solicitudes && Array.isArray(ruta.solicitudes)) {
        // Contar solicitudes entregadas y canceladas
        ruta.solicitudes.forEach((solicitud) => {
          if (
            solicitud.estatusDelivery === "Entregado" ||
            solicitud.estatus === "Entregado"
          ) {
            entregadas++;
          } else if (
            solicitud.estatusDelivery === "Cancelado" ||
            solicitud.estatus === "Cancelado" ||
            solicitud.estatus === "Por Reprogramar"
          ) {
            canceladas++;
          }
        });

        // Calcular pendientes
        pendientes = totalSolicitudes - entregadas - canceladas;
        pendientes = Math.max(0, pendientes); // Asegurar que no sea negativo
      }
    }

    // Obtener información de distancia y tiempo
    let distancia_total = 0;
    let tiempo_estimado = 0;

    // Intentar obtener los valores de diferentes fuentes
    if (ruta.distancia_real !== undefined) {
      distancia_total = Number(ruta.distancia_real);
    }

    if (ruta.tiempo_real !== undefined) {
      tiempo_estimado = Number(ruta.tiempo_real);
    }

    // Calcular el total de solicitudes procesadas
    const procesadas = entregadas + canceladas;

    // Actualizar las estadísticas en el estado
    setStats({
      total: totalSolicitudes, // Total de solicitudes en la ruta
      entregadas,
      canceladas,
      pendientes,
      procesadas, // Total de solicitudes procesadas
      distancia_total:
        distancia_total > 0 ? distancia_total : stats.distancia_total || 0,
      tiempo_estimado:
        tiempo_estimado > 0 ? tiempo_estimado : stats.tiempo_estimado || 0,
    });

    // Verificar si todas las solicitudes han sido procesadas
    // Solo finalizar la ruta si el total de solicitudes procesadas (entregadas + canceladas)
    // es exactamente igual al total de solicitudes en la ruta
   /*  if (totalSolicitudes > 0 && procesadas === totalSolicitudes) {
      // Finalizar la ruta automáticamente
      handleFinalizarRuta();
    } */
  };

  // Función para optimizar la ruta
  const handleOptimizeRoute = async () => {
    if (!rutaActiva || !rutaActiva.id) {
      setError("No hay una ruta activa para optimizar");
      toast.error("No hay una ruta activa para optimizar");
      return;
    }

    try {
      // Obtener las solicitudes de la ruta activa
      const solicitudesIds = rutaActiva.solicitudes
        .filter((sol) => {
          // Verificar si la solicitud está entregada o cancelada en la ruta activa
          // Nota: No usamos estados_solicitudes porque no existe esa propiedad
          const solId =
            typeof sol === "string"
              ? sol
              : sol.id || sol._id || sol.identificador;

          // Verificar si la solicitud está en la lista de solicitudes entregadas o canceladas
          // Esto se puede determinar por el estado de la solicitud directamente
          if (typeof sol === "object" && sol.estado) {
            return sol.estado !== "ENTREGADO" && sol.estado !== "CANCELADO";
          }

          // Si no podemos determinar el estado, mostrar la solicitud
          return true;
        })
        .map((sol) =>
          typeof sol === "string" ? sol : sol.id || sol._id || sol.identificador
        );

      if (solicitudesIds.length === 0) {
        setError("No hay solicitudes pendientes para optimizar");
        toast.warning("No hay solicitudes pendientes para optimizar");
        return;
      }

      // Preparar los datos para la solicitud
      const data = {
        solicitudes_ids: solicitudesIds,
        ruta_id: rutaActiva.id,
      };

      // Llamar a la API para optimizar la ruta
      toast.info("Optimizando ruta...");
      const rutaOptimaResponse = await dispatch(obtenerRutaOptima(data));

      // Verificar si la respuesta es exitosa
      if (rutaOptimaResponse && rutaOptimaResponse.data) {
        let infoRutaOptima = null;

        // Determinar la estructura de la respuesta
        // Caso 1: {data: {...}, status_code: 200, message: "..."}
        if (
          rutaOptimaResponse.data.status_code === 200 &&
          rutaOptimaResponse.data.data
        ) {
          infoRutaOptima = rutaOptimaResponse.data.data;
        }
        // Caso 2: {data: {paradas: [...]}}
        else if (rutaOptimaResponse.data.paradas) {
          infoRutaOptima = rutaOptimaResponse.data;
        }
        // Caso 3: {data: {status_code: 200, message: "...", data: {...}}}
        else if (
          rutaOptimaResponse.data.status_code === 200 &&
          rutaOptimaResponse.data.message
        ) {
          // Si hay un mensaje de éxito pero no hay datos, consideramos que fue exitoso
          toast.success("Ruta optimizada correctamente");
          dispatch(fetchRutaActiva());
          return;
        } else {
          setError("Formato de respuesta no reconocido");
          toast.warning("Formato de respuesta no reconocido");
        }

        if (infoRutaOptima) {
          // Mostrar mensaje de éxito
          if (rutaOptimaResponse.data.message) {
            toast.success(rutaOptimaResponse.data.message);
          } else {
            toast.success("Ruta optimizada correctamente");
          }

          // Verificar que la estructura de la ruta optimizada sea correcta
          if (infoRutaOptima.paradas && Array.isArray(infoRutaOptima.paradas)) {
            //console.log("Estructura de paradas en la ruta optimizada:", infoRutaOptima.paradas  );

            // Verificar que cada parada tenga la estructura correcta
            const paradasValidas = infoRutaOptima.paradas.every(
              (parada) =>
                parada.coordenadas &&
                Array.isArray(parada.coordenadas) &&
                parada.coordenadas.length >= 2
            );

            if (!paradasValidas) {
              setError("Algunas paradas no tienen coordenadas válidas");
              toast.warning("Algunas paradas no tienen coordenadas válidas");
            }

            // Intentar corregir las paradas que no tienen coordenadas
            infoRutaOptima.paradas = infoRutaOptima.paradas.map((parada) => {
              // Si la parada ya tiene coordenadas válidas, devolverla sin cambios
              if (
                parada.coordenadas &&
                Array.isArray(parada.coordenadas) &&
                parada.coordenadas.length >= 2
              ) {
                return parada;
              }

              // Si la parada tiene lat y lng, crear coordenadas a partir de ellas
              if (parada.lat !== undefined && parada.lng !== undefined) {
                return {
                  ...parada,
                  coordenadas: [parada.lat, parada.lng],
                };
              }

              // Si la parada tiene latitud y longitud, crear coordenadas a partir de ellas
              if (
                parada.latitud !== undefined &&
                parada.longitud !== undefined
              ) {
               
                return {
                  ...parada,
                  coordenadas: [parada.latitud, parada.longitud],
                };
              }

              return parada;
            });
          }

          // Actualizar el estado con la ruta optimizada
         
          setRutaOptimizada(infoRutaOptima);

          // Actualizar las estadísticas con los datos de la ruta óptima
          if (infoRutaOptima) {
            // Calcular el total de paradas (excluyendo el origen)
            const totalParadas = infoRutaOptima.paradas
              ? infoRutaOptima.paradas.filter(
                  (parada) => parada.tipo === "destino"
                ).length
              : 0;

            // Asegurarse de que los valores de distancia y tiempo sean números
            const distancia_total = Number(infoRutaOptima.distancia_total) || 0;

            const tiempo_total = Number(infoRutaOptima.tiempo_total) || 0;

            // Actualizar las estadísticas manteniendo los contadores de entregadas y canceladas
            setStats((prevStats) => ({
              ...prevStats,
              total: totalParadas > 0 ? totalParadas : prevStats.total,
              pendientes:
                totalParadas > 0
                  ? totalParadas - prevStats.entregadas - prevStats.canceladas
                  : prevStats.pendientes,
              distancia_total,
              tiempo_estimado: tiempo_total,
              procesadas: prevStats.entregadas + prevStats.canceladas,
            }));
          }
        }
      } else {
        setError("Error al obtener información de ruta óptima");
        toast.error("La ruta se creó pero no se pudo optimizar");
      }
    } catch (error) {
      setError("Error al obtener información de ruta óptima");
      toast.error("La ruta se creó pero ocurrió un error al optimizarla");
    }
  };

  // Efecto para actualizar la ruta optimizada cuando cambia la ruta activa
  useEffect(() => {
    if (rutaActiva) {
      setRutaOptimizada(rutaActiva);
    }
  }, [rutaActiva]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Solicitudes de Entrega" pageTitle="Motorizado" />

          <Row>
            {/* Columna de filtros y estadísticas */}
            <Col lg={4}>
              {/* Filtros - Solo mostrar cuando no hay ruta activa */}
              {!rutaActiva && (
                <FilterBar
                  fechaInicio={filtros.fechaInicio}
                  fechaFin={filtros.fechaFin}
                  onFechaInicioChange={(date) =>
                    handleFilterApply({ ...filtros, fechaInicio: date })
                  }
                  onFechaFinChange={(date) =>
                    handleFilterApply({ ...filtros, fechaFin: date })
                  }
                  onFilterApply={handleFilterApply}
                  onFilterReset={handleFilterReset}
                  onGenerateRoute={handleGenerateRoute}
                  solicitudesCount={
                    filtros.fechaInicio || filtros.fechaFin
                      ? solicitudesFiltradas.length
                      : solicitudes.length
                  }
                  loading={createRutaLoading}
                  rutaActiva={rutaActiva}
                />
              )}

              {/* Estadísticas de ruta */}
              {rutaActiva && (
                <RouteStats stats={stats} loading={updateDeliveryLoading} />
              )}
            </Col>

            {/* Columna principal de contenido */}
            <Col lg={8}>
              {/* Mapa */}
              <RouteMap
                key={`route-map-${rutaActiva ? rutaActiva.id : "no-route"}-${
                  updateDeliveryLoading ? "loading" : "ready"
                }-${mapUpdateKey}`}
                solicitudes={
                  rutaActiva && rutaActiva.solicitudes
                    ? rutaActiva.solicitudes.filter((sol) => {
                        // Verificar si la solicitud está entregada o cancelada en la ruta activa
                        // Nota: No usamos estados_solicitudes porque no existe esa propiedad
                        const solId =
                          typeof sol === "string"
                            ? sol
                            : sol.id || sol._id || sol.identificador;

                        // Verificar si la solicitud está en la lista de solicitudes entregadas o canceladas
                        // Esto se puede determinar por el estado de la solicitud directamente
                        if (typeof sol === "object" && sol.estado) {
                          return (
                            sol.estado !== "ENTREGADO" &&
                            sol.estado !== "CANCELADO"
                          );
                        }

                        // Si no podemos determinar el estado, mostrar la solicitud
                        return true;
                      })
                    : filtros.fechaInicio || filtros.fechaFin
                    ? solicitudesFiltradas
                    : solicitudes
                }
                rutaActiva={rutaActiva}
                rutaOptimizada={rutaOptimizada}
                height="400px"
              />

              {/* Mensajes de error o carga */}
              {storeError && (
                <Alert color="danger" className="mt-4">
                  <i className="ri-error-warning-line me-2"></i>
                  {storeError}
                </Alert>
              )}

              {error && (
                <Alert color="danger" className="mt-4">
                  <i className="ri-error-warning-line me-2"></i>
                  {error}
                </Alert>
              )}

              {loading && (
                <div className="text-center my-5">
                  <Spinner color="primary" />
                  <p className="mt-2">Cargando solicitudes...</p>
                </div>
              )}

              {/* Lista de solicitudes */}
              <div className="mt-4">
                <h5 className="mb-3">
                  {rutaActiva ? "Ruta de Entrega Actual" : ""}
                </h5>

                {!loading &&
                  (filtros.fechaInicio || filtros.fechaFin) &&
                  solicitudesFiltradas.length === 0 && (
                    <Alert color="info">
                      <i className="ri-information-line me-2"></i>
                      No hay solicitudes disponibles para los filtros
                      seleccionados.
                    </Alert>
                  )}

                {/* Mostrar solicitudes de la ruta activa o todas las solicitudes */}
                {(() => {
                  let solicitudesToShow = [];

                  if (rutaActiva && rutaActiva.solicitudes) {
                    // Filtrar solicitudes que no estén entregadas o canceladas
                    const solicitudesFiltradas = rutaActiva.solicitudes.filter(
                      (sol) => {
                        if (typeof sol === "object" && sol.estado) {
                          return (
                            sol.estado !== "ENTREGADO" &&
                            sol.estado !== "CANCELADO"
                          );
                        }
                        return true;
                      }
                    );

                    // Si tenemos ruta optimizada, ordenar según el orden en ruta_optima.paradas
                    if (
                      rutaOptimizada &&
                      rutaOptimizada.ruta_optima &&
                      rutaOptimizada.ruta_optima.paradas &&
                      Array.isArray(rutaOptimizada.ruta_optima.paradas)
                    ) {


                      // Crear un mapa de orden por ID de solicitud (solo para destinos)
                      const ordenPorId = {};
                      rutaOptimizada.ruta_optima.paradas
                        .filter((parada) => parada.tipo === "destino")
                        .forEach((parada, idx) => {
                          if (parada.id_solicitud) {
                            ordenPorId[parada.id_solicitud] = idx;
                          }
                        });

                      // Ordenar las solicitudes según el orden en ruta_optima.paradas
                      solicitudesToShow = [...solicitudesFiltradas].sort(
                        (a, b) => {
                          const idA =
                            typeof a === "string"
                              ? a
                              : a.id || a._id || a.identificador;
                          const idB =
                            typeof b === "string"
                              ? b
                              : b.id || b._id || b.identificador;

                          // Si ambos IDs están en el mapa de orden, ordenar según ese orden
                          if (
                            ordenPorId[idA] !== undefined &&
                            ordenPorId[idB] !== undefined
                          ) {
                            return ordenPorId[idA] - ordenPorId[idB];
                          }

                          // Si solo uno está en el mapa, ponerlo primero
                          if (ordenPorId[idA] !== undefined) return -1;
                          if (ordenPorId[idB] !== undefined) return 1;

                          // Si ninguno está en el mapa, mantener el orden original
                          return 0;
                        }
                      );
                    } else {
                      // Si no hay ruta optimizada, usar las solicitudes filtradas sin ordenar
                      solicitudesToShow = solicitudesFiltradas;
                    }
                  } else if (filtros.fechaInicio || filtros.fechaFin) {
                    // Si no hay ruta activa pero hay filtros, usar solicitudes filtradas
                    solicitudesToShow = solicitudesFiltradas;
                  } else {
                    // Si no hay ruta activa ni filtros, usar todas las solicitudes
                    solicitudesToShow = solicitudes || [];
                  }

                  return solicitudesToShow.map((solicitud, index) => (
                    <SolicitudCard
                      key={
                        solicitud.id ||
                        solicitud._id ||
                        solicitud.identificador ||
                        index
                      }
                      solicitud={solicitud}
                      orden={index + 1}
                      onConfirmClick={openConfirmModal}
                      onCancelClick={openCancelModal}
                      showActions={
                        // Solo mostrar acciones si:
                        // 1. La solicitud no está entregada ni cancelada
                        // 2. Existe una ruta activa
                        solicitud.estatus !== "Entregado" &&
                        solicitud.estatus !== "Cancelado" &&
                        rutaActiva !== null
                      }
                    />
                  ));
                })()}
              </div>
            </Col>
          </Row>

          {/* Modales */}
          <ConfirmModal
            isOpen={confirmModalOpen}
            toggle={() => setConfirmModalOpen(!confirmModalOpen)}
            solicitud={selectedSolicitud}
            onConfirm={handleConfirmDelivery}
            onClose={() => setConfirmModalOpen(false)}
            loading={loading}
            onNotify={(message) => dispatch(createMessage(message))}
          />
          <CancelModal
            isOpen={cancelModalOpen}
            toggle={() => setCancelModalOpen(!cancelModalOpen)}
            solicitud={selectedSolicitud}
            onCancel={handleCancelDelivery}
            onClose={() => {
              setCancelModalOpen(false);
            }}
            loading={loading}
            onNotify={(message) => dispatch(createMessage(message))}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ListaSolicitudes;
