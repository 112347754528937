import {
  getPlantillasMensajes as gets,
  getPlantillaMensaje as get,
  deletePlantillaMensaje as eliminar,
  editPlantillaMensaje as editar,
  createPlantillaMensaje as crear,
} from "../../../helpers/backend_helper";
import {
  apiError,
  plantillasMensajesSuccess,
  plantillaMensajeSuccess,
  plantillaMensajeDeleteSuccess,
  reset_plantillaMensaje_flag,
} from "./reducer";


/**
 * Thunk para obtener las plantillas de mensaje.
 *
 * @function
 * @returns {Promise} Promesa que se resuelve con las plantillas de mensaje obtenidas.
 */
export const getPlantillasMensajes = () => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = gets();
    }
    var data = await response;
    if (data) {
      dispatch(plantillasMensajesSuccess(data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};


/**
 * Thunk para eliminar una plantilla de mensaje por su ID.
 *
 * @function
 * @param {string} id El ID de la plantilla de mensaje a eliminar.
 * @returns {Promise} Promesa que se resuelve con la plantilla de mensaje eliminada.
 */
export const deletePlantillaMensaje = (id) => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = eliminar(id);
    }
    var data = await response;
    if (data) {
      dispatch(plantillaMensajeDeleteSuccess(data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};


/**
 * Thunk para editar una plantilla de mensaje.
 *
 * @function
 * @param {object} planillaEstrategica La plantilla de mensaje a editar.
 * @returns {Promise} Promesa que se resuelve con la plantilla de mensaje editada.
 */

export const editPlantillaMensaje = (planillaEstrategica) => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = editar(planillaEstrategica);
    }
    var data = await response;
    if (data) {
      dispatch(plantillaMensajeSuccess(data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};


/**
 * Thunk para crear una plantilla de mensaje.
 *
 * @function
 * @param {object} planillaEstrategica La plantilla de mensaje a crear.
 * @returns {Promise} Promesa que se resuelve con la plantilla de mensaje creada.
 */
export const createPlantillaMensaje = (planillaEstrategica) => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = crear(planillaEstrategica);
    }
    var data = await response;

    if (data) {
      dispatch(plantillaMensajeSuccess(data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};


/**
 * Thunk para obtener una plantilla de mensaje por su ID.
 *
 * @function
 * @param {string} id El ID de la plantilla de mensaje a obtener.
 * @returns {Promise} Promesa que se resuelve con la plantilla de mensaje obtenida.
 */
export const getPlantillaMensaje = (id) => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = get(id);
    }
    var data = await response;
    if (data) {
      dispatch(plantillaMensajeSuccess(data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};


/**
 * Resets the flag for the plantillas de mensaje to its initial state.
 * Useful when needing to fetch the list of plantillas de mensaje again.
 *
 * @returns {Promise} Promesa que se resuelve con el flag reseteado.
 */
export const resetPlantillasMensajesFlag = () => async (dispatch) => {
  try {
    const response = dispatch(reset_plantillaMensaje_flag());
    return response;
  } catch (error) {
    dispatch(apiError(error));
  }
};
