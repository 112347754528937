import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { getdiagnosticos, createCoverage } from "../../../slices/thunks";
import { editSolicitud } from "../../../slices/thunks";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  FormGroup,
  Label,
  Form,
  Button,
} from "reactstrap";

const Diagnostico = ({ solicitud }) => {
  const dispatch = useDispatch();
  const [selectedMainDiagnostico, setSelectedMainDiagnostico] = useState(null);
  const [selectedSubDiagnostico, setSelectedSubDiagnostico] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showDiagnostico, setShowDiagnostico] = useState(false);
  const [isTextVisible, setIsTextVisible] = useState(false);
  const [codDiagnostico, setCodDiagnostico] = useState(null);
  const [codSubDiagnostico, setCodSubDiagnostico] = useState(null);
  const [formErrors, setFormErrors] = useState({
    mainDiagnostico: false,
    subDiagnostico: false,
  });
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [loadingx, setLoadingx] = useState(false);
  const selectLayoutState = (state) => state;
  const pageData = createSelector(selectLayoutState, (state) => ({
    siscotel: state.Siscotel.siscotel,
    error: state.Siscotel.error,
    loading: state.Siscotel.loading,
    errorMsg: state.Siscotel.errorMsg,
    colorMsg: state.Siscotel.colorMsg,
    exito: state.Siscotel.exito,
  }));

  const { siscotel, error, loading, errorMsg, colorMsg, exito } =
    useSelector(pageData);

  useEffect(() => {
    if (siscotel && siscotel.status == 200 && siscotel.coverage) {
      // Primero verificamos que siscotel sea una cadena de texto
      const coverageValue =
        typeof siscotel.coverage === "string"
          ? parseFloat(siscotel.coverage.replace(" $", ""))
          : typeof siscotel === "number"
          ? siscotel
          : 0;

      const nuevaSolicitud = {
        ...solicitud,
        codigoDiagnosticoFarmacologico: codDiagnostico,
        codigoSubdiagnosticoFarmacologico: codSubDiagnostico,
        montoCobertura: coverageValue,
        porcentaje_avance: 20,
        diagnosticoFarmacologico: selectedMainDiagnostico
          ? selectedMainDiagnostico.label
          : null,
        subdiagnosticoFarmacologico: selectedSubDiagnostico
          ? selectedSubDiagnostico.label
          : null,
      };

      dispatch(editSolicitud(nuevaSolicitud));
      setShowDiagnostico(true);
      setLoadingx(false);

      toast.success(
        "Diagnóstico Farmacológico registrado exitosamente y hemos recibido el monto de la cobertura asociada",
        {}
      );
    }
  }, [dispatch, siscotel]);

  useEffect(() => {
    dispatch(getdiagnosticos());
  }, [dispatch]);

  useEffect(() => {
    if (solicitud) {
      setIsLoading(false);
      if (
        solicitud.diagnosticoFarmacologico ||
        solicitud.subdiagnosticoFarmacologico
      ) {
        setShowDiagnostico(true);
      }
    }
  }, [solicitud]);

  const selectLayoutStateDiagnostico = (state) => state;

  const diagnosticoPageData = createSelector(
    selectLayoutStateDiagnostico,
    (state) => ({
      diagnosticos: state.Diagnosticos.diagnosticos,
    })
  );

  const { diagnosticos } = useSelector(diagnosticoPageData);

  const handleMainDiagnosticoChange = (selectedOption) => {
    setSelectedMainDiagnostico(selectedOption);
    setSelectedSubDiagnostico(null);
    // Resetear errores
    setFormErrors({
      ...formErrors,
      mainDiagnostico: false,
      subDiagnostico: false,
    });
  };
  const handleSubDiagnosticoChange = (selectedOption) => {
    setSelectedSubDiagnostico(selectedOption);
    // Solo actualizar el error si hay sub-diagnósticos disponibles
    if (subDiagnosticos.length > 0) {
      setFormErrors({
        ...formErrors,
        subDiagnostico: false,
      });
    }
  };

  const DiagnosticoTable = ({ solicitud, onEdit }) => {
    if (
      !solicitud ||
      (!solicitud.diagnosticoFarmacologico &&
        !solicitud.subdiagnosticoFarmacologico)
    ) {
      return (
        <div className="text-center p-5 bg-light rounded">
          <i className="ri-stethoscope-line text-muted font-size-24 mb-2"></i>
          <p className="text-muted mb-0">
            No hay información de diagnóstico disponible.
          </p>
        </div>
      );
    }
  
    return (
      <div className="card w-100">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h5 className="card-title mb-0">Información de Diagnóstico</h5>
          </div>
  
          <div className="table-responsive w-100">
            <table className="table table-centered table-hover w-100 mb-0" style={{ minHeight: "150px" }}>
              <thead className="table-light">
                <tr>
                  <th scope="col" className="py-3">Diagnóstico Principal</th>
                  <th scope="col" className="py-3">Subdiagnóstico</th>
                  <th
                    scope="col"
                    className="text-center py-3"
                    style={{ width: "100px" }}
                  >
                    Acción
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="py-3">
                    <span className="fw-medium">
                      {solicitud.diagnosticoFarmacologico || "N/A"}
                    </span>
                  </td>
                  <td className="py-3">{solicitud.subdiagnosticoFarmacologico || "N/A"}</td>
                  <td className="text-center py-3">
                    {solicitud.estatus === "Cancelado" ||
                    solicitud.estatus === "Entregado" ? (
                      <span className="text-muted">-</span>
                    ) : (
                      <Button
                        color="soft-primary"
                        size="sm"
                        className="btn-icon rounded-circle"
                        onClick={onEdit}
                      >
                        <i className="ri-pencil-line"></i>
                      </Button>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  const handleSubmitDiagnostico = (e) => {
    e.preventDefault();

    // Validar campos obligatorios
    const errors = {
      mainDiagnostico: !selectedMainDiagnostico,
      subDiagnostico:
        selectedMainDiagnostico &&
        subDiagnosticos.length > 0 &&
        !selectedSubDiagnostico,
    };

    // Actualizar el estado de los errores
    setFormErrors(errors);

    // Si hay algún error, mostrar mensaje y detener la ejecución
    if (Object.values(errors).some((error) => error)) {
      setShowErrorMessage(true);
      setTimeout(() => setShowErrorMessage(false), 3000);
      return;
    }

    // Solo establecer loading en true si la validación es exitosa
    setLoadingx(true);

    const subcodDiagnostico =
      selectedSubDiagnostico !== null
        ? selectedMainDiagnostico.value.fullCode + selectedSubDiagnostico.value
        : null;

    const codDiagnostico = selectedMainDiagnostico.value.fullCode;

    setCodDiagnostico(codDiagnostico);
    setCodSubDiagnostico(subcodDiagnostico);

    const cobertura = {
      CedulaTitular: solicitud.titularCedula,
      CedulaSolicitante: solicitud.solicitanteCedula,
      CodigoDiagnostico: codDiagnostico,
      CodigoProveedor: solicitud.codigoProveedor,
    };

    // Luego envía el mensaje a Siscotel
    try {
      if (cobertura && cobertura.CodigoDiagnostico) {
        dispatch(createCoverage(cobertura))
          .then(() => {
            console.info("Solicitud del monto de cobertura a Siscotel");
          })
          .catch((error) => {
            console.error("Error al solicitar monto de cobertura:", error);
            setLoadingx(false); // Restablecer loading en caso de error
          });
      }
    } catch (error) {
      console.error("Error al solicitar monto de cobertura a Siscotel:", error);
      setLoadingx(false); // Restablecer loading en caso de error
    }
  };

  const mainDiagnosticos = diagnosticos
    ? diagnosticos
        .filter((diag) => diag.sub_codigo === null)
        .sort((a, b) => a.nombre.localeCompare(b.nombre))
        .map((diag) => ({
          value: {
            fullCode: `${diag.cod1}${diag.cod2}${diag.cod3}${diag.cod4}`,
            cod4: diag.cod4,
          },
          label: diag.nombre,
        }))
    : [];

  const subDiagnosticos =
    diagnosticos && selectedMainDiagnostico
      ? diagnosticos
          .filter(
            (diag) =>
              diag.cod4 === selectedMainDiagnostico.value.cod4 &&
              diag.sub_codigo !== null
          )
          .sort((a, b) => a.nombre.localeCompare(b.nombre))
          .map((diag) => ({ value: diag.sub_codigo, label: diag.nombre }))
      : [];

  const editarSolicitudDiagnostico = () => {
    setShowDiagnostico(false);
  };

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  return (
    <React.Fragment>
      <Col>
        <Card className="border-0 shadow-sm">
          <CardHeader className="bg-white border-bottom border-light py-3">
            <div className="d-flex align-items-center">
              <i className="ri-stethoscope-line text-primary me-2 fs-20"></i>
              <h5 className="card-title mb-0">
                Diagnóstico & Consulta de Cobertura
              </h5>
            </div>
          </CardHeader>
          <CardBody className="p-4">
            {!showDiagnostico ? (
              <Form onSubmit={handleSubmitDiagnostico}>
                {showErrorMessage && (
                  <div
                    className="alert alert-danger d-flex align-items-center"
                    role="alert"
                  >
                    <i className="ri-error-warning-line me-2 fs-18"></i>
                    <div>Por favor complete todos los campos obligatorios</div>
                  </div>
                )}

                <div className="bg-light p-4 rounded mb-4">
                  <Row>
                    <Col md={6} className="mb-3">
                      <FormGroup>
                        <Label
                          for="mainDiagnosticoSelect"
                          className="form-label fw-medium"
                        >
                          Diagnóstico principal{" "}
                          <span className="text-danger">*</span>
                        </Label>
                        <Select
                          id="mainDiagnosticoSelect"
                          options={mainDiagnosticos}
                          value={selectedMainDiagnostico}
                          onChange={handleMainDiagnosticoChange}
                          placeholder="Seleccione un diagnóstico principal"
                          isClearable
                          className={
                            formErrors.mainDiagnostico ? "is-invalid" : ""
                          }
                          classNamePrefix="select"
                          styles={{
                            control: (base) => ({
                              ...base,
                              borderColor: formErrors.mainDiagnostico
                                ? "#dc3545"
                                : base.borderColor,
                              boxShadow: formErrors.mainDiagnostico
                                ? "0 0 0 0.25rem rgba(220, 53, 69, 0.25)"
                                : base.boxShadow,
                              "&:hover": {
                                borderColor: formErrors.mainDiagnostico
                                  ? "#dc3545"
                                  : base["&:hover"].borderColor,
                              },
                            }),
                          }}
                        />
                        {formErrors.mainDiagnostico && (
                          <div className="text-danger small mt-1">
                            <i className="ri-error-warning-line me-1"></i>
                            Por favor seleccione un diagnóstico principal
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md={6} className="mb-3">
                      <FormGroup>
                        <Label
                          for="subDiagnosticoSelect"
                          className="form-label fw-medium"
                        >
                          Sub-diagnóstico{" "}
                          {selectedMainDiagnostico &&
                            subDiagnosticos.length > 0 && (
                              <span className="text-danger">*</span>
                            )}
                        </Label>
                        <Select
                          id="subDiagnosticoSelect"
                          options={subDiagnosticos}
                          value={selectedSubDiagnostico}
                          onChange={handleSubDiagnosticoChange}
                          placeholder={
                            subDiagnosticos.length > 0
                              ? "Seleccione un sub-diagnóstico"
                              : "No hay sub-diagnósticos disponibles"
                          }
                          isDisabled={!selectedMainDiagnostico}
                          isClearable
                          className={
                            formErrors.subDiagnostico ? "is-invalid" : ""
                          }
                          classNamePrefix="select"
                          styles={{
                            control: (base) => ({
                              ...base,
                              borderColor: formErrors.subDiagnostico
                                ? "#dc3545"
                                : base.borderColor,
                              boxShadow: formErrors.subDiagnostico
                                ? "0 0 0 0.25rem rgba(220, 53, 69, 0.25)"
                                : base.boxShadow,
                              "&:hover": {
                                borderColor: formErrors.subDiagnostico
                                  ? "#dc3545"
                                  : base["&:hover"].borderColor,
                              },
                            }),
                          }}
                        />
                        {formErrors.subDiagnostico && (
                          <div className="text-danger small mt-1">
                            <i className="ri-error-warning-line me-1"></i>
                            Por favor seleccione un sub-diagnóstico
                          </div>
                        )}
                        {selectedMainDiagnostico &&
                          subDiagnosticos.length === 0 && (
                            <div className="text-muted small mt-1">
                              <i className="ri-information-line me-1"></i>
                              Este diagnóstico no tiene sub-diagnósticos
                              disponibles
                            </div>
                          )}
                      </FormGroup>
                    </Col>
                  </Row>

                  {solicitud.estatus !== "Cancelado" &&
                    solicitud.estatus !== "Entregado" && (
                      <div className="d-flex justify-content-end gap-2 mt-4">
                        {/* Botón de Cancelar */}
                        <Button
                          color="light"
                          className="d-flex align-items-center"
                          onClick={() => {
                            // Resetear el formulario y mostrar la tabla
                            setSelectedMainDiagnostico(null);
                            setSelectedSubDiagnostico(null);
                            setFormErrors({});
                            setShowErrorMessage(false);
                            setShowDiagnostico(true);
                          }}
                        >
                          <i className="ri-close-line me-1"></i>
                          Cancelar
                        </Button>

                        {/* Botón de Registrar */}
                        <Button
                          type="submit"
                          color="success"
                          className="d-flex align-items-center"
                          disabled={loadingx}
                        >
                          {loadingx ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm me-2"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              Registrando...
                            </>
                          ) : (
                            <>
                              <i className="ri-save-line me-1"></i>
                              Registrar Diagnóstico
                            </>
                          )}
                        </Button>
                      </div>
                    )}
                </div>
              </Form>
            ) : (
              <div className="d-flex justify-content-between align-items-center mb-4">
                <DiagnosticoTable
                  solicitud={solicitud}
                  onEdit={editarSolicitudDiagnostico}
                />

                {solicitud.estatus !== "Cancelado" &&
                  solicitud.estatus !== "Entregado" && (
                    <Button
                      color="light"
                      size="sm"
                      className="position-absolute top-0 end-0 mt-3 me-4"
                      onClick={editarSolicitudDiagnostico}
                    >
                      <i className="ri-pencil-line me-1"></i>
                      Editar
                    </Button>
                  )}
              </div>
            )}
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default Diagnostico;
