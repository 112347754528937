// Modifica el componente TiempoProcesamientoWidget.js

import React, { useState, useEffect } from 'react';
import { Spinner } from 'reactstrap';
import { APIClient } from "../../helpers/api_helper";

const TiempoProcesamientoWidget = ({ identificador }) => {
  const [datos, setDatos] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const obtenerDatos = async () => {
      try {
        setLoading(true);
        const api = new APIClient();
        
        // Usar el cliente API que ya tiene configurada la autenticación
        const response = await api.get(`/solicitud/${identificador}/tiempo-transcurrido`);
        
        // Verificar la estructura de la respuesta y hacer console.log para depuración
        //console.log("Respuesta del endpoint:", response);
        
        if (response && response.data) {
          setDatos(response.data.data);
        } else {
          setError('Formato de respuesta inesperado');
        }
      } catch (err) {
        setError(`Error: ${err.message}`);
        console.error("Error completo:", err);
      } finally {
        setLoading(false);
      }
    };

    if (identificador) {
      obtenerDatos();
      
      // Actualizar cada 5 minutos
      const intervalo = setInterval(obtenerDatos, 300000);
      return () => clearInterval(intervalo);
    }
  }, [identificador]);

  if (loading) {
    return (
      <div className="text-center py-3">
        <Spinner color="primary" size="sm" /> Cargando información...
      </div>
    );
  }
  
  if (error) {
    return <div className="text-danger">{error}</div>;
  }
  
  if (!datos) return null;

  // Verificar la estructura de datos antes de usarla
  const tiempoTranscurrido = datos.tiempo_transcurrido || {};
  const tiempoMaximo = datos.tiempo_maximo || {};
  const porcentaje = datos.porcentaje || 0;
  const estatus = datos.estatus || 'No disponible';
  const tipo = datos.tipo || 'No disponible';
  const fechaCreacion = datos.fecha_creacion || null;
  const fechaActual = datos.fecha_actual || null;

  // Determinar la clase de color basada en el porcentaje
  const getColorClass = () => {
    if (datos.color === "success") return "bg-success";
    if (datos.color === "warning") return "bg-warning";
    if (datos.color === "danger") return "bg-danger";
    return "bg-primary";
  };

  return (
    <div className="tiempo-procesamiento-widget">
      <div className="d-flex justify-content-between mb-2">
        <span className="fw-medium mb-4">Estado: {estatus}</span>
        <span className="badge bg-soft-primary">{tipo}</span>
      </div>
      
      <div className="progress animated-progress custom-progress progress-label">
        <div
          className={`progress-bar ${getColorClass()}`}
          role="progressbar"
          style={{ width: `${porcentaje}%` }}
          aria-valuenow={porcentaje}
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div className="label">{Math.round(porcentaje)}%</div>
        </div>
      </div>
      
      <div className="d-flex justify-content-between mt-2">
        <small className="text-muted">
          Transcurrido: {tiempoTranscurrido.horas || 0}h {tiempoTranscurrido.minutos || 0}m
        </small>
        <small className="text-muted">
          Tiempo máximo: {tiempoMaximo.horas || 0}h {tiempoMaximo.minutos || 0}m
        </small>
      </div>
      
      {porcentaje >= 100 && (
        <div className="alert alert-danger mt-2 py-1 px-2">
          <i className="ri-error-warning-line me-1"></i>
          Tiempo excedido. Se requiere atención inmediata.
        </div>
      )}
      
      {/* {fechaCreacion && (
        <div className="text-muted mt-2 small">
          <i className="ri-time-line me-1"></i>
          Creado: {new Date(fechaCreacion).toLocaleString()}
        </div>
      )} */}

{fechaActual && (
        <div className="text-muted mt-2 small">
          <i className="ri-time-line me-1"></i>
          A la Fecha: {new Date(fechaActual).toLocaleString()}
        </div>
      )}

    </div>
  );
};

export default TiempoProcesamientoWidget;