import React from 'react';

export const DatosSesion = (parametro) => {
  try {
    const authUserRaw = localStorage.getItem("authUser");
    
    if (!authUserRaw) {
      console.log("DatosSesion: No se encontró authUser en localStorage");
      return "";
    }
    
    const authUser = JSON.parse(authUserRaw);
    
    if (!authUser) {
      console.log("DatosSesion: authUser es null después de JSON.parse");
      return "";
    }
    
    // Depuración para ver qué datos están disponibles
    if (parametro === "debug") {
      return authUser;
    }
    
    // Si el parámetro solicitado no existe, mostrar un mensaje de depuración
    if (authUser[parametro] === undefined) {
      console.log(`DatosSesion: El parámetro '${parametro}' no existe en authUser`, authUser);
    }
    
    return authUser[parametro] || "";
  } catch (error) {
    console.error("Error en DatosSesion:", error);
    return "";
  }
};
