import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./slices";
import "./i18n";
import reportWebVitals from './reportWebVitals';

const consoleWarning = `
%c ¡ALTO! ⚠️ Peligro de fraude en Farme 
%cEsta consola es solo para desarrolladores. ¡NO pegues código aquí! Podrías perder el acceso a tu cuenta. Si alguien te lo pide, es un FRAUDE. Contacta a soporte en la app.`;

const consoleWarningStyles = [
    "color: red; font-size: 20px; font-weight: bold; text-shadow: 1px 1px 1px rgba(0,0,0,0.2);",
    "color: gray; font-size: 14px; line-height: 1.5;"
];

console.log(consoleWarning, ...consoleWarningStyles);

const store = configureStore({ reducer: rootReducer, devTools: true });

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <React.Fragment>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <App />
      </BrowserRouter>
    </React.Fragment>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();