import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody, Spinner } from "reactstrap";
import useGeolocation from "../hooks/useGeolocation";

// Variable para controlar si ya se ha iniciado la carga del script de Google Maps
let googleMapsScriptLoaded = false;

/**
 * Función para cargar el script de Google Maps
 * @param {string} apiKey - Clave de API de Google Maps
 * @returns {Promise} - Promesa que se resuelve cuando el script se ha cargado
 */
const loadGoogleMapsScript = (apiKey) => {
  return new Promise((resolve, reject) => {
    // Si el script ya está cargado, resolver inmediatamente
    if (window.google && window.google.maps) {
      resolve();
      return;
    }

    // Si ya se ha iniciado la carga, esperar a que termine
    if (googleMapsScriptLoaded) {
      const checkGoogleMaps = setInterval(() => {
        if (window.google && window.google.maps) {
          clearInterval(checkGoogleMaps);
          resolve();
        }
      }, 100);
      return;
    }

    // Marcar que se ha iniciado la carga
    googleMapsScriptLoaded = true;

    // Crear el script
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places,geometry`;
    script.async = true;
    script.defer = true;

    // Manejar eventos de carga y error
    script.onload = () => resolve();
    script.onerror = (error) => {
      googleMapsScriptLoaded = false;
      reject(new Error("Error al cargar el script de Google Maps"));
    };

    // Añadir el script al documento
    document.head.appendChild(script);
  });
};

/**
 * Componente para mostrar el mapa de ruta con Google Maps
 * @param {Object} props - Propiedades del componente
 * @returns {JSX.Element} - Componente de mapa de ruta
 */
const RouteMap = ({
  solicitudes = [],
  rutaActiva = null,
  rutaOptimizada = null,
  height = "400px",
  showCurrentLocation = true,
}) => {
  const mapRef = useRef(null);
  const mapInstanceRef = useRef(null);
  const markersRef = useRef([]);
  const polylineRef = useRef(null);
  const directionsRendererRef = useRef(null);
  const isRenderingRouteRef = useRef(false);
  const infoWindowRef = useRef([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [mapKey, setMapKey] = useState(Date.now());
  const [mapType, setMapType] = useState("roadmap"); // Definir el estado para el tipo de mapa

  // Usar el hook de geolocalización
  const { position, getCurrentPosition } = useGeolocation({
    autoInit: showCurrentLocation,
  });

  // Forzar actualización cuando cambian las solicitudes o la ruta activa
  useEffect(() => {
    // Generar una nueva clave para forzar la re-renderización del mapa
    setMapKey(Date.now());
  }, [solicitudes, rutaActiva]);

  // Inicializar el mapa cuando el componente se monta
  useEffect(() => {
    // Inicializar el mapa
    const initMap = async () => {
      try {
        setLoading(true);
        setError(null);

        // Cargar el script de Google Maps si aún no está cargado
        if (!window.google || !window.google.maps) {
          try {
            await loadGoogleMapsScript(process.env.REACT_APP_APIKEYGOOGLEMAPS);
          } catch (error) {
            console.error("Error al cargar el script de Google Maps:", error);
            setError(
              "No se pudo cargar el mapa. Verifica tu conexión a internet."
            );
            setLoading(false);
            return;
          }
        }

        // Obtener la posición actual
        let currentPosition = position;
        if (showCurrentLocation && !currentPosition) {
          try {
            currentPosition = await getCurrentPosition();
          } catch (error) {
            console.warn("No se pudo obtener la ubicación actual:", error);
          }
        }

        // Verificar que el elemento del mapa existe
        if (!mapRef.current) {
          console.error("El elemento del mapa no existe en el DOM");
          setError("No se pudo inicializar el mapa: elemento no encontrado");
          setLoading(false);
          return;
        }

        // Crear el mapa
        const defaultCenter = currentPosition
          ? [currentPosition[0], currentPosition[1]]
          : [10.4806, -66.9036]; // Caracas como ubicación predeterminada
        const mapOptions = {
          center: { lat: defaultCenter[0], lng: defaultCenter[1] },
          zoom: 12,
          mapTypeId: window.google.maps.MapTypeId.ROADMAP,
          mapTypeControl: true,
          fullscreenControl: true,
          streetViewControl: false,
          zoomControl: true,
        };

        const map = new window.google.maps.Map(mapRef.current, mapOptions);
        mapInstanceRef.current = map;

        // Crear el renderer de direcciones
        const directionsRenderer = new window.google.maps.DirectionsRenderer({
          suppressMarkers: false,
          polylineOptions: {
            strokeColor: "#4b38b3", // Color primario
            strokeOpacity: 0.8,
            strokeWeight: 5,
          },
        });
        directionsRenderer.setMap(map);
        directionsRendererRef.current = directionsRenderer;

        // Filtrar solicitudes para mostrar solo las pendientes
        let solicitudesFiltradas = solicitudes;

        // Si hay una ruta activa con estados de solicitudes, filtrar según el estado
        if (rutaActiva && rutaActiva.estados_solicitudes) {
          solicitudesFiltradas = solicitudes.filter((sol) => {
            const solId =
              typeof sol === "string"
                ? sol
                : sol.id || sol._id || sol.identificador;
            const estado = rutaActiva.estados_solicitudes[solId];
            // Solo mostrar solicitudes pendientes (no entregadas ni canceladas)
            return (
              !estado || (estado !== "ENTREGADO" && estado !== "CANCELADO")
            );
          });
        }

        // Si hay una ruta activa, mostrarla
        if (
          rutaActiva &&
          solicitudesFiltradas &&
          solicitudesFiltradas.length > 0
        ) {
          await renderRoute(solicitudesFiltradas);
        } else if (solicitudesFiltradas && solicitudesFiltradas.length > 0) {
          // Si no hay ruta activa pero hay solicitudes, mostrar marcadores
          renderMarkers(solicitudesFiltradas, currentPosition);
        }

        setLoading(false);
      } catch (error) {
        console.error("Error al inicializar el mapa:", error);
        setError("No se pudo cargar el mapa. " + error.message);
        setLoading(false);
      }
    };

    // Asegurarse de que el componente está montado antes de inicializar el mapa
    // Usar un pequeño retraso para asegurar que el DOM está listo
    const timer = setTimeout(() => {
      initMap();
    }, 500);

    // Limpiar al desmontar
    return () => {
      clearTimeout(timer);
      if (markersRef.current) {
        markersRef.current.forEach((marker) => marker.setMap(null));
        markersRef.current = [];
      }
      if (polylineRef.current) {
        polylineRef.current.setMap(null);
        polylineRef.current = null;
      }
    };
  }, [position, mapKey]);

  // Renderizar marcadores en el mapa
  const renderMarkers = (solicitudesParam, currentPositionParam) => {
    // Usar los parámetros o los valores del estado si no se proporcionan
    let solicitudesToUse = solicitudesParam || solicitudes;
    const currentPositionToUse = currentPositionParam || position;

    // Verificar que tenemos solicitudes válidas
    if (!solicitudesToUse || !Array.isArray(solicitudesToUse)) {
      console.warn("No hay solicitudes válidas para renderizar marcadores");
      return;
    }

    // Si tenemos ruta optimizada, ordenar las solicitudes según el orden en ruta_optima.paradas
    if (
      rutaOptimizada &&
      rutaOptimizada.ruta_optima &&
      rutaOptimizada.ruta_optima.paradas &&
      Array.isArray(rutaOptimizada.ruta_optima.paradas)
    ) {
      // Crear un mapa de orden por ID de solicitud
      const ordenPorId = {};
      rutaOptimizada.ruta_optima.paradas.forEach((parada) => {
        if (parada.id_solicitud) {
          ordenPorId[parada.id_solicitud] = parada.orden || 0;
        }
      });

      // Ordenar las solicitudes según el orden en ruta_optima.paradas
      solicitudesToUse = [...solicitudesToUse].sort((a, b) => {
        const idA = a.id || a._id || a.identificador;
        const idB = b.id || b._id || b.identificador;

        // Si ambos IDs están en el mapa de orden, ordenar según ese orden
        if (ordenPorId[idA] !== undefined && ordenPorId[idB] !== undefined) {
          return ordenPorId[idA] - ordenPorId[idB];
        }

        // Si solo uno está en el mapa, ponerlo primero
        if (ordenPorId[idA] !== undefined) return -1;
        if (ordenPorId[idB] !== undefined) return 1;

        // Si ninguno está en el mapa, mantener el orden original
        return 0;
      });
    }

    // Limpiar marcadores existentes
    if (markersRef.current) {
      markersRef.current.forEach((marker) => marker.setMap(null));
      markersRef.current = [];
    }

    // Crear límites para ajustar el zoom
    const bounds = new window.google.maps.LatLngBounds();

    // Añadir marcador de posición actual si está disponible
    if (currentPositionToUse) {
      const currentMarker = new window.google.maps.Marker({
        position: {
          lat: currentPositionToUse[0],
          lng: currentPositionToUse[1],
        },
        map: mapInstanceRef.current,
        icon: {
          path: window.google.maps.SymbolPath.CIRCLE,
          fillColor: "#4285F4", // Azul para la posición actual
          fillOpacity: 1,
          strokeWeight: 2,
          strokeColor: "#FFFFFF",
          scale: 10,
        },
        title: "Tu ubicación actual",
        zIndex: 2000, // Mayor que los otros marcadores para que esté por encima
      });
      markersRef.current.push(currentMarker);
      bounds.extend(currentMarker.getPosition());
    }

    // Añadir marcadores para cada solicitud
    solicitudesToUse.forEach((solicitud, index) => {
      if (
        solicitud.coordenadas &&
        Array.isArray(solicitud.coordenadas) &&
        solicitud.coordenadas.length >= 2
      ) {
        const position = {
          lat: solicitud.coordenadas[0],
          lng: solicitud.coordenadas[1],
        };

        // Extender los límites para incluir este punto
        bounds.extend(position);

        // Determinar el número del marcador
        let numeroMarcador = (index + 1).toString(); // Por defecto, usar índice + 1

        // Si tenemos ruta optimizada, usar el orden de la parada
        if (
          rutaOptimizada &&
          rutaOptimizada.ruta_optima &&
          rutaOptimizada.ruta_optima.paradas
        ) {
          // Crear un mapa de orden por ID de solicitud
          const ordenPorId = {};
          rutaOptimizada.ruta_optima.paradas.forEach((parada) => {
            if (parada.id_solicitud) {
              ordenPorId[parada.id_solicitud] = parada.orden || 0;
            }
          });

          // Encontrar la solicitud correspondiente
          const solicitudId =
            solicitud.id || solicitud._id || solicitud.identificador;

          // Si encontramos la solicitud, usar su orden como número
          if (ordenPorId[solicitudId] !== undefined) {
            let orden = ordenPorId[solicitudId];

            // Asegurarse de que orden sea un número
            if (typeof orden !== "number") {
              orden = parseInt(orden, 10);
              if (isNaN(orden)) {
                orden = index + 1;
              }
            }

            numeroMarcador = String(orden);
          }
        }

        // Crear un SVG personalizado con el número dentro
        const svgMarker = {
          path: "M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z",
          fillColor: "#FF0000",
          fillOpacity: 1,
          strokeWeight: 0,
          rotation: 0,
          scale: 2,
          anchor: new window.google.maps.Point(12, 17),
          labelOrigin: new window.google.maps.Point(12, 9),
        };

        const marker = new window.google.maps.Marker({
          position: position,
          map: mapInstanceRef.current,
          icon: svgMarker,
          label: {
            text: numeroMarcador,
            color: "#FFFFFF",
            fontSize: "12px",
            fontWeight: "bold",
          },
          title: solicitud.recibe || `Solicitud #${solicitud.identificador}`,
          animation: window.google.maps.Animation.DROP,
          zIndex: 1000, // Asegurar que esté por encima de otros marcadores
        });

        // Crear ventana de información
        const infoContent = `
          <div style="max-width: 200px;">
            <h6 style="margin-bottom: 5px;">${
              solicitud.recibe || "Cliente"
            }</h6>
            <p style="margin-bottom: 5px; font-size: 12px;">${
              solicitud.direccion || "Sin dirección"
            }</p>
            <p style="margin-bottom: 0; font-size: 12px;">
              <strong>Teléfono:</strong> ${solicitud.telefono || "N/A"}
            </p>
            <p style="margin-bottom: 0; font-size: 12px;">
              <strong>Orden en ruta:</strong> ${numeroMarcador}
            </p>
          </div>
        `;

        const infoWindow = new window.google.maps.InfoWindow({
          content: infoContent,
        });

        // Añadir evento de clic al marcador
        marker.addListener("click", () => {
          infoWindowRef.current.forEach((iw) => iw.close());
          infoWindow.open(mapInstanceRef.current, marker);
          infoWindowRef.current.push(infoWindow);
        });

        markersRef.current.push(marker);
      }
    });

    // Ajustar el mapa para mostrar todos los marcadores
    if (markersRef.current.length > 0) {
      mapInstanceRef.current.fitBounds(bounds);

      // Si solo hay un marcador, ajustar el zoom
      if (markersRef.current.length === 1) {
        mapInstanceRef.current.setZoom(15);
      }
    }
  };

  // Renderizar la ruta en el mapa
  const renderRoute = async (solicitudes) => {
    if (!solicitudes || solicitudes.length === 0) return;

    try {
      // Limpiar marcadores existentes
      if (markersRef.current) {
        markersRef.current.forEach((marker) => marker.setMap(null));
        markersRef.current = [];
      }

      // Obtener la posición actual
      let currentPosition = position;
      if (showCurrentLocation && !currentPosition) {
        try {
          currentPosition = await getCurrentPosition();
        } catch (error) {
          console.warn("No se pudo obtener la ubicación actual:", error);
        }
      }

      // Verificar si la ruta activa tiene información de paradas optimizadas
      if (rutaActiva && rutaActiva.info_ruta && rutaActiva.info_ruta.paradas) {
        renderOptimizedRoute(rutaActiva.info_ruta, currentPosition);
        return;
      }

      // Crear el servicio de direcciones
      const directionsService = new window.google.maps.DirectionsService();

      // Filtrar solicitudes con coordenadas válidas
      const validSolicitudes = solicitudes.filter(
        (s) =>
          s.coordenadas &&
          Array.isArray(s.coordenadas) &&
          s.coordenadas.length >= 2
      );

      if (validSolicitudes.length === 0) {
        setError(
          "No hay solicitudes con coordenadas válidas para generar la ruta"
        );
        return;
      }

      // Crear waypoints para la ruta
      const waypoints = validSolicitudes.slice(0, -1).map((solicitud) => ({
        location: new window.google.maps.LatLng(
          solicitud.coordenadas[0],
          solicitud.coordenadas[1]
        ),
        stopover: true,
      }));

      // Origen (ubicación actual o primera solicitud)
      const origin = currentPosition
        ? new window.google.maps.LatLng(currentPosition[0], currentPosition[1])
        : new window.google.maps.LatLng(
            validSolicitudes[0].coordenadas[0],
            validSolicitudes[0].coordenadas[1]
          );

      // Destino (última solicitud)
      const destination = new window.google.maps.LatLng(
        validSolicitudes[validSolicitudes.length - 1].coordenadas[0],
        validSolicitudes[validSolicitudes.length - 1].coordenadas[1]
      );

      // Configurar la solicitud de ruta
      const request = {
        origin,
        destination,
        waypoints: waypoints,
        optimizeWaypoints: true,
        travelMode: window.google.maps.TravelMode.DRIVING,
      };

      // Obtener la ruta
      directionsService.route(request, (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          directionsRendererRef.current.setDirections(result);

          // Calcular distancia y tiempo total
          let totalDistance = 0;
          let totalDuration = 0;

          const legs = result.routes[0].legs;
          for (let i = 0; i < legs.length; i++) {
            totalDistance += legs[i].distance.value;
            totalDuration += legs[i].duration.value;
          }

          // Convertir a kilómetros y minutos
          const distanceKm = (totalDistance / 1000).toFixed(1);
          const durationMin = Math.round(totalDuration / 60);
        } else {
          setError(`No se pudo generar la ruta: ${status}`);
          console.error("Error al generar la ruta:", status);

          // Si falla la ruta, mostrar marcadores
          renderMarkers(validSolicitudes, currentPosition);
        }
      });
    } catch (error) {
      console.error("Error al renderizar la ruta:", error);
      setError("Error al generar la ruta: " + error.message);
    }
  };

  // Renderizar ruta optimizada usando la información de paradas del endpoint
  const renderOptimizedRoute = (infoRuta, currentPosition) => {
    try {
      // Indicar que estamos renderizando una ruta optimizada para evitar llamadas duplicadas
      isRenderingRouteRef.current = true;

      // Verificar que tenemos un mapa inicializado
      if (!mapInstanceRef.current) {
        console.error("Error: El mapa no está inicializado");
        isRenderingRouteRef.current = false;
        return;
      }

      // Verificar y corregir las coordenadas de las paradas si es necesario
      if (
        infoRuta &&
        infoRuta.ruta_optima &&
        infoRuta.ruta_optima.paradas &&
        Array.isArray(infoRuta.ruta_optima.paradas)
      ) {
        // Crear una copia profunda de infoRuta para no mutar el estado original
        const infoRutaCopia = JSON.parse(JSON.stringify(infoRuta));

        // Filtrar paradas según el estado de las solicitudes
        let paradasFiltradas = infoRutaCopia.ruta_optima.paradas;

        // Verificar si la solicitud está entregada o cancelada directamente en las solicitudes
        if (rutaActiva && rutaActiva.solicitudes) {
          paradasFiltradas = paradasFiltradas.filter((parada) => {
            const solId = parada.id_solicitud;
            if (!solId) return true; // Si no tiene ID de solicitud, mantenerla

            // Buscar la solicitud correspondiente
            const solicitud = solicitudes.find(
              (s) =>
                s.id === solId || s._id === solId || s.identificador === solId
            );

            // Si encontramos la solicitud y tiene estado, filtrar según el estado
            if (solicitud && solicitud.estado) {
              return (
                solicitud.estado !== "ENTREGADO" &&
                solicitud.estado !== "CANCELADO"
              );
            }

            return true; // Si no encontramos la solicitud o no tiene estado, mantenerla
          });
        }

        // NO ordenar las paradas, mantener el orden exacto de rutaActiva.ruta_optima
        // Esto es importante porque el orden de las paradas en rutaActiva.ruta_optima
        // ya está optimizado y debe respetarse exactamente

        // Verificar y corregir las coordenadas de las paradas si es necesario
        const paradasCorregidas = paradasFiltradas.map((parada) => {
          // Si la parada ya tiene coordenadas válidas, devolverla sin cambios
          if (
            parada.coordenadas &&
            Array.isArray(parada.coordenadas) &&
            parada.coordenadas.length >= 2
          ) {
            // Asegurarse de que las coordenadas sean números
            const lat = parseFloat(parada.coordenadas[0]);
            const lng = parseFloat(parada.coordenadas[1]);

            if (!isNaN(lat) && !isNaN(lng)) {
              return {
                ...parada,
                coordenadas: [lat, lng],
              };
            }
          }

          // Si la parada tiene lat y lng, crear coordenadas a partir de ellas
          if (parada.lat !== undefined && parada.lng !== undefined) {
            const lat = parseFloat(parada.lat);
            const lng = parseFloat(parada.lng);

            if (!isNaN(lat) && !isNaN(lng)) {
              return {
                ...parada,
                coordenadas: [lat, lng],
              };
            }
          }

          // Si la parada tiene latitud y longitud, crear coordenadas a partir de ellas
          if (parada.latitud !== undefined && parada.longitud !== undefined) {
            const lat = parseFloat(parada.latitud);
            const lng = parseFloat(parada.longitud);

            if (!isNaN(lat) && !isNaN(lng)) {
              return {
                ...parada,
                coordenadas: [lat, lng],
              };
            }
          }

          return parada;
        });

        // Actualizar las paradas con las coordenadas corregidas
        infoRutaCopia.ruta_optima.paradas = paradasCorregidas;

        // Usar la copia corregida en lugar del original
        infoRuta = infoRutaCopia;

        // Generar log detallado de las paradas
      }

      // Limpiar todos los marcadores existentes
      if (markersRef.current) {
        markersRef.current.forEach((marker) => marker.setMap(null));
        markersRef.current = [];
      }

      // Limpiar polyline anterior
      if (polylineRef.current) {
        polylineRef.current.setMap(null);
        polylineRef.current = null;
      }

      // Limpiar directionsRenderer anterior
      if (directionsRendererRef.current) {
        directionsRendererRef.current.setMap(null);
      }

      // Cargar el logo de Farme para usar como icono personalizado
      const logoFarme = {
        url: "/static/media/mini-logo.png",
        scaledSize: new window.google.maps.Size(24, 24),
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(12, 12),
      };

      // Crear nuevo directionsRenderer
      directionsRendererRef.current = new window.google.maps.DirectionsRenderer(
        {
          suppressMarkers: true, // Importante para que no muestre los marcadores predeterminados
          polylineOptions: {
            strokeColor: "#4285F4",
            strokeOpacity: 0.8,
            strokeWeight: 5,
          },
        }
      );

      // Asignar el mapa al directionsRenderer
      directionsRendererRef.current.setMap(mapInstanceRef.current);

      // Crear límites para ajustar el mapa
      const bounds = new window.google.maps.LatLngBounds();

      // Crear marcadores para todas las paradas en el orden exacto de la ruta optimizada
      infoRuta.ruta_optima.paradas.forEach((parada) => {
        if (parada.coordenadas && parada.coordenadas.length >= 2) {
          const position = {
            lat: parseFloat(parada.coordenadas[0]),
            lng: parseFloat(parada.coordenadas[1]),
          };

          // Extender los límites para incluir este punto
          bounds.extend(position);

          // Determinar el tipo de marcador según el tipo de parada
          if (parada.tipo === "origen") {
            // Crear un SVG personalizado con el número dentro
            const svgMarker = {
              path: "M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z",
              fillColor: "#4b38b3", // Color morado para la farmacia
              fillOpacity: 1,
              strokeWeight: 0,
              rotation: 0,
              scale: 2,
              anchor: new window.google.maps.Point(12, 17),
              labelOrigin: new window.google.maps.Point(12, 9),
            };

            const farmaciaMarker = new window.google.maps.Marker({
              position: position,
              map: mapInstanceRef.current,
              icon: svgMarker,
              label: {
                text: "0",
                color: "#FFFFFF",
                fontSize: "12px",
                fontWeight: "bold",
              },
              title: "Farmacia (Origen)",
              animation: window.google.maps.Animation.DROP,
              zIndex: 1000, // Asegurar que esté por encima de otros marcadores
            });

            // Crear ventana de información para la farmacia
            const infoWindow = new window.google.maps.InfoWindow({
              content: `
                <div style="max-width: 200px; padding: 10px;">
                  <h5 style="margin-top: 0; color: #4CAF50;">Farmacia (Origen)</h5>
                  <p style="margin-bottom: 5px; font-size: 12px;">
                    <strong>Dirección:</strong> ${parada.direccion || "N/A"}
                  </p>
                  ${
                    parada.distancia_siguiente !== undefined
                      ? `<p style="margin-bottom: 0; font-size: 12px;">
                          <strong>Distancia a siguiente:</strong> ${parseFloat(
                            parada.distancia_siguiente
                          ).toFixed(2)} km
                        </p>`
                      : ""
                  }
                  ${
                    parada.tiempo_siguiente !== undefined
                      ? `<p style="margin-bottom: 0; font-size: 12px;">
                          <strong>Tiempo a siguiente:</strong> ${Math.round(
                            parseFloat(parada.tiempo_siguiente)
                          )} min
                        </p>`
                      : ""
                  }
                </div>
              `,
            });

            // Añadir evento de clic al marcador
            farmaciaMarker.addListener("click", () => {
              infoWindowRef.current.forEach((iw) => iw.close());
              infoWindow.open(mapInstanceRef.current, farmaciaMarker);
              infoWindowRef.current.push(infoWindow);
            });

            markersRef.current.push(farmaciaMarker);
          } else if (parada.tipo === "destino") {
            // Encontrar la solicitud correspondiente a esta parada
            const solicitud = solicitudes.find(
              (s) =>
                s.id === parada.id_solicitud ||
                s._id === parada.id_solicitud ||
                s.identificador === parada.id_solicitud
            );

            // Usar el número de orden para los destinos
            // La farmacia es 0, las paradas de destino usan su número de orden
            let numeroParada = parada.orden;

            // Asegurarse de que orden sea un número
            if (typeof numeroParada !== "number") {
              numeroParada = parseInt(numeroParada, 10);
              if (isNaN(numeroParada)) {
                // Buscar el índice de esta parada en el array de paradas
                const index = infoRuta.ruta_optima.paradas.findIndex(
                  (p) => p.id_solicitud === parada.id_solicitud
                );
                // Usar el índice + 1 como número (para evitar el 0)
                numeroParada = index >= 0 ? index + 1 : 1;
              }
            }

            // Crear un SVG personalizado con el número dentro
            const svgMarker = {
              path: "M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z",
              fillColor: "#FF0000",
              fillOpacity: 1,
              strokeWeight: 0,
              rotation: 0,
              scale: 2,
              anchor: new window.google.maps.Point(12, 17),
              labelOrigin: new window.google.maps.Point(12, 9),
            };

            // Marcador para la solicitud (destino)
            const marker = new window.google.maps.Marker({
              position: position,
              map: mapInstanceRef.current,
              icon: svgMarker,
              label: {
                text: String(numeroParada),
                color: "#FFFFFF",
                fontSize: "12px",
                fontWeight: "bold",
              },
              title: solicitud
                ? solicitud.recibe || `Solicitud #${solicitud.identificador}`
                : `Parada ${numeroParada}`,
              animation: window.google.maps.Animation.DROP,
              zIndex: 1000, // Asegurar que esté por encima de otros marcadores
            });

            // Crear ventana de información para la parada
            const infoWindow = new window.google.maps.InfoWindow({
              content: `
                <div style="max-width: 200px; padding: 10px;">
                  <h5 style="margin-top: 0;">${
                    solicitud ? solicitud.recibe || "Cliente" : "Destino"
                  }</h5>
                  <p style="margin-bottom: 5px; font-size: 12px;">
                    <strong>Dirección:</strong> ${parada.direccion || "N/A"}
                  </p>
                  ${
                    solicitud
                      ? `<p style="margin-bottom: 0; font-size: 12px;">
                          <strong>Teléfono:</strong> ${
                            solicitud.telefono || "N/A"
                          }
                        </p>`
                      : ""
                  }
                  <p style="margin-bottom: 0; font-size: 12px;">
                    <strong>Orden en ruta:</strong> ${parada.orden}
                  </p>
                  ${
                    parada.distancia_desde_anterior !== undefined
                      ? `<p style="margin-bottom: 0; font-size: 12px;">
                          <strong>Distancia desde anterior:</strong> ${parseFloat(
                            parada.distancia_desde_anterior
                          ).toFixed(2)} km
                        </p>`
                      : ""
                  }
                  ${
                    parada.tiempo_desde_anterior !== undefined
                      ? `<p style="margin-bottom: 0; font-size: 12px;">
                          <strong>Tiempo desde anterior:</strong> ${Math.round(
                            parseFloat(parada.tiempo_desde_anterior)
                          )} min
                        </p>`
                      : ""
                  }
                  ${
                    parada.distancia_siguiente !== undefined
                      ? `<p style="margin-bottom: 0; font-size: 12px;">
                          <strong>Distancia a siguiente:</strong> ${parseFloat(
                            parada.distancia_siguiente
                          ).toFixed(2)} km
                        </p>`
                      : ""
                  }
                  ${
                    parada.tiempo_siguiente !== undefined
                      ? `<p style="margin-bottom: 0; font-size: 12px;">
                          <strong>Tiempo a siguiente:</strong> ${Math.round(
                            parseFloat(parada.tiempo_siguiente)
                          )} min
                        </p>`
                      : ""
                  }
                </div>
              `,
            });

            // Añadir evento de clic al marcador
            marker.addListener("click", () => {
              infoWindowRef.current.forEach((iw) => iw.close());
              infoWindow.open(mapInstanceRef.current, marker);
              infoWindowRef.current.push(infoWindow);
            });

            markersRef.current.push(marker);
          }
        }
      });

      // Ajustar el mapa para mostrar todos los marcadores
      if (markersRef.current.length > 0) {
        mapInstanceRef.current.fitBounds(bounds);

        // Si solo hay un marcador, ajustar el zoom
        if (markersRef.current.length === 1) {
          mapInstanceRef.current.setZoom(15);
        }
      }

      // Si hay más de una parada, calcular la ruta
      if (infoRuta.ruta_optima.paradas.length > 1) {
        // Usar el servicio de direcciones de Google Maps
        const directionsService = new window.google.maps.DirectionsService();

        // Encontrar la parada de origen
        const paradaOrigen = infoRuta.ruta_optima.paradas.find(
          (parada) => parada.tipo === "origen"
        );

        // Encontrar las paradas de destino ordenadas
        const paradasDestino = infoRuta.ruta_optima.paradas
          .filter((parada) => parada.tipo === "destino")
          .sort((a, b) => a.orden - b.orden);

        // Origen (farmacia)
        const origin = new window.google.maps.LatLng(
          parseFloat(paradaOrigen.coordenadas[0]),
          parseFloat(paradaOrigen.coordenadas[1])
        );

        // Destino (última parada)
        const destination = new window.google.maps.LatLng(
          parseFloat(paradasDestino[paradasDestino.length - 1].coordenadas[0]),
          parseFloat(paradasDestino[paradasDestino.length - 1].coordenadas[1])
        );

        // Waypoints (paradas intermedias)
        const waypoints = paradasDestino.slice(0, -1).map((parada) => {
          const lat = parseFloat(parada.coordenadas[0]);
          const lng = parseFloat(parada.coordenadas[1]);

          return {
            location: new window.google.maps.LatLng(lat, lng),
            stopover: true,
          };
        });

        // Configurar la solicitud de ruta
        const request = {
          origin: origin,
          destination: destination,
          waypoints: waypoints,
          optimizeWaypoints: false, // No optimizar, ya tenemos el orden del backend
          travelMode: window.google.maps.TravelMode.DRIVING,
        };

        // Obtener la ruta
        directionsService.route(request, (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            directionsRendererRef.current.setDirections(result);

            // Las distancias y tiempos ya vienen en km y minutos desde el backend
            let distance = infoRuta.distancia_total;
            let duration = infoRuta.tiempo_total;
          } else {
            console.error("Error al calcular la ruta:", status);

            // Si falla el servicio de direcciones, crear una polyline simple
            try {
              const path = [
                { lat: origin.lat(), lng: origin.lng() },
                ...waypoints.map((wp) => ({
                  lat: wp.location.lat(),
                  lng: wp.location.lng(),
                })),
                { lat: destination.lat(), lng: destination.lng() },
              ];

              createSimplePolyline(path);
            } catch (error) {
              console.error("Error al crear polyline simple:", error);
            }
          }

          // Indicar que hemos terminado de renderizar la ruta optimizada
          isRenderingRouteRef.current = false;
        });
      } else {
        console.error("No hay suficientes paradas para calcular una ruta");
        // Si no hay suficientes paradas, marcar como terminado
        isRenderingRouteRef.current = false;
      }

      // No continuar con el resto de la función
      return;
    } catch (error) {
      console.error("Error al renderizar la ruta optimizada:", error);
      setError("Error al renderizar la ruta optimizada: " + error.message);

      // En caso de error, marcar como terminado
      isRenderingRouteRef.current = false;
    }
  };

  // Crear una polyline simple entre puntos cuando falla Directions Service
  const createSimplePolyline = (locations) => {
    // Limpiar polyline existente
    if (polylineRef.current) {
      polylineRef.current.setMap(null);
    }

    // Crear path para la polyline
    const path = locations.map(
      (loc) => new window.google.maps.LatLng(loc.lat, loc.lng)
    );

    // Crear polyline
    polylineRef.current = new window.google.maps.Polyline({
      path: path,
      geodesic: true,
      strokeColor: "#4b38b3",
      strokeOpacity: 0.8,
      strokeWeight: 5,
    });

    // Asignar al mapa
    polylineRef.current.setMap(mapInstanceRef.current);

    // Crear límites para ajustar el mapa
    const bounds = new window.google.maps.LatLngBounds();

    // Extender los límites para incluir todos los puntos
    path.forEach((point) => bounds.extend(point));

    // Ajustar el mapa para mostrar todos los puntos
    mapInstanceRef.current.fitBounds(bounds);
  };

  // Efecto para actualizar el mapa cuando cambian las solicitudes o la ruta activa
  useEffect(() => {
    if (mapInstanceRef.current && solicitudes.length > 0) {
      // Evitamos que se llame a updateMapMarkers si estamos en proceso de renderizar una ruta optimizada
      if (!isRenderingRouteRef.current) {
        updateMapMarkers();
      }
    }
  }, [solicitudes, rutaActiva]);

  // Efecto específico para la ruta optimizada
  useEffect(() => {
    if (mapInstanceRef.current && rutaOptimizada) {
      // Evitamos que se llame a renderOptimizedRoute si estamos en proceso de renderizar una ruta optimizada
      if (!isRenderingRouteRef.current) {
        renderOptimizedRoute(rutaOptimizada, position);
      }
    }
  }, [rutaOptimizada]);

  // Función para actualizar los marcadores en el mapa
  const updateMapMarkers = () => {
    // Si no hay mapa o está en proceso de renderizar una ruta optimizada, no hacer nada
    if (!mapInstanceRef.current || isRenderingRouteRef.current) {
      return;
    }

    // Limpiar marcadores existentes
    clearMarkers();

    // Si hay una ruta optimizada, usarla en lugar de renderizar marcadores individuales
    if (
      rutaOptimizada &&
      rutaOptimizada.ruta_optima &&
      rutaOptimizada.ruta_optima.paradas &&
      Array.isArray(rutaOptimizada.ruta_optima.paradas) &&
      rutaOptimizada.ruta_optima.paradas.length > 0
    ) {
      renderOptimizedRoute(rutaOptimizada, position);
      return;
    }

    // Si hay ruta activa pero no hay ruta optimizada
    if (
      rutaActiva &&
      rutaActiva.solicitudes &&
      Array.isArray(rutaActiva.solicitudes) &&
      rutaActiva.solicitudes.length > 0
    ) {
      // Crear una estructura similar a ruta_optima.paradas para la ruta activa
      const rutaActivaConParadas = {
        ruta_optima: {
          paradas: [
            // Primero añadimos el origen (farmacia) como parada 0
            {
              tipo: "origen",
              orden: 0,
              direccion: "Farmacia",
              // Usar la posición actual como coordenadas de la farmacia si está disponible
              coordenadas: position || [10.4806, -66.9036], // Coordenadas por defecto si no hay posición
            },
            // Luego añadimos las solicitudes como paradas
            ...solicitudes
              .filter((solicitud) => {
                // Filtrar solicitudes que pertenecen a la ruta activa
                const solicitudId =
                  solicitud.id || solicitud._id || solicitud.identificador;

                return rutaActiva.solicitudes.some(
                  (s) => (s.id || s._id || s) === solicitudId
                );
              })
              .map((solicitud, index) => ({
                tipo: "destino",
                orden: index + 1, // Asignar orden secuencial a las solicitudes
                id_solicitud:
                  solicitud.id || solicitud._id || solicitud.identificador,
                direccion: solicitud.direccion || "Sin dirección",
                coordenadas: solicitud.coordenadas || [
                  solicitud.latitud,
                  solicitud.longitud,
                ],
              })),
          ],
        },
      };

      // Usar renderOptimizedRoute con la estructura creada
      renderOptimizedRoute(rutaActivaConParadas, position);
      return;
    }

    // Crear una estructura similar a ruta_optima.paradas para las solicitudes sin ruta
    const solicitudesSinRuta = {
      ruta_optima: {
        paradas: [
          // Primero añadimos el origen (farmacia) como parada 0
          {
            tipo: "origen",
            orden: 0,
            direccion: "Farmacia",
            // Usar la posición actual como coordenadas de la farmacia si está disponible
            coordenadas: position || [10.4806, -66.9036], // Coordenadas por defecto si no hay posición
          },
          // Luego añadimos todas las solicitudes como paradas
          ...solicitudes.map((solicitud, index) => ({
            tipo: "destino",
            orden: index + 1, // Asignar orden secuencial a las solicitudes
            id_solicitud:
              solicitud.id || solicitud._id || solicitud.identificador,
            direccion: solicitud.direccion || "Sin dirección",
            coordenadas: solicitud.coordenadas || [
              solicitud.latitud,
              solicitud.longitud,
            ],
          })),
        ],
      },
    };

    // Usar renderOptimizedRoute con la estructura creada
    renderOptimizedRoute(solicitudesSinRuta, position);
  };

  // Limpiar marcadores
  const clearMarkers = () => {
    if (markersRef.current) {
      markersRef.current.forEach((marker) => marker.setMap(null));
      markersRef.current = [];
    }
  };

  // Manejar el cambio de tipo de mapa
  const handleMapTypeChange = (type) => {
    setMapType(type);
    if (mapInstanceRef.current) {
      mapInstanceRef.current.setMapTypeId(type);
    }
  };

  // Función para generar URL de Google Maps con waypoints
  const generateGoogleMapsUrl = () => {
    // Si no hay ruta optimizada, no generar URL
    if (
      !rutaOptimizada ||
      !rutaOptimizada.ruta_optima ||
      !rutaOptimizada.ruta_optima.paradas ||
      rutaOptimizada.ruta_optima.paradas.length < 2
    ) {
      return null;
    }

    // Encontrar el origen (farmacia)
    const origen = rutaOptimizada.ruta_optima.paradas.find(
      (parada) => parada.tipo === "origen"
    );
    if (!origen || !origen.coordenadas || origen.coordenadas.length < 2) {
      return null;
    }

    // Filtrar paradas de destino válidas (con coordenadas y que no estén entregadas o canceladas)
    const destinos = rutaOptimizada.ruta_optima.paradas.filter((parada) => {
      // Solo incluir destinos
      if (parada.tipo !== "destino") return false;

      // Verificar que tenga coordenadas válidas
      if (!parada.coordenadas || parada.coordenadas.length < 2) return false;

      // Si tiene ID de solicitud, verificar que no esté entregada o cancelada
      if (parada.id_solicitud) {
        const solicitud = solicitudes.find(
          (s) =>
            s.id === parada.id_solicitud ||
            s._id === parada.id_solicitud ||
            s.identificador === parada.id_solicitud
        );
        if (solicitud && solicitud.estado) {
          return (
            solicitud.estado !== "ENTREGADO" && solicitud.estado !== "CANCELADO"
          );
        }
      }

      return true;
    });

    // Si no hay destinos, no generar URL
    if (destinos.length === 0) {
      return null;
    }

    // Coordenadas de origen
    const origenCoords = `${origen.coordenadas[0]},${origen.coordenadas[1]}`;

    // Último destino (para el destino final)
    const ultimoDestino = destinos[destinos.length - 1];
    const destinoFinalCoords = `${ultimoDestino.coordenadas[0]},${ultimoDestino.coordenadas[1]}`;

    // Waypoints (todos los destinos excepto el último)
    const waypoints = destinos
      .slice(0, -1)
      .map((parada) => `${parada.coordenadas[0]},${parada.coordenadas[1]}`)
      .join("|");

    // Construir URL de Google Maps
    let url = `https://www.google.com/maps/dir/?api=1&origin=${origenCoords}&destination=${destinoFinalCoords}`;

    // Agregar waypoints si hay más de un destino
    if (waypoints) {
      url += `&waypoints=${waypoints}`;
    }

    // Agregar modo de transporte (driving)
    url += "&travelmode=driving";

    return url;
  };

  // Función para generar URL de imagen estática de Google Maps
  const generateStaticMapUrl = () => {
    // Si no hay ruta optimizada, no generar URL
    if (
      !rutaOptimizada ||
      !rutaOptimizada.ruta_optima ||
      !rutaOptimizada.ruta_optima.paradas ||
      rutaOptimizada.ruta_optima.paradas.length < 2
    ) {
      return null;
    }

    // Encontrar el origen (farmacia)
    const origen = rutaOptimizada.ruta_optima.paradas.find(
      (parada) => parada.tipo === "origen"
    );
    if (!origen || !origen.coordenadas || origen.coordenadas.length < 2) {
      return null;
    }

    // Filtrar paradas de destino válidas (con coordenadas y que no estén entregadas o canceladas)
    const destinos = rutaOptimizada.ruta_optima.paradas.filter((parada) => {
      // Solo incluir destinos
      if (parada.tipo !== "destino") return false;

      // Verificar que tenga coordenadas válidas
      if (!parada.coordenadas || parada.coordenadas.length < 2) return false;

      // Si tiene ID de solicitud, verificar que no esté entregada o cancelada
      if (parada.id_solicitud) {
        const solicitud = solicitudes.find(
          (s) =>
            s.id === parada.id_solicitud ||
            s._id === parada.id_solicitud ||
            s.identificador === parada.id_solicitud
        );
        if (solicitud && solicitud.estado) {
          return (
            solicitud.estado !== "ENTREGADO" && solicitud.estado !== "CANCELADO"
          );
        }
      }

      return true;
    });

    // Si no hay destinos, no generar URL
    if (destinos.length === 0) {
      return null;
    }

    // Tamaño de la imagen
    const size = "600x400";

    // Marcadores para cada parada
    let markers = [];

    // Marcador para el origen (color verde)
    markers.push(
      `markers=color:green|label:0|${origen.coordenadas[0]},${origen.coordenadas[1]}`
    );

    // Marcadores para los destinos (color rojo)
    destinos.forEach((parada, index) => {
      markers.push(
        `markers=color:red|label:${index + 1}|${parada.coordenadas[0]},${
          parada.coordenadas[1]
        }`
      );
    });

    // Ruta entre los puntos
    const path = [origen, ...destinos]
      .map((parada) => `${parada.coordenadas[0]},${parada.coordenadas[1]}`)
      .join("|");

    // Construir URL de la imagen estática
    let url = `https://maps.googleapis.com/maps/api/staticmap?size=${size}&maptype=${mapType}`;

    // Agregar marcadores
    markers.forEach((marker) => {
      url += `&${marker}`;
    });

    // Agregar ruta
    url += `&path=color:0x4b38b3|weight:5|${path}`;

    // Agregar clave API (si es necesario)
    // url += `&key=YOUR_API_KEY`;

    return url;
  };

  return (
    <Card className="mb-4 border-0 shadow-sm">
      <CardBody>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h5 className="mb-0">Ruta de Entrega Actual</h5>
        </div>

        {/* Representación visual de la ruta con iconografía */}
        <div
          style={{
            width: "100%",
            height: "auto",
            minHeight: "180px",
            borderRadius: "8px",
            backgroundColor: "#f8f9fa",
            border: "1px solid #e9ecef",
            padding: "20px",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {rutaOptimizada &&
          rutaOptimizada.ruta_optima &&
          rutaOptimizada.ruta_optima.paradas &&
          rutaOptimizada.ruta_optima.paradas.length > 0 ? (
            <div className="text-center">
              {/* Solo el icono principal */}
              <div
                className="bg-primary text-white p-3 rounded-circle"
                style={{
                  width: "120px",
                  height: "120px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <i
                  className="ri-takeaway-fill"
                  style={{ fontSize: "70px" }}
                ></i>
              </div>
            </div>
          ) : (
            <div className="text-center">
              <i
                className="mdi mdi-map-marker-off text-muted"
                style={{ fontSize: "70px" }}
              ></i>
              <p className="text-muted mb-0">No hay ruta disponible</p>
            </div>
          )}
        </div>

        {/* Enlace a Google Maps */}
        {generateGoogleMapsUrl() && (
          <div className="mt-3 text-center">
            <a
              href={generateGoogleMapsUrl()}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-primary"
            >
              <i className="mdi mdi-google-maps me-1"></i>
              Abrir navegación en Google Maps
            </a>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default RouteMap;
