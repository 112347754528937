import React from "react";
import { Card, CardBody } from "reactstrap";
import { format, parse } from "date-fns";

/**
 * Componente para mostrar una tarjeta de solicitud
 * @param {Object} props - Propiedades del componente
 * @returns {JSX.Element} - Componente de tarjeta de solicitud
 */
const SolicitudCard = ({
  solicitud,
  orden,
  onConfirmClick,
  onCancelClick,
  showActions = true
}) => {
  // Obtener el último delivery de la solicitud
  const getLastDelivery = (solicitud) => {
    if (!solicitud.delivery || !Array.isArray(solicitud.delivery)) {
      return null;
    }
    return solicitud.delivery.length > 0
      ? solicitud.delivery[solicitud.delivery.length - 1]
      : null;
  };

  const lastDelivery = getLastDelivery(solicitud);

  // Formatear fecha
  const formatDate = (date) => {
    if (!date) return "";
    try {
      const parsedDate = parse(date, "yyyy-MM-dd'T'HH:mm:ss", new Date());
      return format(parsedDate, "dd/MM/yyyy HH:mm");
    } catch (error) {
      return date;
    }
  };

  return (
    <Card className="mb-3 border-0 shadow-sm">
      <CardBody>
        {/* Header con número de orden y estado */}
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h5 className="mb-0">
            Parada #{orden} - Orden #{solicitud.identificador}
          </h5>
          <span
            className={`badge bg-${
              solicitud.estatus === "Entregado"
                ? "success"
                : solicitud.estatus === "Cancelado"
                ? "danger"
                : "warning"
            }-subtle text-${
              solicitud.estatus === "Entregado"
                ? "success"
                : solicitud.estatus === "Cancelado"
                ? "danger"
                : "warning"
            }`}
          >
            {solicitud.estatus}
          </span>
        </div>

        {/* Información del cliente */}
        <div className="d-flex align-items-center mb-4">
          <div className="avatar-sm me-3">
            <div className="avatar-title bg-light rounded-circle">
              <i className="mdi mdi-account fs-20"></i>
            </div>
          </div>
          <div>
            <h5 className="fs-15 mb-1">{solicitud.recibe}</h5>
            <a href={`tel:${solicitud.telefono}`} className="text-muted">
              <i className="mdi mdi-phone me-1"></i>
              {solicitud.telefono}
            </a>
          </div>
        </div>

        {/* Dirección */}
        <div className="mb-4">
          <div className="d-flex align-items-center mb-2">
            <i className="mdi mdi-map-marker text-primary me-1 fs-18"></i>
            <h6 className="mb-0">Dirección de entrega</h6>
          </div>
          {solicitud.coordenadas && Array.isArray(solicitud.coordenadas) && (
            <a
              href={`https://www.google.com/maps?q=${solicitud.coordenadas[0]},${solicitud.coordenadas[1]}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-muted d-block"
            >
              {solicitud.direccion}
              <i className="mdi mdi-chevron-right ms-1"></i>
            </a>
          )}
        </div>

        {/* Información adicional */}
        <div className="d-flex gap-4 mb-4">
          <div>
            <i className="mdi mdi-clock-outline text-primary me-1"></i>
            {formatDate(lastDelivery?.fecha)}
          </div>
          <div>
            <i className="mdi mdi-road-variant text-primary me-1"></i>
            {lastDelivery?.distancia ? `${lastDelivery.distancia} km` : "N/A"}
          </div>
        </div>

        {/* Botones de acción */}
        {showActions && (
          <div className="hstack gap-2">
            <button
              className="btn btn-soft-danger w-100"
              onClick={() => onCancelClick(solicitud)}
            >
              <i className="mdi mdi-close-circle me-1"></i>
              Cancelar Entrega
            </button>
            <button
              className="btn btn-soft-success w-100"
              onClick={() => onConfirmClick(solicitud)}
            >
              <i className="mdi mdi-check-circle me-1"></i>
              Confirmar Entrega
            </button>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default SolicitudCard;