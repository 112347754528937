/**
 * Helper para manejar la protección CSRF (Cross-Site Request Forgery)
 */

// Clave para almacenar el token CSRF en sessionStorage
const CSRF_TOKEN_KEY = 'csrf_token';

/**
 * Genera un token CSRF aleatorio
 * @returns {string} Token CSRF generado
 */
export const generateCsrfToken = () => {
  // Generar un token aleatorio de 32 caracteres
  const randomToken = Array.from(
    new Uint8Array(32),
    () => Math.floor(Math.random() * 36).toString(36)
  ).join('');
  
  // Guardar el token en sessionStorage
  sessionStorage.setItem(CSRF_TOKEN_KEY, randomToken);
  
  return randomToken;
};

/**
 * Obtiene el token CSRF actual, o genera uno nuevo si no existe
 * @returns {string} Token CSRF
 */
export const getCsrfToken = () => {
  let token = sessionStorage.getItem(CSRF_TOKEN_KEY);
  
  if (!token) {
    token = generateCsrfToken();
  }
  
  return token;
};

/**
 * Añade el token CSRF a los headers de una solicitud
 * @param {Object} headers - Headers de la solicitud
 * @returns {Object} Headers con el token CSRF añadido
 */
export const addCsrfHeader = (headers = {}) => {
  return {
    ...headers,
    'X-CSRF-Token': getCsrfToken()
  };
};

/**
 * Verifica si un token CSRF es válido
 * @param {string} token - Token CSRF a verificar
 * @returns {boolean} True si el token es válido, false en caso contrario
 */
export const validateCsrfToken = (token) => {
  const storedToken = sessionStorage.getItem(CSRF_TOKEN_KEY);
  return storedToken && token === storedToken;
};

/**
 * Limpia el token CSRF almacenado
 */
export const clearCsrfToken = () => {
  sessionStorage.removeItem(CSRF_TOKEN_KEY);
};

export default {
  generateCsrfToken,
  getCsrfToken,
  addCsrfHeader,
  validateCsrfToken,
  clearCsrfToken
};
