import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Input,
  Label,
  FormGroup,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import { toast } from "react-toastify";

/**
 * Componente para la barra de filtros de solicitudes
 * @param {Object} props - Propiedades del componente
 * @returns {JSX.Element} - Componente de barra de filtros
 */
const FilterBar = ({
  fechaInicio,
  fechaFin,
  onFechaInicioChange,
  onFechaFinChange,
  onFilterApply,
  onFilterReset,
  onGenerateRoute,
  solicitudesCount = 0,
  loading = false,
  rutaActiva = null,
}) => {
  const [fechaInicioLocal, setFechaInicioLocal] = useState(fechaInicio);
  const [fechaFinLocal, setFechaFinLocal] = useState(fechaFin);

  // Actualizar fechas locales cuando cambian las props
  useEffect(() => {
    setFechaInicioLocal(fechaInicio);
    setFechaFinLocal(fechaFin);
  }, [fechaInicio, fechaFin]);

  // Manejar cambios en las fechas
  const handleFechaInicioChange = (selectedDates) => {
    const newDate = selectedDates[0];
    setFechaInicioLocal(newDate);
    onFechaInicioChange && onFechaInicioChange(newDate);
  };

  const handleFechaFinChange = (selectedDates) => {
    const newDate = selectedDates[0];
    setFechaFinLocal(newDate);
    onFechaFinChange && onFechaFinChange(newDate);
  };

  // Aplicar filtros
  const handleApplyFilter = () => {
    // Asegurarse de que las fechas sean serializables (no objetos Date)
    const formatDate = (date) => {
      if (!date) return null;

      // Si ya es un string, verificar si tiene formato ISO
      if (typeof date === "string") {
        // Si ya tiene formato YYYY-MM-DD, devolverlo tal cual
        if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
          return date;
        }
        // Intentar convertir el string a Date
        try {
          const dateObj = new Date(date);
          if (!isNaN(dateObj.getTime())) {
            return dateObj.toISOString().split("T")[0];
          }
        } catch (e) {
          console.error("Error al convertir fecha:", e);
        }
      }

      // Si es un objeto Date válido
      if (date instanceof Date && !isNaN(date.getTime())) {
        return date.toISOString().split("T")[0];
      }

      // Si no se pudo convertir, devolver null
      console.warn("No se pudo convertir la fecha:", date);
      return null;
    };

    onFilterApply &&
      onFilterApply({
        fechaInicio: formatDate(fechaInicioLocal),
        fechaFin: formatDate(fechaFinLocal),
      });
  };

  // Resetear filtros
  const handleResetFilter = () => {
    setFechaInicioLocal(null);
    setFechaFinLocal(null);
    onFilterReset && onFilterReset();
  };

  // Generar ruta
  const handleGenerateRoute = () => {
    if (!rutaActiva) {
      toast.info("Generando ruta de entrega optimizada...", {
        autoClose: 3000,
        position: "top-right",
      });
    }
    onGenerateRoute && onGenerateRoute();
  };

  // Obtener la fecha actual para el filtro predeterminado
  const today = new Date();
  const todayFormatted = today.toISOString().split("T")[0];

  return (
    <Card className="mb-4 border-0 shadow-sm">
      <CardBody>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h5 className="card-title mb-0">Filtrar Solicitudes</h5>
          <span className="badge bg-primary rounded-pill">
            {solicitudesCount} solicitudes
          </span>
        </div>

        <Row className="g-3">
          <Col md={6}>
            <FormGroup>
              <Label for="fechaInicio" className="form-label">
                Fecha Inicio
              </Label>
              <Flatpickr
                className="form-control"
                placeholder="Seleccione fecha inicio"
                options={{
                  dateFormat: "Y-m-d",
                  locale: Spanish,
                  defaultDate: todayFormatted,
                }}
                value={fechaInicioLocal}
                onChange={handleFechaInicioChange}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="fechaFin" className="form-label">
                Fecha Fin
              </Label>
              <Flatpickr
                className="form-control"
                placeholder="Seleccione fecha fin"
                options={{
                  dateFormat: "Y-m-d",
                  locale: Spanish,
                  defaultDate: todayFormatted,
                }}
                value={fechaFinLocal}
                onChange={handleFechaFinChange}
              />
            </FormGroup>
          </Col>
        </Row>

        <div className="d-flex gap-2 mt-3">
          <Button color="light" className="w-100" onClick={handleResetFilter}>
            <i className="ri-refresh-line me-1"></i>
            Limpiar
          </Button>
          <Button color="primary" className="w-100" onClick={handleApplyFilter}>
            <i className="ri-filter-line me-1"></i>
            Aplicar Filtros
          </Button>
        </div>

        {rutaActiva === null && (
          <div className="mt-4">
            <Button
              color="success"
              className="w-100"
              onClick={handleGenerateRoute}
              disabled={solicitudesCount === 0 || loading}
            >
              {loading ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Generando ruta optimizada...
                </>
              ) : rutaActiva ? (
                <>
                  <i className="ri-route-line me-1"></i>
                  Ver ruta activa
                </>
              ) : (
                <>
                  <i className="ri-route-line me-1"></i>
                  Generar Ruta de Entrega
                </>
              )}
            </Button>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default FilterBar;
