import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { editSolicitud, createMessage } from "../../../slices/thunks";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Label,
  Row,
  Form,
  FormGroup,
  Input,
} from "reactstrap";

const RegistroMedicamentosCronicos = ({ solicitud }) => {
  const dispatch = useDispatch();
  const [mensaje, setMensaje] = useState("");
  const [mensajeError, setMensajeError] = useState("");
  const [medicamentos, setMedicamnetos] = useState("");
  const [bioequivalentes, setBioequivalentes] = useState("");

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [loadingx, setLoadingx] = useState(false);

  const selectLayoutState = (state) => state;
  const pageData = createSelector(selectLayoutState, (state) => ({
    siscotel: state.Siscotel.siscotel,
    error: state.Siscotel.error,
    loading: state.Siscotel.loading,
    errorMsg: state.Siscotel.errorMsg,
    colorMsg: state.Siscotel.colorMsg,
    exito: state.Siscotel.exito,
  }));

  const { siscotel, error, loading, errorMsg, colorMsg, exito } =
    useSelector(pageData);

  useEffect(() => {
    if (siscotel?.status === 200 && medicamentos !== "" && loadingx) {
      // Preparar el historial de entrega
      const nuevaEntrega = {
        fecha: new Date().toISOString(),
        medicamentos: medicamentos
          ? medicamentos.split("\n").filter((med) => med.trim())
          : [],
        bioequivalentes: bioequivalentes
          ? bioequivalentes.split("\n").filter((bio) => bio.trim())
          : [],
      };

      // Preparar el historial actualizado
      const historialActualizado = solicitud.cronico?.historialEntregas
        ? [...solicitud.cronico.historialEntregas, nuevaEntrega]
        : [nuevaEntrega];

      const editarSolicitud = {
        ...solicitud,
        cronico: {
          ...solicitud.cronico,
          historialEntregas: historialActualizado,
        },
      };

      dispatch(editSolicitud(editarSolicitud));
      setLoadingx(false);
      setBioequivalentes("");
      setMedicamnetos("");

      toast.success("Medicamento registrado exitosamente", {});
    }
  }, [dispatch, siscotel, medicamentos, bioequivalentes, loadingx, solicitud]);

  /**
   * Handles the submission of chronic medication records.
   *
   * This function processes the input medications and bioequivalents, constructs a message
   * in the required format, and dispatches it to the Siscotel system.
   *
   * @function
   * @returns {void}
   */
  const handleSubmit = () => {
    // No es necesario prevenir el evento por defecto ya que ahora es un botón tipo "button"
    
    // Validar que el campo de medicamentos no esté vacío
    if (!medicamentos || medicamentos.trim() === '') {
      setShowErrorMessage(true);
      return;
    }
    
    setShowErrorMessage(false);
    setLoadingx(true);

    // Procesar medicamentos y bioequivalentes
    let medicamentosTratados = medicamentos
      ? medicamentos.split("\n").filter((med) => med.trim())
      : [];

    let bioequivalentesTratados = bioequivalentes
      ? bioequivalentes.split("\n").filter((bio) => bio.trim())
      : [];

    // Construir el mensaje según el formato requerido
    let mensajeFinal = "";

    // Primero colocamos todos los medicamentos
    if (medicamentosTratados.length > 0) {
      mensajeFinal = medicamentosTratados.join("|");
    }

    // Luego agregamos los bioequivalentes si existen
    if (bioequivalentesTratados.length > 0) {
      // Si ya hay medicamentos, agregamos un separador
      if (mensajeFinal) {
        mensajeFinal += "|";
      }
      // Agregamos los bioequivalentes con el formato especificado
      mensajeFinal += bioequivalentesTratados
        .map((bio) => `bioequivalente : ${bio}`)
        .join("|");
    }

    const message = {
      IdentificadorEvento: solicitud.identificador,
      Tipo: "RecibirMedicamento",
      Mensaje: mensajeFinal,
      Adjunto: "",
      CodigoProveedor: solicitud.codigoProveedor,
    };

    dispatch(createMessage(message));
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString("es-ES", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const RegistroMedicamentosCronicosTable = ({ solicitud }) => {
    if (!solicitud || !solicitud.cronico.historialEntregas || solicitud.cronico.historialEntregas.length === 0) {
      return (
        <div className="alert alert-info d-flex align-items-center" role="alert">
          <i className="ri-information-line fs-18 me-2"></i>
          <div>No hay información de entregas anteriores.</div>
        </div>
      );
    }
  
    return (
      <div>
        <h6 className="text-muted mb-3">
          <i className="ri-history-line me-1"></i>
          Historial de Entregas
        </h6>
        <div className="table-responsive">
          <table className="table table-bordered table-hover align-middle mb-0">
            <thead className="table-light">
              <tr>
                <th scope="col" style={{ width: "15%" }}>
                  <div className="d-flex align-items-center">
                    <i className="ri-calendar-line text-success me-1"></i>
                    Fecha
                  </div>
                </th>
                <th scope="col" style={{ width: "42.5%" }}>
                  <div className="d-flex align-items-center">
                    <i className="ri-medicine-bottle-line text-success me-1"></i>
                    Medicamentos
                  </div>
                </th>
                <th scope="col" style={{ width: "42.5%" }}>
                  <div className="d-flex align-items-center">
                    <i className="ri-capsule-line text-success me-1"></i>
                    Bioequivalentes
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {solicitud.cronico.historialEntregas?.map((entrega, index) => (
                <tr key={index}>
                  <td>
                    <span className="badge bg-success-subtle text-success">
                      {new Date(entrega.fecha).toLocaleDateString('es-ES', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric'
                      })}
                    </span>
                  </td>
                  <td>
                    {entrega.medicamentos.length > 0 ? (
                      <ul className="list-group list-group-flush">
                        {entrega.medicamentos.map((med, idx) => (
                          <li key={idx} className="list-group-item border-0 px-0 py-1">
                            <i className="ri-medicine-bottle-line text-muted me-1"></i>
                            {med}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <span className="text-muted fst-italic">No registrados</span>
                    )}
                  </td>
                  <td>
                    {entrega.bioequivalentes.length > 0 ? (
                      <ul className="list-group list-group-flush">
                        {entrega.bioequivalentes.map((bio, idx) => (
                          <li key={idx} className="list-group-item border-0 px-0 py-1">
                            <i className="ri-capsule-line text-muted me-1"></i>
                            {bio}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <span className="text-muted fst-italic">No registrados</span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const isValidSolicitud = () => {
    // Verificar si la solicitud es válida (Nueva Solicitud y está vigente)
    if (solicitud.estatus !== "Nueva Solicitud") return false;

    if (!solicitud.cronico?.fechaVigencia) return false;

    const fechaVigencia = new Date(solicitud.cronico.fechaVigencia);
    const today = new Date();

    // Comparar solo año y mes
    return (
      fechaVigencia.getFullYear() > today.getFullYear() ||
      (fechaVigencia.getFullYear() === today.getFullYear() &&
        fechaVigencia.getMonth() >= today.getMonth())
    );
  };

  const hasEntryThisMonth = () => {
    if (!solicitud.cronico?.historialEntregas?.length) return false;
    if (!isValidSolicitud()) return true; // Si no es válida, consideramos que "ya tiene entrada"

    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();

    return solicitud.cronico.historialEntregas.some((entrega) => {
      const entregaDate = new Date(entrega.fecha);
      return (
        entregaDate.getMonth() === currentMonth &&
        entregaDate.getFullYear() === currentYear
      );
    });
  };

  const getNextAvailableDate = () => {
    const today = new Date();
    const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
    return nextMonth.toLocaleDateString("es-ES", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const getStatusMessage = () => {
    if (solicitud.estatus !== "Nueva Solicitud") {
      return {
        title: "Solicitud no disponible",
        message: "Esta solicitud no está en estado 'Nueva Solicitud'.",
      };
    }

    if (!solicitud.cronico?.fechaVigencia) {
      return {
        title: "Solicitud sin fecha de vigencia",
        message: "Esta solicitud no tiene una fecha de vigencia establecida.",
      };
    }

    const fechaVigencia = new Date(solicitud.cronico.fechaVigencia);
    if (fechaVigencia < new Date()) {
      return {
        title: "Solicitud vencida",
        message: `Esta solicitud venció el ${fechaVigencia.toLocaleDateString(
          "es-ES",
          {
            year: "numeric",
            month: "long",
            day: "numeric",
          }
        )}.`,
      };
    }

    if (hasEntryThisMonth()) {
      return {
        title: "Registro mensual completado",
        message: `Ya se ha registrado una entrega de medicamentos para este mes.
                  La próxima entrega estará disponible a partir del ${getNextAvailableDate()}.`,
      };
    }

    return null;
  };

  //PARTE PRINCIPAL
  return (
    <React.Fragment>
  <Col>
    <Card>
      <CardHeader className="bg-soft-success">
        <div className="d-flex align-items-center">
          <div className="flex-shrink-0">
            <i className="ri-capsule-fill text-success fs-22"></i>
          </div>
          <div className="flex-grow-1 ms-3">
            <h5 className="card-title mb-0 fw-semibold">
              Registro de Medicamentos Crónicos
            </h5>
          </div>
        </div>
      </CardHeader>
      <CardBody className="p-4">
        <RegistroMedicamentosCronicosTable solicitud={solicitud} />

        <div className="mt-4">
          {getStatusMessage() && (
            <div
              className={`alert ${
                !isValidSolicitud() ? "alert-warning" : "alert-info"
              } mt-4`}
              role="alert"
            >
              <div className="d-flex">
                <div className="flex-shrink-0">
                  <i className={`ri-${!isValidSolicitud() ? 'alert' : 'information'}-line fs-18 me-2`}></i>
                </div>
                <div className="flex-grow-1">
                  <h6 className="mb-1">{getStatusMessage().title}</h6>
                  <p className="mb-0">{getStatusMessage().message}</p>
                </div>
              </div>
            </div>
          )}

          {isValidSolicitud() && !hasEntryThisMonth() && (
            <div className="mt-4 p-3 border border-light rounded bg-light">
              {showErrorMessage && (
                <div className="alert alert-danger" role="alert">
                  <i className="ri-error-warning-line me-2"></i>
                  Por favor complete todos los campos obligatorios
                </div>
              )}
              
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="medicamentos" className="form-label fw-medium">
                      <i className="ri-medicine-bottle-line text-success me-1"></i>
                      Medicamentos <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="textarea"
                      className="form-control border-success-subtle"
                      id="medicamentos"
                      rows="4"
                      value={medicamentos}
                      onChange={(e) => setMedicamnetos(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey && e.target.tagName !== 'TEXTAREA') {
                          e.preventDefault(); // Prevenir acción por defecto solo si es Enter sin Shift y no es un textarea
                        }
                      }}
                      placeholder="Ingrese cada medicamento en una línea separada"
                      required
                    />
                    <small className="form-text text-muted">
                      <i className="ri-information-line me-1"></i>
                      Importante: Escriba cada medicamento en una línea separada.
                    </small>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="bioequivalentes" className="form-label fw-medium">
                      <i className="ri-capsule-line text-success me-1"></i>
                      Bioequivalentes
                    </Label>
                    <Input
                      type="textarea"
                      className="form-control border-success-subtle"
                      id="bioequivalentes"
                      rows="4"
                      value={bioequivalentes}
                      onChange={(e) => setBioequivalentes(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey && e.target.tagName !== 'TEXTAREA') {
                          e.preventDefault(); // Prevenir acción por defecto solo si es Enter sin Shift y no es un textarea
                        }
                      }}
                      placeholder="Ingrese cada bioequivalente en una línea separada"
                    />
                    <small className="form-text text-muted">
                      <i className="ri-information-line me-1"></i>
                      Importante: Escriba cada bioequivalente en una línea separada.
                    </small>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          )}

          <Row className="mt-3">
            <Col>
              <div className="mb-3 mt-4 text-end">
                {isValidSolicitud() && !hasEntryThisMonth() && (
                  <button
                    type="button"
                    className="btn btn-success"
                    disabled={loadingx}
                    onClick={handleSubmit}
                  >
                    {loadingx ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm me-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Registrando...
                      </>
                    ) : (
                      <>
                        <i className="ri-save-line align-middle fs-16 me-2"></i>
                        Registrar Medicamentos
                      </>
                    )}
                  </button>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </CardBody>
    </Card>
  </Col>
</React.Fragment>
  );
};

export default RegistroMedicamentosCronicos;
