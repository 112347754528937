import React, { useEffect, useState } from "react";
import {
  createPlantillaMensaje,
  resetPlantillasMensajesFlag,
} from "../../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { Col, Row, Input, Label, Form, Alert, Spinner } from "reactstrap";
import Select from "react-select";
import { Navigate } from "react-router-dom";

const CrearPlantillaMensaje = (props) => {
  const dispatch = useDispatch();

  const selectLayoutState = (state) => state;

  const pageData = createSelector(selectLayoutState, (state) => ({
    plantillaMensaje: state.PlantillasMensajes.plantillaMensaje,
    error: state.PlantillasMensajes.error,
    loading: state.PlantillasMensajes.loading,
    errorMsg: state.PlantillasMensajes.errorMsg,
    colorMsg: state.PlantillasMensajes.colorMsg,
    exito: state.PlantillasMensajes.exito,
  }));

  // Inside your component
  const { plantillaMensaje, error, loading, errorMsg, colorMsg, exito } =
    useSelector(pageData);

  const [nombre, setNombre] = useState("");
  const [contenido, setContenido] = useState("");
  const [tipo, setTipo] = useState(false);
  
  const [redireccionar, setRedireccionar] = useState(false);
  const [loadingx, setLoadingx] = useState(false);

  useEffect(() => {
    if (errorMsg) {
      setTimeout(() => {
        dispatch(resetPlantillasMensajesFlag());
        setLoadingx(false);
      }, 2000);
    }
  }, [dispatch, errorMsg]);

  useEffect(() => {
    if (exito) {
      setTimeout(() => {
        setRedireccionar(true);
      }, 2000);
    }
  }, [exito]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const obj = {
      nombre: nombre.trim(),
      contenido: contenido.trim(),
      tipo: tipo.trim()
    };

    try {
     
      const response = await dispatch(createPlantillaMensaje(obj));
      if (response.error) {
        throw new Error(response.error.message);
      }
      setLoadingx(true);
    } catch (error) {
      console.error("Error al crear una Plantilla de Mensaje:", error);
      // Aquí puedes manejar el error, por ejemplo, mostrando un mensaje al usuario
    }
  };

  return (
    <>
      {redireccionar && <Navigate to="/plantillas-mensajes" replace />}

      <React.Fragment>
        {errorMsg && errorMsg ? (
          <Alert color={colorMsg}> {error} </Alert>
        ) : null}

        <Form onSubmit={handleSubmit}>
          <Row lg={12} className="p-3">
            <Col xxl={6} md={6}>
            <div className="mb-3">
                <Label className="form-label" htmlFor="nombre">
                  Nombre <span className="text-danger">*</span>
                </Label>
              </div>
              <Input
                required
                minLength={3}
                maxLength={50}
                type="text"
                className="form-control"
                id="nombre"
                name="nombre"
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
              />
            </Col>
            
            <Col xxl={6} md={6}>
              <div className="mb-3">
                <Label className="form-label" htmlFor="transnacional">
                  Tipo <span className="text-danger">*</span>
                </Label>

                <Select
                  onChange={(selectedOption) => {
                    setTipo(selectedOption.value);
                  }}
                  options={[
                    { value: "", label: "Seleccione una opcion" },
                    { value: "Orden lista", label: "Orden lista"},
                    { value: "En camino", label: "En camino" },
                    { value: "Entregado", label: "Entregado" },
                    { value: "Cancelado", label: "Cancelado" },
                    { value: "Otro", label: "Otro" },
                  ]}
                  required
                  className="js-example-basic-single mb-0"
                />
              </div>
            </Col>

            <Col xxl={12} md={12}>
              <div className="mb-3">
                <Label className="form-label" htmlFor="gen-info-apellido-input">
                  Contenido <span className="text-danger">*</span>
                </Label>
                <Input
                  type= 'textarea'
                  required
                  className="form-control"
                  id="contenido"
                  name="contenido"
                  value={contenido}
                  onChange={(e) => setContenido(e.target.value)}
                />
              </div>
            </Col>

            
            
          </Row>
          <Row className="align-items-end">
            <Col lg={12}>
              <div className="mt-4 mb-3 text-end">
                <button
                  type="submit"
                  className="btn btn-success btn-label right ms-auto nexttab nexttab"
                >
                  {loadingx ? (
                    <Spinner size="sm" className="me-2">
                      {" "}
                      Loading...{" "}
                    </Spinner>
                  ) : null}
                  <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                  Crear Plantilla de Mensaje
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    </>
  );
};

export default CrearPlantillaMensaje;
