import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { Card, CardBody, CardHeader, Col, Row, Badge, Table } from "reactstrap";
import { pathologies } from "./patologiasCronicas";
import { createCoverage, editSolicitud } from "../../../slices/thunks";

/**
 * InfoCronico es un componente que se encarga de mostrar los detalles de una
 * solicitud de medicamentos de tipo Cronica. Recibe como parámetro un objeto con la
 * información de la solicitud y devuelve un JSX con los detalles de la
 * solicitud.
 *
 * @param {object} props - Objeto con la información de la solicitud.
 *
 * @returns {JSX} Un JSX con los detalles de la solicitud.
 */
const InfoCronico = ({ solicitud }) => {
  const dispatch = useDispatch();

  const nombreCronico =
    pathologies.find((p) => p.codigo === solicitud.cronico.patologia)
      ?.nombre_cronico ?? "Nombre crónico no encontrado";

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString("es-ES", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const [loadingx, setLoadingx] = useState(false);
  const selectLayoutState = (state) => state;
  const pageData = createSelector(selectLayoutState, (state) => ({
    siscotel: state.Siscotel.siscotel,
    error: state.Siscotel.error,
    loading: state.Siscotel.loading,
    errorMsg: state.Siscotel.errorMsg,
    colorMsg: state.Siscotel.colorMsg,
    exito: state.Siscotel.exito,
  }));

  const { siscotel, error, loading, errorMsg, colorMsg, exito } =
    useSelector(pageData);

  useEffect(() => {
    if (siscotel && siscotel.status == 200 && siscotel.coverage) {
      // Primero verificamos que siscotel sea una cadena de texto
      const coverageValue =
        typeof siscotel.coverage === "string"
          ? parseFloat(siscotel.coverage.replace(" $", ""))
          : typeof siscotel === "number"
          ? siscotel
          : 0;

      const nuevaSolicitud = {
        ...solicitud,
        diagnosticoFarmacologico: nombreCronico,
        codigoDiagnosticoFarmacologico: solicitud.cronico.patologia,
        montoCobertura: coverageValue,
        porcentaje_avance: 20,
      };

      dispatch(editSolicitud(nuevaSolicitud));
      setLoadingx(false);

      toast.success(
        "Hemos recibido el monto de la cobertura asociada exitosamente",
        {}
      );
    }
  }, [dispatch, siscotel]);

  const handleSubmitDiagnostico = (e) => {
    e.preventDefault();
    setLoadingx(true);

    const cobertura = {
      CedulaTitular: solicitud.titularCedula,
      CedulaSolicitante: solicitud.solicitanteCedula,
      CodigoDiagnostico: solicitud.cronico.patologia,
      CodigoProveedor: solicitud.codigoProveedor,
    };

    // Luego envía el mensaje a Siscotel
    try {
      if (cobertura && cobertura.CodigoDiagnostico) {
        // Verifica que el mensaje existe y tiene contenido
        dispatch(createCoverage(cobertura)).then(() => {
          console.info("Solicitud del monto de cobertura a Siscotel");
        });
      }
    } catch (error) {
      console.error("Error al solicitar monto de cobertura a Siscotel:", error);
    }
  };

  return (
    <React.Fragment>
      <Col>
        <Card>
          <CardHeader className="bg-soft-primary">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <i className="ri-medicine-bottle-line text-primary fs-22"></i>
              </div>
              <div className="flex-grow-1 ms-3">
                <h5 className="card-title mb-0 fw-semibold">
                  Información Crónica
                </h5>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <div className="table-responsive table-card">
              <Table className="table-borderless align-middle mb-0">
                <tbody>
                  <tr>
                    <td style={{ width: "35%" }}>
                      <div className="d-flex align-items-center">
                        <i className="ri-virus-line text-primary me-2 fs-16"></i>
                        <span className="fw-medium">Patología</span>
                      </div>
                    </td>
                    <td>
                      <span className="badge bg-primary-subtle text-primary fs-12 me-2">
                        {solicitud.cronico.patologia}
                      </span>
                      {nombreCronico}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex align-items-center">
                        <i className="ri-calendar-check-line text-primary me-2 fs-16"></i>
                        <span className="fw-medium">Fecha Creación</span>
                      </div>
                    </td>
                    <td>{formatDate(solicitud.cronico.fechaCreacion)}</td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex align-items-center">
                        <i className="ri-calendar-event-line text-primary me-2 fs-16"></i>
                        <span className="fw-medium">Fecha Vigencia</span>
                      </div>
                    </td>
                    <td>{formatDate(solicitud.cronico.fechaVigencia)}</td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex align-items-center">
                        <i className="ri-medicine-bottle-line text-primary me-2 fs-16"></i>
                        <span className="fw-medium">
                          Medicamentos Solicitados
                        </span>
                      </div>
                    </td>
                    <td>{solicitud.cronico.medicamentosSolicitados}</td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex align-items-center">
                        <i className="ri-capsule-line text-primary me-2 fs-16"></i>
                        <span className="fw-medium">
                          Medicamentos Bioequivalentes
                        </span>
                      </div>
                    </td>
                    <td>{solicitud.cronico.medicamentosBioequivalentes}</td>
                  </tr>
                </tbody>
              </Table>
            </div>

            <div className="mb-3 mt-4 text-end">
              {solicitud.estatus !== "Cancelada" && (
                <button
                  type="submit"
                  className="btn btn-success"
                  disabled={loadingx}
                  onClick={handleSubmitDiagnostico}
                >
                  {loadingx ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm me-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Consultando...
                    </>
                  ) : (
                    <>
                      <i className="ri-money-dollar-circle-line me-1"></i>
                      Consultar Cobertura
                    </>
                  )}
                </button>
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default InfoCronico;
