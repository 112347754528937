import { useState, useEffect } from 'react';

/**
 * Hook personalizado para manejar la geolocalización
 * @param {Object} options - Opciones para la geolocalización
 * @returns {Object} - Estado de la geolocalización
 */
const useGeolocation = (options = {}) => {
  const [position, setPosition] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const defaultOptions = {
    enableHighAccuracy: true,
    timeout: 10000,
    maximumAge: 0,
    ...options
  };

  /**
   * Obtiene la posición actual del usuario
   * @param {number} retries - Número de reintentos
   * @returns {Promise<Object>} - Promesa con un objeto de coordenadas {latitude, longitude}
   */
  const getCurrentPosition = async (retries = 2) => {
    setLoading(true);
    setError(null);

    try {
      // Intentar GPS primero
      for (let attempt = 1; attempt <= retries; attempt++) {
        try {
          const coords = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
              (pos) => resolve({
                latitude: pos.coords.latitude,
                longitude: pos.coords.longitude
              }),
              reject,
              defaultOptions
            );
          });
          setPosition(coords);
          setLoading(false);
          return coords;
        } catch (gpsError) {
          if (attempt === retries) throw gpsError;
          // Esperar antes de reintentar
          await new Promise((resolve) => setTimeout(resolve, 2000 * attempt));
        }
      }
    } catch (error) {
      console.warn("No se pudo obtener la geolocalización por GPS:", error);
      
      // Informar del error en lugar de usar coordenadas por defecto
      setError(error);
      setLoading(false);
      throw error; // Propagar el error para que pueda ser manejado por el componente
    }
  };

  // Inicializar la geolocalización al montar el componente
  useEffect(() => {
    // Solo inicializar si se solicita automáticamente
    if (options.autoInit) {
      getCurrentPosition().catch(err => {
        console.error("Error al inicializar geolocalización:", err);
      });
    }
  }, []);

  return {
    position,
    error,
    loading,
    getCurrentPosition
  };
};

export default useGeolocation;