import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
} from "reactstrap";
import { Lista} from "./Lista";
import { Link } from "react-router-dom";

const ServicioDeEntregaListar = () => {
  document.title = "Lista de Farmacias | Farme";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="border-0">
                  <div className="d-md-flex align-items-center">
                    <h5 className="card-title mb-3 mb-md-0 flex-grow-1">
                    Farmacias
                    </h5>
                    <div className="flex-shrink-0">
                      <div className="d-flex gap-1 flex-wrap">
                        <Link to="/farmacias/crear">
                          <Button
                            color="success"
                            type="button"
                            className="add-btn"
                            data-bs-toggle="modal"
                            id="create-btn"
                            data-bs-target="#showModal"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Crear Farmacia
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <Lista/>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ServicioDeEntregaListar;
