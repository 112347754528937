import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    rutaOptimizada: null,
    rutaActiva: null,
    estadisticas: null,
    loading: false,
    error: null,
  };

const rutasSlice = createSlice({
    name: "rutas",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        rutaOptimizadaSuccess: (state, action) => {
            state.rutaOptimizada = action.payload;
            state.loading = false;
            state.error = null;
        },
        rutaActivaSuccess: (state, action) => {
            state.rutaActiva = action.payload.data.detail === 'No se encontró una ruta activa para este motorizado' 
                ? null 
                : action.payload.data;
            state.loading = false;
            state.error = null;
        },
        estadisticasSuccess: (state, action) => {
            state.estadisticas = action.payload;
            state.loading = false;
            state.error = null;
        },
        apiError: (state, action) => {
            // Asegurarnos de que el error sea serializable
            state.error = action.payload instanceof Error 
                ? action.payload.message 
                : typeof action.payload === 'object' && action.payload !== null
                    ? JSON.stringify(action.payload)
                    : action.payload;
            state.loading = false;
        },
        clearError: (state) => {
            state.error = null;
        }
    },
    
});

export const {
    setLoading,
    rutaOptimizadaSuccess,
    rutaActivaSuccess,
    estadisticasSuccess,
    apiError,
    clearError
} = rutasSlice.actions;

export default rutasSlice.reducer;