import axios from "axios";
import * as url from "./url_helper";
import SecureStorage from "./secure_storage";
import AuthNotifications from "./auth_notifications";

// Definir la URL base para las solicitudes API
const API_BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001";

// Crear una instancia de axios
const api = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
  },
});

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = SecureStorage.getAuthUser();
  if (user) return user;
  return null;
};

// //is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Login Method
export const postJwtLogin = async (data) => {
  try {
    // Crear FormData para enviar los datos de login
    const formData = new FormData();
    formData.append("username", data.username);
    formData.append("password", data.password);
    
    // Configuración específica para form-data
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };
    
/*     console.log("Enviando solicitud de login con FormData:", {
      url: `${API_BASE_URL}${url.POST_FAKE_JWT_LOGIN}`,
      username: data.username,
      passwordLength: data.password ? data.password.length : 0
    }); */
    
    const response = await axios.post(`${API_BASE_URL}${url.POST_FAKE_JWT_LOGIN}`, formData, config);
    
/*     console.log("Respuesta de login recibida:", {
      status: response.status,
      hasAccessToken: !!response.data?.access_token
    }); */
    
    // Guardar tokens en almacenamiento seguro
    if (response.data && response.data.access_token) {
      // Guardar token de acceso
      SecureStorage.setAccessToken(response.data.access_token);
      
      // Guardar refresh token si existe
      if (response.data.refresh_token) {
        SecureStorage.setRefreshToken(response.data.refresh_token);
      }
      
      // Calcular y guardar expiración
      if (response.data.expires_in) {
        const expiryTime = new Date().getTime() + response.data.expires_in * 1000;
        SecureStorage.setTokenExpiry(expiryTime);
      }
      
      // Guardar datos del usuario
      SecureStorage.setAuthUser(response.data);
      
      // Notificar éxito
      AuthNotifications.notify(
        AuthNotifications.EVENTS.LOGIN_SUCCESS,
        AuthNotifications.TYPES.SUCCESS,
        "Inicio de sesión exitoso"
      );
    }
    
    return response.data;
  } catch (error) {
    console.error("Error en login:", error);
    
    let errorMessage = "Error desconocido en el inicio de sesión";
    
    if (error.response) {
      console.error("Detalles del error de respuesta:", {
        status: error.response.status,
        statusText: error.response.statusText,
        data: error.response.data
      });
      
      const status = error.response.status;
      
      if (status === 401) {
        errorMessage = "Credenciales incorrectas";
      } else if (status === 400) {
        errorMessage = "Datos de inicio de sesión inválidos";
      } else {
        errorMessage = `Error ${status}: ${error.response.statusText || 'Error en la respuesta del servidor'}`;
      }
    } else if (error.request) {
      console.error("No se recibió respuesta del servidor:", error.request);
      errorMessage = "No se recibió respuesta del servidor";
    } else if (error.message) {
      errorMessage = error.message;
    }
    
    // Notificar error de login
    AuthNotifications.notify(
      AuthNotifications.EVENTS.LOGIN_FAILED,
      AuthNotifications.TYPES.ERROR,
      errorMessage
    );
    
    throw new Error(errorMessage);
  }
};

// postForgetPwd 
export const postJwtForgetPwd = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}${url.POST_FAKE_JWT_PASSWORD_FORGET}`, data);
    return response.data;
  } catch (error) {
    let errorMessage = "Error al solicitar recuperación de contraseña";
    
    if (error.response) {
      errorMessage = `Error ${error.response.status}: ${error.response.statusText || 'Error en la respuesta del servidor'}`;
    } else if (error.request) {
      errorMessage = "No se recibió respuesta del servidor";
    } else if (error.message) {
      errorMessage = error.message;
    }
    
    throw new Error(errorMessage);
  }
};

// Refresh Token Method
export const postJwtRefreshToken = async (data) => {
  try {
    // Crear objeto JSON con el refresh token
    const jsonData = typeof data === 'string' 
      ? { refresh_token: data } 
      : (data instanceof FormData 
        ? { refresh_token: data.get('refresh_token') } 
        : data);
    
/*     console.log("Enviando solicitud de refresh token con JSON:", {
      url: `${API_BASE_URL}${url.POST_FAKE_JWT_REFRESH_TOKEN}`,
      hasRefreshToken: !!jsonData.refresh_token
    }); */
    
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    
    const response = await axios.post(`${API_BASE_URL}${url.POST_FAKE_JWT_REFRESH_TOKEN}`, jsonData, config);
    
/*     console.log("Respuesta de refresh token recibida:", {
      status: response.status,
      hasAccessToken: !!response.data?.access_token
    }); */
    
    return response.data;
  } catch (error) {
    console.error("Error en refresh token:", error);
    
    let errorMessage = "Error al refrescar token";
    
    if (error.response) {
      console.error("Detalles del error de respuesta:", {
        status: error.response.status,
        statusText: error.response.statusText,
        data: error.response.data
      });
      
      const status = error.response.status;
      
      if (status === 401) {
        errorMessage = "Token inválido o expirado";
      } else if (status === 422) {
        errorMessage = "Formato de datos incorrecto para refresh token";
      } else {
        errorMessage = `Error ${status}: ${error.response.statusText || 'Error en la respuesta del servidor'}`;
      }
    } else if (error.request) {
      errorMessage = "No se recibió respuesta del servidor";
    } else if (error.message) {
      errorMessage = error.message;
    }
    
    throw new Error(errorMessage);
  }
};

// Logout Method JWT
export const postJwtLogout = async () => {
  try {
    const refreshToken = SecureStorage.getRefreshToken();
    if (!refreshToken) {
      throw new Error("No hay refresh token disponible");
    }
    
    // Crear objeto JSON para enviar el refresh token
    const jsonData = {
      refresh_token: refreshToken
    };
    
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    
/*     console.log("Enviando solicitud de logout con JSON:", {
      url: `${API_BASE_URL}${url.POST_FAKE_JWT_LOGOUT}`,
      hasRefreshToken: !!jsonData.refresh_token
    });
     */
    const response = await axios.post(`${API_BASE_URL}${url.POST_FAKE_JWT_LOGOUT}`, jsonData, config);
    
/*     console.log("Respuesta de logout recibida:", {
      status: response.status
    }); */
    
    // Limpiar tokens
    SecureStorage.clearAuthTokens();
    
    // Notificar éxito
    AuthNotifications.notify(
      AuthNotifications.EVENTS.LOGOUT_SUCCESS,
      AuthNotifications.TYPES.SUCCESS,
      "Sesión cerrada exitosamente"
    );
    
    return response.data;
  } catch (error) {
    console.error("Error en logout:", error);
    
    // Manejar errores específicos
    let errorMessage = "Error desconocido al cerrar sesión";
    let localOnly = false;
    
    if (error.response) {
      console.error("Detalles del error de respuesta:", {
        status: error.response.status,
        statusText: error.response.statusText,
        data: error.response.data
      });
      
      const status = error.response.status;
      
      if (status === 404) {
        errorMessage = "El endpoint de logout no está implementado en el servidor";
        localOnly = true;
      } else if (status === 401) {
        errorMessage = "Token inválido o expirado";
      } else if (status === 422) {
        errorMessage = "Formato de datos incorrecto para logout";
      } else {
        errorMessage = `Error ${status}: ${error.response.statusText || 'Error en la respuesta del servidor'}`;
      }
    } else if (error.request) {
      console.error("No se recibió respuesta del servidor:", error.request);
      errorMessage = "No se recibió respuesta del servidor";
    } else if (error.message) {
      errorMessage = error.message;
    }
    
    // Si el endpoint no existe (404), hacer logout local
    if (localOnly) {
      // Limpiar tokens localmente
      SecureStorage.clearAuthTokens();
      
      // Notificar que se hizo logout local
      AuthNotifications.notify(
        AuthNotifications.EVENTS.LOGOUT_FAILED,
        AuthNotifications.TYPES.WARNING,
        errorMessage
      );
      
      return {
        localOnly: true,
        message: errorMessage,
        suggestion: "Verifica que el endpoint /auth/logout esté correctamente configurado en el backend"
      };
    }
    
    // Para otros errores, notificar el fallo
    AuthNotifications.notify(
      AuthNotifications.EVENTS.LOGOUT_FAILED,
      AuthNotifications.TYPES.ERROR,
      errorMessage
    );
    
    throw new Error(errorMessage);
  }
};

export default api;
