// React imports
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

// Redux imports
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  deleteUser,
  getUsers,
  resetUsuarioFlag,
  getFarmacias,
} from "../../../slices/thunks";

// Component imports
import DeleteModal from "../../../Components/Common/DeleteModal";
import TableContainer from "../../../Components/Common/TableContainer";

// UI component imports
import { Alert, UncontrolledTooltip } from "reactstrap";

const ListaUsuario = () => {
  const dispatch = useDispatch();

  const [listaFarmacias, setListaFarmacias] = useState([]);

  const selectLayoutState = (state) => state;
  const usuarioPageData = createSelector(selectLayoutState, (state) => ({
    usuarios: state.Usuarios.usuarios,
    error: state.Usuarios.error,
    loading: state.Usuarios.loading,
    errorMsg: state.Usuarios.errorMsg,
    colorMsg: state.Usuarios.colorMsg,
  }));
  const { usuarios, error, loading, errorMsg, colorMsg } =
    useSelector(usuarioPageData);

  const farmaciasPageData = createSelector(selectLayoutState, (state) => ({
    farmacias: state.Farmacias.farmacias,
  }));
  const { farmacias } = useSelector(farmaciasPageData);

  useEffect(() => {
    if (errorMsg) {
      setTimeout(() => {
        dispatch(resetUsuarioFlag());
      }, 2000);
    }
  }, [dispatch, errorMsg]);

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch, loading]);

  useEffect(() => {
    if (!farmacias || farmacias.length === 0) {
      dispatch(getFarmacias());
    }
  }, [dispatch, farmacias]);

  useEffect(() => {
    if (farmacias && farmacias[1] > 0) {
      setListaFarmacias(farmacias[0]);
    }
  }, [farmacias]);

  const [deleteModal, setDeleteModal] = useState(false);
  const [borrarSolicitud, setBorrarSolicitud] = useState({});

  const onClickDelete = (borrar) => {
    if (borrar) {
      dispatch(deleteUser(borrarSolicitud.id));
    }

    setDeleteModal(false);
  };

  const solicitudDelete = (solicitud) => {
    setBorrarSolicitud(solicitud);
    setDeleteModal(true);
  };

  const columns = useMemo(
    () => [
      {
        header: "Nombres",
        accessorKey: "nombre",
        enableColumnFilter: false,
      },
      {
        header: "Apellidos",
        accessorKey: "apellido",
        enableColumnFilter: false,
      },
      {
        header: "Correo Electrónico",
        accessorKey: "correo",
        enableColumnFilter: false,
      },
      {
        header: "Usuario",
        accessorKey: "usuario",
        enableColumnFilter: false,
      },
      {
        header: "Perfil",
        accessorKey: "perfil",
        enableColumnFilter: false,
      },
      {
        header: "Farmacia",
        accessorKey: "farmacia",
        enableColumnFilter: false,
        cell: (cell) => {
          const farmaciaId = cell.getValue();

          // If no farmacia_id, show a dash
          if (!farmaciaId) {
            return <span>NA</span>;
          }


          // Find the farmacia in listaFarmacias
          const farmacia = listaFarmacias.find(
            (f) => String(f.id) === String(farmaciaId)
          );



          if (!farmacia) {
            return <span>{farmaciaId}</span>; // Fallback to ID if name not found
          }

          // Display with badge styling
          return <span>{farmacia.nombre}</span>;
        },
      },
      {
        header: "Estatus",
        cell: (cell) => {
          return (
            <React.Fragment>
              {cell.getValue() ? (
                <span className="badge bg-success-subtle text-success text-uppercase">
                  Activo
                </span>
              ) : (
                <span className="badge bg-danger-subtle text-danger text-uppercase">
                  {" "}
                  No Activo{" "}
                </span>
              )}
            </React.Fragment>
          );
        },

        accessorKey: "activo",
        enableColumnFilter: false,
      },
      {
        header: "Acción",
        accessorKey: "accion",
        enableColumnFilter: false,
        cell: (cell) => {
          const rowId = cell.row.original.id;
          return (
            <div className="d-flex gap-3">
              <Link to={`/usuarios/editar/${rowId}`} className="text-success">
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Editar
                </UncontrolledTooltip>
              </Link>

              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cell.row.original;
                  solicitudDelete(orderData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Eliminar
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    [listaFarmacias, usuarios]
  );

  return (
    <React.Fragment>
      {errorMsg && errorMsg ? <Alert color={colorMsg}> {error} </Alert> : null}
      <DeleteModal
        show={deleteModal}
        onCloseClick={() => setDeleteModal(false)}
        onDeleteClick={() => {
          onClickDelete(true);
        }}
      />

      <TableContainer
        columns={columns || []}
        data={usuarios[0] || []}
        isGlobalFilter={true}
        customPageSize={20}
        SearchPlaceholder="Buscar..."
        divClass="table-responsive"
      />
    </React.Fragment>
  );
};

export { ListaUsuario };
