import React, { useMemo, useState, useEffect } from "react";
import { Alert } from "reactstrap";
import { getPlanillas, deletePlanilla, resetPlanillasFlag,} from "../../../slices/thunks";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import { UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";

import TableContainer from "../../../Components/Common/TableContainer";
import DeleteModal from "../../../Components/Common/DeleteModal";

const Lista = () => {
  const dispatch = useDispatch();

  const selectLayoutState = (state) => state;
  const pageData = createSelector(selectLayoutState, (state) => ({
    planillas: state.PlanillaEstrategica.planillas,
    error: state.PlanillaEstrategica.error,
    loading: state.PlanillaEstrategica.loading,
    errorMsg: state.PlanillaEstrategica.errorMsg,
    colorMsg: state.PlanillaEstrategica.colorMsg,
    exito: state.PlanillaEstrategica.exito,
  }));

  // Inside your component
  const { planillas, error, loading, errorMsg, colorMsg, exito} =
    useSelector(pageData);


  useEffect(() => {
      dispatch(getPlanillas());
  }, [dispatch, loading]);

  useEffect(() => {
    if (errorMsg) {
      setTimeout(() => {
        dispatch(resetPlanillasFlag());
      }, 2000);
    }
  }, [dispatch, errorMsg]);

  // Modal para eliminar
  const [deleteModal, setDeleteModal] = useState(false);
  const [borrarSolicitud, setBorrarSolicitud] = useState({});

  const onClickDelete = (borrar) => {
    if (borrar) {
      dispatch(deletePlanilla(borrarSolicitud.id));
    }

    setDeleteModal(false);
  };

  const solicitudDelete = (solicitud) => {
    setBorrarSolicitud(solicitud);
    setDeleteModal(true);
  };


  //Presentar la tabla
  const columns = useMemo(
    () => [
      {
        header: "Planilla Estratégica",
        accessorKey: "nombre",
        enableColumnFilter: false,
      },
      

      {
        header: "Estatus",
        cell: (cell) => {
          return (
            <React.Fragment>
              {
                cell.getValue()  ? 
                ( <span className="badge bg-success-subtle text-success text-uppercase">Activo</span> ) : 
                ( <span className="badge bg-danger-subtle text-danger text-uppercase"> No Activo </span>)
                }
            </React.Fragment>
          );
        },

        accessorKey: "activo",
        enableColumnFilter: false,
      },

      {
        header: "Acción",
        accessorKey: "accion",
        enableColumnFilter: false,
        cell: (cell) => {
          const rowId = cell.row.original.id;
          return (
            <div className="d-flex gap-3">
              <Link
                to={`/planilla-estrategica/editar/${rowId}/`}
                className="text-success"
              >
                <i className="mdi mdi-pencil font-size-28" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Editar
                </UncontrolledTooltip>
              </Link>

              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cell.row.original;
                  solicitudDelete(orderData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />

                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Eliminar
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>

      {errorMsg && errorMsg ? <Alert color={colorMsg}> {error} </Alert> : null}

      <DeleteModal
        show={deleteModal}
        onCloseClick={() => setDeleteModal(false)}
        onDeleteClick={() => {
          onClickDelete(true);
        }}
      />

      <TableContainer
        columns={columns || []}
        data={planillas[0] || []}
        isGlobalFilter={true}
        customPageSize={10}
        SearchPlaceholder="Buscar..."
        divClass="table-responsive"
      />
    </React.Fragment>
  );
};

export { Lista };
