import React, { useState, useEffect, useMemo } from "react";
import { Col, Row } from "reactstrap";
import Flatpickr from "react-flatpickr";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { getDashboardKpis } from "../../slices/thunks";
import { getFarmacia } from "../../slices/configuracion/farmacias/thunk";
import { DatosSesion } from "../../Components/Common/DatosSesion";

// Formatear fechas a YYYY-MM-DD
const formatearFecha = (fecha) => {
  if (!fecha) return "";
  return fecha instanceof Date
    ? fecha.toISOString().split("T")[0]
    : new Date(fecha).toISOString().split("T")[0];
};

// Formatear fechas para mostrar
const formatearFechaParaMostrar = (fecha) => {
  if (!fecha) return "";
  const date = new Date(fecha);
  return date.toLocaleDateString("es-ES", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
};

// Obtener fecha actual y primer día del mes
const obtenerFechaActual = () => {
  const hoy = new Date();
  return formatearFecha(hoy);
};

const obtenerPrimerDiaMes = () => {
  const hoy = new Date();
  return formatearFecha(new Date(hoy.getFullYear(), hoy.getMonth(), 1));
};

// Función para obtener saludo según la hora
const obtenerSaludo = () => {
  const hora = new Date().getHours();

  if (hora >= 5 && hora < 12) {
    return "¡Buenos días";
  } else if (hora >= 12 && hora < 19) {
    return "¡Buenas tardes";
  } else {
    return "¡Buenas noches";
  }
};

// Crear selectores memoizados fuera del componente
const selectDashboardState = (state) => state.Dashboard;
const selectFarmaciasState = (state) => state.Farmacias;

const dashboardSelector = createSelector(
  [selectDashboardState, selectFarmaciasState],
  (dashboardState, farmaciasState) => ({
    error: dashboardState.error,
    farmacia: farmaciasState?.farmacia || null,
  })
);

const Section = (props) => {
  const dispatch = useDispatch();

  // Estado para las fechas - inicializar con el mes actual
  const [fechaInicio, setFechaInicio] = useState(obtenerPrimerDiaMes());
  const [fechaFin, setFechaFin] = useState(obtenerFechaActual());
  const [codigoProveedor, setCodigoProveedor] = useState(null);
  const [cargandoFarmacia, setCargandoFarmacia] = useState(false);

  // Obtener el ID de la farmacia del usuario
  const farmaciaId = props.farmaciaId;

  // Obtener datos de Redux usando el selector memoizado
  const { error, farmacia } = useSelector(dashboardSelector);

  // Determinar si los datos provienen de la caché
  const datosDesdeCache = error === "Datos obtenidos de caché";

  // Efecto para cargar los datos de la farmacia si el usuario tiene una asignada
  useEffect(() => {
    if (farmaciaId) {
      setCargandoFarmacia(true);
      dispatch(getFarmacia(farmaciaId));
    }
  }, [dispatch, farmaciaId]);

  // Efecto para actualizar el código de proveedor cuando se carga la farmacia
  useEffect(() => {
    if (farmacia && farmacia.codigo_myh && farmacia.codigo_myh.trim() !== "") {
      setCodigoProveedor(farmacia.codigo_myh);
      setCargandoFarmacia(false);
    } else {
      // Si no hay código de proveedor válido, establecerlo como null
      setCodigoProveedor(null);
      setCargandoFarmacia(false);
    }
  }, [farmacia]);

  // Manejar cambio de fechas
  const handleFechasChange = (selectedDates) => {
    if (selectedDates.length === 2) {
      const inicio = formatearFecha(selectedDates[0]);
      const fin = formatearFecha(selectedDates[1]);

      setFechaInicio(inicio);
      setFechaFin(fin);

      // Actualizar KPIs con las nuevas fechas
      const params = {
        fecha_inicio: inicio,
        fecha_fin: fin,
      };

      // Solo añadir código de proveedor si existe y no está vacío
      if (codigoProveedor && codigoProveedor.trim() !== "") {
        params.codigo_proveedor = codigoProveedor;
      }

      dispatch(getDashboardKpis(params));
    }
  };

  // Cargar KPIs iniciales
  useEffect(() => {
    // No cargar KPIs si estamos esperando datos de la farmacia
    if (farmaciaId && cargandoFarmacia) {
      return;
    }

    // Asegurarse de que las fechas estén correctamente formateadas
    const inicio = obtenerPrimerDiaMes();
    const fin = obtenerFechaActual();

    const params = {
      fecha_inicio: inicio,
      fecha_fin: fin,
    };

    // Solo añadir código de proveedor si existe y no está vacío
    if (codigoProveedor && codigoProveedor.trim() !== "") {
      params.codigo_proveedor = codigoProveedor;
    }

    // Llamar a la API (usará caché si está disponible)
    dispatch(getDashboardKpis(params));
  }, [dispatch, codigoProveedor, cargandoFarmacia, farmaciaId]);

  return (
    <React.Fragment>
      <Row className="mb-3 pb-1">
        <Col xs={12}>
          <div className="d-flex align-items-lg-center flex-lg-row flex-column">
            <div className="flex-grow-1">
              <h4 className="fs-16 mb-1">
                {obtenerSaludo()}, {props.nombre}!
              </h4>
              <p className="text-muted mb-0">
                Aquí tienes un resumen de los KPIs del mes en curso.
                {farmaciaId && farmacia && (
                  <span>
                    {" "}
                    Mostrando datos para: <strong>{farmacia.nombre}</strong>
                  </span>
                )}
              </p>
            </div>
            <div className="mt-3 mt-lg-0">
              <form action="#">
                <Row className="g-3 mb-0 align-items-center">
                  <div className="col-sm-auto">
                    <div className="input-group">
                      <div className="input-group-text bg-primary border-primary text-white">
                        <i className="ri-calendar-2-line"></i>
                      </div>
                      <Flatpickr
                        className="form-control dash-filter-picker shadow"
                        options={{
                          mode: "range",
                          dateFormat: "d M, Y",
                          defaultDate: [
                            new Date(fechaInicio),
                            new Date(fechaFin),
                          ],
                          locale: {
                            firstDayOfWeek: 1,
                            weekdays: {
                              shorthand: [
                                "Do",
                                "Lu",
                                "Ma",
                                "Mi",
                                "Ju",
                                "Vi",
                                "Sa",
                              ],
                              longhand: [
                                "Domingo",
                                "Lunes",
                                "Martes",
                                "Miércoles",
                                "Jueves",
                                "Viernes",
                                "Sábado",
                              ],
                            },
                            months: {
                              shorthand: [
                                "Ene",
                                "Feb",
                                "Mar",
                                "Abr",
                                "May",
                                "Jun",
                                "Jul",
                                "Ago",
                                "Sep",
                                "Oct",
                                "Nov",
                                "Dic",
                              ],
                              longhand: [
                                "Enero",
                                "Febrero",
                                "Marzo",
                                "Abril",
                                "Mayo",
                                "Junio",
                                "Julio",
                                "Agosto",
                                "Septiembre",
                                "Octubre",
                                "Noviembre",
                                "Diciembre",
                              ],
                            },
                          },
                        }}
                        onChange={handleFechasChange}
                        placeholder={`${formatearFechaParaMostrar(
                          fechaInicio
                        )} - ${formatearFechaParaMostrar(fechaFin)}`}
                      />
                    </div>
                    <small className="text-muted d-block mt-1">
                      Seleccione rango de fechas para filtrar
                    </small>
                  </div>
                  {datosDesdeCache && (
                    <div className="col-auto">
                      <span className="badge bg-info text-white">
                        <i className="mdi mdi-database-check me-1"></i>
                        Datos en caché
                      </span>
                    </div>
                  )}
                  {cargandoFarmacia && (
                    <div className="col-auto">
                      <span className="badge bg-warning text-white">
                        <i className="mdi mdi-loading mdi-spin me-1"></i>
                        Cargando farmacia
                      </span>
                    </div>
                  )}
                </Row>
              </form>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Section;
