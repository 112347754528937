import {
  FETCH_SOLICITUDES_REQUEST,
  FETCH_SOLICITUDES_SUCCESS,
  FETCH_SOLICITUDES_FAIL,
  FETCH_RUTA_ACTIVA_REQUEST,
  FETCH_RUTA_ACTIVA_SUCCESS,
  FETCH_RUTA_ACTIVA_FAILURE,
  CREATE_RUTA_REQUEST,
  CREATE_RUTA_SUCCESS,
  CREATE_RUTA_FAIL,
  UPDATE_DELIVERY_REQUEST,
  UPDATE_DELIVERY_SUCCESS,
  UPDATE_DELIVERY_FAIL,
  FILTER_SOLICITUDES,
  RESET_FILTERS,
  FINALIZAR_RUTA_REQUEST,
  FINALIZAR_RUTA_SUCCESS,
  FINALIZAR_RUTA_FAIL,
  UPDATE_ROUTE_PROGRESS,
  RESET_RUTA_ACTIVA,
  SET_LOADING
} from "./types";

const initialState = {
  solicitudes: [],
  solicitudesFiltradas: [],
  rutaActiva: null,
  loading: false,
  error: null,
  filtros: {
    fechaInicio: null,
    fechaFin: null
  },
  progresoRuta: {
    entregasCompletadas: 0,
    entregasCanceladas: 0,
    distanciaTotal: 0,
    tiempoTotal: 0
  }
};

const motorizadoReducer = (state = initialState, action) => {
  switch (action.type) {
    // Control de estado de carga
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };

    // Solicitudes
    case FETCH_SOLICITUDES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_SOLICITUDES_SUCCESS:
      return {
        ...state,
        loading: false,
        solicitudes: action.payload,
        solicitudesFiltradas: action.payload,
        error: null
      };
    case FETCH_SOLICITUDES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    // Ruta Activa
    case FETCH_RUTA_ACTIVA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_RUTA_ACTIVA_SUCCESS:
      return {
        ...state,
        loading: false,
        rutaActiva: action.payload,
        error: null
      };
    case FETCH_RUTA_ACTIVA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    // Crear Ruta
    case CREATE_RUTA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_RUTA_SUCCESS:
      return {
        ...state,
        loading: false,
        rutaActiva: action.payload,
        error: null
      };
    case CREATE_RUTA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    // Actualizar Delivery
    case UPDATE_DELIVERY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_DELIVERY_SUCCESS:
      // Actualizar la solicitud en el estado
      const updatedSolicitudes = state.solicitudes.map(solicitud => 
        solicitud.identificador === action.payload.identificador 
          ? { ...solicitud, ...action.payload } 
          : solicitud
      );
      
      // Actualizar las solicitudes filtradas
      const updatedSolicitudesFiltradas = state.solicitudesFiltradas.filter(
        solicitud => solicitud.identificador !== action.payload.identificador
      );
      
      // Actualizar la ruta activa si existe
      let updatedRutaActiva = state.rutaActiva;
      if (updatedRutaActiva && updatedRutaActiva.solicitudes) {
        // Actualizar el estado de la solicitud en la ruta activa
        updatedRutaActiva = {
          ...updatedRutaActiva,
          solicitudes: updatedRutaActiva.solicitudes.map(solicitud => {
            const solicitudId = solicitud.id || solicitud._id || solicitud.identificador;
            if (solicitudId === action.payload.identificador) {
              return { ...solicitud, estatus: action.payload.estatus };
            }
            return solicitud;
          })
        };
        
        // Actualizar las estadísticas si existen
        if (updatedRutaActiva.estadisticas) {
          const isEntregada = action.payload.estatus === "Entregado";
          const isCancelada = action.payload.estatus === "Por Reprogramar" || action.payload.estatusDelivery === "Cancelada";
          
          updatedRutaActiva = {
            ...updatedRutaActiva,
            estadisticas: {
              ...updatedRutaActiva.estadisticas,
              entregas_exitosas: isEntregada 
                ? (updatedRutaActiva.estadisticas.entregas_exitosas || 0) + 1 
                : updatedRutaActiva.estadisticas.entregas_exitosas || 0,
              entregas_canceladas: isCancelada 
                ? (updatedRutaActiva.estadisticas.entregas_canceladas || 0) + 1 
                : updatedRutaActiva.estadisticas.entregas_canceladas || 0
            }
          };
        }
      }
      
      return {
        ...state,
        loading: false,
        solicitudes: updatedSolicitudes,
        solicitudesFiltradas: updatedSolicitudesFiltradas,
        rutaActiva: updatedRutaActiva,
        error: null
      };
    case UPDATE_DELIVERY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    // Filtros
    case FILTER_SOLICITUDES:
      return {
        ...state,
        filtros: action.payload.filtros,
        solicitudesFiltradas: action.payload.solicitudesFiltradas
      };
    case RESET_FILTERS:
      return {
        ...state,
        filtros: {
          fechaInicio: null,
          fechaFin: null
        },
        solicitudesFiltradas: state.solicitudes
      };

    // Finalizar Ruta
    case FINALIZAR_RUTA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FINALIZAR_RUTA_SUCCESS:
      return {
        ...state,
        loading: false,
        rutaActiva: null,
        progresoRuta: {
          entregasCompletadas: 0,
          entregasCanceladas: 0,
          distanciaTotal: 0,
          tiempoTotal: 0
        },
        error: null
      };
    case FINALIZAR_RUTA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    // Resetear Ruta Activa
    case RESET_RUTA_ACTIVA:
      return {
        ...state,
        rutaActiva: null
      };

    // Actualizar Progreso de Ruta
    case UPDATE_ROUTE_PROGRESS:
      return {
        ...state,
        progresoRuta: {
          ...state.progresoRuta,
          ...action.payload
        }
      };

    default:
      return state;
  }
};

export default motorizadoReducer;