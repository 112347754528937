import {
  getDiagnosticos,
} from "../../../helpers/backend_helper";
import {
  apiError,
  diagnosticosSuccess,
  reset_diagnostico_flag,
} from "./reducer";

export const getdiagnosticos = () => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = getDiagnosticos();
    }

    var data = await response;

    if (data) {
      const serializableData = {
        data: data.data,
        message: data.message,
        status: data.status,
      };
      dispatch(diagnosticosSuccess(serializableData));
    }
  } catch (error) {
    dispatch(apiError(error));
  } 
};


export const resetDiagnosticoFlag = () => async (dispatch) => {
  try {
    const response = dispatch(reset_diagnostico_flag());
    return response;
  } catch (error) {
    dispatch(apiError(error));
  }
};
