import React from "react";
import { Col } from "reactstrap";
import Adjuntos from "./Adjuntos";
import Diagnostico from "./Diagnostico";
import Facturacion from "./Facturacion";
import Delivery from "./Delivery";
import InfoCronico from "./InfoCronico";
import RegistroMedicamentosCronicos from "./RegistroMedicamentosCronicos";

const TicketDescription = ({ solicitud, usuarios, farmacias }) => {
  return (
    <React.Fragment>
      <Col xxl={9}>
        <Adjuntos solicitud={solicitud} />

        {solicitud.tipo === "Cronica" && <InfoCronico solicitud={solicitud} />}

        {solicitud.tipo === "Aguda" && <Diagnostico solicitud={solicitud} />}

        {solicitud.montoCobertura > 0 && solicitud.tipo == "Cronica" && (
          <>
            <RegistroMedicamentosCronicos solicitud={solicitud} />
          </>
        )}

        {solicitud.diagnosticoFarmacologico != null && (
          <Facturacion usuarios={usuarios} solicitud={solicitud} />
        )}

        <Delivery
          usuarios={usuarios}
          solicitud={solicitud}
          farmacias={farmacias}
        />
      </Col>
    </React.Fragment>
  );
};

export default TicketDescription;
