import React, { useEffect, useState } from "react";
import {
  createPrincActivo,
  resetPrincipiosActivosFlag,
} from "../../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Row,
  Input,
  Label,
  Form,
  Spinner,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
} from "reactstrap";
import Select from "react-select";
import { Link } from "react-router-dom";
import ObjectId from "bson-objectid";
import { createSelector } from "reselect";
import { Navigate } from "react-router-dom";

const CrearPrincipioActivo = () => {
  const dispatch = useDispatch();

  const selectLayoutState = (state) => state;

  const principiosActivosPageData = createSelector(
    selectLayoutState,
    (state) => ({
      principioActivo: state.PrincipiosActivos.principioActivo,
      error: state.PrincipiosActivos.error,
      loading: state.PrincipiosActivos.loading,
      errorMsg: state.PrincipiosActivos.errorMsg,
      colorMsg: state.PrincipiosActivos.colorMsg,
      exito: state.PrincipiosActivos.exito,
    })
  );

  // Inside your component
  const { principioActivo, error, loading, errorMsg, colorMsg, exito } =
    useSelector(principiosActivosPageData);

  useEffect(() => {
    if (errorMsg) {
      setTimeout(() => {
        dispatch(resetPrincipiosActivosFlag());
        setLoadingx(false);
      }, 2000);
    }
  }, [dispatch, errorMsg]);

  const [nombre, setNombre] = useState("");
  const [activo, setActivo] = useState(true);
  const [redireccionar, setRedireccionar] = useState(false);

  const [presentations, setPresentations] = useState([]);
  const [presentationId, setPresentationId] = useState(null);

  const [loadingx, setLoadingx] = useState(false);

  const optionsBoolean = [
    { value: "true", label: "Si" },
    { value: "false", label: "No" },
  ];

  const [activoOptionSelect, setActivoOptionSelect] = useState(
    activo ? optionsBoolean[0] : optionsBoolean[1]
  );

  const [modal_grid_composicion, setmodal_grid_composicion] = useState(false);

  function tog_grid_composicion() {
    setmodal_grid_composicion(!modal_grid_composicion);
  }

  const handleAddPresentation = () => {
    const newPresentation = {
      id: ObjectId().toHexString(), // Genera un ID único
      nombre: "",
      composicion: [],
    };

    setPresentations((prevState) => [...prevState, newPresentation]);
  };

  const handleRemovePresentation = (index) => {
    setPresentations(presentations.filter((_, i) => i !== index));
  };

  const initialState = {
    presentations: [],
  };
  const [components, setComponents] = useState(initialState);

  const handleRemoveComponent = (compIndex, presentationId) => {

    setPresentations((prev) => {
      const updatedPresentations = prev.map((pres) => {
        if (pres.id === presentationId) {
          return {
            ...pres,
            composicion: pres.composicion.filter(
              (comp, index) => index !== compIndex
            ),
          };
        }
        return pres;
      });

      return updatedPresentations;
    });
  };

  useEffect(() => {
    if (exito) {
      setTimeout(() => {
        setRedireccionar(true);
      }, 2000);
    }
  }, [exito]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const create = {
      nombre: nombre,
      activo: activo,
      presentacion: presentations,
      creado: null,
      actualizado: null,
    };

    dispatch(createPrincActivo(create));
    setLoadingx(true);
  };

  const handleSubmitModalComponente = (e) => {
    e.preventDefault();
    const newComponent = document.getElementById("componente").value.trim();

    // Verifica si hay una presentación seleccionada
    if (newComponent && presentationId) {
      // Busca la presentación actual en el estado
      const presentationToUpdate = presentations.find(
        (p) => p.id === presentationId
      );

      if (presentationToUpdate) {
        // Actualiza la lista de composiciones de la presentación actual
        const updatedCompositions = [...presentationToUpdate.composicion];
        updatedCompositions.push(newComponent);

        // Actualiza la presentación en el estado
        setPresentations((prevState) =>
          prevState.map((p) =>
            p.id === presentationId
              ? { ...p, composicion: updatedCompositions }
              : p
          )
        );
      } else {
        alert("No se encontró la presentación seleccionada");
      }
    }

    setmodal_grid_composicion(false);
  };

  return (
    <>
      {redireccionar && <Navigate to="/principiosactivos" replace />}

      <React.Fragment>
        {errorMsg && errorMsg ? (
          <Alert color={colorMsg}> {error} </Alert>
        ) : null}

        <Form onSubmit={handleSubmit}>
          <Row lg={12} className="p-3">
            <Col xxl={6} md={6}>
              <div className="mb-3">
                <Label className="form-label" htmlFor="gen-info-email-input">
                  Nombre del Principio Activo{" "}
                  <span className="text-danger">*</span>
                </Label>

                <Input
                  required
                  minLength={3}
                  maxLength={50}
                  type="text"
                  className="form-control"
                  id="nombre"
                  name="nombre"
                  value={nombre}
                  onChange={(e) => setNombre(e.target.value)}
                />
              </div>
            </Col>
            <Col xxl={6} md={6}>
              <div className="mb-3">
                <Label className="form-label" htmlFor="activo">
                  Activo <span className="text-danger">*</span>
                </Label>
                <Select
                  defaultValue={activoOptionSelect}
                  onChange={(selectedOption) => {
                    setActivo(selectedOption.value === "true");
                  }}
                  options={optionsBoolean}
                  required
                  id="activo"
                  className="js-example-basic-single mb-0"
                  name="activo"
                />
              </div>
            </Col>

            <Col xxl={12} md={12}>
              <div className="mb-3">
                <div className="form-group">
                  <div id="presentaciones-container">
                    <ul>
                      <Label className="form-label" htmlFor="activo">
                        Listado de Presentaciones:{" "}
                        <span className="text-danger">*</span>
                      </Label>

                      {presentations.map((presentation, index) => (
                        <div key={index} className="presentation-item">
                          <Row>
                            <Col>
                              <Label
                                className="form-label mt-3"
                                htmlFor={`nombre-${index}`}
                              >
                                Nombre: <span className="text-danger"> * </span>
                              </Label>

                              <Input
                                required
                                minLength={3}
                                maxLength={50}
                                type="text"
                                className="form-control"
                                name={`nombre-${index}`}
                                value={presentation.nombre}
                                onChange={(e) => {
                                  const newPresentations = presentations.map(
                                    (p, idx) =>
                                      idx === index
                                        ? { ...p, nombre: e.target.value }
                                        : p
                                  );
                                  setPresentations(newPresentations);
                                }}
                              />
                            </Col>

                            <Col>
                              <Button
                                className="mt-3 mb-3 btn btn-sm btn-danger"
                                onClick={() => handleRemovePresentation(index)}
                              >
                                <i
                                  className="mdi mdi-delete font-size-18"
                                  id="deletetooltip"
                                />
                              </Button>
                            </Col>
                          </Row>

                          <Label className="form-label mt-3" htmlFor="activo">
                            Composición:{" "}
                            <span className="text-danger"> * </span>
                          </Label>

                          <Button
                            className="m-3"
                            color="primary"
                            onClick={() => {
                              setPresentationId(presentation.id);
                              setmodal_grid_composicion(true);
                            }}
                          >
                            Agregar
                          </Button>

                          <ul>
                            {presentation.composicion.map((comp, compIndex) => (
                              <li key={compIndex}>
                                <Row>
                                  <Col xs={8}>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      value={comp}
                                    />
                                  </Col>
                                  <Col xs={4}>
                                    <Button
                                      className="mt-3 mb-3 btn btn-sm btn-danger"
                                      onClick={() =>
                                        handleRemoveComponent(
                                          compIndex,
                                          presentation.id
                                        )
                                      }
                                    >
                                      <i className="mdi mdi-delete font-size-18" />
                                    </Button>
                                  </Col>
                                </Row>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </ul>
                  </div>
                  <Button variant="primary" onClick={handleAddPresentation}>
                    Agregar Presentación
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="align-items-end">
            <Col lg={12}>
              <div className="mt-4 mb-3 text-end">
                <button
                  type="submit"
                  className="btn btn-success btn-label right ms-auto nexttab nexttab"
                >
                  {loadingx ? (
                    <Spinner size="sm" className="me-2">
                      {" "}
                      Loading...{" "}
                    </Spinner>
                  ) : null}
                  <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                  Crear Principio Activo
                </button>
              </div>
            </Col>
          </Row>
        </Form>

        <Modal
          isOpen={modal_grid_composicion}
          toggle={() => {
            tog_grid_composicion();
          }}
        >
          <ModalHeader>
            <h5 className="modal-title">Agregar Composición</h5>
            <Button
              type="button"
              onClick={() => {
                setmodal_grid_composicion(false);
              }}
              className="btn-close"
              aria-label="Close"
            ></Button>
          </ModalHeader>

          <ModalBody>
            <Form className="row g-3" onSubmit={handleSubmitModalComponente}>
              <div className="row g-3">
                <Col xxl={6}>
                  <div>
                    <label htmlFor="nomnbre" className="form-label">
                      Nombre <span className="text-danger"> * </span>
                    </label>
                    <Input
                      type="text"
                      className="form-control"
                      id="componente"
                      placeholder="Ej. 500 mg"
                      required
                    />
                  </div>
                </Col>

                <div className="col-lg-12">
                  <div className="hstack gap-2 justify-content-end">
                    <Button
                      color="light"
                      onClick={() => setmodal_grid_composicion(false)}
                    >
                      Cerrar
                    </Button>
                    <Button type="submit" color="primary">
                      Guardar
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </React.Fragment>
    </>
  );
};

export default CrearPrincipioActivo;
