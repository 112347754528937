//LOGIN FARME
export const POST_FAKE_JWT_LOGIN = "/auth/token";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const POST_FAKE_JWT_REFRESH = "/auth/refresh";
export const POST_FAKE_JWT_REFRESH_TOKEN = "/auth/refresh";
export const POST_FAKE_JWT_LOGOUT = "/auth/logout";
//LABORATORIOS FARME
export const BASE_LABORATORIOS = "/laboratorios";
//USUARIOS FARME
export const BASE_USUARIOS = "/usuarios";
//PRINCIPIOS ACTIVOS FARME
export const BASE_PRINCIPIOSACTIVOS = "/principiosactivos";
//PRINCIPIOS ACTIVOS CATEGORIAS FARME
export const BASE_PRINCIPIOSACTIVOSCATEGORIAS = "/principiosactivocategorizacion";
//Servicio De Entrega
export const BASE_SERVICIODEENTREGAS = "/servicioentregas";
//Planilla Estrategica
export const BASE_PLANILLAESTRATEGICA = "/planillaestrategica";
//Solicitudes
export const BASE_SOLICITUDES = "/solicitud";
//DIAGNOSTICOS FARME
export const BASE_DIAGNOSTICOS = "/diagnostico/";
//PLANTILLAS MENSAJES
export const BASE_PLANTILLASMENSAJES = "/plantillasmensajes/";
//FARMACIAS FARME
export const BASE_FARMACIAS = "/farmacias/";
//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/user";
//SICOTEL
export const BASE_SICOTEL = "/siscotel/";
//RUTAS
export const BASE_RUTAS = "/rutas/";