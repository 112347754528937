import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { cambiarPassword, resetUsuarioFlag } from "../../slices/thunks";

// import images
// import profile from "../../assets/images/bg.png";
import logoLight from "../../assets/images/logo-farme.png";
import ParticlesAuth from "./ParticlesAuth";
import { createSelector } from "reselect";

const ForgetPasswordPage = (props) => {
  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      usuario: "",
      telefono: "",
    },
    validationSchema: Yup.object({
      usuario: Yup.string().required("Por favor ingrese su usuario"),
      telefono: Yup.string().required(
        "Por favor ingrese su numero de telefono con codigo internacional"
      ),
    }),

    onSubmit: (values) => {
      //console.log(values);

      dispatch(cambiarPassword(values, props.history));
    },
  });

  const selectLayoutState2 = (state) => state;
  const usuarioPageData = createSelector(selectLayoutState2, (state) => ({
    usuario: state.Usuarios.usuario,
    error: state.Usuarios.error,
    loading: state.Usuarios.loading,
    errorMsg: state.Usuarios.errorMsg,
    colorMsg: state.Usuarios.colorMsg,
    exito: state.Usuarios.exito,
  }));

  const { usuario, exito, error, loading, errorMsg, colorMsg } =
    useSelector(usuarioPageData);

  //console.log(usuario, exito, error, loading, errorMsg, colorMsg);

  useEffect(() => {
    
      setTimeout(() => {
        dispatch(resetUsuarioFlag());
      }, 10000);
    
  }, [dispatch, errorMsg]);

  document.title = "Reset Password | Farme";
  return (
    <ParticlesAuth>
      <div className="auth-page-content mt-lg-5">
        <Container>
          <Row>
            <Col lg={12}></Col>
          </Row>

          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="mt-4">
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <div className="text-center mt-sm-5 mb-4 text-white-50">
                      <div>
                        <Link to="/" className="d-inline-block auth-logo">
                          <img src={logoLight} alt="" height="50" />
                        </Link>
                      </div>
                    </div>
                    <p className="text-muted">Resetear Contraseña</p>
                  </div>

                  <div className="p-2">
                    {colorMsg && colorMsg ? (
                      <Alert color={colorMsg} style={{ marginTop: "13px" }}>
                        {error}
                      </Alert>
                    ) : null}

                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-4">
                        <Label className="form-label">Usuario</Label>
                        <Input
                          name="usuario"
                          className="form-control"
                          placeholder="Ingrese usuario"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.usuario || ""}
                          invalid={
                            validation.touched.usuario &&
                            validation.errors.usuario
                              ? true
                              : false
                          }
                        />
                        {validation.touched.usuario &&
                        validation.errors.usuario ? (
                          <FormFeedback type="invalid">
                            <div>{validation.errors.usuario}</div>
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-4">
                        <Label className="form-label">Telefono</Label>
                        <Input
                          name="telefono"
                          className="form-control"
                          placeholder="Ingresat telefono"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.telefono || ""}
                          invalid={
                            validation.touched.telefono &&
                            validation.errors.telefono
                              ? true
                              : false
                          }
                        />
                        {validation.touched.telefono &&
                        validation.errors.telefono ? (
                          <FormFeedback type="invalid">
                            <div>{validation.errors.telefono}</div>
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="text-center mt-4">
                        <button className="btn btn-success w-100" type="submit">
                          Enviar nueva contrasena a mi telefono
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </ParticlesAuth>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);
