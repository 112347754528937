// Tipos de acciones para el módulo de Motorizado
export const FETCH_SOLICITUDES_REQUEST = "FETCH_SOLICITUDES_REQUEST";
export const FETCH_SOLICITUDES_SUCCESS = "FETCH_SOLICITUDES_SUCCESS";
export const FETCH_SOLICITUDES_FAIL = "FETCH_SOLICITUDES_FAIL";

// Tipos de acciones para ruta activa
export const FETCH_RUTA_ACTIVA_REQUEST = "FETCH_RUTA_ACTIVA_REQUEST";
export const FETCH_RUTA_ACTIVA_SUCCESS = "FETCH_RUTA_ACTIVA_SUCCESS";
export const FETCH_RUTA_ACTIVA_FAILURE = "FETCH_RUTA_ACTIVA_FAILURE";
export const RESET_RUTA_ACTIVA = "RESET_RUTA_ACTIVA";

export const CREATE_RUTA_REQUEST = "CREATE_RUTA_REQUEST";
export const CREATE_RUTA_SUCCESS = "CREATE_RUTA_SUCCESS";
export const CREATE_RUTA_FAIL = "CREATE_RUTA_FAIL";

export const UPDATE_DELIVERY_REQUEST = "UPDATE_DELIVERY_REQUEST";
export const UPDATE_DELIVERY_SUCCESS = "UPDATE_DELIVERY_SUCCESS";
export const UPDATE_DELIVERY_FAIL = "UPDATE_DELIVERY_FAIL";

export const FILTER_SOLICITUDES = "FILTER_SOLICITUDES";
export const RESET_FILTERS = "RESET_FILTERS";

export const FINALIZAR_RUTA_REQUEST = "FINALIZAR_RUTA_REQUEST";
export const FINALIZAR_RUTA_SUCCESS = "FINALIZAR_RUTA_SUCCESS";
export const FINALIZAR_RUTA_FAIL = "FINALIZAR_RUTA_FAIL";

export const UPDATE_ROUTE_PROGRESS = "UPDATE_ROUTE_PROGRESS";

// Tipos de acciones para manejo de estado
export const SET_LOADING = "SET_LOADING";