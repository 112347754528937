import React, { useEffect, useState } from "react";
import { Navigate, Route } from "react-router-dom";
import { setAuthorization } from "../helpers/api_helper";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { loginSuccess } from "../slices/auth/login/reducer";

const AuthProtected = (props) => {
  const dispatch = useDispatch();
  const { loading } = useSelector(state => ({
    loading: state.Profile.loading,
  }));
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [checkingAuth, setCheckingAuth] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        setCheckingAuth(true);
        
        // Verificar si hay un token en localStorage
        const authUserStr = localStorage.getItem("authUser");
        const directToken = localStorage.getItem("auth_token");
        
        //console.log("AuthProtected - localStorage.getItem('authUser'):", authUserStr);
        //console.log("AuthProtected - localStorage.getItem('auth_token'):", directToken);
        
        let token = null;
        
        // Primero intentar obtener el token desde auth_token
        if (directToken) {
          //console.log("AuthProtected - Usando token directo de auth_token");
          token = directToken;
        } 
        // Si no hay token directo, intentar obtenerlo de authUser
        else if (authUserStr) {
          try {
            const authUser = JSON.parse(authUserStr);
            //console.log("AuthProtected - authUser:", authUser);
            
            if (authUser?.access_token) {
              //console.log("AuthProtected - Usando token de authUser.access_token");
              token = authUser.access_token;
            } else if (authUser?.token) {
              //console.log("AuthProtected - Usando token de authUser.token");
              token = authUser.token;
            }
          } catch (error) {
            console.error("AuthProtected - Error al parsear authUser:", error);
          }
        }
        
        if (token) {
          //console.log("AuthProtected - Token encontrado, estableciendo autorización");
          setAuthorization(token);
          await dispatch(loginSuccess({}));
          setIsLoggedIn(true);
        } else {
          //console.log("AuthProtected - No se encontró token, redirigiendo a login");
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error("AuthProtected - Error al verificar autenticación:", error);
        setIsLoggedIn(false);
      } finally {
        setCheckingAuth(false);
      }
    };

    checkAuth();
  }, [dispatch]);

  if (checkingAuth) {
    return null;
  }

  if (!isLoggedIn) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }

  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (<> <Component {...props} /> </>);
      }}
    />
  );
};

export { AuthProtected, AccessRoute };