import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Button, Row, Col, Alert, ListGroup, ListGroupItem, Badge } from 'reactstrap';
import AuthNotifications from '../helpers/auth_notifications';
import SecureStorage from '../helpers/secure_storage';
import { refreshAuthToken } from '../helpers/api_helper';
import { postJwtLogout } from '../helpers/fakebackend_helper';
import axios from 'axios';

/**
 * Componente para probar las funcionalidades de autenticación
 * Permite probar el refresh token, logout y visualizar el estado actual de los tokens
 */
const AuthTester = () => {
  const [tokens, setTokens] = useState({
    accessToken: null,
    refreshToken: null,
    expiry: null
  });
  const [testResults, setTestResults] = useState([]);
  const [loading, setLoading] = useState(false);

  // Cargar tokens al montar el componente
  useEffect(() => {
    updateTokensInfo();
  }, []);

  // Actualizar información de tokens
  const updateTokensInfo = () => {
    const accessToken = SecureStorage.getAccessToken();
    const refreshToken = SecureStorage.getRefreshToken();
    const expiry = SecureStorage.getTokenExpiry();
    
    setTokens({
      accessToken,
      refreshToken,
      expiry
    });
  };

  // Formatear token para mostrar
  const formatToken = (token) => {
    if (!token) return 'No disponible';
    if (token.length <= 10) return token;
    return token.substring(0, 10) + '...';
  };

  // Formatear fecha de expiración
  const formatExpiry = (expiry) => {
    if (!expiry) return 'No disponible';
    
    const expiryDate = new Date(parseInt(expiry));
    const now = new Date();
    const diffMs = expiryDate - now;
    const diffMins = Math.round(diffMs / 60000);
    
    return `${expiryDate.toLocaleTimeString()} (en ${diffMins} minutos)`;
  };

  // Agregar resultado de prueba
  const addTestResult = (success, message) => {
    const result = {
      id: Date.now(),
      success,
      message,
      timestamp: new Date().toLocaleTimeString()
    };
    
    setTestResults(prev => [result, ...prev].slice(0, 10));
    return result;
  };

  // Probar endpoint protegido
  const testProtectedEndpoint = async () => {
    setLoading(true);
    addTestResult(true, 'Probando acceso a endpoint protegido...');
    
    try {
      const API_BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001";
      const response = await axios.get(`${API_BASE_URL}/auth/users/me/`, {
        headers: {
          'Authorization': `Bearer ${tokens.accessToken}`
        }
      });
      
      addTestResult(true, `Acceso exitoso. Usuario: ${JSON.stringify(response.data)}`);
    } catch (error) {
      let errorMessage = "Error desconocido";
      
      if (error.response) {
        errorMessage = `Error ${error.response.status}: ${error.response.statusText || 'Error en la respuesta del servidor'}`;
        
        if (error.response.data && error.response.data.message) {
          errorMessage += ` - ${error.response.data.message}`;
        }
      } else if (error.request) {
        errorMessage = "No se recibió respuesta del servidor";
      } else if (error.message) {
        errorMessage = error.message;
      }
      
      addTestResult(false, errorMessage);
    } finally {
      setLoading(false);
    }
  };

  // Probar refresh token manualmente
  const testManualRefreshToken = async () => {
    setLoading(true);
    addTestResult(true, 'Probando refresh token...');
    addTestResult(true, `Usando refresh token: ${formatToken(tokens.refreshToken)}`);
    
    try {
      const result = await refreshAuthToken();
      
      if (result.success) {
        addTestResult(true, 'Refresh token exitoso');
        updateTokensInfo();
      } else {
        addTestResult(false, result.message || 'Error desconocido al refrescar token');
        if (result.suggestion) {
          addTestResult(false, `Sugerencia: ${result.suggestion}`);
        }
      }
    } catch (error) {
      addTestResult(false, error.message || 'Error desconocido');
    } finally {
      setLoading(false);
    }
  };

  // Probar refresh automático
  const testAutoRefresh = async () => {
    setLoading(true);
    addTestResult(true, 'Probando refresh automático...');
    
    // Guardar el token original
    const originalToken = tokens.accessToken;
    
    // Simular token expirado cambiando la fecha de expiración
    const now = new Date().getTime();
    SecureStorage.setTokenExpiry(now - 1000); // Expirado hace 1 segundo
    addTestResult(true, 'Token simulado como expirado');
    
    // Intentar acceder a un endpoint protegido
    addTestResult(true, 'Probando refresh automático. Haciendo solicitud con token expirado...');
    
    try {
      const API_BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001";
      const response = await axios.get(`${API_BASE_URL}/auth/users/me/`, {
        headers: {
          'Authorization': `Bearer ${tokens.accessToken}`
        }
      });
      
      addTestResult(true, `Solicitud exitosa después del refresh automático. Usuario: ${JSON.stringify(response.data)}`);
      addTestResult(true, 'El refresh automático funcionó correctamente');
    } catch (error) {
      let errorMessage = "Error desconocido";
      
      if (error.response) {
        errorMessage = `Error ${error.response.status}: ${error.response.statusText || 'Error en la respuesta del servidor'}`;
        
        if (error.response.data && error.response.data.message) {
          errorMessage += ` - ${error.response.data.message}`;
        }
      } else if (error.request) {
        errorMessage = "No se recibió respuesta del servidor";
      } else if (error.message) {
        errorMessage = error.message;
      }
      
      addTestResult(false, errorMessage);
    } finally {
      // Restaurar el token original
      if (originalToken) {
        SecureStorage.setAccessToken(originalToken);
        // Restaurar la expiración (1 día desde ahora)
        const tomorrow = new Date().getTime() + 24 * 60 * 60 * 1000;
        SecureStorage.setTokenExpiry(tomorrow);
        addTestResult(true, 'Token original restaurado');
      }
      
      updateTokensInfo();
      setLoading(false);
    }
  };

  // Probar logout
  const testLogout = async () => {
    setLoading(true);
    addTestResult(true, 'Probando logout...');
    
    try {
      const result = await postJwtLogout();
      
      if (result.localOnly) {
        addTestResult(false, result.message || 'Error: El endpoint de logout no está implementado en el servidor');
        if (result.suggestion) {
          addTestResult(false, `Sugerencia: ${result.suggestion}`);
        }
        addTestResult(true, 'Realizando logout local a pesar del error en el servidor');
      } else {
        addTestResult(true, 'Logout exitoso en el servidor');
      }
      
      addTestResult(true, 'Tokens eliminados localmente');
      updateTokensInfo();
    } catch (error) {
      addTestResult(false, error.message || 'Error desconocido');
      
      // Intentar logout local
      SecureStorage.clearAuthTokens();
      addTestResult(true, 'Se realizó logout local a pesar del error');
      updateTokensInfo();
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="auth-tester-container">
      <Row>
        <Col md={6}>
          <Card className="mb-4">
            <CardHeader className="bg-primary text-white">
              <h5 className="mb-0">Información de Tokens</h5>
            </CardHeader>
            <CardBody>
              <ListGroup>
                <ListGroupItem>
                  <strong>Access Token:</strong> {formatToken(tokens.accessToken)}
                </ListGroupItem>
                <ListGroupItem>
                  <strong>Refresh Token:</strong> {formatToken(tokens.refreshToken)}
                </ListGroupItem>
                <ListGroupItem>
                  <strong>Expiración:</strong> {formatExpiry(tokens.expiry)}
                </ListGroupItem>
              </ListGroup>
              <Button 
                color="secondary" 
                className="mt-3" 
                onClick={updateTokensInfo}
                disabled={loading}
              >
                Actualizar información
              </Button>
            </CardBody>
          </Card>
          
          <Card>
            <CardHeader className="bg-primary text-white">
              <h5 className="mb-0">Pruebas de Autenticación</h5>
            </CardHeader>
            <CardBody>
              <Row>
                <Col xs={6} className="mb-2">
                  <Button 
                    color="info" 
                    className="w-100" 
                    onClick={testProtectedEndpoint}
                    disabled={loading || !tokens.accessToken}
                  >
                    Probar Endpoint Protegido
                  </Button>
                </Col>
                <Col xs={6} className="mb-2">
                  <Button 
                    color="warning" 
                    className="w-100" 
                    onClick={testManualRefreshToken}
                    disabled={loading || !tokens.refreshToken}
                  >
                    Probar Refresh Token
                  </Button>
                </Col>
                <Col xs={6} className="mb-2">
                  <Button 
                    color="success" 
                    className="w-100" 
                    onClick={testAutoRefresh}
                    disabled={loading || !tokens.accessToken || !tokens.refreshToken}
                  >
                    Probar Refresh Automático
                  </Button>
                </Col>
                <Col xs={6} className="mb-2">
                  <Button 
                    color="danger" 
                    className="w-100" 
                    onClick={testLogout}
                    disabled={loading || !tokens.accessToken}
                  >
                    Probar Logout
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        
        <Col md={6}>
          <Card>
            <CardHeader className="bg-primary text-white">
              <h5 className="mb-0">Resultados de Pruebas</h5>
            </CardHeader>
            <CardBody>
              {testResults.length === 0 ? (
                <Alert color="info">
                  No hay resultados de pruebas. Ejecuta alguna prueba para ver los resultados.
                </Alert>
              ) : (
                <div className="test-results-container" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                  {testResults.map(result => (
                    <Alert 
                      key={result.id} 
                      color={result.success ? 'success' : 'danger'}
                      className="mb-2"
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <Badge color={result.success ? 'success' : 'danger'} pill>
                          {result.success ? 'OK' : 'ERROR'}
                        </Badge>
                        <small>{result.timestamp}</small>
                      </div>
                      <p className="mb-0 mt-2">{result.message}</p>
                    </Alert>
                  ))}
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AuthTester;
