import React from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import AuthTester from "../../Components/AuthTester";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TestAuth = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Pruebas del Sistema de Autenticación</h4>
                  <p className="card-text text-muted mb-4">
                    Esta página permite probar las funcionalidades del sistema de autenticación mejorado, 
                    incluyendo login, refresh token, acceso a endpoints protegidos y logout.
                  </p>
                  
                  {/* Componente de pruebas de autenticación */}
                  <AuthTester />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      
      {/* Contenedor de notificaciones toast */}
      <ToastContainer 
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </React.Fragment>
  );
};

export default TestAuth;