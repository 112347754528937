import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Alert,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import CrearUsuario from "./CrearUsuario";
import { DatosSesion } from "../../../Components/Common/DatosSesion";

const UsuarioCrear = () => {
  document.title = "Crear Usuario | Farme";
  const perfil = DatosSesion("perfil");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {perfil === "Administrador" ||
          perfil === "Farmabien" ||
          perfil === "MyHealth" ? (
            <>
              <BreadCrumb
                title="Crear"
                pageTitle="Listar Usuario"
                url={`/usuarios/`}
              />
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardHeader>
                      <h5 className="card-title mb-0">Usuario</h5>
                    </CardHeader>
                    <CardBody>
                      <CrearUsuario />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            <Alert color="danger">
              No tienes permisos para ver esta página.
            </Alert>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UsuarioCrear;
