import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { Link } from "react-router-dom";
import TiempoProcesamientoWidget from "../../../Components/Common/TiempoProcesamientoWidget";

// Función de formateo de fecha local
const formatDate = (dateString) => {
  if (!dateString) return "No disponible";

  const date = new Date(dateString);
  return date.toLocaleDateString("es-ES", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

const Solicitud = ({ solicitud }) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <React.Fragment>
      {/* Entrega de Medicamentos */}
      <Col>
        <Card>
          <CardHeader>
            <div className="d-flex align-items-center">
              <i className="ri-medicine-bottle-line text-primary me-2 fs-20"></i>
              <h5 className="card-title mb-0">Entrega de Medicamentos</h5>
            </div>
          </CardHeader>
          <CardBody>
            <div className="p-2">
              <Link
                to="#"
                className="d-flex align-items-center text-primary"
                onClick={(e) => {
                  e.preventDefault();
                  toggle();
                }}
              >
                <i className="ri-file-list-3-line me-2"></i>
                Ver Políticas de Beconsult
              </Link>
            </div>
          </CardBody>
        </Card>
      </Col>

      {/* Efectividad de la Entrega */}
      <Col>
        <Card>
          <CardHeader>
            <div className="d-flex align-items-center">
              <i className="ri-timer-line text-primary me-2 fs-20"></i>
              <h5 className="card-title mb-0">Efectividad de la Entrega</h5>
            </div>
          </CardHeader>
          <CardBody>
            <TiempoProcesamientoWidget
              identificador={solicitud?.identificador}
            />
          </CardBody>
        </Card>
      </Col>

      {/* Calificación del Servicio (condicional) */}
      {solicitud?.calificacion && (
        <Col>
          <Card>
            <CardHeader>
              <div className="d-flex align-items-center">
                <i className="ri-star-line text-primary me-2 fs-20"></i>
                <h5 className="card-title mb-0"> Calificación del Servicio</h5>
              </div>
            </CardHeader>
            <CardBody>
              <div className="info-list">
                <div className="info-item d-flex flex-column mb-3">
                  <span className="fw-medium text-muted mb-2">Valoración</span>
                  <div className="d-flex align-items-center">
                    {[...Array(5)].map((_, index) => (
                      <i
                        key={index}
                        className={`ri-star-${
                          index < solicitud.calificacion.valoracion
                            ? "fill"
                            : "line"
                        } me-1 fs-18`}
                        style={{ color: "#FFD700" }}
                      ></i>
                    ))}
                  </div>
                </div>

                <div className="info-item d-flex flex-column mb-3">
                  <span className="fw-medium text-muted mb-2">Comentario</span>
                  <div className="p-3 bg-light-subtle border rounded">
                    {solicitud.calificacion.mensaje || "Sin comentarios"}
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      )}

      {/* Información de la Solicitud */}
      <Col>
        <Card>
          <CardHeader>
            <div className="d-flex align-items-center">
              <i className="ri-information-line me-2 fs-20"></i>
              <h5 className="card-title mb-0"> Información de la Solicitud</h5>
            </div>
          </CardHeader>
          <CardBody>
            <div className="info-list">
              <div className="info-item d-flex flex-column mb-3">
                <span className="fw-medium text-muted mb-2">
                  Fecha Creación
                </span>
                <div>
                  <span className="fw-medium" id="create-date">
                    {formatDate(solicitud.creado)}
                  </span>
                </div>
              </div>

              <div className="info-item d-flex flex-column mb-3">
                <span className="fw-medium text-muted mb-2">Detalle</span>
                <div className="p-3 bg-light-subtle border rounded">
                  {solicitud?.detalle}
                </div>
              </div>

              <div className="info-item d-flex flex-column mb-3">
                <span className="fw-medium text-muted mb-2">Diagnóstico</span>
                <div className="p-3 bg-light-subtle border rounded">
                  {solicitud?.diagnostico}
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>

      {/* Modal de Políticas */}
      <Modal isOpen={modal} toggle={toggle} size="xl" scrollable={true}>
        <ModalHeader toggle={toggle}>
          <div className="d-flex align-items-center">
            <i className="ri-file-list-3-line text-primary fs-24 me-2"></i>
            <div>
              <h5 className="card-title mb-0">
                Especificación del Proceso Entrega de Medicamentos
              </h5>
              <small className="text-muted">Fecha: 09/09/2024</small>
            </div>
          </div>
        </ModalHeader>
        <ModalBody className="p-4">
          <Row>
            <Col md={12}>
              <div className="policy-content">
                <div className="mb-4 pb-2 border-bottom">
                  <h5 className="mb-3 text-primary">OBJETIVO</h5>
                  <p className="text-muted">
                    Definir el proceso para la entrega oportuna de medicamentos
                    para enfermedades Agudas y Crónicas a los asegurados de
                    Seguros Caracas – Red Beconsult
                  </p>
                </div>

                <div className="mb-4 pb-2 border-bottom">
                  <h5 className="mb-3 text-primary">ALCANCE</h5>
                  <p className="text-muted">
                    Aplica únicamente para la cobertura Básica de Gastos Médicos
                    de Beconsult
                  </p>
                </div>

                <div className="mb-4 pb-2 border-bottom">
                  <h5 className="mb-3 text-primary">REGLAS GENERALES</h5>
                  <div className="policy-list position-relative ps-4 border-start border-3 border-primary">
                    {[
                      "Todas las solicitudes deben realizarse a través de la App MiSalud yMás",
                      "Las patologías crónicas son aquellas que requieren medicamentos para tratamientos de más de 6 meses, un año e inclusive por tiempo indefinido",
                      "Las patologías agudas son enfermedades de aparición súbita de tratamiento rápido, para un tiempo máximo de seis meses. Si el tratamiento es indicado por un período superior a 30 días, se deben realizar solicitudes mensuales hasta el límite de 6 meses",
                      "Enfermedades Crónicas: El equipo médico revisa y aprueba los medicamentos, la entrega se realizará dentro de los seis (06) días hábiles siguientes a la fecha de solicitud",
                      "Enfermedades Agudas: Una vez revisada la solicitud por parte del proveedor, la entrega se realizará dentro de los dos (2) días hábiles siguientes a la fecha de solicitud",
                      "Los Récipes y/o Informes Médicos tendrán una validez de seis (6) meses",
                      "No se dispensarán fármacos prescritos en Récipes y/o Informes Médicos vencidos o que presenten falla en la Identificación del Asegurado, sello del médico y/o fecha",
                      "Los cambios de tratamientos médico para enfermedades crónicas deberán solicitarse a través de la App, cancelando la solicitud inicial y generando una nueva solicitud acompañadas del nuevo récipe e Informe Médico",
                      "En ausencia o incumplimiento de algunos de los recaudos y/o requisitos exigidos al afiliado, el Proveedor de Servicio de farmacia no deberá dispensar el tratamiento",
                    ].map((item, index) => (
                      <div key={index} className="d-flex mb-4">
                        <div className="me-3">
                          <div className="avatar-xs">
                            <div className="avatar-title rounded-circle bg-primary text-white">
                              {index + 1}
                            </div>
                          </div>
                        </div>
                        <div className="flex-grow-1 text-muted">{item}</div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="mb-4 pb-2 border-bottom">
                  <h5 className="mb-3 text-primary">RECAUDOS REQUERIDOS</h5>
                  <div className="policy-list position-relative ps-4 border-start border-3 border-success">
                    {[
                      "Récipe médico original con sello húmedo del médico tratante",
                      "Informe médico original con sello húmedo del médico tratante (para tratamientos crónicos)",
                      "Copia de la cédula de identidad del asegurado",
                      "Copia del carnet de asegurado vigente",
                    ].map((item, index) => (
                      <div key={index} className="d-flex mb-4">
                        <div className="me-3">
                          <div className="avatar-xs">
                            <div className="avatar-title rounded-circle bg-success text-white">
                              <i className="ri-check-line"></i>
                            </div>
                          </div>
                        </div>
                        <div className="flex-grow-1 text-muted">{item}</div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="mb-4 pb-2 border-bottom">
                  <h5 className="mb-3 text-primary">TIEMPOS DE ENTREGA</h5>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="card border-0 bg-light-subtle">
                        <div className="card-body">
                          <div className="d-flex align-items-center mb-3">
                            <div className="flex-shrink-0">
                              <div className="avatar-sm">
                                <div className="avatar-title rounded-circle bg-danger text-white">
                                  <i className="ri-heart-pulse-line"></i>
                                </div>
                              </div>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h5 className="mb-0">Enfermedades Agudas</h5>
                            </div>
                          </div>
                          <p className="text-muted mb-0">
                            <i className="ri-time-line me-1 text-danger"></i>
                            Entrega en <strong>2 días hábiles</strong> después
                            de la aprobación
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card border-0 bg-light-subtle">
                        <div className="card-body">
                          <div className="d-flex align-items-center mb-3">
                            <div className="flex-shrink-0">
                              <div className="avatar-sm">
                                <div className="avatar-title rounded-circle bg-info text-white">
                                  <i className="ri-calendar-check-line"></i>
                                </div>
                              </div>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h5 className="mb-0">Enfermedades Crónicas</h5>
                            </div>
                          </div>
                          <p className="text-muted mb-0">
                            <i className="ri-time-line me-1 text-info"></i>
                            Entrega en <strong>6 días hábiles</strong> después
                            de la aprobación
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-center mt-4">
                  <a
                    href="https://drive.google.com/file/d/1cj9KErh0xxbvOSfmTYuhdVyOvnOLfPgB/view?usp=sharing"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-primary"
                  >
                    <i className="ri-download-2-line me-1"></i>
                    Descargar Documento Completo
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Solicitud;
