import React, { useEffect, useState } from "react";
import { editFarmacia } from "../../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row, Input, Label, Form, Spinner, Button } from "reactstrap";
import Select from "react-select";
import { Link } from "react-router-dom";

const EditarFarmacia = ({ farmacia }) => {
  const dispatch = useDispatch();
  const [nombre, setNombre] = useState(farmacia.nombre);
  const [codigo_farmabien, setCodigoFarmabien] = useState(
    farmacia.codigo_farmabien
  );
  const [codigo_myh, setCodigoMyh] = useState(farmacia.codigo_myh);
  const [direccion, setDireccion] = useState(farmacia.direccion);
  const [coordenadas, setCoordenadas] = useState(farmacia.coordenadas);
  const [loading, setLoading] = useState(false); // Define el estado loading

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  }, [dispatch, loading]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const update = {
      id: farmacia.id,
      nombre: nombre,
      codigo_farmabien: codigo_farmabien,
      codigo_myh: codigo_myh,
      direccion:direccion,
      coordenadas:coordenadas,
      creado: farmacia.creado,
      actualizado: null,
    };

    try {
      const response = await dispatch(editFarmacia(update));
      if (response.error) {
        throw new Error(response.error.message);
      }
    } catch (error) {
      console.error("Error al crear una Farmacia:", error);
      // Aquí puedes manejar el error, por ejemplo, mostrando un mensaje al usuario
    }
  };

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <Row lg={12} className="p-3">
          <Col xxl={6} md={6}>
            <div>
              <Label className="form-label" htmlFor="gen-info-email-input">
                Nombre <span className="text-danger">*</span>
              </Label>
            </div>
            <Input
              required
              minLength={3}
              maxLength={50}
              type="text"
              className="form-control"
              id="nombre"
              name="nombre"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
            />
          </Col>

          <Col xxl={6} md={6}>
              <div>
                <Label className="form-label" htmlFor="codigo_farmabien">
                Codigo Farmabien <span className="text-danger">*</span>
                </Label>
              </div>
              <Input
                required
                minLength={3}
                maxLength={50}
                type="text"
                className="form-control"
                id="codigo_farmabien"
                name="codigo_farmabien"
                value={codigo_farmabien}
                onChange={(e) => setCodigoFarmabien(e.target.value)}
              />
            </Col>
            <Col xxl={6} md={6}>
              <div>
                <Label className="form-label" htmlFor="codigo_myh">
                Codigo MyH+ <span className="text-danger">*</span>
                </Label>
              </div>
              <Input
                required
                minLength={3}
                maxLength={50}
                type="text"
                className="form-control"
                id="codigo_myh"
                name="codigo_myh"
                value={codigo_myh}
                onChange={(e) => setCodigoMyh(e.target.value)}
              />
            </Col>

            <Col xxl={6} md={6}>
              <div>
                <Label className="form-label" htmlFor="coordenadas">
                Coordenadas <span className="text-danger">*</span>
                </Label>
              </div>
              <Input
                required
                minLength={3}
                maxLength={50}
                type="text"
                className="form-control"
                id="coordenadas"
                name="coordenadas"
                value={coordenadas}
                onChange={(e) => setCoordenadas(e.target.value)}
              />
            </Col>
            <Col xxl={12} md={12}>
              <div className="mb-3">
                <Label className="form-label" htmlFor="direccion">
                Direccion <span className="text-danger">*</span>
                </Label>
                <Input
                  type="textarea"
                  required
                  className="form-control"
                  id="direccion"
                  name="direccion"
                  value={direccion}
                  onChange={(e) => setDireccion(e.target.value)}
                />
              </div>
            </Col>

          

          
        </Row>
        <Row className="align-items-end">
          <Col lg={6}>
            <div className="mt-4 mb-3">
              <Link to="/farmacias">
                <Button className="btn btn-info btn-label left ms-auto nexttab nexttab">
                  <i className="ri-arrow-left-line label-icon align-middle fs-16 ms-2"></i>
                  Regresar
                </Button>
              </Link>
            </div>
          </Col>
          <Col lg={6}>
            <div className="mt-4 mb-3 text-end">
              <button
                type="submit"
                className="btn btn-success btn-label right ms-auto nexttab nexttab"
              >
                {loading ? (
                  <Spinner size="sm" className="me-2">
                    {" "}
                    Loading...{" "}
                  </Spinner>
                ) : null}
                <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                Actualizar
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

export default EditarFarmacia;
