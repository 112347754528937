import { toast } from 'react-toastify';

/**
 * Clase para manejar notificaciones relacionadas con autenticación
 */
class AuthNotifications {
  // Tipos de notificaciones
  static TYPES = {
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning',
    INFO: 'info'
  };

  // Eventos de autenticación
  static EVENTS = {
    // Eventos de login
    LOGIN_SUCCESS: 'login_success',
    LOGIN_FAILED: 'login_failed',
    
    // Eventos de logout
    LOGOUT_SUCCESS: 'logout_success',
    LOGOUT_FAILED: 'logout_failed',
    
    // Eventos de refresh token
    REFRESH_TOKEN_SUCCESS: 'refresh_token_success',
    REFRESH_TOKEN_FAILED: 'refresh_token_failed',
    
    // Eventos de sesión
    SESSION_EXPIRED: 'session_expired',
    SESSION_INVALID: 'session_invalid',
    
    // Eventos de seguridad
    SECURITY_ERROR: 'security_error',
    CSRF_ERROR: 'csrf_error',
    SUSPICIOUS_ACTIVITY: 'suspicious_activity'
  };

  // Configuración de notificaciones por evento
  static EVENT_CONFIG = {
    [AuthNotifications.EVENTS.LOGIN_SUCCESS]: {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    },
    [AuthNotifications.EVENTS.LOGIN_FAILED]: {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    },
    [AuthNotifications.EVENTS.SECURITY_ERROR]: {
      position: "top-center",
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false
    }
    // Otros eventos usan la configuración por defecto
  };

  /**
   * Muestra una notificación
   * @param {string} event - Evento de autenticación
   * @param {string} type - Tipo de notificación (success, error, warning, info)
   * @param {string} message - Mensaje a mostrar
   */
  static notify(event, type, message) {
    // Asegurarse de que el mensaje sea una cadena
    const safeMessage = typeof message === 'string' ? message : 
                        (message && message.toString ? message.toString() : 'Error desconocido');
    
    // Obtener configuración específica del evento o usar la predeterminada
    const config = AuthNotifications.EVENT_CONFIG[event] || {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    };
    
    // Mostrar notificación según el tipo
    switch (type) {
      case AuthNotifications.TYPES.SUCCESS:
        toast.success(safeMessage, config);
        break;
      case AuthNotifications.TYPES.ERROR:
        toast.error(safeMessage, config);
        break;
      case AuthNotifications.TYPES.WARNING:
        toast.warning(safeMessage, config);
        break;
      case AuthNotifications.TYPES.INFO:
        toast.info(safeMessage, config);
        break;
      default:
        toast(safeMessage, config);
    }
    
    // Registrar en consola para depuración
    //console.log(`[Auth Notification - ${event}] ${type}: ${safeMessage}`);
  }
}

export default AuthNotifications;
