import React, { useEffect, useState } from "react";
import { editPlantillaMensaje } from "../../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row, Input, Label, Form, Spinner, Button } from "reactstrap";
import Select from "react-select";
import { Link } from "react-router-dom";

const EditarPlantillasMensajes = ({ plantillaMensaje }) => {
  const dispatch = useDispatch();
  const [nombre, setNombre] = useState(plantillaMensaje.nombre);
  const [contenido, setContenido] = useState(plantillaMensaje.contenido);
  const [tipo, setTipo] = useState(plantillaMensaje.tipo);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  }, [dispatch, loading]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const update = {
      id: plantillaMensaje.id,
      nombre: nombre,
      contenido: contenido,
      tipo: tipo,
      creado: plantillaMensaje.creado,
      actualizado: null,
    };

    try {
      const response = await dispatch(editPlantillaMensaje(update));
      if (response.error) {
        throw new Error(response.error.message);
      }
    } catch (error) {
      console.error("Error al crear una Plantilla de Mensaje:", error);
      // Aquí puedes manejar el error, por ejemplo, mostrando un mensaje al usuario
    }
  };

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <Row lg={12} className="p-3">
          <Col xxl={6} md={6}>
            <div>
              <Label className="form-label" htmlFor="gen-info-email-input">
                Nombre <span className="text-danger">*</span>
              </Label>
            </div>
            <Input
              required
              minLength={3}
              maxLength={50}
              type="text"
              className="form-control"
              id="nombre"
              name="nombre"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
            />
          </Col>

          <Col xxl={6} md={6}>
            <div className="mb-3">
              <Label className="form-label" htmlFor="transnacional">
                Tipo <span className="text-danger">*</span>
              </Label>

              <Select
                value={{ value: tipo, label: tipo || "Seleccione una opcion" }}
                onChange={(selectedOption) => {
                  setTipo(selectedOption.value);
                }}
                options={[
                  { value: "", label: "Seleccione una opcion" },
                  { value: "Orden lista", label: "Orden lista" },
                  { value: "En camino", label: "En camino" },
                  { value: "Entregado", label: "Entregado" },
                  { value: "Cancelado", label: "Cancelado" },
                  { value: "Otro", label: "Otro" },
                ]}
                required
              />
            </div>
          </Col>

          <Col xxl={12} md={12}>
            <div className="mb-3">
              <Label className="form-label" htmlFor="gen-info-apellido-input">
                Contenido <span className="text-danger">*</span>
              </Label>
              <Input
                type= 'textarea'
                required
                className="form-control"
                id="contenido"
                name="contenido"
                value={contenido}
                onChange={(e) => setContenido(e.target.value)}
              />
            </div>
          </Col>
        </Row>
        <Row className="align-items-end">
          <Col lg={6}>
            <div className="mt-4 mb-3">
              <Link to="/plantillas-mensajes">
                <Button className="btn btn-info btn-label left ms-auto nexttab nexttab">
                  <i className="ri-arrow-left-line label-icon align-middle fs-16 ms-2"></i>
                  Regresar
                </Button>
              </Link>
            </div>
          </Col>
          <Col lg={6}>
            <div className="mt-4 mb-3 text-end">
              <button
                type="submit"
                className="btn btn-success btn-label right ms-auto nexttab nexttab"
              >
                {loading ? (
                  <Spinner size="sm" className="me-2">
                    {" "}
                    Loading...{" "}
                  </Spinner>
                ) : null}
                <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                Actualizar
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

export default EditarPlantillasMensajes;
