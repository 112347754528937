import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

// Authentication
import LoginReducer from "./auth/login/reducer";

//import AccountReducer from "./auth/register/reducer";
import ForgetPasswordReducer from "./auth/forgetpwd/reducer";
import ProfileReducer from "./auth/profile/reducer";

//FARME
//Laboratorios
import LaboratoriosReducer from "./configuracion/laboratorios/reducer";
//Usuarios
import UsuariosReducer from "./configuracion/usuarios/reducer";
//PrincipiosActivos
import PrincipiosActivosReducer from "./configuracion/principiosactivos/reducer";
//PrincipiosActivosCategorias
import PrincipiosActivosCategoriasReducer from "./configuracion/principiosactivoscategorias/reducer";

//Servicio De Entrega
import ServicioEntregasReducer from "./configuracion/serviciodeentrega/reducer";
//Planilla Estratégica
import PlanillaEstrategicaReducer from "./configuracion/planillaestrategica/reducer";
//Solicitudes
import SolicitudReducer from "./configuracion/solicitudes/reducer"
//Diagnosticos
import DiagnosticosReducer from "./configuracion/diagnosticos/reducer"
//Plantillas Mensajes
import PlantillasMensajesReducer from "./configuracion/plantillasmensajes/reducer"
//Farmacias
import  FarmaciasReducer  from "./configuracion/farmacias/reducer";
//Siscotel
import SiscotelReducer from "./configuracion/siscotel/reducer"
//Rutas
import RutasReducer from "./configuracion/rutas/reducer"
//Dashboard
import DashboardReducer from "./configuracion/dashboard/reducer";
//Motorizado
import MotorizadoReducer from "../pages/Motorizado/store/reducer";

const rootReducer = combineReducers({
    Layout: LayoutReducer,
    Login: LoginReducer,
    //Account: AccountReducer,
    ForgetPassword: ForgetPasswordReducer,
    Profile: ProfileReducer,
    Laboratorios: LaboratoriosReducer,
    Usuarios: UsuariosReducer,
    PrincipiosActivos: PrincipiosActivosReducer,
    PrincipiosActivosCategorias: PrincipiosActivosCategoriasReducer,
    ServicioEntregas: ServicioEntregasReducer,
    PlanillaEstrategica: PlanillaEstrategicaReducer,
    Solicitudes: SolicitudReducer,
    Diagnosticos: DiagnosticosReducer,
    PlantillasMensajes: PlantillasMensajesReducer,
    Farmacias: FarmaciasReducer,
    Siscotel: SiscotelReducer,
    Rutas: RutasReducer,
    Dashboard: DashboardReducer,
    Motorizado: MotorizadoReducer
});

export default rootReducer;