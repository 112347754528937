import React from "react";
import { Navigate } from "react-router-dom";
import { DatosSesion } from "../Components/Common/DatosSesion";

//Dashboard
import DashboardEcommerce from "../pages/DashboardEcommerce";
//Faqs
import Preguntas from "../pages/Faqs/Preguntas";

//Reportes
import FiltarSolicitudes from "../pages/Reportes/Solicitudes";
import ReporteFacturacion from "../pages/Reportes/Facturacion";

//

//Solicitudes
import ListView from "../pages/Solicitudes/ListView";
import SolicitudDetalle from "../pages/Solicitudes/DetailsView";

//Plantillas Mensajes
import PlantillasMensajes from "../pages/PlantillasMensajes/index";
import PlantillasMensajesCrear from "../pages/PlantillasMensajes/Crear";
import PlantillasMensajesEditar from "../pages/PlantillasMensajes/Editar"; 

//Farmacias
import Farmacias from "../pages/Farmacias/index";
import FarmaciasCrear from "../pages/Farmacias/Crear";
import FarmaciasEditar from "../pages/Farmacias/Editar";  

//Laboratorios
import Laboratorios from "../pages/Laboratorios/index";
import LaboratorioListar from "../pages/Laboratorios/LaboratorioListar";
import LaboratorioCrear from "../pages/Laboratorios/LaboratorioCrear";
import LaboratorioEditar from "../pages/Laboratorios/LaboratorioEditar";

//Clientes
import ClienteListar from "../pages/Clientes/ClienteListar";
import ClienteVer from "../pages/Clientes/ClienteVer";
import ClienteCrear from "../pages/Clientes/ClienteCrear/";
import ClienteEditar from "../pages/Clientes/ClienteEditar";

//login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import TestAuth from "../pages/Authentication/TestAuth";
//import Register from "../pages/Authentication/Register";
import VerificarFactura from "../pages/Verificacion/VerificarFactura"

// Planilla Estrategica 
import PlanillaEstrategica from "../pages/PlanillaEstrategica/index";
import PlanillaEstrategicaListar from "../pages/PlanillaEstrategica/PlanillaEstrategicaListar";
import PlanillaEstrategicaCrear from "../pages/PlanillaEstrategica/PlanillaEstrategicaCrear";
import PlanillaEstrategicaEditar from "../pages/PlanillaEstrategica/PlanillaEstrategicaEditar";

//Usuarios
import UsuariosListar from "../pages/Usuarios/UsuarioListar";
import UsuariosCrear from "../pages/Usuarios/UsuarioCrear";
import UsuariosEditar from "../pages/Usuarios/UsuarioEditar";


//Delivery
import Delivery from "../pages/Delivery/index";
import DeliveryListar from "../pages/Delivery/Listar";

//Motorizados
import MotorizadoListar from "../pages/Motorizado/Listar/Lista";

//Principios Activos
//import PrincipiosActivos from "../pages/PrincipiosActivos/index";
import PrincipioActivoListar from "../pages/PrincipiosActivos/PrincipioActivoListar";
import PrincipioActivoCrear from "../pages/PrincipiosActivos/PrincipioActivoCrear";
import PrincipioActivoEditar from "../pages/PrincipiosActivos/PrincipioActivoEditar";

//Principios Activos Categorias
//import PrincipiosActivos from "../pages/PrincipiosActivos/index";
import PrincipioActivoCategoriaListar from "../pages/PrincipiosActivosCategorias/Listar";
import PrincipioActivoCategoriaCrear from "../pages/PrincipiosActivosCategorias/Crear";
import PrincipioActivoCategoriaEditar from "../pages/PrincipiosActivosCategorias/Editar";


// User Profile
import UserProfile from "../pages/Authentication/user-profile";

const authProtectedRoutes = [
  { path: "/dashboard", component: <DashboardEcommerce /> },
  { path: "/index", component: <DashboardEcommerce /> },
  { path: "/faqs", component: <Preguntas /> },

  //Solicitudes
  { path: "/solicitudes", component: <ListView /> },
  { path: "/solicitud/ver/:id", component: <SolicitudDetalle /> },

  //Laboratorios
  { path: "/laboratorios", component: <LaboratorioListar /> },
  { path: "/laboratorios/editar/:id", component: <LaboratorioEditar  /> },
  { path: "/laboratorios/crear", component: <LaboratorioCrear  /> },
  
  //Usuarios
  { path: "/usuarios", component: <UsuariosListar /> },
  { path: "/usuarios/crear", component: <UsuariosCrear /> },
  { path: "/usuarios/editar/:id", component: <UsuariosEditar /> },

  //Delivery
  { path: "/delivery/", component: <DeliveryListar /> },

  //Motorizado
  { path: "/motorizado/", component: <MotorizadoListar /> },

  //Planilla Estrategica
  { path: "/planilla-estrategica/", component: <PlanillaEstrategicaListar /> },
  { path: "/planilla-estrategica/crear/", component: <PlanillaEstrategicaCrear /> },
  { path: "/planilla-estrategica/editar/:id", component: <PlanillaEstrategicaEditar /> },

   //Farmacias
   { path: "/farmacias/", component: <Farmacias/> },
   { path: "/farmacias/crear/", component: <FarmaciasCrear/> },
   { path: "/farmacias/editar/:id", component: <FarmaciasEditar/> },

  //Planillas Mensajes
  { path: "/plantillas-mensajes/", component: <PlantillasMensajes/> },
  { path: "/plantillas-mensajes/crear/", component: <PlantillasMensajesCrear/> },
  { path: "/plantillas-mensajes/editar/:id", component: <PlantillasMensajesEditar/> },

  //User Profile
  { path: "/profile", component: <UserProfile/> },

  //Principios Activos
  { path: "/principiosactivos", component: <PrincipioActivoListar /> },
  { path: "/principiosactivos/editar/:id", component: <PrincipioActivoEditar  /> },
  { path: "/principiosactivos/crear", component: <PrincipioActivoCrear  /> },

  
  //Principios Activos Categorias
  { path: "/principiosactivoscategorias", component: <PrincipioActivoCategoriaListar /> },
  { path: "/principiosactivoscategorias/editar/:id", component: <PrincipioActivoCategoriaEditar /> },
  { path: "/principiosactivoscategorias/crear", component: <PrincipioActivoCategoriaCrear  /> },

  //Reportes
  { path: "/reportes/solicitudes", component: <FiltarSolicitudes /> },
  { path: "/reportes/facturacion", component: <ReporteFacturacion /> },

  //Validacion de Facturas


  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/test-auth", component: <TestAuth /> },
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/verificarQR/:id/:factura", component: <VerificarFactura /> },
 


];

export { authProtectedRoutes, publicRoutes };
