import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  farmacias: [],
  farmacia: {},
  error: "",
  loading: false,
  errorMsg: false, 
  colorMsg: "",
  exito: false,
};

const farmaciasSlice = createSlice({
  name: "farmacias",
  initialState,
  reducers: {
    apiError(state, action) {
      state.error = action.payload;
      state.loading = false;
      state.errorMsg = true;
      state.colorMsg = "danger";
    },
    farmaciasSuccess(state, action) {
      state.farmacias = action.payload.data.data;
      state.loading = false;
      state.errorMsg = false;
      state.error = action.payload.data.message;
      state.colorMsg = "success";
      state.exito = false;
    },
    farmaciaSuccess(state, action) {
      state.farmacia = action.payload.data.data;
      state.loading = false;
      state.errorMsg = true;
      state.colorMsg = "success";
      state.error = action.payload.data.message;
      state.exito = true;
    },
    farmaciaDeleteSuccess(state, action) {
      state.error = action.payload.message;
      state.loading = true;
      state.errorMsg = true;
      state.error = action.payload.data.message;
      state.colorMsg = "success";
      state.exito = false;
    },
    reset_farmacia_flag(state) {
      state.success = false;
      state.error = "";
      state.loading = false;
      state.errorMsg = false;
      state.colorMsg = "";
    },
  },
});

export const {
  apiError, farmaciasSuccess, farmaciaSuccess, farmaciaDeleteSuccess, reset_farmacia_flag
} = farmaciasSlice.actions;

export default farmaciasSlice.reducer;
