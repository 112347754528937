import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  plantillasMensajes: [],
  plantillaMensaje: {},
  error: "", // for error message
  loading: false,
  errorMsg: false, // for error
  colorMsg: "",
  exito: false,
};

const plantillasMensajesSlice = createSlice({
  name: "plantillasMensajes",
  initialState,
  reducers: {
    apiError(state, action) {
      state.error = action.payload;
      state.loading = false;
      state.errorMsg = true;
      state.colorMsg = "danger";
    },
    plantillasMensajesSuccess(state, action) {
      state.plantillasMensajes = action.payload.data;
      state.loading = false;
      state.errorMsg = false;
      state.error = action.payload.message;
      state.colorMsg = "success";
      state.exito = false;
    },
    plantillaMensajeSuccess(state, action) {
      state.plantillaMensaje = action.payload.data;
      state.loading = false;
      state.errorMsg = true;
      state.colorMsg = "success";
      state.error = action.payload.message;
      state.exito = true;
    },
    plantillaMensajeDeleteSuccess(state, action) {
      state.error = action.payload.message;
      state.loading = true;
      state.errorMsg = true;
      state.error = action.payload.message;
      state.colorMsg = "success";
      state.exito = false;
    },
    reset_plantillaMensaje_flag(state) {
      state.success = false;
      state.error = "";
      state.loading = false;
      state.errorMsg = false;
      state.colorMsg = "";
    },
  },
});

export const {
  apiError, plantillasMensajesSuccess, plantillaMensajeSuccess, plantillaMensajeDeleteSuccess, reset_plantillaMensaje_flag
} = plantillasMensajesSlice.actions;

export default plantillasMensajesSlice.reducer;
