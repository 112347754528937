import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import SolicitudLabel from "./SolicitudLabel";
import {
  Card,
  CardBody,
  Col,
  Row,
  Badge,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Input,
  Label,
} from "reactstrap";
import logo_mh_plus_sm from "../../../assets/images/mh-sm.png";
import {
  editSolicitud,
  createMessage,
  resetSiscotelFlag,
} from "../../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/**
 * Section es un componente que se encarga de mostrar los detalles de una
 * solicitud de medicamentos. Recibe como parámetro un objeto con la
 * información de la solicitud y devuelve un JSX con los detalles de la
 * solicitud.
 *
 * @param {object} props - Objeto con la información de la solicitud.
 *
 * @returns {JSX} Un JSX con los detalles de la solicitud.
 */
const Section = ({ solicitud }) => {
  const labelRef = useRef();
  const dispatch = useDispatch();
  const [motivo, setMotivo] = useState(null);

  const selectLayoutState = (state) => state;
  const pageData = createSelector(selectLayoutState, (state) => ({
    siscotel: state.Siscotel.siscotel,
    error: state.Siscotel.error,
    loading: state.Siscotel.loading,
    errorMsg: state.Siscotel.errorMsg,
    colorMsg: state.Siscotel.colorMsg,
    exito: state.Siscotel.exito,
  }));

  const { siscotel, error, loading, errorMsg, colorMsg, exito } =
    useSelector(pageData);

  useEffect(() => {
    if (siscotel && siscotel.status == 200 && motivo != null) {
      const now = new Date();
      const creado = new Date(
        now.getTime() - now.getTimezoneOffset() * 60000
      ).toISOString();

      const comentario = {
        tipo: "Enviado",
        mensaje: "Solicitud cancelada por: " + motivo,
        estatus: true,
        adjunto: null,
        creado: creado,
      };

      const nuevaSolicitud = {
        ...solicitud,
        estatus: "Cancelado",
        comentarios: solicitud.comentarios
          ? [
              ...(Array.isArray(solicitud.comentarios)
                ? solicitud.comentarios
                : []),
              comentario,
            ]
          : [comentario],
      };

      dispatch(editSolicitud(nuevaSolicitud)).then(() => {
        setMotivo("");
        tog_standard();
      });
    }
  }, [dispatch, siscotel]);

  useEffect(() => {
    if (siscotel.status == "400" || (errorMsg && colorMsg == "danger")) {
      // Muestra el toast con el mensaje de error
      toast.error(`API Myh+: ${error}`, {
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "bg-danger text-white",
        icon: ({ theme, type }) => (
          <svg
            viewBox="0 0 24 24"
            width="100%"
            height="100%"
            fill="white"
            style={{ color: "white" }}
          >
            <path d="M11.983 0a12.206 12.206 0 00-8.51 3.653A11.8 11.8 0 000 12.207 11.779 11.779 0 0011.8 24h.214A12.111 12.111 0 0024 11.791 11.766 11.766 0 0011.983 0zM10.5 16.542a1.476 1.476 0 011.449-1.53h.027a1.527 1.527 0 011.523 1.47 1.475 1.475 0 01-1.449 1.53h-.027a1.529 1.529 0 01-1.523-1.47zM11 12.5v-6a1 1 0 012 0v6a1 1 0 11-2 0z" />
          </svg>
        ),
      });

      setTimeout(() => {
        dispatch(resetSiscotelFlag());
      }, 10000);
    }
  }, [dispatch, errorMsg]);

  const getBadgeColor = (priority) => {
    if (!priority) return "info";

    switch (priority.toLowerCase()) {
      case "alta":
        return "danger";

      case "media":
        return "warning";

      case "baja":
        return "info";

      case "nueva solicitud":
        return "primary";

      case "por entregar":
        return "secondary";

      case "en tránsito":
        return "info";

      case "entregado":
        return "success";

      case "cancelado":
        return "danger";

      default:
        return "info";
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString("es-ES", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const [modal_standard, setmodal_standard] = useState(false);

  function tog_standard() {
    setmodal_standard(!modal_standard);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const message = {
      IdentificadorEvento: solicitud.identificador,
      Tipo: "Cancelada",
      Mensaje: motivo,
      Adjunto: "",
      CodigoProveedor: solicitud.codigoProveedor,
    };

    dispatch(createMessage(message));
  };

  const handleSendMessage = () => {
    const message = {
      IdentificadorEvento: solicitud.identificador,
      Tipo: "Mensaje",
      Mensaje: "Solicitud Aceptada",
      Adjunto: "",
      CodigoProveedor: solicitud.codigoProveedor,
    };

    try {
      dispatch(createMessage(message));
    } catch (error) {
      console.error(
        "Error al enviar mensaje:",
        error.message || "Error desconocido"
      );
      toast.error("Error al enviar el mensaje. Por favor, intente nuevamente.");
    }
  };

  const [showLabel, setShowLabel] = useState(false);

  const handlePrint = () => {
    const printContent = labelRef.current;

    if (printContent) {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(`
        <html>
          <head>
            <title>Etiqueta ${solicitud.identificador}</title>
            
          </head>
          <body>
            <div class="print-container">
              ${printContent.outerHTML}
            </div>
            <script>
              window.onload = function() {
                window.print();
                window.onafterprint = function() {
                  window.close();
                }
              }
            </script>
          </body>
        </html>
      `);
      printWindow.document.close();
    } else {
      console.error("No se encontró el contenido para imprimir");
    }
  };

  return (
    <React.Fragment>
      <Col lg={12}>
        {" "}
        <Card className="mt-n4 mx-n4 mb-n5">
          <div className="bg-success-subtle">
            <CardBody className="pb-4 mb-5">
              <Row>
                <div className="col-md">
                  <Row className="align-items-center">
                    <div className="col-md-auto">
                      <div className="avatar-md mb-md-0 mb-4">
                        <div className="avatar-title bg-white rounded-circle">
                          <img
                            src={logo_mh_plus_sm}
                            alt=""
                            className="avatar-sm"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md">
                      <h4 className="fw-semibold" id="ticket-title">
                        #{solicitud.identificador} - Solicitud:{" "}
                        {solicitud.solicitante} - C.I:{" "}
                        {solicitud.solicitanteCedula}
                      </h4>
                      <div className="hstack gap-3 flex-wrap">
                        <div className="text-muted">
                          {" "}
                          Titular:
                          <span className="fw-medium" id="titular">
                            {" "}
                            {solicitud.titular}
                          </span>{" "}
                          C.I:
                          <span className="fw-medium" id="cedula">
                            {" "}
                            {solicitud.titularCedula}
                          </span>{" "}
                        </div>
                        <div className="text-muted">
                          {" "}
                          <a
                            href={`tel:${solicitud?.telefono}`}
                            className="btn btn-soft-primary btn-sm rounded-pill"
                          >
                            <i className="ri-phone-line me-1"></i>
                            {solicitud?.telefono}
                          </a>

                        </div>

                        <div className="text-muted">
                          {" "}
                          <Badge
                            pill
                            className="fs-12"
                            color={getBadgeColor(solicitud.estatus)}
                          >
                            {solicitud.estatus}
                          </Badge>
                        </div>

                        <div className="text-muted">

                          <Badge
                            pill
                            className="fs-12"
                            color={getBadgeColor(solicitud.prioridad)}
                          >
                            {solicitud.prioridad}
                          </Badge>
                        </div>
                        <div className="vr"></div>

                        {solicitud.estatus === "Cancelado" ||
                        solicitud.estatus === "Entregado" ? (
                          ""
                        ) : (
                          <Button
                            size="sm"
                            color="danger"
                            onClick={() => tog_standard()}
                          >
                            <i className="ri-error-warning-line label-icon align-middle fs-16 me-2"></i>
                            Cancelar la Solicitud
                          </Button>
                        )}

                        {solicitud.facturacion &&
                        solicitud.facturacion.length > 0 ? (
                          <Button
                            size="sm"
                            color="primary"
                            onClick={handlePrint}
                          >
                            <i className="ri-printer-line align-middle fs-16 me-2"></i>
                            Imprimir Etiqueta
                          </Button>
                        ) : (
                          ""
                        )}

                        {/* Componente de la etiqueta */}
                        <div style={{ display: "none" }}>
                          <SolicitudLabel
                            ref={labelRef}
                            solicitud={solicitud}
                          />
                        </div>
                      </div>
                    </div>
                  </Row>
                </div>
              </Row>
            </CardBody>
          </div>
        </Card>
      </Col>

      <Modal
        id="myModal"
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
      >
        <Form onSubmit={handleSubmit}>
          <ModalHeader>
            <h5 className="modal-title" id="myModalLabel">
              Cancelar una Solicitud
            </h5>
          </ModalHeader>
          <ModalBody>
            <h5 className="fs-15">
              Motivo por el cual se cancela la solicitud #
              {solicitud.identificador}
            </h5>

            <Col xxl={12} md={12}>
              <div>
                <Input
                  type="textarea"
                  className="form-control"
                  id="motivo"
                  rows="3"
                  value={motivo}
                  onChange={(e) => setMotivo(e.target.value)}
                  required
                />
              </div>
            </Col>
          </ModalBody>
          <ModalFooter>
            <Button
              color="light"
              type="button"
              onClick={() => {
                setMotivo("");
                tog_standard();
              }}
            >
              {"  "}Salir{"  "}
            </Button>
            <Button color="danger" type="submit">
              Guardar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default Section;
