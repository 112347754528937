// SolicitudLabel.js
import React from "react";
import { QRCodeSVG } from "qrcode.react";
import logo_mh_plus_sm from "../../../assets/images/Logo-FarmaBien.png";
import logo_farme from "../../../assets/images/logo-farme.png";
import { urlPublicaQR } from "../../../config";

const SolicitudLabel = React.forwardRef(({ solicitud }, ref) => {
  // Obtener la última factura del array de facturación
  const ultimaFactura =
    solicitud.facturacion && solicitud.facturacion.length > 0
      ? solicitud.facturacion[solicitud.facturacion.length - 1]
      : null;


  const qrContent = ultimaFactura
    ? `${urlPublicaQR.QR_URL}/verificarQR/${solicitud.id}/${ultimaFactura.numeroFactura}`
    : `${urlPublicaQR.QR_URL}/verificarQR/${solicitud.id}`;

  
  return (
    <div
      ref={ref}
      style={{
        width: "80mm", // Ancho estándar para impresora térmica
        backgroundColor: "white",
        padding: "0",
        margin: "0 auto",
        fontFamily: "Arial, sans-serif",
        fontSize: "10pt",
      }}
    >
      {/* Encabezado */}
      <div
        style={{
          textAlign: "center",
          marginBottom: "3mm",
          paddingTop: "3mm",
        }}
      >
        <img
          src={logo_farme}
          alt="Logo"
          style={{
            height: "12mm",
            marginBottom: "2mm",
          }}
        />
        <div
          style={{
            fontSize: "12pt",
            fontWeight: "bold",
            marginBottom: "2mm",
          }}
        >
          Solicitud #{solicitud.identificador}
        </div>
      </div>

      {/* Línea separadora */}
      <div
        style={{
          borderTop: "1px dashed black",
          margin: "2mm 0",
        }}
      ></div>

      {/* Información del solicitante */}
      <div
        style={{
          padding: "0 2mm",
          marginBottom: "3mm",
        }}
      >
        <div style={{ marginBottom: "2mm" }}>
          <strong>Solicitante:</strong>
          <div>{solicitud.solicitante}</div>
        </div>
        <div style={{ marginBottom: "2mm" }}>
          <strong>C.I:</strong>
          <div>{solicitud.solicitanteCedula}</div>
        </div>
        <div style={{ marginBottom: "2mm" }}>
          <strong>Teléfono:</strong>
          <div>{solicitud.telefono}</div>
        </div>
        <div style={{ marginBottom: "2mm" }}>
          <strong>Dirección:</strong>
          <div>{solicitud.direccion}</div>
        </div>
      </div>

      {/* Línea separadora */}
      <div
        style={{
          borderTop: "1px dashed black",
          margin: "2mm 0",
        }}
      ></div>

      {/* QR Code centrado */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "2mm 0",
          backgroundColor: "white",
          margin: "1mm 0",
        }}
      >
        <QRCodeSVG
          value={qrContent}
          size={140} // Aumentado significativamente el tamaño
          level="H" // Nivel más alto de corrección de errores
          includeMargin={true}
          style={{
            padding: "2mm",
            backgroundColor: "white",
          }}
        />
      </div>

      {/* Línea separadora final */}
      <div
        style={{
          borderTop: "1px dashed black",
          margin: "1mm 0",
        }}
      ></div>

      {/* Logo y mensaje de desarrollo */}
      <div
        style={{
          textAlign: "center",
          padding: "2mm",
          marginTop: "2mm",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "2mm", // Espacio entre el texto y el logo
        }}
      >
        <div
          style={{
            fontSize: "8pt",
          }}
        >
          Proveedor de los Medicamentos
        </div>
        <img
          src={logo_mh_plus_sm}
          alt="Logo Farmabien"
          style={{
            height: "15mm",
          }}
        />
      </div>

      {/* Espacio final para el corte */}
      <div style={{ height: "10mm" }}></div>
    </div>
  );
});

export default SolicitudLabel;
