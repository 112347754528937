import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  solicitudes: [],
  solicitud: {},
  ruta: null,
  medicamentos: null,
  error: null, // for error message
  loading: false,
  errorMsg: false, // for error
  colorMsg: "",
  exito: false,
  mensaje: "",
  estadisticas: null,
  facturacion: null,
  facturacionQR: null
};

const solicitudSlice = createSlice({
  name: "solicitud",
  initialState,
  reducers: {
    apiError(state, action) {
      state.error = action.payload;
      state.loading = false;
      state.errorMsg = true;
      state.colorMsg = "danger";
    },
    medicamentosSuccess(state, action) {
      state.medicamentos = action.payload.data;
      state.loading = false;
      state.errorMsg = false;
      state.error = action.payload.data.message;
      state.colorMsg = "success";
      state.exito = false;
    },
    solicitudesSuccess(state, action) {
      state.solicitudes = action.payload.data.data;
      state.loading = false;
      state.errorMsg = false;
      state.error = action.payload.data.message;
      state.colorMsg = "success";
      state.exito = false;
    },
    solicitudSuccess(state, action) {
      state.solicitud = action.payload.data.data;
      state.loading = false;
      state.errorMsg = true;
      state.colorMsg = "success";
      state.error = action.payload.data.message;
      state.exito = true;
    },
    solicitudDeleteSuccess(state, action) {
      state.error = action.payload.data.message;
      state.loading = true;
      state.errorMsg = true;
      state.colorMsg = "success";
      state.exito = false;
    },
    reset_solicitud_flag(state) {
      state.success = false;
      state.error = "";
      state.loading = false;
      state.errorMsg = false;
      state.colorMsg = "";
      state.ruta = null;
      state.facturacion = null; 
      state.estadisticas = null; 
      state.facturacionQR = null;
    },

    filtroSolicitudesSuccess(state, action) {
      state.solicitudes = action.payload.solicitudes;
      state.estadisticas = action.payload.estadisticas;
      state.loading = false;
      state.error = null;
    },

    facturacionSuccess(state, action) {
      state.facturacion = {
        detalles: action.payload.data.data.detalles,
        estadisticas: action.payload.data.data.estadisticas,
        facturas_por_tipo: action.payload.data.data.facturas_por_tipo
      };
      state.loading = false;
      state.errorMsg = false;
    },

    facturacionQRSuccess(state, action) {
      state.facturacionQR = {
        detalles: action.payload.data.data.detalles,
        patologia: action.payload.data.data.patologia,
        persona: action.payload.data.data.persona,
        cedula: action.payload.data.data.cedula,
        tipo: action.payload.data.data.tipo,
      };
      state.loading = false;
      state.errorMsg = false;
      state.error = action.payload.message;
      state.colorMsg = "success";
      state.exito = false;
    },


    rutaSuccess: (state, action) => {
      state.ruta = action.payload.data.data;
    },

    updateDeliverySuccess: (state, action) => {
      state.mensaje = action.payload;
    },
  },
});

export const {
  apiError,
  medicamentosSuccess,
  solicitudesSuccess,
  solicitudSuccess,
  solicitudDeleteSuccess,
  reset_solicitud_flag,
  rutaSuccess,
  updateDeliverySuccess,
  filtroSolicitudesSuccess,
  facturacionSuccess,
  facturacionQRSuccess
} = solicitudSlice.actions;

export default solicitudSlice.reducer;
