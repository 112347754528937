import React, { useEffect } from "react";
import { getUser, resetUsuarioFlag } from "../../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { useParams } from "react-router-dom";

// Importaciones de reacciones
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Alert,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import EditarUsuario from "./EditarUsuario";
import { DatosSesion } from "../../../Components/Common/DatosSesion";

const UsuarioEditar = () => {
  // Obtiene el ID desde la URL
  const { id } = useParams();
  const dispatch = useDispatch();
  const perfil = DatosSesion("perfil");

  useEffect(() => {
    dispatch(getUser(id));
  }, [dispatch, id]);

  const selectLayoutState = (state) => state;
  const usuarioPageData = createSelector(selectLayoutState, (state) => ({
    usuario: state.Usuarios.usuario,
    error: state.Usuarios.error,
    loading: state.Usuarios.loading,
    errorMsg: state.Usuarios.errorMsg,
    colorMsg: state.Usuarios.colorMsg,
    exito: state.Usuarios.exito,
  }));
  // Inside your component
  const { usuario, exito, error, loading, errorMsg, colorMsg } =
    useSelector(usuarioPageData);

  useEffect(() => {
    if (errorMsg) {
      setTimeout(() => {
        dispatch(resetUsuarioFlag());
      }, 3000);
    }
  }, [dispatch, errorMsg]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {perfil === "Administrador" ||
          perfil === "Farmabien" ||
          perfil === "MyHealth" ? (
            <>
              <BreadCrumb
                title="Editar Usuario"
                pageTitle="Listar Usuario"
                url={`/usuarios/`}
              />
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardHeader className="border-0">
                      {errorMsg && errorMsg ? (
                        <Alert color={colorMsg}> {error} </Alert>
                      ) : null}
                    </CardHeader>
                    <CardBody>
                      {exito && <EditarUsuario usuario={usuario} />}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            <Alert color="danger">
              No tienes permisos para ver esta página.
            </Alert>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UsuarioEditar;
