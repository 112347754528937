import React, { useState, useEffect } from "react";
import { Col, Row, Input, Label, Form, Spinner, Button } from "reactstrap";
import { editUser, getFarmacias } from "../../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { createSelector } from "reselect";
import { Link } from "react-router-dom";

const EditarUsuario = ({ usuario }) => {
  const dispatch = useDispatch();
  const selectLayoutState = (state) => state;

  const farmaciasPageData = createSelector(selectLayoutState, (state) => ({
    farmacias: state.Farmacias.farmacias,
  }));

  const { farmacias } = useSelector(farmaciasPageData);

  useEffect(() => {
    if (!farmacias || farmacias.length === 0) {
      dispatch(getFarmacias());
    } else {
      setFarmaciasLoaded(true);
    }
  }, [dispatch, farmacias]);

  const [nombre, setNombre] = useState(usuario.nombre);
  const [apellido, setApellido] = useState(usuario.apellido);
  const [correo, setCorreo] = useState(usuario.correo);
  const [telefono, setTelefono] = useState(usuario.telefono);
  const [usuarioRol, setUsuarioRol] = useState(usuario.usuario);
  const [creado, setCreado] = useState(usuario.creado);
  const [activo, setActivo] = useState(usuario.activo);
  const [contrasena, setContrasena] = useState("");
  const [perfil, setPerfil] = useState(usuario.perfil);
  const [farmacia, setFarmacia] = useState(usuario.farmacia);
  const opcionesPerfil = ["Administrador", "MyHealth", "Farmabien", "Delivery"];
  const [perfilOptionSelect, setPerfilOptionSelect] = useState({
    value: usuario.perfil,
    label: usuario.perfil,
  });

  const [activoOptionSelect, setActivoOptionSelect] = useState({
    value: usuario.activo,
    label: usuario.activo ? "Si" : "No",
  });

  const [farmaciaOptionSelect, setFarmaciaOptionSelect] = useState({
    value: "",
    label: "",
  });

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(null);

  const [farmaciasLoaded, setFarmaciasLoaded] = useState(false);

  // Custom password validation function
  const validatePassword = (password) => {
    const errors = [];

    if (password.length < 8) {
      errors.push("La contraseña debe tener al menos 8 caracteres.");
    }

    if (!/[a-z]/.test(password)) {
      errors.push("La contraseña debe contener al menos una letra minúscula.");
    }

    if (!/[A-Z]/.test(password)) {
      errors.push("La contraseña debe contener al menos una letra mayúscula.");
    }

    if (!/\d/.test(password)) {
      errors.push("La contraseña debe contener al menos un número.");
    }

    if (!/[!@#$%^&*()_+\-=\]{};':"\\|,.<>?]/.test(password)) {
      errors.push("La contraseña debe contener al menos un símbolo especial.");
    }

    return errors.length > 0 ? errors : null;
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setContrasena(newPassword);
    const validationErrors = validatePassword(newPassword);
    setPasswordError(validationErrors);
  };

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  }, [dispatch, loading]);

  useEffect(() => {
    if (farmacias && farmacias.length > 0) {
      const farmacia = farmacias[0]?.find(
        (farmacia) => farmacia.id === usuario.farmacia
      );
      if (farmacia) {
        setFarmaciaOptionSelect({
          value: usuario.farmacia,
          label: farmacia.nombre,
        });
      }
    }
  }, [farmacias, usuario.farmacia]);

  useEffect(() => {
    if (perfil !== "Farmabien" && perfil !== "Delivery") {
      setFarmacia(null);
      setFarmaciaOptionSelect({ value: "", label: "" });
    }
  }, [perfil]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const updatedUser = {
      id: usuario.id,
      nombre: nombre.trim(),
      apellido: apellido.trim(),
      correo: correo,
      telefono: telefono.trim(),
      usuario: usuarioRol.trim(),
      activo: activo,
      contrasena: contrasena.trim(),
      perfil: perfil,
      farmacia_id: farmacia,
      creado: creado,
      actualizado: null,
    };
    dispatch(editUser(updatedUser));
    setLoading(true);
  };

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <Row lg={12} className="p-3">
          <Col xxl={4} md={4}>
            <div className="mb-3">
              <Label className="form-label" htmlFor="nombre">
                Nombre <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                className="form-control"
                id="nombre"
                name="nombre"
                minLength={3}
                maxLength={50}
                value={nombre}
                required
                onChange={(e) => setNombre(e.target.value)}
              />
            </div>
          </Col>
          <Col xxl={4} md={4}>
            <div className="mb-3">
              <Label className="form-label" htmlFor="apellido">
                Apellido <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                className="form-control"
                id="apellido"
                name="apellido"
                minLength={3}
                maxLength={50}
                required
                value={apellido}
                onChange={(e) => setApellido(e.target.value)}
              />
            </div>
          </Col>
          <Col xxl={4} md={4}>
            <div className="mb-3">
              <Label className="form-label" htmlFor="correo">
                Email <span className="text-danger">*</span>
              </Label>

              <Input
                type="email"
                className="form-control"
                id="correo"
                name="correo"
                required
                value={correo}
                onChange={(e) => setCorreo(e.target.value)}
              />
            </div>
          </Col>
          <Col xxl={4} md={4}>
            <div className="mb-3">
              <Label className="form-label" htmlFor="telefono">
                Teléfono <span className="text-danger">*</span>
              </Label>
              <Input
                type="tel"
                className="form-control"
                id="telefono"
                name="telefono"
                required
                minLength={13}
                maxLength={13}
                value={telefono}
                onChange={(e) => setTelefono(e.target.value)}
              />
            </div>
          </Col>
          <Col xxl={4} md={4}>
            <div className="mb-3">
              <Label className="form-label" htmlFor="usuario">
                Usuario <span className="text-danger">*</span>
              </Label>

              <Input
                type="text"
                className="form-control"
                id="usuario"
                name="usuario"
                required
                minLength={5}
                maxLength={50}
                value={usuarioRol}
                onChange={(e) => setUsuarioRol(e.target.value)}
              />
            </div>
          </Col>

          <Col xxl={4} md={4}>
            <div className="mb-3 position-relative">
              <Label className="form-label" htmlFor="contrasena">
                Contraseña <span className="text-danger">*</span>
              </Label>

              <div className="position-relative">
                <Input
                  type={showPassword ? "text" : "password"}
                  className={`form-control pr-5 ${
                    passwordError ? "is-invalid" : ""
                  }`}
                  id="contrasena"
                  name="contrasena"
                  required
                  minLength={8}
                  maxLength={12}
                  value={contrasena}
                  onChange={handlePasswordChange}
                />

                <button
                  type="button"
                  className="btn btn-link position-absolute end-0 top-0 text-decoration-none"
                  onClick={() => setShowPassword(!showPassword)}
                  style={{ height: "100%", zIndex: 10 }}
                >
                  <i className={`ri-eye${showPassword ? "-off" : ""}-line`}></i>
                </button>

                {passwordError && (
                  <div className="invalid-feedback">
                    <ul>
                      {passwordError.map((error, index) => (
                        <li key={index}>{error}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </Col>

          <Col xxl={4} md={4}>
            <div className="mb-3">
              <Label className="form-label" htmlFor="perfil">
                Perfil <span className="text-danger">*</span>
              </Label>

              <Select
                defaultValue={perfilOptionSelect}
                onChange={(selectedOption) => {
                  setPerfil(selectedOption.value);
                }}
                options={opcionesPerfil.map((item) => ({
                  value: item,
                  label: item,
                }))}
                id="perfil"
                className="js-example-basic-single mb-0"
                name="perfil"
                required
              />
            </div>
          </Col>

          <Col xxl={4} md={4}>
            <div className="mb-3">
              <Label className="form-label" htmlFor="activo">
                Activo <span className="text-danger">*</span>
              </Label>

              <Select
                defaultValue={activoOptionSelect}
                onChange={(selectedOption) => {
                  setActivo(selectedOption.value === "true");
                }}
                options={[
                  { value: true, label: "Si" },
                  { value: false, label: "No" },
                ]}
                required
                id="activo"
                className="js-example-basic-single mb-0"
                name="activo"
              />
            </div>
          </Col>

          <Col xxl={4} md={4}>
            {(perfil === "Farmabien" || perfil === "Delivery") && (
              <div className="mb-3">
                <Label className="form-label" htmlFor="activo">
                  Farmacia <span className="text-danger">*</span>
                </Label>

                {!farmaciasLoaded ? (
                  <Spinner size="sm" color="primary" />
                ) : farmacias.length === 0 ? (
                  <p>No hay farmacias disponibles</p>
                ) : (
                  <Select
                    defaultValue={farmaciaOptionSelect}
                    onChange={(selectedOption) => {
                      setFarmacia(selectedOption.value);
                    }}
                    options={farmacias[0]?.map((farmacia) => ({
                      value: farmacia.id,
                      label: farmacia.nombre,
                    }))}
                    required
                    id="farmacia"
                    className="js-example-basic-single mb-0"
                    name="farmacia"
                  />
                )}
              </div>
            )}
          </Col>
        </Row>

        <Row className="align-items-end">
          <Col lg={6}>
            <div className="mt-4 mb-3">
              <Link to="/usuarios">
                <Button className="btn btn-info btn-label left ms-auto nexttab nexttab">
                  <i className="ri-arrow-left-line label-icon align-middle fs-16 ms-2"></i>
                  Regresar a Usuarios
                </Button>
              </Link>
            </div>
          </Col>
          <Col lg={6}>
            <div className="mt-4 mb-3 text-end">
              <button
                type="submit"
                className="btn btn-success btn-label right ms-auto nexttab nexttab"
              >
                {loading ? (
                  <Spinner size="sm" className="me-2">
                    {" "}
                    Loading...{" "}
                  </Spinner>
                ) : null}
                <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                Editar Usuario
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

export default EditarUsuario;
