import React from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";

const Adjuntos = ({ solicitud }) => {
  const createBlobUrl = (base64Data) => {
    const parts = base64Data.split(";base64,");
    const contentType = parts[0].split(":")[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    const blob = new Blob([uInt8Array], { type: contentType });
    return URL.createObjectURL(blob);
  };

  const abrirImagen = (base64URL) => {
    const blobUrl = createBlobUrl(base64URL);
    window.open(blobUrl, "_blank");
  };

  return (
    <React.Fragment>
      <Col>
        <Card>
          <CardHeader>
            <div className="d-flex align-items-center">
              <i className="ri-information-line me-1 text-primary me-2 fs-20"></i>
              <h5 className="card-title mb-0">
                Solicitud de Tipo: {solicitud?.tipo}
              </h5>
            </div>
          </CardHeader>
          <CardBody>
            <Row>
              {/* Recipe - Primera columna */}
              <Col md={6}>
                <div className="d-flex align-items-center border border-dashed p-2 rounded h-100">
                  <div className="flex-shrink-0 avatar-sm">
                    <div className="avatar-title bg-light rounded">
                      <i className="ri-attachment-line fs-20 text-primary"></i>
                    </div>
                  </div>

                  <div className="flex-grow-1 ms-3">
                    <h6 className="mb-1">
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          abrirImagen(solicitud.recipe);
                        }}
                      >
                        Recipe
                      </a>
                    </h6>
                    <small className="text-muted">
                      {solicitud.fechaRecipe}
                    </small>
                  </div>

                  <div className="hstack gap-3 fs-16">
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        abrirImagen(solicitud.recipe);
                      }}
                      className="text-muted"
                    >
                      <i className="ri-eye-line"></i>
                    </a>
                  </div>
                </div>
              </Col>

              {/* Informe Medico - Segunda columna */}
              <Col md={6}>
                <div className="d-flex align-items-center border border-dashed p-2 rounded h-100">
                  <div className="flex-shrink-0 avatar-sm">
                    <div className="avatar-title bg-light rounded">
                      <i className="ri-attachment-line fs-20 text-primary"></i>
                    </div>
                  </div>

                  <div className="flex-grow-1 ms-3">
                    <h6 className="mb-1">
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          abrirImagen(solicitud.informe);
                        }}
                      >
                        Informe Médico
                      </a>
                    </h6>
                    <small className="text-muted">
                      {solicitud.fechaInforme}
                    </small>
                  </div>

                  <div className="hstack gap-3 fs-16">
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        abrirImagen(solicitud.informe);
                      }}
                      className="text-muted"
                    >
                      <i className="ri-eye-line"></i>
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default Adjuntos;
