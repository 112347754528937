import React, { useState, useEffect, useCallback } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import SimpleBar from "simplebar-react";
import {
  getNotificacionesNoLeidas,
  marcarNotificacionLeida,
  marcarTodasNotificacionesLeidas,
} from "../../helpers/api_helper";
import { formatDistanceToNow } from "date-fns";
import { es } from "date-fns/locale";
import bell from "../../assets/images/svg/bell.svg";

const NotificationDropdown = () => {
  const navigate = useNavigate();
  const [notificaciones, setNotificaciones] = useState([]);
  const [cantidadNoLeidas, setCantidadNoLeidas] = useState(0);
  const [isNotificationDropdown, setIsNotificationDropdown] = useState(false);

  // Función para obtener las notificaciones
  const obtenerNotificaciones = useCallback(async () => {
    try {
      //console.log("Intentando obtener notificaciones...");
      const response = await getNotificacionesNoLeidas();
      //console.log("Respuesta de notificaciones:", response);
      
      // Verificar si la respuesta fue exitosa (código HTTP 200)
      if (response.status === 200) {
        // Acceder a los datos correctamente en la estructura anidada
        setNotificaciones(response.data.data.notificaciones || []);
        setCantidadNoLeidas(response.data.data.cantidad || 0);
      }
    } catch (error) {
      console.error("Error al obtener notificaciones:", error);
    }
  }, []);

  // Cargar notificaciones al montar el componente
  useEffect(() => {
    obtenerNotificaciones();

    // Actualizar cada 2 minutos
    const intervalo = setInterval(obtenerNotificaciones, 120000);
    return () => clearInterval(intervalo);
  }, [obtenerNotificaciones]);

  // Función para marcar como leída
  const handleMarcarLeida = async (id, e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      await marcarNotificacionLeida(id);
      // Actualizar la lista después de marcar como leída
      obtenerNotificaciones();
    } catch (error) {
      console.error("Error al marcar notificación como leída:", error);
    }
  };

  // Función para marcar todas las notificaciones como leídas
  const handleMarcarTodasLeidas = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      await marcarTodasNotificacionesLeidas();
      // Actualizar la lista después de marcar todas como leídas
      obtenerNotificaciones();
    } catch (error) {
      console.error("Error al marcar todas las notificaciones como leídas:", error);
    }
  };

  // Formatear fecha relativa
  const formatearFecha = (fecha) => {
    try {
      return formatDistanceToNow(new Date(fecha), {
        addSuffix: true,
        locale: es,
      });
    } catch (error) {
      return "fecha desconocida";
    }
  };

  // Función para navegar a la solicitud
  const navegarASolicitud = (id, notificacionId) => {
    // Marcar como leída antes de navegar
    marcarNotificacionLeida(notificacionId)
      .then(() => {
        // Navegar a la página de la solicitud usando navigate en lugar de window.location.href
        navigate(`/solicitud/ver/${id}`);
      })
      .catch((error) => {
        console.error("Error al marcar notificación:", error);
        // Navegar de todas formas
        navigate(`/solicitud/ver/${id}`);
      });
  };

  // Toggle dropdown
  const toggleNotificationDropdown = () => {
    setIsNotificationDropdown(!isNotificationDropdown);
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isNotificationDropdown}
        toggle={toggleNotificationDropdown}
        className="topbar-head-dropdown ms-1 header-item"
      >
        <DropdownToggle
          type="button"
          tag="button"
          className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
        >
          <i className="bx bx-bell fs-22"></i>
          {cantidadNoLeidas > 0 && (
            <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
              {cantidadNoLeidas}
              <span className="visually-hidden">mensajes no leídos</span>
            </span>
          )}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="dropdown-head bg-primary bg-pattern rounded-top">
            <div className="p-3">
              <div className="row align-items-center">
                <div className="col">
                  <h6 className="m-0 fs-16 fw-semibold text-white">
                    Notificaciones
                  </h6>
                </div>
                {cantidadNoLeidas > 0 && (
                  <div className="col-auto dropdown-tabs">
                    <span className="badge badge-soft-light fs-13">
                      {cantidadNoLeidas} nuevas
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>

          <SimpleBar style={{ maxHeight: "300px" }}>
            <div className="p-2">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h6 className="mt-0 mb-0 fs-13 fw-semibold">
                  Comentarios en solicitudes
                </h6>
                {notificaciones.length > 0 && (
                  <button 
                    className="btn btn-sm btn-soft-primary"
                    onClick={handleMarcarTodasLeidas}
                  >
                    Marcar todas como leídas
                  </button>
                )}
              </div>

              {notificaciones.length > 0 ? (
                notificaciones.map((notificacion, index) => (
                  <div
                    key={index}
                    className="text-reset notification-item d-block dropdown-item position-relative"
                    onClick={() =>
                      navegarASolicitud(
                        notificacion.referencia_id,
                        notificacion.id
                      )
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <div className="d-flex">
                      <div className="avatar-xs me-3">
                        <span className="avatar-title bg-soft-info text-info rounded-circle fs-16">
                          <i className="bx bx-message-dots"></i>
                        </span>
                      </div>
                      <div className="flex-1">
                        <div className="fs-13 text-muted">
                          <p className="mb-1">{notificacion.mensaje}</p>
                          <p className="mb-0">
                            <i className="mdi mdi-clock-outline"></i>{" "}
                            <span>
                              {formatearFecha(notificacion.fecha_creacion)}
                            </span>
                          </p>
                        </div>
                        <button
                          className="btn btn-sm btn-soft-primary mt-2"
                          onClick={(e) => handleMarcarLeida(notificacion.id, e)}
                        >
                          Marcar como leída
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="w-50 w-sm-50 pt-3 mx-auto">
                  <img src={bell} className="img-fluid" alt="user-pic" />
                  <div className="pb-5 mt-2">
                    <h6 className="fs-14 fw-semibold lh-base">
                      No tienes notificaciones
                    </h6>
                  </div>
                </div>
              )}
            </div>
          </SimpleBar>

         
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default NotificationDropdown;
