// index.js
import React, { useState, useEffect } from "react";
import {
  getSolicitudes,
  resetSolicitudFlag,
  editSolicitud,
  getFarmacias,
} from "../../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import Lista from "./Lista";
import { DatosSesion } from "../../../Components/Common/DatosSesion";

const Listar = () => {
  document.title = "Lista de Delivery | Farme";

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [solicitudesEntregar, setSolicitudesEntregar] = useState([]);
  const selectLayoutState = (state) => state;

  const pageData = createSelector(selectLayoutState, (state) => ({
    solicitudes: state.Solicitudes.solicitudes,
    error: state.Solicitudes.error,
    loading: state.Solicitudes.loading,
    errorMsg: state.Solicitudes.errorMsg,
    colorMsg: state.Solicitudes.colorMsg,
    exito: state.Solicitudes.exito,
  }));

  const { solicitudes, error, loading, errorMsg, colorMsg, exito } =
    useSelector(pageData);

  const farmaciasPageData = createSelector(selectLayoutState, (state) => ({
    farmacias: state.Farmacias.farmacias,
    loadingFarmacias: state.Farmacias.loading,
  }));

  const { farmacias, loadingFarmacias } = useSelector(farmaciasPageData);

  // Obtener datos de sesión
  const perfil = DatosSesion("perfil");
  const farmaciaId = DatosSesion("farmacia");
  const [farmaciaActual, setFarmaciaActual] = useState(null);

  // Efecto para establecer la farmacia actual cuando se tengan los datos
  useEffect(() => {
    if (farmaciaId && farmacias && farmacias[0]) {
      const farmaciaEncontrada = farmacias[0].find(
        (farmacia) => farmacia.id === farmaciaId
      );
      setFarmaciaActual(farmaciaEncontrada);
    }
  }, [farmacias, farmaciaId]);

  useEffect(() => {
    if (errorMsg) {
      setTimeout(() => {
        dispatch(resetSolicitudFlag());
      }, 2000);
    }
  }, [dispatch, errorMsg]);

  // Efecto para cargar solicitudes
  useEffect(() => {
    setIsLoading(true);

    let queryParams = [];

    // Si el usuario es Administrador o MyH+, obtener todas las solicitudes
    if (perfil === "Administrador" || perfil === "MyH+") {
      queryParams.push(`estatus=Por Entregar`);
      const finalQuery = queryParams.length > 0 ? queryParams.join("&") : "";
      dispatch(getSolicitudes(finalQuery)).finally(() => setIsLoading(false));
    }
    // Si es otro perfil y tiene farmacia actual, filtrar por código de proveedor
    else if (farmaciaActual) {
      queryParams.push(`estatus=Por Entregar`);
      queryParams.push(`codProveedor=${farmaciaActual.codigo_myh}`);
      const finalQuery = queryParams.join("&");
      dispatch(getSolicitudes(finalQuery)).finally(() => setIsLoading(false));
    }
    // Si no cumple ninguna condición anterior, quitar el loading
    else {
      setIsLoading(false);
    }
  }, [dispatch, farmaciaActual, farmaciaId, perfil]);

  useEffect(() => {
    if (solicitudes) {
      setIsLoading(false);
    }
  }, [solicitudes]);

  // Efecto para manejar las solicitudes a entregar
  // Lista.js
  const handleSolicitudesEntregar = () => {
    try {
      const selectedIds = getSelectedSolicitudes();
      //console.log("IDs seleccionados:", selectedIds);

      const selectedSolicitudesDetails = solicitudes.filter((sol) =>
        selectedIds.includes(sol.id.toString())
      );

      /* console.log(
        "Solicitudes seleccionadas detalle completo:",
        JSON.stringify(selectedSolicitudesDetails, null, 2)
      ); */

      // ... resto del código
    } catch (error) {
      console.error("Error al procesar solicitudes:", error);
    }
  };

  useEffect(() => {
    if (solicitudesEntregar.length > 0) {
      let costoDelivery = process.env.REACT_APP_COSTO_DELIVERY;
  
      solicitudesEntregar.forEach((solicitud) => {
        // Verificamos que delivery sea un array
        if (!Array.isArray(solicitud.delivery)) {
          console.error('El delivery no es un array:', solicitud);
          return;
        }
  
        // Obtenemos el índice del último delivery
        const deliveryIndex = solicitud.delivery.length - 1;
        
        // Verificamos que exista al menos un delivery
        if (deliveryIndex < 0) {
          console.error('No hay deliveries en la solicitud:', solicitud);
          return;
        }
  
        // Obtenemos el último delivery
        const deliveryActual = solicitud.delivery[deliveryIndex];
  
        // Creamos el delivery actualizado
        const deliveryActualizado = {
          ...deliveryActual, // Mantenemos los datos originales
         /*  servicioEntrega: deliveryActual.servicioEntrega, */
          estatus: "En Transito",
          /* fecha: deliveryActual.fecha,
          distancia: deliveryActual.distancia, */
        };
  
        // Creamos una copia del array de deliveries
        const nuevosDeliveries = [...solicitud.delivery];

        // Actualizamos el último delivery
        nuevosDeliveries[deliveryIndex] = deliveryActualizado;
  
        // Creamos la nueva solicitud con el array de deliveries actualizado
        const nuevaSolicitud = {
          ...solicitud,
          porcentaje_avance: 80,
          estatus: "En Transito",
          delivery: nuevosDeliveries,
        };
   
        //console.log('Solicitud actualizada:', nuevaSolicitud);
  
        try {
          dispatch(editSolicitud(nuevaSolicitud));

          setTimeout(() => {
            window.location.reload();
          }, 2000);



        } catch (error) {
          console.error("Error al actualizar solicitud:", error);
        }
      });
    }
  }, [solicitudesEntregar]);

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="border-0">
                  <div className="d-md-flex align-items-center">
                    <h5 className="card-title mt-5 mb-md-0 flex-grow-1">
                      Confirmación de las Direcciones de Entrega
                    </h5>
                    <div className="flex-shrink-0">
                      <div className="d-flex gap-1 flex-wrap"></div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <Lista
                    solicitudes={solicitudes[0]}
                    error={error}
                    errorMsg={errorMsg}
                    colorMsg={colorMsg}
                    setSolicitudesEntregar={setSolicitudesEntregar}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

// Función auxiliar para obtener el último delivery
const getLastDelivery = (solicitud) => {
  if (!solicitud.delivery || !Array.isArray(solicitud.delivery)) {
    return null;
  }
  return solicitud.delivery.length > 0
    ? solicitud.delivery[solicitud.delivery.length - 1]
    : null;
};

export default Listar;
