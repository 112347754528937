import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  kpis: {},
  error: "", // for error message
  loading: false,
  errorMsg: false, // for error
  colorMsg: "",
  exito: false,
  // Añadir campos para caché
  cachedKpis: {},
  lastFetchParams: null,
  lastFetchTime: null,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    apiError(state, action) {
      state.error = action.payload;
      state.loading = false;
      state.errorMsg = true;
      state.colorMsg = "danger";
    },
    kpisSuccess(state, action) {
      // Verificar la estructura de los datos para manejar tanto datos de caché como del API
      const kpisData = action.payload.data && action.payload.data.data 
        ? action.payload.data.data  // Estructura cuando viene de caché
        : action.payload.data;      // Estructura cuando viene directamente del API
      
      state.kpis = kpisData;
      state.loading = false;
      state.errorMsg = false;
      state.error = action.payload.message;
      state.colorMsg = "success";
      state.exito = false;
      
      // Guardar en caché
      if (state.lastFetchParams) {
        state.cachedKpis[JSON.stringify(state.lastFetchParams)] = {
          data: kpisData,
          timestamp: state.lastFetchTime
        };
      }
    },
    
    setFetchParams(state, action) {
      state.lastFetchParams = action.payload;
      state.lastFetchTime = Date.now();
    },
    
    reset_dashboard_flag(state) {
      state.success = false;
      state.error = "";
      state.loading = false;
      state.errorMsg = false;
      state.colorMsg = "";
      state.exito = false;
    },
  },
});

export const {
  apiError, kpisSuccess, reset_dashboard_flag, setFetchParams
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
