import {
  FETCH_SOLICITUDES_REQUEST,
  FETCH_SOLICITUDES_SUCCESS,
  FETCH_SOLICITUDES_FAIL,
  FETCH_RUTA_ACTIVA_REQUEST,
  FETCH_RUTA_ACTIVA_SUCCESS,
  FETCH_RUTA_ACTIVA_FAILURE,
  CREATE_RUTA_REQUEST,
  CREATE_RUTA_SUCCESS,
  CREATE_RUTA_FAIL,
  UPDATE_DELIVERY_REQUEST,
  UPDATE_DELIVERY_SUCCESS,
  UPDATE_DELIVERY_FAIL,
  FILTER_SOLICITUDES,
  RESET_FILTERS,
  FINALIZAR_RUTA_REQUEST,
  FINALIZAR_RUTA_SUCCESS,
  FINALIZAR_RUTA_FAIL,
  UPDATE_ROUTE_PROGRESS,
  RESET_RUTA_ACTIVA,
} from "./types";

import {
  getSolicitudesEntregar,
  obtenerRutaActivaMotorizado,
  crearNuevaRuta,
  updateDelivery,
  finalizarRutaEntrega,
  optimizarRuta,
} from "../../../slices/thunks";

// Importar la función solicitudesEntregar directamente
import {
  obtenerSolicitudesMotorizado,
  crearRuta,
  rutaOptima,
  actualizarSolicitudEntrega,
  solicitudesEntregar,
  actualizarEstadoSolicitudEnRuta,
} from "../../../helpers/backend_helper";
import * as url from "../../../helpers/url_helper";
import { APIClient } from "../../../helpers/api_helper";
import { DatosSesion } from "../../../Components/Common/DatosSesion";

// Instancia de la API para pruebas de conexión
const api = new APIClient().client;

// Acciones para solicitudes
export const fetchSolicitudes =
  (filtros = {}) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: FETCH_SOLICITUDES_REQUEST });

      // Obtener el ID del motorizado
      const idMotorizado = DatosSesion("id");

      // Verificar si el ID es válido
      if (!idMotorizado) {
        console.error(
          "No se pudo obtener el ID del motorizado desde la sesión"
        );
        dispatch({
          type: FETCH_SOLICITUDES_FAIL,
          payload: "No se pudo obtener el ID del motorizado",
        });
        return [];
      }

      let response;
      try {
        // Intento directo con el ID tal como está
        response = await solicitudesEntregar(idMotorizado);
        //console.log("Respuesta completa de la API:", response);
      } catch (error) {
        console.error("Error al llamar a solicitudesEntregar:", error);

        // Intentar obtener más información sobre el error
        if (error.response) {
          // El servidor respondió con un código de estado fuera del rango 2xx
          console.error("Respuesta de error del servidor:", {
            status: error.response.status,
            data: error.response.data,
            headers: error.response.headers,
          });

          // Si es un error 404, podría ser un problema con el formato del ID
          if (error.response.status === 404) {
            ////console.log("Error 404: Verificando si es un problema con el formato del ID");

            // Verificar si hay un problema de formato con el ID
            try {
              // Intentar hacer una solicitud a un endpoint general para verificar la conexión
              const testResponse = await api.get(url.BASE_SOLICITUDES);
              ////console.log("Prueba de conexión exitosa:", testResponse);
              console.error(
                "El problema parece ser específicamente con el ID del motorizado o el endpoint"
              );
            } catch (testError) {
              console.error("Error en la prueba de conexión:", testError);
              console.error(
                "Parece haber un problema general de conexión con el backend"
              );
            }
          }
        } else if (error.request) {
          // La solicitud se realizó pero no se recibió respuesta
          console.error("No se recibió respuesta del servidor:", error.request);
          ////console.log("Verificando si el servidor está accesible...");

          // Intentar una solicitud simple para verificar si el servidor está accesible
          try {
            const pingResponse = await api.get("/");
            ////console.log("El servidor está accesible:", pingResponse);
          } catch (pingError) {
            console.error("El servidor no parece estar accesible:", pingError);
          }
        } else {
          // Algo ocurrió al configurar la solicitud que desencadenó un error
          console.error("Error al configurar la solicitud:", error.message);
        }

        dispatch({
          type: FETCH_SOLICITUDES_FAIL,
          payload: error.message || "Error al obtener solicitudes",
        });
        return [];
      }

      // Verificar la respuesta de la API
      if (!response) {
        console.error("No se recibió respuesta de la API");
        dispatch({
          type: FETCH_SOLICITUDES_FAIL,
          payload: "No se recibió respuesta de la API",
        });
        return [];
      }

      // Verificar si la respuesta contiene datos
      if (!response.data) {
        console.error("La respuesta de la API no contiene datos");
        dispatch({
          type: FETCH_SOLICITUDES_FAIL,
          payload: "La respuesta de la API no contiene datos",
        });
        return [];
      }

      // Depurar la estructura de la respuesta para entender mejor los datos
      /*     //console.log("Estructura de la respuesta:", {
      tieneData: !!response.data,
      tieneDataData: !!(response.data && response.data.data),
      esArray: !!(response.data && response.data.data && Array.isArray(response.data.data)),
      longitud: response.data && response.data.data && Array.isArray(response.data.data) ? response.data.data.length : 0,
      primerElemento: response.data && response.data.data && Array.isArray(response.data.data) && response.data.data.length > 0 
        ? typeof response.data.data[0] 
        : 'No hay elementos'
    }); */

      /* ////console.log("Respuesta de la API:", response.data.data);
    
    const solicitudes = response.data.data[0];
    ////console.log(`Todas las solicitudes obtenidas: ${solicitudes ? solicitudes.length : 0}`);
    
    // Mostrar las fechas de entrega de cada solicitud
    if (solicitudes && solicitudes.length > 0) {
      ////console.log("Fechas de entrega de las solicitudes:");
      solicitudes.forEach(solicitud => {
        const identificador = solicitud.identificador || solicitud.id || solicitud._id;
        const deliveryInfo = solicitud.delivery && Array.isArray(solicitud.delivery) && solicitud.delivery.length > 0
          ? solicitud.delivery[solicitud.delivery.length - 1]
          : null;
        
        const fecha = deliveryInfo?.fecha;
        
        ////console.log(`Solicitud #${identificador}: Fecha de entrega: ${fecha}`);
      });
    }  */

      // Extraer las solicitudes de la estructura de respuesta
      let solicitudesConCoordenadas = [];

      if (response && response.data) {
        // La respuesta tiene la estructura esperada
        /* console.log("Estructura de la respuesta:", {
        tieneData: !!response.data,
        tieneDataData: !!response.data.data,
        esDataDataArray: Array.isArray(response.data.data),
        longitudDataData: Array.isArray(response.data.data) ? response.data.data.length : 'No es array',
        esDataArray: Array.isArray(response.data),
      }); */

        // Si los datos están en data.data[0]
        if (
          response.data.data &&
          Array.isArray(response.data.data) &&
          response.data.data.length > 0
        ) {
          solicitudesConCoordenadas = response.data.data[0];
          //console.log("Usando datos de response.data.data[0]", solicitudesConCoordenadas );
        }
        /*       // Si los datos están directamente en data
      else if (Array.isArray(response.data)) {
        solicitudesConCoordenadas = response.data;
        ////console.log("Usando datos de response.data (array)");
      }

      // Si los datos están en data.data (no en un array)
      else if (response.data.data && !Array.isArray(response.data.data)) {
        solicitudesConCoordenadas = [response.data.data];
        ////console.log("Usando datos de response.data.data (no array)");
      }
      // Si los datos están directamente en data.data
      else if (response.data.data) {
        solicitudesConCoordenadas = response.data.data;
        ////console.log("Usando datos de response.data.data");
      } */
      }

      //console.log("Solicitudes extraídas:", solicitudesConCoordenadas);

      // Asegurarnos de que todas las solicitudes tengan coordenadas válidas
      solicitudesConCoordenadas = Array.isArray(solicitudesConCoordenadas)
        ? solicitudesConCoordenadas.map((solicitud) => {
            // Si la solicitud no tiene coordenadas o no son válidas, asignar coordenadas por defecto
            if (
              !solicitud.coordenadas ||
              !Array.isArray(solicitud.coordenadas) ||
              solicitud.coordenadas.length < 2
            ) {
              // Verificar si las coordenadas están en formato string y convertirlas
              if (
                solicitud.coordenadas &&
                typeof solicitud.coordenadas === "string"
              ) {
                try {
                  // Intentar convertir string a array (por ejemplo, "[10.4806, -66.9036]" a [10.4806, -66.9036])
                  const coordenadasArray = JSON.parse(solicitud.coordenadas);
                  if (
                    Array.isArray(coordenadasArray) &&
                    coordenadasArray.length >= 2
                  ) {
                    return {
                      ...solicitud,
                      coordenadas: coordenadasArray,
                    };
                  }
                } catch (e) {
                  // Error al parsear coordenadas
                }
              }

              // Si no se pudieron convertir o no son válidas, asignar coordenadas por defecto
              return {
                ...solicitud,
                coordenadas: [10.4806, -66.9036],
              };
            }

            // Si las coordenadas ya son válidas, asegurarnos de que sean números
            if (
              Array.isArray(solicitud.coordenadas) &&
              solicitud.coordenadas.length >= 2
            ) {
              const coordenadasNumericas = solicitud.coordenadas.map(
                (coord) => {
                  // Convertir a número si es string
                  if (typeof coord === "string") {
                    return parseFloat(coord);
                  }
                  return coord;
                }
              );

              return {
                ...solicitud,
                coordenadas: coordenadasNumericas,
              };
            }

            return solicitud;
          })
        : [];

      // Filtrar solicitudes con estatus "En Transito"
      let solicitudesEnTransito = Array.isArray(solicitudesConCoordenadas)
        ? solicitudesConCoordenadas.filter(
            (solicitud) => solicitud.estatus === "En Transito"
          )
        : [];

      // Obtener la ruta activa del estado
      const state = getState();
      const rutaActiva = state.Motorizado?.rutaActiva;

      // Si existe una ruta activa, filtrar para mostrar solo las solicitudes que están en la ruta
      if (
        rutaActiva &&
        rutaActiva.solicitudes &&
        Array.isArray(rutaActiva.solicitudes)
      ) {
        ////console.log("Hay una ruta activa, filtrando solicitudes...");

        // Obtener los IDs de las solicitudes en la ruta activa
        const solicitudesEnRuta = rutaActiva.solicitudes.map(
          (s) => s.id || s._id || s.identificador
        );

        ////console.log("Solicitudes en ruta activa:", solicitudesEnRuta);

        // Filtrar las solicitudes para mostrar solo las que están en la ruta
        solicitudesEnTransito = solicitudesEnTransito.filter((solicitud) => {
          const solicitudId =
            solicitud.id || solicitud._id || solicitud.identificador;
          return solicitudesEnRuta.includes(solicitudId);
        });

        //console.log(`Solicitudes filtradas por ruta activa: ${solicitudesEnTransito.length}`);
      } else {
        //console.log(`No hay ruta activa, mostrando todas las solicitudes en tránsito ${solicitudesEnTransito.length}`);
      }

      dispatch({
        type: FETCH_SOLICITUDES_SUCCESS,
        payload: solicitudesEnTransito,
      });

      return solicitudesEnTransito;
    } catch (apiError) {
      console.error("Error al obtener solicitudes de la API:", apiError);

      dispatch({
        type: FETCH_SOLICITUDES_FAIL,
        payload: apiError.message || "Error al obtener solicitudes",
      });

      return [];
    }
  };

// Acciones para ruta activa
export const fetchRutaActiva =
  (motorizadoId = null) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: FETCH_RUTA_ACTIVA_REQUEST });

      const userId = DatosSesion("id");
      const idMotorizado = motorizadoId || userId;

      if (!idMotorizado) {
        dispatch({
          type: FETCH_RUTA_ACTIVA_FAILURE,
          payload: "No se pudo obtener el ID del motorizado",
        });
        return;
      }

      // Obtener las solicitudes directamente del endpoint
      let todasLasSolicitudes = [];
      try {
        const solicitudesResponse = await solicitudesEntregar(idMotorizado);
        if (
          solicitudesResponse &&
          solicitudesResponse.data &&
          solicitudesResponse.data.data &&
          Array.isArray(solicitudesResponse.data.data) &&
          solicitudesResponse.data.data.length > 0
        ) {
          todasLasSolicitudes = solicitudesResponse.data.data[0] || [];
        }
      } catch (error) {
        ////console.log("Error al obtener solicitudes:", error);
        // Continuar aunque falle la obtención de solicitudes
      }

      //console.log("Todas las solicitudes obtenidas:", todasLasSolicitudes.length);

      // Obtener la ruta activa usando dispatch para el thunk
      let rutaResponse;
      try {
        rutaResponse = await dispatch(
          obtenerRutaActivaMotorizado(idMotorizado)
        );
      } catch (error) {
        ////console.log("Error al obtener ruta activa:", error);
        dispatch({ type: FETCH_RUTA_ACTIVA_SUCCESS, payload: null });
        return;
      }

      // Verificar si la respuesta es válida y no contiene un mensaje de error
      if (
        !rutaResponse ||
        !rutaResponse.data ||
        rutaResponse.data.detail || // Detectar mensaje de error del backend
        !rutaResponse.data.id
      ) {
        // Verificar que tenga un ID válido
        /* ////console.log("No hay ruta activa para el motorizado:", idMotorizado); */
        dispatch({ type: FETCH_RUTA_ACTIVA_SUCCESS, payload: null });
        return;
      }

      const rutaActiva = rutaResponse.data;

      /*     console.log("*************************** 5 ***************************************" ); 
    console.log("Ruta activa obtenida:", rutaActiva); */

      // Extraer los IDs de solicitudes de la ruta
      let rutaSolicitudesIds = [];
      if (rutaActiva.solicitudes && Array.isArray(rutaActiva.solicitudes)) {
        rutaSolicitudesIds = rutaActiva.solicitudes.map((item) =>
          typeof item === "string"
            ? item
            : item.id || item._id || item.identificador
        );
      }

      /*     console.log("*************************** 6 ***************************************" ); 
    console.log("IDs de solicitudes en la ruta:", rutaSolicitudesIds); */

      // Si no hay solicitudes en la ruta, devolver la ruta sin detalles adicionales
      if (!rutaSolicitudesIds || rutaSolicitudesIds.length === 0) {
        dispatch({ type: FETCH_RUTA_ACTIVA_SUCCESS, payload: rutaActiva });
        return;
      }

      // Filtrar las solicitudes completas que pertenecen a la ruta activa
      let solicitudesRuta = todasLasSolicitudes.filter((solicitud) => {
        const solicitudId =
          solicitud.id || solicitud._id || solicitud.identificador;
        return rutaSolicitudesIds.includes(solicitudId);
      });

      dispatch({
        type: FETCH_RUTA_ACTIVA_SUCCESS,
        payload: {
          ...rutaActiva,
          solicitudes: [...solicitudesRuta],
          ruta_optima: rutaActiva.ruta_optima,
        },
      });
    } catch (error) {
      dispatch({
        type: FETCH_RUTA_ACTIVA_FAILURE,
        payload: error.message || "Error al obtener la ruta activa",
      });

      // Asegurar que se muestren todas las solicitudes si hay un error
      try {
        dispatch(fetchSolicitudes());
      } catch (e) {
        console.error("Error al recuperar solicitudes después de un error:", e);
      }
    }
  };

// Acciones para crear ruta
export const createRuta = (rutaData) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_RUTA_REQUEST });

    // Obtener el ID del motorizado
    const motorizadoId = rutaData.motorizado_id || DatosSesion("id");

    if (!motorizadoId) {
      console.error("No se pudo obtener el ID del motorizado");
      dispatch({
        type: CREATE_RUTA_FAIL,
        payload: "No se pudo obtener el ID del motorizado",
      });
      return { error: { message: "No se pudo obtener el ID del motorizado" } };
    }

    // Obtener el ID de la farmacia
    const farmaciaId = DatosSesion("farmacia");

    if (!farmaciaId) {
      console.error("No se pudo obtener el ID de la farmacia");
      dispatch({
        type: CREATE_RUTA_FAIL,
        payload: "No se pudo obtener el ID de la farmacia",
      });
      return { error: { message: "No se pudo obtener el ID de la farmacia" } };
    }

    // Preparar los datos para crear la ruta
    const datosRuta = {
      solicitudes: rutaData.solicitudes || [],
      motorizado_id: motorizadoId,
      farmacia_id: farmaciaId,
      fecha_inicio: new Date().toISOString(),
    };

    // Crear la ruta
    const response = await dispatch(crearNuevaRuta(datosRuta));

    // Verificar si la respuesta es válida
    if (response && response.data) {
      const rutaId = response.data.id || response.data._id;

      // Obtener las solicitudes completas del estado
      const solicitudesCompletas = rutaData.solicitudes || [];

      // Obtener los IDs de las solicitudes de la ruta
      const solicitudesIds = datosRuta.solicitudes || [];

      // Buscar las solicitudes completas por ID
      let solicitudesConDetalles = solicitudesIds.map((id) => {
        const solicitudCompleta = solicitudesCompletas.find(
          (s) => s.id === id || s._id === id || s.identificador === id
        );

        return solicitudCompleta || { id: id };
      });

      // Ahora que la ruta está creada, optimizarla usando el endpoint /ruta-optima/
      try {
        // Preparar datos para la optimización según el endpoint /ruta-optima/
        const datosOptimizacion = {
          solicitudes_ids: solicitudesIds,
          ruta_id: rutaId,
        };

        // Llamar al endpoint de ruta óptima
        const optimizacionResponse = await rutaOptima(datosOptimizacion);

        if (optimizacionResponse && optimizacionResponse.data) {
          // La respuesta puede tener la estructura {data: {data: {paradas: [...]}}}
          // o directamente {data: {paradas: [...]}}
          const dataRutaOptima =
            optimizacionResponse.data.data || optimizacionResponse.data;

          // Crear una copia profunda para evitar mutaciones
          infoRutaOptima = JSON.parse(JSON.stringify(dataRutaOptima));

          if (infoRutaOptima && infoRutaOptima.paradas) {
            // Extraer el orden optimizado de las paradas
            const paradasOrdenadas = infoRutaOptima.paradas
              .filter((parada) => parada.tipo === "destino")
              .sort((a, b) => a.orden - b.orden);

            // Obtener los IDs de las solicitudes en el orden optimizado
            const solicitudesOptimizadas = paradasOrdenadas.map(
              (parada) => parada.id_solicitud
            );

            if (solicitudesOptimizadas.length > 0) {
              // Reordenar las solicitudes según el orden optimizado
              const solicitudesOrdenadas = [];
              for (const id of solicitudesOptimizadas) {
                const solicitud = solicitudesConDetalles.find((s) => {
                  const solicitudId = s.id || s._id || s.identificador;
                  return solicitudId === id;
                });
                if (solicitud) {
                  solicitudesOrdenadas.push(solicitud);
                }
              }

              // Actualizar la lista de solicitudes con el orden optimizado
              if (solicitudesOrdenadas.length > 0) {
                solicitudesConDetalles = solicitudesOrdenadas;
              }
            }
          }
        } else {
          console.warn(
            "No se pudo obtener información de ruta óptima:",
            optimizacionResponse?.data?.message || "Respuesta inválida"
          );
        }
      } catch (optimizacionError) {
        console.error("Error al optimizar la ruta:", optimizacionError);
        // Continuar con el orden original si falla la optimización
      }

      // Asegurarnos de que los datos sean serializables
      const serializableResponse = {
        id: rutaId,
        solicitudes: solicitudesConDetalles,
        origen: response.data.origen || [],
        estado: response.data.estado || "Activa",
        fecha_creacion:
          response.data.fecha_creacion || new Date().toISOString(),
      };

      dispatch({
        type: CREATE_RUTA_SUCCESS,
        payload: serializableResponse,
      });

      return serializableResponse;
    } else {
      throw new Error("La respuesta no contiene datos válidos");
    }
  } catch (error) {
    console.error("Error al crear ruta:", error);

    // Asegurarnos de que el error sea serializable
    const errorMessage = error.message || "Error al crear ruta";

    dispatch({
      type: CREATE_RUTA_FAIL,
      payload: errorMessage,
    });

    // No lanzamos el error para evitar que se propague
    return { error: errorMessage };
  }
};

// Acciones para actualizar delivery
export const updateDeliveryStatus = (deliveryData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_DELIVERY_REQUEST });

    // Actualizar Solicitud
    const response = await dispatch(updateDelivery(deliveryData));

    dispatch({
      type: UPDATE_DELIVERY_SUCCESS,
      payload: deliveryData,
    });

    return response;
  } catch (error) {
    // console.error("Error al actualizar delivery:", error);
    dispatch({
      type: UPDATE_DELIVERY_FAIL,
      payload: error.message || "Error al actualizar delivery",
    });
    throw error;
  }
};

// Acción para actualizar el estado de una solicitud en una ruta
export const updateSolicitudEnRuta =
  (rutaId, solicitudId, datos) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_DELIVERY_REQUEST });
      const backendHelper = await import("../../../helpers/backend_helper");

      // Verificar que la función existe y es accesible
      if (!backendHelper.actualizarEstadoSolicitudEnRuta) {
        console.error(
          "La función actualizarEstadoSolicitudEnRuta no está disponible en backend_helper"
        );
        throw new Error(
          "Función actualizarEstadoSolicitudEnRuta no disponible"
        );
      }

      const response = await backendHelper.actualizarEstadoSolicitudEnRuta(
        rutaId,
        solicitudId,
        datos
      );

      // Despachar acción de éxito
      dispatch({
        type: UPDATE_DELIVERY_SUCCESS,
        payload: {
          rutaId,
          solicitudId,
          estado: datos.estatus,
          datos,
        },
      });

      return response;
    } catch (error) {
      console.error("Error al actualizar estado de solicitud en ruta:", error);

      // Despachar acción de error
      dispatch({
        type: UPDATE_DELIVERY_FAIL,
        payload:
          error.message || "Error al actualizar estado de solicitud en ruta",
      });

      throw error;
    }
  };

// Acciones para filtros
/**
 * Filtra las solicitudes según los criterios especificados
 * @param {Object} filtros - Criterios de filtrado
 * @param {Array} solicitudes - Lista de solicitudes a filtrar
 * @returns {Object} - Acción Redux
 */
export const filterSolicitudes = (filtros, solicitudes = []) => {
  // Convertir fechas a objetos Date para comparación
  const fechaInicio = filtros.fechaInicio
    ? new Date(filtros.fechaInicio)
    : null;
  const fechaFin = filtros.fechaFin ? new Date(filtros.fechaFin) : null;

  // Si no hay fechas de filtro, devolver todas las solicitudes
  if (!fechaInicio && !fechaFin) {
    return {
      type: FILTER_SOLICITUDES,
      payload: {
        filtros: {
          fechaInicio: null,
          fechaFin: null,
        },
        solicitudesFiltradas: solicitudes,
      },
    };
  }

  // Establecer la hora de fechaFin a 23:59:59 para incluir todo el día
  /*   if (fechaFin) {
    fechaFin.setHours(23, 59, 59, 999);
  } */

  // Filtrar solicitudes por fecha de entrega
  const solicitudesFiltradas = solicitudes.filter((solicitud) => {
    // Obtener la fecha de entrega de la solicitud
    const deliveryInfo =
      solicitud.delivery &&
      Array.isArray(solicitud.delivery) &&
      solicitud.delivery.length > 0
        ? solicitud.delivery[solicitud.delivery.length - 1]
        : null;

    if (!deliveryInfo || !deliveryInfo.fecha) {
      return false; // Si no hay fecha de entrega, no incluir
    }

    // Convertir la fecha de entrega a objeto Date
    const fechaEntregaStr = deliveryInfo.fecha;

    // Extraer solo la parte de la fecha (YYYY-MM-DD) para comparar solo por día
    const fechaEntregaSoloFecha = fechaEntregaStr.split("T")[0];
    const fechaInicioSoloFecha = fechaInicio
      ? fechaInicio.toISOString().split("T")[0]
      : null;
    const fechaFinSoloFecha = fechaFin
      ? fechaFin.toISOString().split("T")[0]
      : null;

    // Aplicar filtros de fecha (comparando solo la parte de la fecha, no la hora)
    let cumpleFiltros = true;

    // Filtrar por fecha de inicio
    if (fechaInicio) {
      cumpleFiltros =
        cumpleFiltros && fechaEntregaSoloFecha >= fechaInicioSoloFecha;
    }

    // Filtrar por fecha de fin
    if (fechaFin) {
      cumpleFiltros =
        cumpleFiltros && fechaEntregaSoloFecha <= fechaFinSoloFecha;
    }

    return cumpleFiltros;
  });

  // Convertir fechas a formato ISO string para serialización
  const serializedFiltros = {
    fechaInicio: filtros.fechaInicio
      ? filtros.fechaInicio instanceof Date
        ? filtros.fechaInicio.toISOString().split("T")[0]
        : filtros.fechaInicio
      : null,
    fechaFin: filtros.fechaFin
      ? filtros.fechaFin instanceof Date
        ? filtros.fechaFin.toISOString().split("T")[0]
        : filtros.fechaFin
      : null,
  };

  return {
    type: FILTER_SOLICITUDES,
    payload: {
      filtros: serializedFiltros,
      solicitudesFiltradas: solicitudesFiltradas,
    },
  };
};

export const resetFilters = () => ({
  type: RESET_FILTERS,
});

// Acciones para resetear ruta activa
export const resetRutaActiva = () => ({
  type: RESET_RUTA_ACTIVA,
});

// Acciones para finalizar ruta
export const finalizarRuta = (rutaId) => async (dispatch) => {
  try {
    dispatch({ type: FINALIZAR_RUTA_REQUEST });

    // Importar directamente la función para asegurar que estamos usando la versión correcta
    // Usar import() para asegurar que obtenemos la versión más reciente
    const backendHelper = await import("../../../helpers/backend_helper");

    // Verificar que la función existe y es accesible
    if (!backendHelper.finalizarRuta) {
      console.error(
        "La función finalizarRuta no está disponible en backend_helper"
      );
      throw new Error("Función finalizarRuta no disponible");
    }

    // Llamar al endpoint para finalizar la ruta

    const response = await backendHelper.finalizarRuta(rutaId);

    // Despachar acción de éxito
    dispatch({
      type: FINALIZAR_RUTA_SUCCESS,
      payload: { rutaId, response },
    });

    // Resetear la ruta activa
    dispatch(resetRutaActiva());

    return {
      success: true,
      data: response.data,
      status: response.status,
    };
  } catch (error) {
    console.error("Error al finalizar ruta:", error);

    // Despachar acción de error
    dispatch({
      type: FINALIZAR_RUTA_FAIL,
      payload: error.message || "Error al finalizar ruta",
    });

    return {
      success: false,
      error: error.message || "Error al finalizar ruta",
    };
  }
};

// Actualizar progreso de ruta
export const updateRouteProgress = (progressData) => ({
  type: UPDATE_ROUTE_PROGRESS,
  payload: progressData,
});
