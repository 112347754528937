import {
  getKpis
} from "../../../helpers/backend_helper";
import {
  apiError,
  kpisSuccess,
  reset_dashboard_flag,
  setFetchParams
} from "./reducer";

// Tiempo de caducidad de la caché en milisegundos (5 minutos)
const CACHE_EXPIRY_TIME = 5 * 60 * 1000;

export const getDashboardKpis = (params) => async (dispatch, getState) => {
  // Verificar si tenemos datos en caché para estos parámetros
  const state = getState();
  const cacheKey = JSON.stringify(params);
  const cachedData = state.Dashboard.cachedKpis[cacheKey];
  
  // Si hay datos en caché y no han expirado, usarlos
  if (cachedData && (Date.now() - cachedData.timestamp < CACHE_EXPIRY_TIME)) {
    // Simular éxito con datos de caché
    return dispatch(kpisSuccess({ data: cachedData.data, message: "Datos obtenidos de caché" }));
  }
  
  // Si no hay caché o ha expirado, hacer la petición al API
  dispatch(reset_dashboard_flag());
  dispatch(setFetchParams(params));
  
  try {
    const response = await getKpis(params);
    const serializableData = {
      data: response.data,
      message: response.message,
      status: response.status_code,
    };

    dispatch(kpisSuccess(serializableData));
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const resetDashboardFlag = () => async (dispatch) => {
  try {
    const response = dispatch(reset_dashboard_flag());
    return response;
  } catch (error) {
    dispatch(apiError(error));
  }
};