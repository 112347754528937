import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  Input,
} from "reactstrap";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageEdit from "filepond-plugin-image-edit";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import useImageUpload from "../hooks/useImageUpload";

// Register FilePond plugins
registerPlugin(
  FilePondPluginImagePreview,
  FilePondPluginImageExifOrientation,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType,
  FilePondPluginFileEncode,
  FilePondPluginImageTransform,
  FilePondPluginImageResize,
  FilePondPluginImageEdit
);

const compressImage = async (base64String) => {
  try {
    // Crear una imagen desde el base64
    const img = new Image();

    // Crear una promesa para manejar la carga de la imagen
    const imageLoadPromise = new Promise((resolve, reject) => {
      img.onload = () => resolve(img);
      img.onerror = (error) => reject(error);
      img.src = base64String;
    });

    // Esperar a que la imagen cargue
    const loadedImg = await imageLoadPromise;
 

    // Crear un canvas para la compresión
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    // Calcular las dimensiones máximas
    const MAX_WIDTH = 800;
    const scaleFactor = MAX_WIDTH / loadedImg.width;
    canvas.width = MAX_WIDTH;
    canvas.height = loadedImg.height * scaleFactor;

    // Dibujar la imagen en el canvas con las nuevas dimensiones
    ctx.drawImage(loadedImg, 0, 0, canvas.width, canvas.height);

    // Convertir el canvas a base64 con compresión
    const compressedBase64 = canvas.toDataURL("image/jpeg", 0.7);

    return compressedBase64;
  } catch (error) {
    console.error("Error en la compresión de imagen:", error);
    throw error;
  }
};

const ConfirmModal = ({
  isOpen,
  toggle,
  solicitud,
  onConfirm,
  ubicacion,
  telefono,
  loading: externalLoading,
  error: externalError,
  onNotify
}) => {
  const [files, setFiles] = useState([]);
  const [observaciones, setObservaciones] = useState("");
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Limpiar estados cuando se cierra el modal
  useEffect(() => {
    if (!isOpen) {
      setFiles([]);
      setObservaciones("");
      setError(null);
      setIsSubmitting(false);
    }
  }, [isOpen]);

  // Manejar errores externos
  useEffect(() => {
    if (externalError) {
      setError(externalError);
      setIsSubmitting(false);
    }
  }, [externalError]);

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      setError(null);

      // Validaciones
      if (!files || files.length === 0) {
        setError("Por favor, adjunte una imagen de la entrega");
        setIsSubmitting(false);
        return;
      }

      const file = files[0];
      let archivoAdjunto = null;

      try {
        // Procesar la imagen
        const fileData = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
          reader.readAsDataURL(file.file);
        });

        if (!fileData) {
          throw new Error("No se pudo leer la imagen");
        }

        // Comprimir imagen
        archivoAdjunto = await compressImage(fileData);

        if (!archivoAdjunto || archivoAdjunto.length < 1000) {
          throw new Error("Error al procesar la imagen. El archivo resultante no es válido.");
        }



        const currentFacturacion = [...(solicitud.facturacion || [])];
        const lastFacturacionIndex = currentFacturacion.length - 1;
        let mensaje = "Medicamentos entregados";

        if (lastFacturacionIndex >= 0) {
          mensaje =
            mensaje +
            " de " +
            new Date(currentFacturacion[lastFacturacionIndex].fecha)
              .toLocaleDateString("es", { month: "2-digit", year: "numeric" })
              .replace("/", "-");
        }

        // Crear el mensaje con el identificador correcto
        const message = {
          IdentificadorEvento: solicitud.identificador,
          Tipo: "Entregado",
          Mensaje: mensaje,
          Adjunto: "",
          CodigoProveedor: solicitud.codigoProveedor,
        };
        //Notificando a Siscotel
        onNotify(message);




        // Enviar datos
        await onConfirm({
          id: solicitud.id,
          observaciones,
          imagen: archivoAdjunto,
        });



        // Limpiar y cerrar si todo fue exitoso
        setFiles([]);
        setObservaciones("");
        toggle();

      } catch (error) {
        console.error("Error al procesar imagen:", error);
        if (error.message.includes('403 Forbidden')) {
          setError("Error de autorización. Por favor, intente nuevamente.");
        } else {
          setError(error.message || "Error al procesar la imagen");
        }
        return;
      }

    } catch (error) {
      console.error("Error en el envío:", error);
      setError(error.message || "Error al procesar la solicitud");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered size="lg">
      <div className="modal-header">
        <h5 className="modal-title">
          <i className="ri-checkbox-circle-line text-success me-2"></i>
          Confirmar Entrega
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={toggle}
          aria-label="Cerrar"
          disabled={isSubmitting}
        ></button>
      </div>
      <div className="modal-body">
        <div className="mb-3">
          <label className="form-label">Adjuntar Imagen</label>
          <FilePond
            files={files}
            onupdatefiles={setFiles}
            allowMultiple={false}
            maxFiles={1}
            name="files"
            labelIdle='Arrastre y suelte su archivo o <span class="filepond--label-action">Búsquelo</span>'
            acceptedFileTypes={["application/pdf", "image/*"]}
            labelFileTypeNotAllowed="Solo se permiten imágenes"
            labelTapToCancel="click para cancelar"
            labelTapToRetry="click para reintentar"
            labelTapToUndo="click para deshacer"
            onaddfile={(error, file) => {
              if (error) {
                console.error("Error al agregar archivo:", error);
                setError("Error al cargar la imagen. Intente con otra imagen.");
                return;
              }
              setError(null);
            }}
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Observaciones</label>
          <textarea
            className="form-control"
            value={observaciones}
            onChange={(e) => setObservaciones(e.target.value)}
            rows="3"
            placeholder="Ingrese observaciones sobre la entrega..."
            disabled={isSubmitting}
          />
        </div>

        {error && (
          <div className="alert alert-danger d-flex align-items-center" role="alert">
            <i className="ri-error-warning-line me-2 fs-5"></i>
            <div>{error}</div>
          </div>
        )}

        {ubicacion && (
          <div className="alert alert-info d-flex align-items-center" role="alert">
            <i className="ri-map-pin-line me-2 fs-5"></i>
            <div>
              Ubicación actual: {ubicacion.latitude.toFixed(6)}, {ubicacion.longitude.toFixed(6)}
            </div>
          </div>
        )}
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-light"
          onClick={toggle}
          disabled={isSubmitting || externalLoading}
        >
          <i className="ri-close-line me-1"></i>
          Cancelar
        </button>
        <button
          type="button"
          className="btn btn-success"
          onClick={handleSubmit}
          disabled={isSubmitting || externalLoading || !files.length}
        >
          {(isSubmitting || externalLoading) ? (
            <>
              <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
              Procesando...
            </>
          ) : (
            <>
              <i className="ri-check-line me-1"></i>
              Confirmar
            </>
          )}
        </button>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
