import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Table,
  Badge,
  Spinner,
} from "reactstrap";
import ParticlesAuth from "../Authentication/ParticlesAuth";
import logo_farmabien from "../../assets/images/logo-farme.png";
import logo_gemini from "../../assets/images/Gemini-Logo.png";
import logo_alarma from "../../assets/images/alarma.png";

import { getSolicitudQR, resetSolicitudFlag } from "../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import * as moment from "moment";

const handleValidDate = (date) => {
  const date1 = moment(new Date(date)).format("DD MMM Y");
  return date1;
};

const handleValidTime = (time) => {
  const time1 = new Date(time);
  const getHour = time1.getUTCHours();
  const getMin = time1.getUTCMinutes();
  const getTime = `${getHour}:${getMin}`;
  var meridiem = "";
  if (getHour >= 12) {
    meridiem = "PM";
  } else {
    meridiem = "AM";
  }
  const updateTime = moment(getTime, "hh:mm").format("hh:mm") + " " + meridiem;
  return updateTime;
};

const VerificarFactura = () => {
  const dispatch = useDispatch();
  const { id, factura } = useParams();
  const [loadingx, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getSolicitudQR({ id, factura }));
    setLoading(true);
  }, [dispatch, factura, id]);

  const selectLayoutState = (state) => state;
  const pageData = createSelector(selectLayoutState, (state) => ({
    facturacionQR: state.Solicitudes.facturacionQR,
    error: state.Solicitudes.error,
    loading: state.Solicitudes.loading,
    errorMsg: state.Solicitudes.errorMsg,
    colorMsg: state.Solicitudes.colorMsg,
    exito: state.Solicitudes.exito,
  }));

  const { facturacionQR, error, loading, errorMsg, colorMsg, exito } =
    useSelector(pageData);

  if (loading || (!facturacionQR && !error)) {
    return (
      <div
        className="page-content d-flex justify-content-center align-items-center"
        style={{ minHeight: "80vh" }}
      >
        <div className="text-center">
          <Spinner color="primary" className="mb-2" />
          <h5 className="mt-2">Verificando datos...</h5>
          <p className="text-muted">
            Por favor espere mientras procesamos su solicitud
          </p>
        </div>
      </div>
    );
  }

  return (
    <ParticlesAuth>
      <div className="auth-page-content">
        <Container>
          <Row className="justify-content-center">
            <Col md={10} lg={8} xl={8}>
              <div className="text-center my-4">
                <div className="bg-white d-inline-block p-2 rounded shadow-sm">
                  <img
                    src={logo_farmabien}
                    alt="Farme"
                    height="70"
                    className="mx-auto"
                  />
                </div>
              </div>

              <Card className="overflow-hidden border-0 shadow-lg">
                <div
                  className={`card-header bg-${
                    facturacionQR ? "success" : "danger"
                  } py-3`}
                >
                  <h5 className="text-white mb-0">
                    {facturacionQR ? (
                      <>
                        <i className="ri-checkbox-circle-line me-1"></i>{" "}
                        Verificación Exitosa
                      </>
                    ) : (
                      <>
                        <i className="ri-error-warning-line me-1"></i>{" "}
                        Verificación Fallida
                      </>
                    )}
                  </h5>
                </div>

                <CardBody className="p-4">
                  {facturacionQR ? (
                    <>
                      <div className="alert alert-success bg-success-subtle border-success mb-4">
                        <h5 className="alert-heading">
                          <i className="ri-user-smile-line me-1"></i> Hola,{" "}
                          {facturacionQR.persona}
                        </h5>
                        <p className="mb-0">
                          Nos complace proporcionarle un resumen de su solicitud
                          de medicamentos. A continuación encontrará los
                          detalles de su factura y entrega.
                        </p>
                      </div>

                      <div className="card mb-4 border-0 bg-light">
                        <div className="card-body">
                          <h5 className="card-title border-bottom pb-2 mb-3">
                            <i className="ri-file-list-3-line me-1"></i>{" "}
                            Detalles de la Solicitud
                          </h5>
                          <Row>
                            <Col md={6}>
                              <ul className="list-unstyled">
                                <li className="mb-2">
                                  <span className="fw-medium text-muted">
                                    Proveedor:
                                  </span>
                                  <p className="mb-0">Farmabien</p>
                                </li>
                                <li className="mb-2">
                                  <span className="fw-medium text-muted">
                                    Tipo de Solicitud:
                                  </span>
                                  <p className="mb-0">
                                    <Badge
                                      color="info"
                                      className="rounded-pill"
                                    >
                                      {facturacionQR.tipo}
                                    </Badge>
                                  </p>
                                </li>
                                <li className="mb-2">
                                  <span className="fw-medium text-muted">
                                    Patología:
                                  </span>
                                  <p className="mb-0">
                                    <Badge
                                      color="warning"
                                      className="rounded-pill"
                                    >
                                      {facturacionQR.patologia}
                                    </Badge>
                                  </p>
                                </li>
                              </ul>
                            </Col>
                            <Col md={6}>
                              <ul className="list-unstyled">
                                <li className="mb-2">
                                  <span className="fw-medium text-muted">
                                    Fecha de Factura:
                                  </span>
                                  <p className="mb-0">
                                    <i className="ri-calendar-line me-1"></i>
                                    {handleValidDate(
                                      facturacionQR.detalles.fecha
                                    )}
                                  </p>
                                </li>
                                <li className="mb-2">
                                  <span className="fw-medium text-muted">
                                    Número de Factura:
                                  </span>
                                  <p className="mb-0">
                                    <i className="ri-bill-line me-1"></i>
                                    {facturacionQR.detalles.numeroFactura}
                                  </p>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </div>
                      </div>

                      <div className="card mb-4 border-0 shadow-sm">
                        <div className="card-header bg-primary-subtle">
                          <h5 className="card-title mb-0">
                            <i className="ri-medicine-bottle-line me-1"></i>{" "}
                            Comprobante de Entrega
                          </h5>
                        </div>
                        <div className="card-body text-center p-3">
                          {facturacionQR.detalles.adjunto.startsWith(
                            "data:image"
                          ) ? (
                            <img
                              src={facturacionQR.detalles.adjunto}
                              className="img-fluid rounded shadow"
                              alt="Comprobante de entrega"
                              style={{ maxWidth: "100%", maxHeight: "400px" }}
                            />
                          ) : facturacionQR.detalles.adjunto.startsWith(
                              "data:application/pdf"
                            ) ? (
                            <div className="pdf-container border rounded">
                              <embed
                                src={facturacionQR.detalles.adjunto}
                                type="application/pdf"
                                width="100%"
                                height="400px"
                                className="rounded"
                              />
                            </div>
                          ) : (
                            <div className="alert alert-warning">
                              <i className="ri-file-warning-line me-1"></i>{" "}
                              Formato de archivo no soportado
                            </div>
                          )}
                        </div>
                      </div>

                      <Row className="g-3">
                        <Col lg={6}>
                          <div className="card h-100 border-0 shadow-sm">
                            <div className="card-header bg-primary-subtle">
                              <h5 className="card-title mb-0">
                                <i className="ri-alarm-line me-1"></i>{" "}
                                Recordatorio de Medicamentos
                              </h5>
                            </div>
                            <div className="card-body">
                              <div className="d-flex flex-column flex-md-row">
                                <div className="text-center me-md-3 mb-3 mb-md-0">
                                  <img
                                    src={logo_alarma}
                                    alt="Alarma y aviso de remedio"
                                    className="img-fluid rounded"
                                    style={{
                                      maxHeight: "100px",
                                      maxWidth: "100px",
                                    }}
                                  />
                                </div>
                                <div>
                                  <h6>Alarma y aviso de remedio</h6>
                                  <p className="small text-muted mb-2">
                                    Como parte de nuestro compromiso con su
                                    salud, le recomendamos descargar esta
                                    aplicación gratuita que le ayudará a
                                    gestionar sus medicamentos con recordatorios
                                    personalizados.
                                  </p>
                                  <p className="small mb-2">
                                    <strong>
                                      Características principales:
                                    </strong>
                                  </p>
                                  <ul className="small ps-3 mb-3">
                                    <li>Recordatorios de medicamentos</li>
                                    <li>Seguimiento de dosis</li>
                                    <li>Historial de medicación</li>
                                    <li>Notificaciones personalizables</li>
                                  </ul>
                                  <a
                                    href="https://play.google.com/store/apps/details?id=br.com.caiocrol.alarmandpillreminder&pli=1"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn btn-sm btn-success w-100"
                                  >
                                    <i className="ri-google-play-fill me-1"></i>{" "}
                                    Descargar desde Google Play
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>

                        <Col lg={6}>
                          <div className="card h-100 border-0 shadow-sm">
                            <div className="card-header bg-info-subtle">
                              <h5 className="card-title mb-0">
                                <i className="ri-robot-line me-1"></i> Consejos
                                de Salud
                              </h5>
                            </div>
                            <div className="card-body">
                              <div className="d-flex flex-column flex-md-row">
                                <div className="text-center me-md-3 mb-3 mb-md-0">
                                  <img
                                    src={logo_gemini}
                                    alt="Gemini AI"
                                    className="img-fluid rounded"
                                    style={{
                                      maxHeight: "100px",
                                      maxWidth: "100px",
                                    }}
                                  />
                                </div>
                                <div>
                                  <h6>
                                    Consejos para {facturacionQR.patologia}
                                  </h6>
                                  <p className="small text-muted mb-2">
                                    Entendemos que vivir con{" "}
                                    {facturacionQR.patologia} puede presentar
                                    desafíos. Le recomendamos consultar con
                                    Gemini, una herramienta de inteligencia
                                    artificial que puede proporcionarle
                                    información y consejos personalizados sobre
                                    su condición.
                                  </p>
                                  <p className="small mb-2">
                                    <strong>Puede preguntar sobre:</strong>
                                  </p>
                                  <ul className="small ps-3 mb-3">
                                    <li>Hábitos alimenticios recomendados</li>
                                    <li>
                                      Ejercicios adecuados para su condición
                                    </li>
                                    <li>
                                      Consejos para mejorar su calidad de vida
                                    </li>
                                    <li>
                                      Información sobre medicamentos y
                                      tratamientos
                                    </li>
                                  </ul>
                                  <div className="alert alert-warning py-2 px-3 small mb-3">
                                    <i className="ri-information-line me-2"></i>
                                    <strong>Importante:</strong> La información
                                    proporcionada por Gemini es solo orientativa
                                    y no reemplaza el consejo médico
                                    profesional. Siempre consulte con su médico
                                    antes de realizar cambios en su tratamiento.
                                  </div>
                                  <a
                                    href={`https://gemini.google.com/`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn btn-sm btn-info text-white w-100"
                                  >
                                    <i className="ri-robot-fill me-1"></i>{" "}
                                    Consultar con Gemini
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <div className="alert alert-info mt-4 mb-0 text-center">
                        <p className="mb-0">
                          <i className="ri-heart-line me-1"></i>
                          En Farme agradecemos su confianza. No olvide calificar
                          nuestro servicio en la APP MisaludyMas.
                        </p>
                      </div>
                    </>
                  ) : (
                    <div className="text-center py-5">
                      <div className="mb-3">
                        <i className="ri-error-warning-line display-4 text-danger"></i>
                      </div>
                      <h4 className="mb-3">No se encontraron datos válidos</h4>
                      <p className="text-muted mb-4">
                        Lo sentimos, no pudimos verificar la información
                        proporcionada. Por favor, asegúrese de que el código QR
                        escaneado es correcto.
                      </p>
                      <div className="alert alert-warning">
                        <p className="mb-0">
                          <i className="ri-customer-service-2-line me-1"></i>
                          Si necesita ayuda, por favor contacte a nuestro
                          servicio al cliente.
                        </p>
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>

              <div className="mt-4 text-center text-muted small">
                <p className="mb-0">
                  © {new Date().getFullYear()} Farme. Todos los derechos
                  reservados.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </ParticlesAuth>
  );
};

export default VerificarFactura;
