import React, { useMemo, useState, useEffect } from "react";
import { Alert } from "reactstrap";
import {
  getFarmacias,
  deleteFarmacia,
  resetFarmaciasFlag,
} from "../../../slices/thunks";
//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import { UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";

import TableContainer from "../../../Components/Common/TableContainer";
import DeleteModal from "../../../Components/Common/DeleteModal";

const Lista = () => {
  const dispatch = useDispatch();

  const selectLayoutState = (state) => state;

  const pageData = createSelector(selectLayoutState, (state) => ({
    farmacias: state.Farmacias.farmacias,
    error: state.Farmacias.error,
    loading: state.Farmacias.loading,
    errorMsg: state.Farmacias.errorMsg,
    colorMsg: state.Farmacias.colorMsg,
  }));

  // Inside your component
  const { farmacias, error, loading, errorMsg, colorMsg } =
    useSelector(pageData);

  useEffect(() => {
    if (errorMsg) {
      setTimeout(() => {
        dispatch(resetFarmaciasFlag());
      }, 2000);
    }
  }, [dispatch, errorMsg]);

  useEffect(() => {
    dispatch(getFarmacias());
  }, [dispatch, loading]);

  const [deleteModal, setDeleteModal] = useState(false);
  const [borrarSolicitud, setBorrarSolicitud] = useState({});

  const onClickDelete = (borrar) => {
    if (borrar) {
      dispatch(deleteFarmacia(borrarSolicitud.id));
    }

    setDeleteModal(false);
  };

  const solicitudDelete = (solicitud) => {
    setBorrarSolicitud(solicitud);
    setDeleteModal(true);
  };

  const columns = useMemo(
    () => [
      {
        header: "Nombre",
        accessorKey: "nombre",
        enableColumnFilter: false,
      },

      {
        header: "Acción",
        accessorKey: "accion",
        enableColumnFilter: false,
        cell: (cell) => {
          const rowId = cell.row.original.id;
          return (
            <div className="d-flex gap-3">
              <Link to={`/farmacias/editar/${rowId}/`} className="text-success">
                <i className="mdi mdi-pencil font-size-28" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Editar
                </UncontrolledTooltip>
              </Link>

              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cell.row.original;
                  solicitudDelete(orderData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />

                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Eliminar
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      {errorMsg && errorMsg ? <Alert color={colorMsg}> {error} </Alert> : null}

      <DeleteModal
        show={deleteModal}
        onCloseClick={() => setDeleteModal(false)}
        onDeleteClick={() => {
          onClickDelete(true);
        }}
      />

      <TableContainer
        columns={columns || []}
        data={farmacias[0] || []}
        isGlobalFilter={true}
        customPageSize={10}
        SearchPlaceholder="Buscar..."
        divClass="table-responsive"
      />
    </React.Fragment>
  );
};

export { Lista };
