import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DatosSesion } from "../Components/Common/DatosSesion";

const Navdata = () => {
  const history = useNavigate();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isApps, setIsApps] = useState(false);
  const [isLaboratorios, setIsLaboratorios] = useState(false);
  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }

    if (iscurrentState !== "Apps") {
      setIsApps(false);
    }

    if (iscurrentState !== "Laboratorios") {
      setIsLaboratorios(false);
    }
  }, [history, iscurrentState, isDashboard, isApps, isLaboratorios]);

  const menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "dashboard",
      label: "Dashboard",
      icon: " ri-apps-line",
      link: "/#",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        //setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },

    {
      id: "solicitudes",
      label: "Solicitudes",
      icon: " ri-stack-line",
      link: "/solicitudes",
      stateVariables: isApps,
      click: function (e) {
        e.preventDefault();
        // setIsApps(!isApps);
        setIscurrentState("Solicitudes");
        updateIconSidebar(e);
      },
    },

   /*  {
      id: "delivery",
      label: "Delivery",
      icon: "ri-map-pin-line",
      link: "/delivery",
      //stateVariables: isApps,
      click: function (e) {
        e.preventDefault();
        // setIsApps(!isApps);
        //setIscurrentState("Faqs");
        updateIconSidebar(e);
      },
    },
 */
    {
      id: "motorizado",
      label: "Entregas",
      icon: "ri-e-bike-2-line",
      link: "/motorizado/",
      //stateVariables: isApps,
      click: function (e) {
        e.preventDefault();
        // setIsApps(!isApps);
        //setIscurrentState("Faqs");
        updateIconSidebar(e);
      },
    },

    /*     {
      id: "usuarios",
      label: "Usuarios",
      icon: "ri-user-line",
      link: "/usuarios",
      stateVariables: isApps,
      click: function (e) {
        e.preventDefault();
        // setIsApps(!isApps);
        //setIscurrentState("Faqs");
        updateIconSidebar(e);
      },
    }, */

    {
      id: "reportes",
      label: "Reportes",
      icon: "ri-settings-5-line",
      link: "/#",
      stateVariables: isApps,
      click: function (e) {
        e.preventDefault();
        setIsApps(!isApps);
        setIscurrentState("Apps");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "solicitudes",
          label: "Solicitudes",
          icon: "ri-play-list-add-line",
          link: "/reportes/solicitudes/",
          click: function (e) {
            e.preventDefault();
            setIscurrentState("Solicitudes");
          },
        },
        {
          id: "facturacion",
          label: "Facturacion",
          icon: "ri-play-list-add-line",
          link: "/reportes/facturacion/",
          click: function (e) {
            e.preventDefault();
            setIscurrentState("Solicitudes");
          },
        },
      ],
    },

    {
      id: "configuracion",
      label: "Configuracion",
      icon: "ri-settings-5-line",
      link: "/#",
      stateVariables: isApps,
      click: function (e) {
        e.preventDefault();
        setIsApps(!isApps);
        setIscurrentState("Apps");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "usuarios",
          label: "Usuarios",
          icon: "ri-play-list-add-line",
          link: "/usuarios/",
          click: function (e) {
            e.preventDefault();
            setIscurrentState("Usuarios");
          },
        },
        {
          id: "Laboratorios",
          label: "Laboratorios",
          icon: "ri-play-list-add-line",
          link: "/laboratorios/",
          click: function (e) {
            e.preventDefault();
            setIscurrentState("Laboratorios");
          },
        },

        {
          id: "PrincipiosActivos",
          label: "Principios Activos",
          icon: "ri-play-list-add-line",
          link: "/principiosactivos/",
          click: function (e) {
            e.preventDefault();
            updateIconSidebar(e);
            setIscurrentState("Principios Activos");
          },
        },

        {
          id: "PrincipiosActivosCategorias",
          label: "Principios Activos Categorias",
          icon: "ri-play-list-add-line",
          link: "/principiosactivoscategorias/",
          click: function (e) {
            e.preventDefault();
            updateIconSidebar(e);
            setIscurrentState("Principios Activos Categorias");
          },
        },

        {
          id: "plantillasmensajes",
          label: "Plantillas Mensajes",
          icon: "ri-play-list-add-line",
          link: "/plantillas-mensajes",
          click: function (e) {
            e.preventDefault();
            setIscurrentState("Plantillas Mensajes");
            updateIconSidebar(e);
          },
        },

        {
          id: "planillaestrategica",
          label: "Planilla Estratégica",
          icon: "ri-play-list-add-line",
          link: "/planilla-estrategica",

          click: function (e) {
            e.preventDefault();
            setIscurrentState("Planilla Estratégica");
            updateIconSidebar(e);
          },
        },

        {
          id: "farmacias",
          label: "Farmacias",
          icon: "ri-play-list-add-line",
          link: "/farmacias",

          click: function (e) {
            e.preventDefault();
            setIscurrentState("Farmacias");
            updateIconSidebar(e); 
          },
        },
      ],
    },
  ];

  const perfil = DatosSesion("perfil");
  switch (perfil?.trim()) {
    case "Delivery":
      const elementosDelivery = [menuItems[3]]; 
      menuItems.splice(0, menuItems.length, ...elementosDelivery);
      break;
    case "Farmabien":
      const elementosDeseados = [
        menuItems[1],
        menuItems[2],
        menuItems[4],
   
      ];
      menuItems.splice(0, menuItems.length, ...elementosDeseados);
      break;
    case "MyHealth":
      const elementosDeseados2 = [
        menuItems[1],
        menuItems[2],
        menuItems[4],
      ];
      menuItems.splice(0, menuItems.length, ...elementosDeseados2);
      break;

    default:
      break;
  }

  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
