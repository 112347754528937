import React, { useEffect } from "react";
import {
  getPlantillaMensaje,
  resetPlantillasMensajesFlag,
} from "../../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Alert,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import EditarPlantillasMensajes from "./Editar";
import { DatosSesion } from "../../../Components/Common/DatosSesion";

const Editar = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const perfil = DatosSesion("perfil");
  useEffect(() => {
    dispatch(getPlantillaMensaje(id));
  }, [dispatch, id]);

  const selectLayoutState = (state) => state;
  const pageData = createSelector(selectLayoutState, (state) => ({
    plantillaMensaje: state.PlantillasMensajes.plantillaMensaje,
    error: state.PlantillasMensajes.error,
    loading: state.PlantillasMensajes.loading,
    errorMsg: state.PlantillasMensajes.errorMsg,
    colorMsg: state.PlantillasMensajes.colorMsg,
    exito: state.PlantillasMensajes.exito,
  }));

  // Inside your component
  const { plantillaMensaje, error, loading, errorMsg, colorMsg, exito } =
    useSelector(pageData);

  useEffect(() => {
    if (errorMsg) {
      setTimeout(() => {
        dispatch(resetPlantillasMensajesFlag());
      }, 3000);
    }
  }, [dispatch, errorMsg]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {perfil === "Administrador" ||
          perfil === "Farmabien" ||
          perfil === "MyHealth" ? (
            <>
              <BreadCrumb
                title="Editar Laboratorio"
                pageTitle="Listar Laboratorio"
                url="/laboratorios"
              />

              <BreadCrumb
                title="Editar Plantilla Mensaje"
                pageTitle="Listar Plantillas Mensajes"
                url="/plantillas-mensajes"
              />

              <Row>
                <Col lg={12}>
                  <Card>
                    <CardHeader className="border-0">
                      {errorMsg && errorMsg ? (
                        <Alert color={colorMsg}> {error} </Alert>
                      ) : null}
                    </CardHeader>
                    <CardBody>
                      {exito && (
                        <EditarPlantillasMensajes
                          plantillaMensaje={plantillaMensaje}
                        />
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            <Alert color="danger">
              No tienes permisos para ver esta página.
            </Alert>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Editar;
