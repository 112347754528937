import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Label,
  Input,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

const MapComponent = ({ handleSubmit2 }) => {
  const [latitud, setLatitud] = useState("");
  const [longitud, setLongitud] = useState("");
  const [direccion, setDireccion] = useState("");
  const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_APIKEYGOOGLEMAPS;
  const [addressObtained, setAddressObtained] = useState(false);

  const obtenerDireccionDesdeCoordenadas = async (lat, lng) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&result_type=street_address&key=${GOOGLE_MAPS_API_KEY}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.results.length > 0) {
        setDireccion(data.results[0].formatted_address);
        setAddressObtained(true);
      } else {
        alert("No se encontró dirección para estas coordenadas.");
      }
    } catch (error) {
      console.error("Error al obtener la dirección:", error);
    }
  };

  const handleCoordinatesSubmit = (e) => {
    e.preventDefault();
    const lat = parseFloat(latitud);
    const lng = parseFloat(longitud);
    obtenerDireccionDesdeCoordenadas(lat, lng);
  };

  const handleRegisterSearch = () => {
    // Llama a la función handleSubmit con la dirección y las coordenadas
    handleSubmit2(direccion, [parseFloat(latitud), parseFloat(longitud)]);
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(
        "Por favor, envíe las coordenadas (latitud y longitud) de la dirección de entrega. Puede utilizar las siguientes herramientas para obtenerlas: Coordenadas GPS (https://www.coordenadas-gps.com/) o Google Maps (https://www.google.com/maps/)."
      )
      .then(() => {
        // Puedes agregar un mensaje de éxito si lo deseas
        /* console.log("URL copiada al portapapeles"); */
      })
      .catch((err) => {
        console.error("Error al copiar la URL: ", err);
      });
  };

  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className="card-body">
              <div className="row align-items-center">
                <form onSubmit={handleCoordinatesSubmit}>
                  <Row className="gy-4">
                    <Col lg={6}>
                      <div>
                        <Label htmlFor="basiInput" className="form-label">
                          Latitud <span className="text-danger">*</span>
                        </Label>
                        <Input
                          className="form-control"
                          id="basiInput"
                          type="text"
                          value={latitud}
                          onChange={(e) => setLatitud(e.target.value)}
                          placeholder="Ingrese la latitud"
                          required
                        />
                      </div>
                    </Col>

                    <Col lg={6}>
                      <div>
                        <Label htmlFor="labelInput" className="form-label">
                          Longitud <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          value={longitud}
                          onChange={(e) => setLongitud(e.target.value)}
                          placeholder="Ingrese la longitud"
                          required
                        />
                      </div>
                    </Col>

                    <Col
                      lg={12}
                      className="d-flex align-items-start justify-content-center"
                    >
                      <Button
                        color="primary"
                        className="btn btn-primary btn-sm w-50"
                        style={{ cursor: "pointer" }}
                        type="submit"
                      >
                        <i className="mdi mdi-pencil font-size-24" /> Obtener
                        Dirección
                      </Button>
                    </Col>

                    {!addressObtained && (
                      <Col
                        lg={12}
                        className="d-flex align-items-start justify-content-center"
                      >
                        <div
                          className="text-center my-5"
                          onClick={copyToClipboard}
                          style={{ cursor: "pointer" }}
                        >
                          <i className="ri-map-pin-fill fs-1 text-muted"></i>
                          <p className="mt-2" id="edittooltip">
                            Sugerir usar la aplicación:
                            https://www.coordenadas-gps.com/
                            <UncontrolledTooltip
                              placement="top"
                              target="edittooltip"
                            >
                              Clic para copiar
                            </UncontrolledTooltip>
                          </p>
                        </div>
                      </Col>
                    )}
                  </Row>
                </form>

                {direccion && (
                  <div className="text-center my-5">
                    <i className=" ri-pushpin-fill fs-1 text-muted"></i>
                    <p className="mt-2">Dirección: {direccion}</p>
                    <Button
                      color="primary"
                      className="btn btn-success btn-sm mt-2"
                      style={{ cursor: "pointer" }}
                      onClick={handleRegisterSearch}
                    >
                      <i className="mdi mdi-pencil font-size-24" /> Ver en el
                      mapa y confirmar
                    </Button>
                  </div>
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default MapComponent;
