import React from "react";
import { Route, Routes } from "react-router-dom";

import Lista from "./Listar";
import Crear from "./Crear";
import Editar from "./Editar";
import Page404 from "../../Components/Common/Page404";

/**
 * Componente que contiene las rutas para el manejo de plantillas de mensaje:
 * - Listar plantillas de mensaje
 * - Crear plantillas de mensaje
 * - Editar plantillas de mensaje
 * - Mostrar una pgina de error 404 si se intenta acceder a una ruta que no existe.
 */
const PlantillasMensajes = () => {
  return (
    <Routes>
      <Route path="/" element={<Lista />} />
      <Route path="crear" element={<Crear />} />
      <Route path="editar/:id" element={<Editar />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default PlantillasMensajes;