import React, { useState, useEffect } from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  getSolicitud,
  resetSolicitudFlag,
  getServicios,
  getUsers,
  getFarmacias,
} from "../../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { useParams } from "react-router-dom";
import { Container, Row, Alert } from "reactstrap";
import Section from "./Section";
import TicketDescription from "./TicketDescription";
import TicketDetails from "./TicketDetails";
import Comentarios from "./Comentarios";
import { medicamentos } from "../../../helpers/backend_helper";
import { DatosSesion } from "../../../Components/Common/DatosSesion";

const SolicitudDetalle = () => {
  document.title = "Detalle de la Solicitud | Farme";
  // Obtiene el ID desde la URL
  const { id } = useParams();
  const dispatch = useDispatch();
  const perfil = DatosSesion("perfil");
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        await Promise.all([
          dispatch(getSolicitud(id)),
          dispatch(getUsers()),
          dispatch(getFarmacias()),
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dispatch, id]);

  const selectLayoutState = (state) => state;
  const pageData = createSelector(selectLayoutState, (state) => ({
    solicitud: state.Solicitudes.solicitud,
    error: state.Solicitudes.error,
    loading: state.Solicitudes.loading,
    errorMsg: state.Solicitudes.errorMsg,
    colorMsg: state.Solicitudes.colorMsg,
    exito: state.Solicitudes.exito,
  }));

  // Inside your component
  const { solicitud, error, loading, errorMsg, colorMsg, exito } =
    useSelector(pageData);

  //OBTENER Servicios de Entrega
  const selectLayoutStateUser = (state) => state;
  const usuarioPageData = createSelector(selectLayoutStateUser, (state) => ({
    usuarios: state.Usuarios.usuarios,
  }));

  // Inside your component
  const { usuarios } = useSelector(usuarioPageData);

  //OBTENER Farmacias
  const farmaciasPageData = createSelector(selectLayoutState, (state) => ({
    farmacias: state.Farmacias.farmacias,
    loadingFarmacias: state.Farmacias.loading,
  }));

  const { farmacias, loadingFarmacias } = useSelector(farmaciasPageData);


  // Verificar si hay datos válidos antes de renderizar
  if (!solicitud || Object.keys(solicitud).length === 0) {
    return (
      <div className="error-container">
        <span>No se encontró la solicitud o ocurrió un error al cargarla</span>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
           <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            limit={1}
            enableMultiContainer={false}
          />

          {perfil === "Administrador" ||
          perfil === "Farmabien" ||
          perfil === "MyHealth" ? (
            <>
              {isLoading ? (
                <div className="d-flex justify-content-center my-5">
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Cargando...</span>
                  </div>
                </div>
              ) : (
              <>
                <Row>
                  <Section solicitud={solicitud} />
                </Row>
                <Row>
                  <TicketDescription
                    solicitud={solicitud}
                    usuarios={usuarios}
                    farmacias={farmacias[0]}
                  />

                  <TicketDetails solicitud={solicitud} />
                </Row>
              </>
              )}
            </>
          ) : (
            <Alert color="danger">
              No tienes permisos para ver esta página.
            </Alert>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SolicitudDetalle;
