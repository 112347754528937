import React, { useMemo, useState, useEffect } from "react";
import { Alert } from "reactstrap";
import {
  getPrincipiosActivosCat,
  resetPrincipiosActivosCategoriaFlag,
  deletePrincipioActivoCategoria,
} from "../../../slices/thunks";
//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import { UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";

import TableContainer from "../../../Components/Common/TableContainer";
import DeleteModal from "../../../Components/Common/DeleteModal";

const Lista = () => {
  const dispatch = useDispatch();

  const selectLayoutState = (state) => state;

  const principiosActivosPageData = createSelector(
    selectLayoutState,
    (state) => ({
      principiosActivosCategorias:
        state.PrincipiosActivosCategorias.principiosActivosCategorias,
      error: state.PrincipiosActivosCategorias.error,
      loading: state.PrincipiosActivosCategorias.loading,
      errorMsg: state.PrincipiosActivosCategorias.errorMsg,
      colorMsg: state.PrincipiosActivosCategorias.colorMsg,
    })
  );

  // Inside your component
  const { principiosActivosCategorias, error, loading, errorMsg, colorMsg } =
    useSelector(principiosActivosPageData);


  useEffect(() => {
    try {
      const result = dispatch(getPrincipiosActivosCat());
    } catch (error) {
      console.error("Error dispatching action:", error);
    }
  }, [dispatch, loading]);

  useEffect(() => {
    if (errorMsg) {
      setTimeout(() => {
        dispatch(resetPrincipiosActivosCategoriaFlag());
      }, 2000);
    }
  }, [dispatch, errorMsg]);

  const [deleteModal, setDeleteModal] = useState(false);

  const [borrarSolicitud, setBorrarSolicitud] = useState({});

  const onClickDelete = (borrar) => {
    if (borrar) {
      dispatch(deletePrincipioActivoCategoria(borrarSolicitud.id));
    }

    setDeleteModal(false);
  };

  const solicitudDelete = (solicitud) => {
    setBorrarSolicitud(solicitud);
    setDeleteModal(true);
  };

  const columns = useMemo(
    () => [
      {
        header: "Departamento",
        accessorKey: "departamento",
        enableColumnFilter: false,
      },
      {
        header: "Categoria",
        accessorKey: "categoria",
        enableColumnFilter: false,
      },
      {
        header: "Subcategoria",
        accessorKey: "subcategoria",
        enableColumnFilter: false,
      },

      {
        header: "Acción",
        accessorKey: "accion",
        enableColumnFilter: false,
        cell: (cell) => {
          const rowId = cell.row.original.id;
          return (
            <div className="d-flex gap-3">
              <Link
                to={`/principiosactivoscategorias/editar/${rowId}/`}
                className="text-success"
              >
                <i className="mdi mdi-pencil font-size-28" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Editar
                </UncontrolledTooltip>
              </Link>

              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cell.row.original;
                  solicitudDelete(orderData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />

                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Eliminar
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      {errorMsg && errorMsg ? <Alert color={colorMsg}> {error} </Alert> : null}

      <DeleteModal
        show={deleteModal}
        onCloseClick={() => setDeleteModal(false)}
        onDeleteClick={() => {
          onClickDelete(true);
        }}
      />

      <TableContainer
        columns={columns || []}
        data={principiosActivosCategorias[0] || []}
        isGlobalFilter={true}
        customPageSize={20}
        SearchPlaceholder="Buscar..."
        divClass="table-responsive"
      />
    </React.Fragment>
  );
};

export { Lista };
