import React from "react";
import { Route, Routes } from "react-router-dom";

import Lista from "./Listar";
import Crear from "./Crear";
import Editar from "./Editar";
import Page404 from "../../Components/Common/Page404";


/**
 * Farmacias component that defines the routes for the Farmacias section.
 * 
 * Routes:
 * - "/" renders the Lista component.
 * - "/crear" renders the Crear component.
 * - "/editar/:id" renders the Editar component with a dynamic id parameter.
 * - "*" renders the Page404 component for any undefined routes.
 * 
 * @returns {JSX.Element} The Routes component with defined routes.
 */
const Farmacias = () => {
  return (
    <Routes>
      <Route path="/" element={<Lista />} />
      <Route path="crear" element={<Crear />} />
      <Route path="editar/:id" element={<Editar />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default Farmacias;