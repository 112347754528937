import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/**
 * Componente que muestra notificaciones relacionadas con la autenticación
 * Utiliza react-toastify para mostrar notificaciones
 */
const AuthNotifier = () => {
  return (
    <React.Fragment>
      {/* Contenedor de notificaciones toast */}
      <ToastContainer 
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </React.Fragment>
  );
};

export default AuthNotifier;
