import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import {
  createMessage,
  addComentario,
} from "../../../slices/thunks";
import { APIClient } from "../../../helpers/api_helper";
import {
  Card,
  CardBody,
  Col,
  Label,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
} from "reactstrap";
import SimpleBar from "simplebar-react";
//import images
import logo_mh_plus_sm from "../../../assets/images/mh-sm.png";
import logo_farmabien_sm from "../../../assets/images/farmabien-sm.png";

// Estilos para la animación de giro
const spinStyle = `
  @keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
  
  .spin {
    animation: spin 1s linear infinite;
    display: inline-block;
  }
`;

// Primero, agregar las funciones de utilidad al inicio del archivo
const compressImage = async (base64String) => {
  try {
    // Crear una imagen desde el base64
    const img = new Image();

    // Crear una promesa para manejar la carga de la imagen
    const imageLoadPromise = new Promise((resolve, reject) => {
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = base64String;
    });

    // Esperar a que la imagen cargue
    const loadedImg = await imageLoadPromise;

    // Crear un canvas para la compresión
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    // Calcular las dimensiones máximas
    const MAX_WIDTH = 800;
    const scaleFactor = MAX_WIDTH / loadedImg.width;
    canvas.width = MAX_WIDTH;
    canvas.height = loadedImg.height * scaleFactor;

    // Dibujar la imagen en el canvas con las nuevas dimensiones
    ctx.drawImage(loadedImg, 0, 0, canvas.width, canvas.height);

    // Convertir el canvas a base64 con compresión
    const compressedBase64 = canvas.toDataURL("image/jpeg", 0.7);

    return compressedBase64;
  } catch (error) {
    console.error("Error en la compresión de imagen:", error);
    throw new Error(`Error al comprimir la imagen: ${error.message}`);
  }
};

const cleanBase64 = (base64String) => {
  if (!base64String) return "";
  const match = base64String.match(/^data:([A-Za-z-+\/]+);base64,(.+)$/);
  return match ? match[2] : base64String;
};

const Comentarios = ({ solicitud }) => {
  const dispatch = useDispatch();
  const [mensaje, setMensaje] = useState("");
  const [mensajeError, setMensajeError] = useState("");
  const [adjunto, setAdjunto] = useState(null);
  const [loadingx, setLoadingx] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [lastUpdateTime, setLastUpdateTime] = useState(Date.now());
  const [isRefreshing, setIsRefreshing] = useState(false);

  const [showImageModal, setShowImageModal] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  // Agregar el estilo al DOM cuando el componente se monta
  useEffect(() => {
    // Crear un elemento de estilo
    const styleElement = document.createElement('style');
    styleElement.innerHTML = spinStyle;
    document.head.appendChild(styleElement);

    // Limpiar cuando el componente se desmonta
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  // Agrega estas funciones para manejar la visualización de archivos
  const handleOpenFile = (adjunto) => {
    if (isImageFile(adjunto)) {
      // Si es una imagen, mostrar en modal
      setCurrentImage(adjunto);
      setShowImageModal(true);
    } else if (isPdfFile(adjunto)) {
      // Si es un PDF, abrir en nueva pestaña
      openPdfInNewTab(adjunto);
    }
  };

  const isImageFile = (fileData) => {
    return (
      fileData &&
      (fileData.includes("data:image/") ||
        (fileData.includes("base64") && !fileData.includes("JVBERi0")))
    );
  };

  const isPdfFile = (fileData) => {
    return (
      fileData &&
      (fileData.includes("data:application/pdf") ||
        fileData.includes("JVBERi0")) // Firma de PDF en base64
    );
  };

  const openPdfInNewTab = (pdfData) => {
    const pdfWindow = window.open();
    pdfWindow.document.write(
      `<iframe width="100%" height="100%" src="${pdfData}"></iframe>`
    );
  };

  const selectLayoutState = (state) => state;
  const pageData = createSelector(selectLayoutState, (state) => ({
    siscotel: state.Siscotel.siscotel,
    error: state.Siscotel.error,
    loading: state.Siscotel.loading,
    errorMsg: state.Siscotel.errorMsg,
    colorMsg: state.Siscotel.colorMsg,
    exito: state.Siscotel.exito,
  }));

  const { siscotel, error, loading, errorMsg, colorMsg, exito } =
    useSelector(pageData);

  useEffect(() => {
    if (siscotel && siscotel.status == 200 && mensaje !== "") {
      console.info("Creando un mensaje");
      const now = new Date();
      const creado = new Date(
        now.getTime() - now.getTimezoneOffset() * 60000
      ).toISOString();

      const comentario = {
        tipo: "Enviado",
        mensaje: mensaje.trim(),
        estatus: true,
        adjunto: adjunto,
        creado: null,
      };

      // Make sure identificador exists
      if (!solicitud.identificador) {
        console.error("Identificador is missing");
        toast.error("Error: Identificador no encontrado");
        return;
      }

      // Asegurarse de que el identificador sea una cadena
      const idString = String(solicitud.identificador);

      dispatch(addComentario({ identificador: idString, comentario }))
        .then(() => {
          setMensaje("");
          setAdjunto(null);
          setLoadingx(false);
          toast.success("Mensaje enviado exitosamente");
        })
        .catch((error) => {
          console.error("Error adding comment:", error);


          // Mostrar un mensaje de error más específico basado en el error
          if (error.status === 404) {
            toast.error(`Error: ${error.message || "Solicitud no encontrada"}`);
          } else {
            toast.error(`Error: ${error.message || "Error desconocido"}`);
          }
          setLoadingx(false);
        });
    }
  }, [siscotel]);

  const handleFileChange = async (e) => {
    try {
      const file = e.target.files[0];
      const maxSize = 1024 * 1024; // 1MB en bytes
      const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];

      if (!file) {
        return;
      }

      // Validar tipo de archivo
      if (!allowedTypes.includes(file.type)) {
        alert("Solo se permiten archivos JPG, PNG y PDF");
        e.target.value = ""; // Limpiar el input
        return;
      }

      // Validar tamaño
      if (file.size > maxSize) {
        alert("El archivo no debe superar 1MB");
        e.target.value = ""; // Limpiar el input
        return;
      }

      // Procesar el archivo
      const reader = new FileReader();
      reader.onloadend = async () => {
        try {
          let finalData = reader.result;

          // Si es una imagen, comprimir
          if (file.type.startsWith("image/")) {
            finalData = await compressImage(reader.result);
          }

          setAdjunto(finalData);
        } catch (error) {
          console.error("Error procesando archivo:", error);
          alert("Error al procesar el archivo. Por favor, intente nuevamente.");
          e.target.value = "";
        }
      };

      reader.onprogress = (event) => {
        if (event.lengthComputable) {
          const progress = (event.loaded / event.total) * 100;
          setUploadProgress(progress);
        }
      };

      reader.onerror = () => {
        console.error("Error leyendo archivo");
        alert("Error al leer el archivo. Por favor, intente nuevamente.");
        e.target.value = "";
      };

      reader.readAsDataURL(file);
    } catch (error) {
      console.error("Error en handleFileChange:", error);
      alert("Error al procesar el archivo. Por favor, intente nuevamente.");
      e.target.value = "";
    }
  };

  const resetForm = () => {
    setMensaje("");
    setAdjunto(null);
    setLoadingx(false);
    setUploadProgress(0);
    setMensajeError("");

    // Limpiar el input file
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleSubmitComentario = async () => {
    try {
      setLoadingx(true);

      if (!mensaje || mensaje.trim() === "") {
        setMensajeError("El mensaje es obligatorio");
        setLoadingx(false);
        return;
      }

      setMensajeError("");

      let adjuntoProcessed = null;
      if (adjunto) {
        if (adjunto.startsWith("data:image")) {
          try {
            adjuntoProcessed = await compressImage(adjunto);
          } catch (error) {
            console.error("Error procesando adjunto:", error);
            throw new Error("Error al procesar el archivo adjunto");
          }
        }
        adjuntoProcessed = cleanBase64(adjunto);
      }

      const message = {
        IdentificadorEvento: solicitud.identificador,
        Tipo: "Mensaje",
        Mensaje: mensaje,
        Adjunto: adjuntoProcessed || "",
        CodigoProveedor: solicitud.codigoProveedor,
      };

      try {
        await dispatch(createMessage(message));
        setLoadingx(false);
      } catch (error) {
        console.error(
          "Error al enviar mensaje:",
          error.message || "Error desconocido"
        );
        setLoadingx(false);
        toast.error(
          "Error al enviar el mensaje. Por favor, intente nuevamente."
        );
      }
    } catch (error) {
      console.error(
        "❌ Error en el proceso:",
        error.message || "Error desconocido"
      );
      setLoadingx(false);
      toast.error(
        "Error al procesar el mensaje. Por favor, intente nuevamente."
      );
    }
  };

  const createBlobUrl = (base64Data) => {
    const parts = base64Data.split(";base64,");
    const contentType = parts[0].split(":")[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    const blob = new Blob([uInt8Array], { type: contentType });
    return URL.createObjectURL(blob);
  };

  const formatDate = (dateString) => {
    // Verificar el formato de la fecha
    const isZuluTime = dateString.endsWith("Z");
    const date = new Date(dateString);

    return date.toLocaleString("es-ES", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
      // Si es Zulu time (termina en Z), usar UTC, si no, usar America/Bogota
      timeZone: isZuluTime ? "UTC" : "America/Caracas",
    });
  };

  // Función para refrescar los comentarios
  const refreshComments = useCallback(async () => {
    if (!solicitud || !solicitud._id) return;
    
    try {
      setIsRefreshing(true);
      
      // Realizar la solicitud directamente al API en lugar de usar el dispatch
      const api = new APIClient();
      const response = await api.get(`/solicitud/${solicitud._id}`);
      
      if (response && response.data) {
        // Actualizar manualmente los comentarios en el estado local
        const updatedSolicitud = {
          ...solicitud,
          comentarios: response.data.data.comentarios || []
        };
        
        // Actualizar el estado global a través de Redux
        dispatch(solicitudSuccess({
          data: {
            data: updatedSolicitud
          },
          status: 200
        }));
        
      }
      
      setLastUpdateTime(Date.now());
    } catch (error) {
      console.error("Error al actualizar comentarios:", error);
    } finally {
      setIsRefreshing(false);
    }
  }, [solicitud, dispatch]);

  // Efecto para actualizar periódicamente los comentarios (cada 30 segundos)
  useEffect(() => {

    
    // Solo configurar el intervalo si tenemos una solicitud válida
    if (solicitud && solicitud._id) {
      const intervalId = setInterval(() => {
        refreshComments();
      }, 30000); // 30 segundos
      
      // Limpiar el intervalo cuando el componente se desmonte
      return () => {
        clearInterval(intervalId);
      };
    }
    
    return () => {}; // Retorno vacío si no hay solicitud válida
  }, [refreshComments, solicitud]);

  return (
    <React.Fragment>
      <Col>
        <div
          className="comentarios-container"
          style={{ maxWidth: "100%", overflowX: "hidden" }}
          data-saving={loadingx}
        >
          <Card>
            <CardBody className="p-4">
              <div className="mb-4">
                <h5 className="card-title mb-2">Comentarios</h5>
                <div className="d-flex align-items-center mt-2">
                  <Button 
                    color="link" 
                    className="btn-sm p-0 me-2 text-muted"
                    onClick={refreshComments}
                    disabled={isRefreshing}
                  >
                    <i className={`ri-refresh-line ${isRefreshing ? 'spin' : ''}`}></i>
                    {isRefreshing ? '' : 'Actualizar'}
                  </Button>
                  <small className="text-muted">
                    Última actualización: {new Date(lastUpdateTime).toLocaleTimeString()}
                  </small>
                </div>
              </div>
              <SimpleBar
                style={{ maxHeight: "500px", height: "auto" }}
                className="px-3 mx-n3"
              >
                {solicitud.comentarios && solicitud.comentarios.length > 0 ? (
                  [...solicitud.comentarios]
                    .reverse()
                    .map((comentario, index) => (
                      <div className="d-flex mb-4" key={index}>
                        <div className="flex-shrink-0">
                          <img
                            src={
                              comentario.tipo === "Enviado"
                                ? logo_farmabien_sm
                                : logo_mh_plus_sm
                            }
                            alt=""
                            className="avatar-xs rounded-circle me-3"
                          />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <div className="d-flex align-items-center mb-1">
                            <h5 className="fs-13 mb-0 me-2">
                              {comentario.tipo === "Enviado" ? (
                                <span className="text-primary">Farmabien</span>
                              ) : (
                                <span className="text-success">
                                  {solicitud.solicitante}
                                </span>
                              )}
                            </h5>
                            <small className="text-muted">
                              {formatDate(comentario.creado) || ""}
                            </small>
                          </div>
                          <div
                            className={`p-3 rounded ${
                              comentario.tipo === "Enviado"
                                ? "bg-light-subtle"
                                : "bg-light"
                            }`}
                            style={{ wordBreak: "break-word" }}
                          >
                            <p className="mb-0">{comentario.mensaje}</p>
                          </div>

                          {comentario.adjunto && (
                            <Button
                              color="link"
                              className="p-0 text-info"
                              onClick={(e) => {
                                e.preventDefault();
                                handleOpenFile(comentario.adjunto);
                              }}
                            >
                              <i
                                className={`me-1 ${
                                  isPdfFile(comentario.adjunto)
                                    ? "ri-file-pdf-line"
                                    : isImageFile(comentario.adjunto)
                                    ? "ri-image-line"
                                    : "ri-file-line"
                                }`}
                              ></i>
                              {isPdfFile(comentario.adjunto)
                                ? "Ver PDF"
                                : isImageFile(comentario.adjunto)
                                ? "Ver Imagen"
                                : "Ver Adjunto"}
                            </Button>
                          )}
                        </div>
                      </div>
                    ))
                ) : (
                  <div className="text-center text-muted py-4">
                    <i className="ri-chat-off-line fs-24 mb-2 d-block"></i>
                    <p className="mb-0">
                      No hay comentarios para esta solicitud.
                    </p>
                  </div>
                )}
              </SimpleBar>

              {solicitud &&
              solicitud.estatus !== "Cancelado" &&
              solicitud.estatus !== "Entregado" ? (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmitComentario();
                  }}
                  className="mt-3"
                >
                  <Row className="g-3">
                    <Col lg={12}>
                      <div className="mb-3">
                        <Label htmlFor="mensaje" className="form-label">
                          Mensaje <span className="text-danger">*</span>
                        </Label>
                        <textarea
                          className={`form-control ${
                            mensajeError ? "is-invalid" : ""
                          }`}
                          id="mensaje"
                          rows="3"
                          value={mensaje}
                          onChange={(e) => {
                            setMensaje(e.target.value);
                            if (e.target.value.trim() !== "") {
                              setMensajeError("");
                            }
                          }}
                          placeholder="Escribe tu mensaje aquí..."
                          maxLength={1000}
                        ></textarea>
                        {mensajeError && (
                          <div className="invalid-feedback">{mensajeError}</div>
                        )}
                        <small className="text-muted d-block text-end mt-1">
                          {mensaje.length}/1000 caracteres
                        </small>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Label htmlFor="fileInput" className="form-label">
                          Adjuntar archivo (opcional)
                        </Label>
                        <div className="custom-file-upload">
                          <input
                            type="file"
                            className="form-control"
                            id="fileInput"
                            onChange={handleFileChange}
                            accept=".jpg,.jpeg,.png,.pdf"
                          />
                          {uploadProgress > 0 && uploadProgress < 100 && (
                            <div
                              className="progress mt-2"
                              style={{ height: "6px" }}
                            >
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{ width: `${uploadProgress}%` }}
                                aria-valuenow={uploadProgress}
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          )}
                          {adjunto && (
                            <div className="mt-2 d-flex align-items-center">
                              <span className="text-success me-2">
                                <i className="ri-check-line"></i> Archivo
                                cargado
                              </span>
                              <button
                                type="button"
                                className="btn btn-sm btn-outline-danger"
                                onClick={() => {
                                  setAdjunto(null);
                                  document.getElementById("fileInput").value =
                                    "";
                                }}
                              >
                                <i className="ri-delete-bin-line"></i>
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col lg={12} className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success"
                        disabled={loadingx}
                      >
                        {loadingx ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm me-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Enviando...
                          </>
                        ) : (
                          <>
                            <i className="ri-discuss-line align-middle fs-16 me-2"></i>
                            Enviar
                          </>
                        )}
                      </button>
                    </Col>
                  </Row>
                </form>
              ) : (
                <div className="alert alert-info mt-3">
                  <i className="ri-information-line me-2"></i>
                  No es posible añadir comentarios a esta solicitud.
                </div>
              )}
            </CardBody>
          </Card>
        </div>
      </Col>

      <Modal
        isOpen={showImageModal}
        toggle={() => setShowImageModal(!showImageModal)}
        size="lg"
        centered
      >
        <ModalHeader toggle={() => setShowImageModal(!showImageModal)}>
          Vista previa de imagen
        </ModalHeader>
        <ModalBody className="text-center p-4">
          {currentImage && (
            <img
              src={currentImage}
              alt="Imagen adjunta"
              className="img-fluid"
              style={{ maxHeight: "70vh" }}
            />
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Comentarios;
