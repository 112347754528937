import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  siscotel: {},
  error: "",
  loading: false,
  errorMsg: false,
  colorMsg: "",
  exito: false,
};

const siscotelSlice = createSlice({
  name: "siscotel",
  initialState,
  reducers: {
    apiError(state, action) {
      state.error = action.payload;
      state.loading = false;
      state.errorMsg = true;
      state.colorMsg = "danger";
    },

    siscotelSuccess(state, action) {
      state.siscotel = action.payload.data;
      state.loading = false;
      state.errorMsg = true;
      state.colorMsg = "success";
      state.error = action.payload.message;
      state.exito = true;
    },

    reset_siscotel_flag(state) {
      state.siscotel = {}
      state.success = false;
      state.error = "";
      state.loading = false;
      state.errorMsg = false;
      state.colorMsg = "";
    },
  },
});

export const {
  apiError,
  siscotelSuccess,
  reset_siscotel_flag,
} = siscotelSlice.actions;

export default siscotelSlice.reducer;
