import {
  optimizarRuta,
  crearRuta,
  actualizarRuta,
  finalizarRuta,
  obtenerRutaActiva,
  obtenerEstadisticasRuta,
  actualizarEstadoSolicitudEnRuta,
} from "../../../helpers/backend_helper";
import { loginSuccess } from "../../auth/login/reducer";

import {
  apiError,
  rutaOptimizadaSuccess,
  rutaActivaSuccess,
  estadisticasSuccess,
  setLoading,
} from "./reducer";

/**
 * Optimiza la ruta para un conjunto de entregas
 */
/* export const optimizarRutaEntregas = (datos) => async (dispatch) => {
  try {
    dispatch(setLoading());
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = optimizarRuta(datos);
    }
    const data = await response;
    if (data) {
      dispatch(rutaOptimizadaSuccess(data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
}; */

/**
 * Crea una nueva ruta de entrega
 */
export const crearNuevaRuta = (datos) => async (dispatch) => {
  try {
    dispatch(setLoading());
    //console.log("Datos enviados al backend:", datos);
    
    if (!datos.motorizado_id) {
      throw new Error("ID de motorizado no proporcionado");
    }
    
    // Verificar que los datos tengan el formato correcto según el modelo RutaEntregaCreate
    const datosFormateados = {
      solicitudes: datos.solicitudes || [],
      motorizado_id: datos.motorizado_id,
      farmacia_id: datos.farmacia_id,
      fecha_inicio: datos.fecha_inicio || new Date().toISOString()
    };
    
    //console.log("Datos formateados para enviar al backend:", datosFormateados);
    
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = await crearRuta(datosFormateados);
    }
    
    if (response) {
      const serializableData = {
        data: response.data,
        message: response.message,
        status: response.status_code,
      };
      dispatch(rutaActivaSuccess(serializableData));
      return serializableData;
    }
    
    return null;
  } catch (error) {
    console.error("Error al crear ruta:", error);
    
    // Asegurarnos de que el error sea serializable
    const errorMessage = error.response?.data?.detail || 
                         error.message || 
                         "Error al crear ruta";
    
    dispatch(apiError(errorMessage));
    return { error: errorMessage };
  }
};

/**
 * Actualiza el estado de una ruta
 */
export const actualizarEstadoRuta = (datos) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = await actualizarRuta(datos);
    }

    if (response) {
      dispatch(rutaActivaSuccess(response));
      return { success: true, data: response };
    }
  } catch (error) {
    const errorMessage =
      error.response?.data?.detail ||
      error.message ||
      "Error al actualizar el estado de la ruta";
    dispatch(apiError(errorMessage));
    return { success: false, error: errorMessage };
  }
};

/**
 * Finaliza una ruta de entrega
 */
export const finalizarRutaEntrega = (rutaId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    if (!rutaId) {
      throw new Error("ID de ruta no válido");
    }



    // Llamar al endpoint para finalizar la ruta
    const response = await finalizarRuta(rutaId);
    //console.log("Respuesta de finalizar ruta:", response);

    // Verificar la respuesta
    if (response && response.data) {
      // Limpiar la ruta activa en el estado
      dispatch(rutaActivaSuccess(null));
      
      // Ya no es necesario actualizar las solicitudes aquí, se hará desde el componente
      
      return { success: true, data: response.data };
    } else {
      throw new Error("Respuesta inválida del servidor");
    }
  } catch (error) {
    console.error("Error al finalizar la ruta:", error);
    
    // Extraer el mensaje de error de diferentes fuentes posibles
    const errorMessage =
      error.response?.data?.detail ||
      error.message ||
      "Error al finalizar la ruta";
    
    // Despachar el error para actualizar el estado
    dispatch(apiError(errorMessage));
    
    return { success: false, error: errorMessage };
  } finally {
    dispatch(setLoading(false));
  }
};

/**
 * Obtiene la ruta activa de un motorizado
 */
export const obtenerRutaActivaMotorizado = (motorizadoId) => async (dispatch) => {
  try {
    dispatch(setLoading());
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = await obtenerRutaActiva(motorizadoId);
    }
    if (response) {
      //console.log("obtenerRutaActivaMotorizado", response);

      const serializableData = {
        data: response.data,
        message: response.message,
        status: response.status_code,
      };
      dispatch(rutaActivaSuccess(serializableData));
      
      // Devolver la respuesta para que pueda ser utilizada
      return serializableData;
    }
    return null;
  } catch (error) {
    dispatch(apiError(error));
    return { error: error };
  }
};

/**
 * Obtiene las estadísticas de rutas de un motorizado
 */
export const obtenerEstadisticasMotorizado =
  (motorizadoId, params) => async (dispatch) => {
    try {
      dispatch(setLoading());
      let response;
      if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
        response = obtenerEstadisticasRuta(motorizadoId, params);
      }
      const data = await response;
      if (data) {
        dispatch(estadisticasSuccess(data));
      }
    } catch (error) {
      dispatch(apiError(error));
    }
  };

/**
 * Actualiza el estado de una solicitud específica en una ruta
 * @param {Object} datos - Objeto con los datos necesarios para actualizar
 * @param {string} datos.rutaId - ID de la ruta
 * @param {string} datos.solicitudId - ID de la solicitud
 * @param {string} datos.estado - Nuevo estado (ENTREGADO, CANCELADO)
 */
export const actualizarEstadoSolicitudEnLaRuta =
  (datos) => async (dispatch) => {
    try {
      dispatch(setLoading(true));

      const { rutaId, solicitudId, estado } = datos;

      // Validar que todos los datos necesarios estén presentes
      if (!rutaId || !solicitudId || !estado) {
        throw new Error(
          "Datos incompletos para actualizar el estado de la solicitud"
        );
      }

      let response;

      if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
        response = await actualizarEstadoSolicitudEnRuta({
          rutaId,
          solicitudId,
          estado,
        });
      }

      if (response) {
        // Actualizar el estado en el store
        dispatch(rutaActivaSuccess(response));
        return {
          success: true,
          data: response,
          message: `Estado de solicitud actualizado a ${estado} exitosamente`,
        };
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.detail ||
        error.message ||
        "Error al actualizar el estado de la solicitud";

      dispatch(apiError(errorMessage));
      return {
        success: false,
        error: errorMessage,
      };
    } finally {
      dispatch(setLoading(false));
    }
  };
