import React, { useEffect, useState } from "react";
import { editPrincipioActivoCategoria } from "../../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Row,
  Input,
  Label,
  Form,
  Spinner,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Select from "react-select";
import { Link } from "react-router-dom";
import ObjectId from "bson-objectid";

const Editar = ({ principioActivoCategoria }) => {
  const dispatch = useDispatch();

  const [departamento, setDepartamento] = useState(
    principioActivoCategoria.departamento
  );
  const [categoria, setCategoria] = useState(
    principioActivoCategoria.categoria
  );
  const [subcategoria, setSubcategoria] = useState(
    principioActivoCategoria.subcategoria
  );

  const handleDepartamentoChange = (e) => {
    setDepartamento(e.target.value);
  };

  const handleCategoriaChange = (e) => {
    setCategoria(e.target.value);
  };

  const handleSubcategoriaChange = (e) => {
    setSubcategoria(e.target.value);
  };

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  }, [dispatch, loading]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const update = {
      id: principioActivoCategoria.id,
      departamento: departamento,
      categoria: categoria,
      subcategoria: subcategoria,
    };

    dispatch(editPrincipioActivoCategoria(update));
    setLoading(true);
  };

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <Row lg={12} className="p-3">
          <Col xxl={4} md={4}>
            <div className="mb-3">
              <Label className="form-label" htmlFor="departamento">
                Departamento <span className="text-danger">*</span>
              </Label>

              <Input
                required
                minLength={3}
                maxLength={50}
                type="text"
                className="form-control"
                id="departamento"
                name="departamento"
                value={departamento}
                onChange={(e) => setDepartamento(e.target.value)}
              />
            </div>
          </Col>
          <Col xxl={4} md={4}>
            <div className="mb-3">
              <Label className="form-label" htmlFor="categoria">
                Categoria <span className="text-danger">*</span>
              </Label>

              <Input
                required
                minLength={3}
                maxLength={50}
                type="text"
                className="form-control"
                id="categoria"
                name="categoria"
                value={categoria}
                onChange={(e) => setCategoria(e.target.value)}
              />
            </div>
          </Col>
          <Col xxl={4} md={4}>
            <div className="mb-3">
              <Label className="form-label" htmlFor="subcategoria">
                Sub. Categoria <span className="text-danger">*</span>
              </Label>

              <Input
                required
                minLength={3}
                maxLength={50}
                type="text"
                className="form-control"
                id="subcategoria"
                name="subcategoria"
                value={subcategoria}
                onChange={(e) => setSubcategoria(e.target.value)}
              />
            </div>
          </Col>
        </Row>
        <Row className="align-items-end">
          <Col lg={6}>
            <div className="mt-4 mb-3">
              <Link to="/principiosactivoscategorias">
                <Button className="btn btn-info btn-label left ms-auto nexttab nexttab">
                  <i className="ri-arrow-left-line label-icon align-middle fs-16 ms-2"></i>
                  Regresar al Listado
                </Button>
              </Link>
            </div>
          </Col>
          <Col lg={6}>
            <div className="mt-4 mb-3 text-end">
              <button
                type="submit"
                className="btn btn-success btn-label right ms-auto nexttab nexttab"
              >
                {loading ? (
                  <Spinner size="sm" className="me-2">
                    {" "}
                    Loading...{" "}
                  </Spinner>
                ) : null}
                <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                Actualizar Principio Activo Categoria
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

export default Editar;
