import {
  createEventResult as eventResult,
  createDocument as document,
  createMessage as message,
  createCoverage as coverage
} from "../../../helpers/backend_helper";
import {
  apiError,
  siscotelSuccess,
  reset_siscotel_flag,
} from "./reducer";

// Función para serializar el error antes de pasarlo a Redux
const serializeError = (error) => {
  return error.message || "Error desconocido";
};

/**
 * Asynchronous thunk action to create an event result for Siscotel.
 *
 * @param {Object} siscotel - The Siscotel object containing event result data.
 * @returns {Function} A thunk function that dispatches actions based on the API response.
 */
export const createEventResult = (siscotel) => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = await eventResult(siscotel);
    }
    
    // Crear un objeto serializable para Redux
    const serializableData = {
      data: response.data,
      message: response.message,
      status: response.status,
    };

    dispatch(siscotelSuccess(serializableData));
    return serializableData;
  } catch (error) {    
    dispatch(apiError(serializeError(error)));
    throw error;
  }
};




/**
 * Asynchronous thunk action to create a document for siscotel.
 *
 * @param {Object} siscotel - The siscotel object to create a document for.
 * @returns {Function} A thunk function that dispatches actions based on the API response.
 */
export const createDocument = (siscotel) => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = await document(siscotel);
    }
    
    // Crear un objeto serializable para Redux
    const serializableData = {
      data: response.data,
      message: response.message,
      status: response.status,
    };

    dispatch(siscotelSuccess(serializableData));
    return serializableData;
  } catch (error) {
    dispatch(apiError(serializeError(error)));
    throw error;
  }
};



/**
 * Asynchronous thunk action to create a message for Siscotel.
 *
 * @param {Object} siscotel - The Siscotel object.
 * @returns {Function} A thunk function that dispatches actions based on the API response.
 */
export const createMessage = (siscotel) => async (dispatch) => {
    try {
      let response;
      if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
        response = await message(siscotel);
      }
      
      // Crear un objeto serializable para Redux
      const serializableData = {
        data: response.data,
        message: response.message,
        status: response.status,
      };

      dispatch(siscotelSuccess(serializableData));
      return serializableData;
    } catch (error) {
      dispatch(apiError(serializeError(error)));
      throw error;
    }
  };




/**
 * Asynchronous thunk action to create coverage.
 *
 * @param {Object} siscotel - The siscotel object to be used in the action.
 * @returns {Function} A thunk function that dispatches actions based on the API response.
 */
export const createCoverage = (siscotel) => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = await coverage(siscotel);
    }
    
    // Crear un objeto serializable para Redux
    const serializableData = {
      data: response.data,
      message: response.message,
      status: response.status,
    };

    dispatch(siscotelSuccess(serializableData));
    return serializableData;
  } catch (error) {
    dispatch(apiError(serializeError(error)));
    throw error;
  }
};

/**
 * Asynchronous thunk action to reset the siscotel flag.
 *
 * This function dispatches the `reset_siscotel_flag` action and returns the response.
 *
 * @returns {Promise} The response from the `reset_siscotel_flag` action.
 */
export const resetSiscotelFlag = () => async (dispatch) => {
  try {
    const response = dispatch(reset_siscotel_flag());
    return response;
  } catch (error) {
    dispatch(apiError(serializeError(error)));
    throw error;
  }
};