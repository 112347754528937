import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getsolicitudes,
  getsolicitud,
  deletesolicitud,
  editsolicitud,
  createsolicitud,
  medicamentos,
  rutaOptima,
  solicitudesEntregar,
  updateDeliverySolicitud,
  agregarComentario,
  agregarDelivery,
  agregarFacturacion,
  getSolicitudesPorTipo,
  exportarSolicitudesPorTipo,
  getFacturacionPorPeriodo,
  exportarFacturacionPorPeriodo,
  getSolicitudesFacturacion,
  downloadBlob,
} from "../../../helpers/backend_helper";

import {
  apiError,
  solicitudesSuccess,
  solicitudSuccess,
  solicitudDeleteSuccess,
  medicamentosSuccess,
  reset_solicitud_flag,
  rutaSuccess,
  updateDeliverySuccess,
  filtroSolicitudesSuccess,
  facturacionSuccess,
  facturacionQRSuccess,
} from "./reducer";

// Función para crear un objeto serializable a partir de la respuesta
const createSerializableResponse = (response) => {
  // Extraer solo los datos necesarios, omitiendo headers y otras propiedades no serializables
  return {
    data: response.data,
    message: response.message,
    status: response.status,
  };
};

// Obtener todas las solicitudes
export const getSolicitudes = createAsyncThunk(
  "solicitudes/getSolicitudes",
  async (query, { dispatch }) => {
    try {
      const response = await getsolicitudes(query);
      const serializableData = createSerializableResponse(response);
      dispatch(solicitudesSuccess(serializableData));
      return serializableData;
    } catch (error) {
      dispatch(apiError(error.message || "Error desconocido"));
      throw error;
    }
  }
);

// Eliminar una solicitud
export const deleteSolicitud = createAsyncThunk(
  "solicitudes/deleteSolicitud",
  async (id, { dispatch }) => {
    try {
      const response = await deletesolicitud(id);
      const serializableData = createSerializableResponse(response);
      dispatch(solicitudDeleteSuccess(serializableData));
      return serializableData;
    } catch (error) {
      dispatch(apiError(error.message || "Error desconocido"));
      throw error;
    }
  }
);

// Editar una solicitud
export const editSolicitud = createAsyncThunk(
  "solicitudes/editSolicitud",
  async (solicitud, { dispatch }) => {
    try {
      const response = await editsolicitud(solicitud);
      const serializableData = createSerializableResponse(response);
      dispatch(solicitudSuccess(serializableData));
      return serializableData;
    } catch (error) {
      dispatch(apiError(error.message || "Error desconocido"));
      throw error;
    }
  }
);

// Crear una nueva solicitud
export const createSolicitud = createAsyncThunk(
  "solicitudes/createSolicitud",
  async (solicitud, { dispatch }) => {
    try {
      const response = await createsolicitud(solicitud);
      const serializableData = createSerializableResponse(response);
      dispatch(solicitudSuccess(serializableData));
      return serializableData;
    } catch (error) {
      dispatch(apiError(error.message || "Error desconocido"));
      throw error;
    }
  }
);

// Obtener una solicitud específica
export const getSolicitud = createAsyncThunk(
  "solicitudes/getSolicitud",
  async (id, { dispatch }) => {
    try {
      const response = await getsolicitud(id);
      const serializableData = createSerializableResponse(response);
      dispatch(solicitudSuccess(serializableData));
      return serializableData;
    } catch (error) {
      dispatch(apiError(error.message || "Error desconocido"));
      throw error;
    }
  }
);

// Resetear flags de solicitud
export const resetSolicitudFlag = createAsyncThunk(
  "solicitudes/resetFlag",
  async (_, { dispatch }) => {
    try {
      dispatch(reset_solicitud_flag());
      return true;
    } catch (error) {
      dispatch(apiError(error.message || "Error desconocido"));
      throw error;
    }
  }
);

// Obtener lista de medicamentos
export const listaMedicamentos = createAsyncThunk(
  "solicitudes/listaMedicamentos",
  async (lista, { dispatch }) => {
    try {
      const response = await medicamentos(lista);
      const serializableData = createSerializableResponse(response);
      dispatch(medicamentosSuccess(serializableData));
      return serializableData;
    } catch (error) {
      dispatch(apiError(error.message || "Error desconocido"));
      throw error;
    }
  }
);

// Obtener solicitudes para entregar
export const getSolicitudesEntregar = createAsyncThunk(
  "solicitudes/getSolicitudesEntregar",
  async (id_user, { dispatch }) => {
    try {
      const response = await solicitudesEntregar(id_user);
      const serializableData = createSerializableResponse(response);
      dispatch(solicitudesSuccess(serializableData));
      return serializableData;
    } catch (error) {
      dispatch(apiError(error.message || "Error desconocido"));
      throw error;
    }
  }
);

// Crear ruta óptima
export const createRutaOptima = createAsyncThunk(
  "solicitudes/createRutaOptima",
  async (payload, { dispatch }) => {
    try {
      const response = await rutaOptima({
        solicitudes_ids: payload.solicitudes,
        ruta_id: payload.ruta_id,
      });

      const serializableData = createSerializableResponse(response);
      dispatch(rutaSuccess(serializableData));
      return serializableData;
    } catch (error) {
      dispatch(apiError(error.message || "Error desconocido"));
      throw error;
    }
  }
);

// Actualizar delivery
export const updateDelivery = createAsyncThunk(
  "solicitudes/updateDelivery",
  async (solicitud, { dispatch }) => {
    try {
      const response = await updateDeliverySolicitud(solicitud);
      const serializableData = createSerializableResponse(response);
      dispatch(updateDeliverySuccess(serializableData));
      return serializableData;
    } catch (error) {
      dispatch(apiError(error.message || "Error desconocido"));
      throw error;
    }
  }
);

// Agregar comentario
export const addComentario = createAsyncThunk(
  "solicitudes/addComentario",
  async ({ identificador, comentario }, { dispatch }) => {
    try {
      // Convertir identificador a string para asegurar consistencia
      const idString = String(identificador);
      const response = await agregarComentario(idString, comentario);
      const serializableData = createSerializableResponse(response);
      dispatch(solicitudSuccess(serializableData));
      return serializableData;
    } catch (error) {
      console.error("Thunk: Error al agregar comentario:", error);

      // Asegurarse de que el error sea serializable
      const serializableError = {
        message: error.message || "Error al agregar comentario",
        status: error.status || 500,
      };
      dispatch(apiError(serializableError.message));
      throw serializableError;
    }
  }
);

// Agregar Facturacion
export const addFacturacion = createAsyncThunk(
  "solicitudes/addFacturacion",
  async ({ identificador, facturacionArray }, { dispatch }) => {
    try {
      // Convertir identificador a string para asegurar consistencia
      const idString = String(identificador);
      const response = await agregarFacturacion(idString, facturacionArray);
      const serializableData = createSerializableResponse(response);
      dispatch(solicitudSuccess(serializableData));
      return serializableData;
    } catch (error) {
      console.error("Thunk: Error al agregar facturación:", error);

      // Asegurarse de que el error sea serializable
      const serializableError = {
        message: error.message || "Error al agregar facturación",
        status: error.status || 500,
      };
      dispatch(apiError(serializableError.message));
      throw serializableError;
    }
  }
);

// Agregar Delivery
export const addDelivery = createAsyncThunk(
  "solicitudes/addDelivery",
  async ({ identificador, delivery }, { dispatch }) => {
    try {
      // Convertir identificador a string para asegurar consistencia
      const idString = String(identificador);
      const response = await agregarDelivery(idString, delivery);
      const serializableData = createSerializableResponse(response);
      dispatch(solicitudSuccess(serializableData));
      return serializableData;
    } catch (error) {
      console.error("Thunk: Error al agregar comentario:", error);

      // Asegurarse de que el error sea serializable
      const serializableError = {
        message: error.message || "Error al agregar comentario",
        status: error.status || 500,
      };
      dispatch(apiError(serializableError.message));
      throw serializableError;
    }
  }
);


// Obtener solicitudes por tipo
export const obtenerSolicitudesPorTipo = createAsyncThunk(
  "solicitudes/obtenerPorTipo",
  async (params, { dispatch }) => {
    try {
      const response = await getSolicitudesPorTipo(params);

      if (response?.data) {
        // Asegurarse de que los datos son serializables
        const serializableData = {
          solicitudes: response.data.data.solicitudes,
          estadisticas: response.data.data.estadisticas,
        };
        dispatch(filtroSolicitudesSuccess(serializableData));
      }
      return response.data;
    } catch (error) {
      dispatch(apiError(error.message || "Error desconocido"));
      throw error;
    }
  }
);

export const exportarReporteSolicitudes = createAsyncThunk(
  "solicitudes/exportarReporte",
  async (params, { dispatch }) => {
    try {
      const blob = await exportarSolicitudesPorTipo(params);

      // 2. Eliminar lógica redundante de creación de Blob
      if (!(blob instanceof Blob)) {
        throw new Error("Respuesta no es un Blob válido");
      }

      // 3. Optimizar descarga con función reusable
      const fileName = `reporte-solicitudes-${
        new Date().toISOString().split("T")[0]
      }.xlsx`;
      downloadBlob(blob, fileName);

      return { success: true, message: "Reporte generado exitosamente" };
    } catch (error) {
      console.error("[Export Error]", error);
      dispatch(apiError(error.message || "Error al generar reporte"));
      throw error;
    }
  }
);

export const obtenerFacturacionPorPeriodo = createAsyncThunk(
  "solicitudes/obtenerFacturacion",
  async (params, { dispatch }) => {
    try {
      const response = await getFacturacionPorPeriodo(params);

      // Crear un objeto serializable para la acción
      const serializableData = {
        data: response.data,
        message: response.message,
        status: response.status_code,
      };

      // Despachar la acción facturacionSuccess con los datos serializables
      dispatch(facturacionSuccess(serializableData));

      return serializableData;
    } catch (error) {
      dispatch(apiError(error.message || "Error desconocido"));
      throw error;
    }
  }
);

export const exportarReporteFacturacion = createAsyncThunk(
  "solicitudes/exportarReporteFacturacion",
  async (params, { dispatch }) => {
    try {
      const blob = await exportarFacturacionPorPeriodo(params);

      // 2. Eliminar lógica redundante de creación de Blob
      if (!(blob instanceof Blob)) {
        throw new Error("Respuesta no es un Blob válido");
      }

      // 3. Optimizar descarga con función reusable
      const fileName = `reporte-facturacion-${
        new Date().toISOString().split("T")[0]
      }.xlsx`;
      downloadBlob(blob, fileName);

      return { success: true, message: "Reporte generado exitosamente" };
    } catch (error) {
      console.error("[Export Error]", error);
      dispatch(apiError(error.message || "Error al generar reporte"));
      throw error;
    }
  }
);

export const getSolicitudQR = createAsyncThunk(
  "solicitud/verificarqr",
  async (params, { dispatch }) => {
    try {
      const response = await getSolicitudesFacturacion(params);

      const serializableData = {
        data: response.data,
        message: response.message,
        status: response.status,
      };
      dispatch(facturacionQRSuccess(serializableData));

      return serializableData;
    } catch (error) {
      dispatch(apiError(error.message || "Error desconocido"));
      throw error;
    }
  }
);
