import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  obtenerFacturacionPorPeriodo,
  exportarReporteFacturacion,
  getFarmacias,
} from "../../../slices/thunks";
import TableContainer from "../../../Components/Common/TableContainer";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Form,
  Input,
  Button,
  Label,
} from "reactstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { Link } from "react-router-dom";
import { DatosSesion } from "../../../Components/Common/DatosSesion";

// Opciones para los selectores (mantenemos las mismas)
const opcionesTipo = [
  { value: "Todas", label: "Todas" },
  { value: "Aguda", label: "Aguda" },
  { value: "Cronica", label: "Crónica" },
];

const opcionesEstatus = [
  { value: "", label: "Todos los estados" },
  { value: "Nueva Solicitud", label: "Nueva Solicitud" },
  { value: "Por Entregar", label: "Por Entregar" },
  { value: "En Transito", label: "En Tránsito" },
  { value: "Entregado", label: "Entregado" },
  { value: "Cancelada", label: "Cancelada" },
  { value: "Por Renovar", label: "Por Renovar" },
  { value: "Por Reprogramar", label: "Por Reprogramar" },
];

// Formatear fechas
const formatearFecha = (fecha) => {
  if (!fecha) return "";
  return fecha instanceof Date
    ? fecha.toISOString().split("T")[0]
    : new Date(fecha).toISOString().split("T")[0];
};

const ReporteFacturacion = () => {
  const dispatch = useDispatch();
  document.title = "Reporte de Facturación | Farme";

  // Obtener datos de sesión
  const perfil = DatosSesion("perfil");
  const farmaciaId = DatosSesion("farmacia");

  const isAdmin = perfil === "Administrador" || perfil === "MyH+";

  // Estados
  const [busquedaRealizada, setBusquedaRealizada] = useState(false);
  const [farmacia, setFarmacia] = useState(isAdmin ? null : farmaciaId);
  const [fechaInicio, setFechaInicio] = useState(null);
  const [fechaFin, setFechaFin] = useState(null);
  const [tipo, setTipo] = useState(null);
  const [estatus, setEstatus] = useState(null);
  const [isExporting, setIsExporting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    fechaInicio: false,
    fechaFin: false,
  });

  // Selectores de Redux
  const farmacias = useSelector((state) => state.Farmacias.farmacias);
  //const facturacion = useSelector((state) => state.Solicitudes.facturacion);

  const facturacion = useSelector((state) => {
    //console.log("Estado completo de Solicitudes:", state.Solicitudes);
    return state.Solicitudes.facturacion;
  });

  // Convertir las farmacias a opciones para el Select
  const opcionesFarmacias = useMemo(
    () => [
      ...(isAdmin ? [{ value: "", label: "Todas las farmacias" }] : []),
      ...(Array.isArray(farmacias) && farmacias.length > 0
        ? farmacias[0].map((farm) => ({
            value: farm.codigo_myh,
            label: farm.nombre,
          }))
        : []),
    ],
    [farmacias, isAdmin]
  );

  // Definir las columnas para la tabla de facturación
  const columns = useMemo(
    () => [
      {
        header: "ID",
        accessorKey: "identificador_solicitud",
        enableColumnFilter: false,
      },
      {
        header: "Tipo",
        accessorKey: "tipo_solicitud",
        enableColumnFilter: false,
        cell: (cell) => {
          const tipo_solicitud = cell.getValue();
          // Verificar si estatus es null o undefined
          if (!tipo_solicitud) {
            return <span>-</span>;
          }

          let badgeClass = "";
          let colorClass = "";

          switch (tipo_solicitud) {
            case "Aguda":
              badgeClass = "Aguda";
              colorClass = "bg-danger-subtle text-danger";
              break;
            case "Cronica":
              badgeClass = "Crónica";
              colorClass = "bg-success-subtle text-success";
              break;
            default:
              colorClass = "bg-info-subtle text-info";
          }

          return (
            <span className={`badge ${colorClass}`}>{badgeClass || tipo_solicitud}</span>
          );
        },
      },
      {
        header: "Solicitante",
        accessorKey: "solicitante",
        enableColumnFilter: false,
      },
      {
        header: "Cédula",
        accessorKey: "cedula",
        enableColumnFilter: false,
      },
      {
        header: "Cod. Farmacia",
        accessorKey: "codigoProveedor",
        enableColumnFilter: false,
        cell: (cell) => {
          const codigoProveedor = cell.getValue();
          // Verificar si estatus es null o undefined
          if (!codigoProveedor) {
            return <span>-</span>;
          }

          let badgeClass = "";
          let colorClass = "";

          switch (codigoProveedor) {
            case "00077":
              badgeClass = "Caracas";
              colorClass = "bg-primary-subtle text-primary";
              break;
            default:
              badgeClass = "Lara";
              colorClass = "bg-warning-subtle text-warning";
          }

          return (
            <span className={`badge ${colorClass}`}>
              {codigoProveedor} - {badgeClass}
            </span>
          );
        },
      },
      {
        header: "Fecha Factura",
        accessorKey: "fecha_factura",
        enableColumnFilter: false,
      },
      {
        header: "Número Factura",
        accessorKey: "numero_factura",
        enableColumnFilter: false,
      },
      {
        header: "Monto Total",
        accessorKey: "monto_total",
        enableColumnFilter: false,
        cell: (cell) => {
          const monto = cell.getValue();
          return `Bs. ${monto.toLocaleString("es-VE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`;
        },
      },
      {
        header: "Estado",
        accessorKey: "estatus",
        enableColumnFilter: false,
        cell: (cell) => {
          const estatus = cell.getValue();
          // Verificar si estatus es null o undefined
          if (!estatus) {
            return <span>-</span>;
          }
          let badgeClass = "";

          // Versión alternativa usando clases de color con fondo sutil
          switch (estatus.toLowerCase()) {
            case "entregado":
              return (
                <span className="badge bg-success-subtle text-success">
                  Entregado
                </span>
              );
            case "nueva solicitud":
              return (
                <span className="badge bg-info-subtle text-info">
                  Nueva Solicitud
                </span>
              );
            case "por entregar":
              return (
                <span className="badge bg-primary-subtle text-primary">
                  Por Entregar
                </span>
              );
            case "en transito":
            case "en tránsito":
              return (
                <span className="badge bg-dark-subtle text-dark">
                  En Tránsito
                </span>
              );
            case "cancelado":
              return (
                <span className="badge bg-danger-subtle text-danger">
                  Cancelado
                </span>
              );
            case "por renovar":
              return (
                <span className="badge bg-secondary-subtle text-secondary">
                  Por Renovar
                </span>
              );
            case "por reprogramar":
              return (
                <span className="badge bg-warning-subtle text-warning">
                  Por Reprogramar
                </span>
              );
            default:
              return (
                <span className="badge bg-light text-dark">{estatus}</span>
              );
          }
        },
      },
      {
        header: "Factura",
        accessorKey: "adjunto",
        enableColumnFilter: false,
        cell: (cell) => {
          const adjunto = cell.getValue();

          // Validación más estricta del adjunto
          if (!adjunto || typeof adjunto !== "string") {
            return "No";
          }

          if (!adjunto.startsWith("data:")) {
            console.error("Formato de adjunto inválido:", adjunto);
            return "Error en formato";
          }

          // Función para crear el blob URL
          const createBlobUrl = (base64Data) => {
            try {
              const parts = base64Data.split(";base64,");
              if (parts.length !== 2) {
                throw new Error("Formato base64 inválido");
              }

              const contentType = parts[0].split(":")[1];
              const raw = window.atob(parts[1]);
              const rawLength = raw.length;
              const uInt8Array = new Uint8Array(rawLength);

              for (let i = 0; i < rawLength; ++i) {
                uInt8Array[i] = raw.charCodeAt(i);
              }

              const blob = new Blob([uInt8Array], { type: contentType });
              return URL.createObjectURL(blob);
            } catch (error) {
              console.error("Error al crear blob URL:", error);
              return null;
            }
          };

          // Función para abrir la imagen
          const abrirImagen = (base64URL) => {
            const blobUrl = createBlobUrl(base64URL);
            if (blobUrl) {
              window.open(blobUrl, "_blank");
            } else {
              toast.error("Error al abrir el archivo");
            }
          };

          // Determinar el tipo de archivo de manera segura
          const isPDF = adjunto.toLowerCase().includes("application/pdf");
          const isImage = adjunto.toLowerCase().includes("image/");

          return (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                abrirImagen(adjunto);
              }}
              className="text-primary"
            >
              Ver Adjunto{" "}
              {isPDF ? (
                <i className="ri-file-pdf-line ms-1"></i>
              ) : isImage ? (
                <i className="ri-image-line ms-1"></i>
              ) : (
                <i className="ri-external-link-line ms-1"></i>
              )}
            </a>
          );
        },
      },
      
      {
        header: "Acción",
        accessorKey: "accion",
        enableColumnFilter: false,
        cell: (cell) => {
          const rowId = cell.row.original.id_solicitud;
          return (
            <div className="d-flex gap-3">
              <Link
                to={`/solicitud/ver/${rowId}`}
                className="text-primary d-inline-block"
              >
                <i className="ri-eye-fill fs-16"></i>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const hayFacturas = useMemo(() => {
    //console.log("Verificando facturas en:", facturacion);
    return facturacion?.detalles && facturacion.detalles.length > 0;
  }, [facturacion]);

  // Función para generar el reporte
  const generarReporte = useCallback(async () => {
    setErrors({
      fechaInicio: false,
      fechaFin: false,
    });

    let hasErrors = false;
    if (!fechaInicio) {
      setErrors((prev) => ({ ...prev, fechaInicio: true }));
      hasErrors = true;
    }
    if (!fechaFin) {
      setErrors((prev) => ({ ...prev, fechaFin: true }));
      hasErrors = true;
    }

    if (hasErrors) return;

    try {
      setIsLoading(true);


      // Obtener el código_myh correcto para la farmacia del usuario no admin
      let codigoFarmacia = "";
      if (isAdmin) {
        codigoFarmacia = farmacia?.value || "";
      } else {
        // Si hay farmacias disponibles, buscar la que coincide con farmaciaId
        if (Array.isArray(farmacias) && farmacias.length > 0) {
          const farmaciaEncontrada = farmacias[0].find(
            (f) => f.id === farmaciaId
          );
          if (farmaciaEncontrada) {
            codigoFarmacia = farmaciaEncontrada.codigo_myh;
          }
        }
      }
     
      const params = {
        tipo: tipo?.value || "Todas",
        farmacia: codigoFarmacia,
        estatus: estatus?.value || "",
        fecha_inicio: formatearFecha(fechaInicio),
        fecha_fin: formatearFecha(fechaFin),
      };

      await dispatch(obtenerFacturacionPorPeriodo(params));
      setBusquedaRealizada(true); 
    } catch (error) {
      console.error("Error al obtener facturación:", error);
      toast.error("Error al cargar los datos. Por favor intente nuevamente.");
    } finally {
      setIsLoading(false);
    }
  }, [
    fechaInicio,
    fechaFin,
    tipo,
    farmacia,
    estatus,
    dispatch,
    isAdmin,
    farmaciaId,
    farmacias,
  ]);

  // Función para exportar a Excel
  const exportarExcel = useCallback(async () => {
    setErrors({
      fechaInicio: false,
      fechaFin: false,
    });

    let hasErrors = false;
    if (!fechaInicio) {
      setErrors((prev) => ({ ...prev, fechaInicio: true }));
      hasErrors = true;
    }
    if (!fechaFin) {
      setErrors((prev) => ({ ...prev, fechaFin: true }));
      hasErrors = true;
    }

    if (hasErrors) return;

    try {
      setIsExporting(true);

      // Obtener el código_myh correcto para la farmacia del usuario no admin
      // Obtener el código_myh correcto para la farmacia del usuario no admin

      console.log("farmaciaId:", farmaciaId);
      console.log("farmacias:", farmacias);

      let codigoFarmacia = "";
      if (isAdmin) {
        codigoFarmacia = farmacia?.value || "";
      } else {
        // Si hay farmacias disponibles, buscar la que coincide con farmaciaId
        if (
          Array.isArray(farmacias) &&
          farmacias.length > 0 &&
          Array.isArray(farmacias[0])
        ) {
          const listaFarmacias = farmacias[0];
          const farmaciaEncontrada = listaFarmacias.find(
            (f) => f.id === farmaciaId
          );
          if (farmaciaEncontrada) {
            codigoFarmacia = farmaciaEncontrada.codigo_myh;
          } else {
            // Si no se encuentra, usar directamente el farmaciaId
            codigoFarmacia = farmaciaId;
          }
        } else {
          // Si no hay farmacias disponibles, usar el farmaciaId directamente
          codigoFarmacia = farmaciaId;
        }
      }
      console.log("codigoFarmacia final:", codigoFarmacia);

      const params = {
        tipo: tipo?.value || "Todas",
        farmacia: codigoFarmacia,
        estatus: estatus?.value || "",
        fecha_inicio: formatearFecha(fechaInicio),
        fecha_fin: formatearFecha(fechaFin),
      };

      await dispatch(exportarReporteFacturacion(params)).unwrap();
      toast.success("Reporte exportado exitosamente");
    } catch (error) {
      console.error("Error al exportar:", error);
      toast.error(error.message || "Error al exportar el reporte");
    } finally {
      setIsExporting(false);
    }
  }, [
    fechaInicio,
    fechaFin,
    tipo,
    farmacia,
    estatus,
    dispatch,
    isAdmin,
    farmaciaId,
    farmacias,
  ]);

  useEffect(() => {
    dispatch(getFarmacias());
  }, [dispatch]);

  // Efecto separado para preseleccionar la farmacia cuando las farmacias están disponibles
  useEffect(() => {
    // Si el usuario no es admin y tenemos farmacias disponibles, preseleccionar su farmacia
    if (
      !isAdmin &&
      farmaciaId &&
      Array.isArray(farmacias) &&
      farmacias.length > 0
    ) {
      const farmaciaEncontrada = farmacias[0].find(
        (f) => f.codigo_myh === farmaciaId
      );
      if (farmaciaEncontrada) {
        setFarmacia({
          value: farmaciaEncontrada.codigo_myh,
          label: farmaciaEncontrada.nombre,
        });
      }
    }
  }, [isAdmin, farmaciaId, farmacias]);

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <Container fluid>
          {/* Cards de Estadísticas */}
          {facturacion?.estadisticas && (
            <Row className="mb-3">
              <Col xl={3} md={6}>
                <Card className="card-animate">
                  <CardBody>
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1">
                        <p className="text-uppercase fw-medium text-muted mb-0">
                          Total Facturas
                        </p>
                        <h4 className="fs-22 fw-semibold mb-0">
                          {facturacion.estadisticas.total_facturas}
                        </h4>
                      </div>
                      <div className="avatar-sm flex-shrink-0">
                        <span className="avatar-title bg-soft-success rounded fs-3">
                          <i className="ri-file-list-3-line text-success"></i>
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={3} md={6}>
                <Card className="card-animate">
                  <CardBody>
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1">
                        <p className="text-uppercase fw-medium text-muted mb-0">
                          Promedio Montos
                        </p>
                        <h4 className="fs-22 fw-semibold mb-0">
                          Bs.{" "}
                          {facturacion.estadisticas.promedio_monto.toLocaleString(
                            "es-VE",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}
                        </h4>
                      </div>
                      <div className="avatar-sm flex-shrink-0">
                        <span className="avatar-title bg-soft-info rounded fs-3">
                          <i className="ri-money-dollar-circle-line text-info"></i>
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={3} md={6}>
                <Card className="card-animate">
                  <CardBody>
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1">
                        <p className="text-uppercase fw-medium text-muted mb-0">
                          Monto Máximo
                        </p>
                        <h4 className="fs-22 fw-semibold mb-0">
                          Bs.{" "}
                          {facturacion.estadisticas.monto_maximo.toLocaleString(
                            "es-VE",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}
                        </h4>
                      </div>
                      <div className="avatar-sm flex-shrink-0">
                        <span className="avatar-title bg-soft-warning rounded fs-3">
                          <i className="ri-arrow-up-circle-line text-warning"></i>
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={3} md={6}>
                <Card className="card-animate">
                  <CardBody>
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1">
                        <p className="text-uppercase fw-medium text-muted mb-0">
                          Monto Mínimo
                        </p>
                        <h4 className="fs-22 fw-semibold mb-0">
                          Bs.{" "}
                          {facturacion.estadisticas.monto_minimo.toLocaleString(
                            "es-VE",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}
                        </h4>
                      </div>
                      <div className="avatar-sm flex-shrink-0">
                        <span className="avatar-title bg-soft-danger rounded fs-3">
                          <i className="ri-arrow-down-circle-line text-danger"></i>
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="border-0">
                  <div className="d-md-flex align-items-center">
                    <h5 className="card-title mb-3 mb-md-0 flex-grow-1">
                      Reporte de Facturación
                    </h5>
                    <div className="flex-shrink-0">
                      <div className="d-flex gap-1 flex-wrap">
                        <Button
                          color="success"
                          onClick={exportarExcel}
                          disabled={isExporting || !hayFacturas}
                          title={
                            !hayFacturas
                              ? "No hay datos para exportar"
                              : "Exportar a Excel"
                          }
                        >
                          {isExporting ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm me-2"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              Exportando...
                            </>
                          ) : (
                            <>
                              <i className="ri-file-download-line align-bottom me-1"></i>
                              Exportar Excel
                            </>
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0">
                  <Form>
                    <Row className="g-3">
                      <Col xxl={2} sm={6}>
                        <div>
                          <Label htmlFor="tipo" className="form-label">
                            Tipo de Solicitud
                          </Label>
                          <Select
                            value={tipo}
                            onChange={setTipo}
                            options={opcionesTipo}
                            classNamePrefix="select2-selection"
                            placeholder="Seleccione tipo..."
                          />
                        </div>
                      </Col>
                      <Col xxl={3} sm={6}>
                        <div>
                          <Label htmlFor="farmacia" className="form-label">
                            Farmacia
                          </Label>
                          <Select
                            value={farmacia}
                            onChange={setFarmacia}
                            options={opcionesFarmacias}
                            classNamePrefix="select2-selection"
                            placeholder="Seleccione farmacia..."
                            isClearable={isAdmin}
                            isDisabled={!isAdmin}
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.label}
                          />
                        </div>
                      </Col>
                      <Col xxl={2} sm={6}>
                        <div>
                          <Label htmlFor="estatus" className="form-label">
                            Estado
                          </Label>
                          <Select
                            value={estatus}
                            onChange={setEstatus}
                            options={opcionesEstatus}
                            classNamePrefix="select2-selection"
                            placeholder="Seleccione estado..."
                          />
                        </div>
                      </Col>

                      <Col xxl={2} sm={4}>
                        <div>
                          <Label htmlFor="fecha_inicio" className="form-label">
                            Fecha Inicio <span className="text-danger">*</span>
                          </Label>
                          <Flatpickr
                            className={`form-control ${
                              errors.fechaInicio ? "is-invalid" : ""
                            }`}
                            options={{
                              dateFormat: "Y-m-d",
                              altInput: true,
                              altFormat: "F j, Y",
                            }}
                            value={fechaInicio}
                            onChange={([date]) => {
                              setFechaInicio(date);
                              setErrors((prev) => ({
                                ...prev,
                                fechaInicio: false,
                              }));
                            }}
                          />
                          {errors.fechaInicio && (
                            <div className="invalid-feedback">
                              La fecha de inicio es obligatoria
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col xxl={2} sm={4}>
                        <div>
                          <Label htmlFor="fecha_fin" className="form-label">
                            Fecha Fin <span className="text-danger">*</span>
                          </Label>
                          <Flatpickr
                            className={`form-control ${
                              errors.fechaFin ? "is-invalid" : ""
                            }`}
                            options={{
                              dateFormat: "Y-m-d",
                              altInput: true,
                              altFormat: "F j, Y",
                            }}
                            value={fechaFin}
                            onChange={([date]) => {
                              setFechaFin(date);
                              setErrors((prev) => ({
                                ...prev,
                                fechaFin: false,
                              }));
                            }}
                          />
                          {errors.fechaFin && (
                            <div className="invalid-feedback">
                              La fecha fin es obligatoria
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col xxl={1} sm={4}>
                        <div>
                          <Label className="form-label">&nbsp;</Label>
                          <Button
                            type="button"
                            color="primary"
                            className="btn w-100"
                            onClick={generarReporte}
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <>
                                <span
                                  className="spinner-border spinner-border-sm me-2"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                Cargando...
                              </>
                            ) : (
                              <>
                                <i className="ri-equalizer-fill me-1 align-bottom"></i>
                                Filtrar
                              </>
                            )}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
                <CardBody>
                  {isLoading ? (
                    <div className="text-center my-5">
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="visually-hidden">Cargando...</span>
                      </div>
                      <div className="mt-2">Cargando datos...</div>
                    </div>
                  ) : !busquedaRealizada ? (
                    <div className="text-center my-5">
                      <i className="ri-filter-line fs-1 text-muted"></i>
                      <p className="mt-2">
                        Seleccione los filtros y haga clic en "Filtrar" para ver
                        las facturas
                      </p>
                    </div>
                  ) : !hayFacturas ? (
                    <div className="text-center my-5">
                      <i className="ri-file-search-line fs-1 text-muted"></i>
                      <p className="mt-2">
                        No se encontraron facturas con los filtros
                        seleccionados
                      </p>
                    </div>
                  ) : (
                    <>
                      {/* Sección de facturas por tipo */}
                      {facturacion?.facturas_por_tipo && (
                        <div className="mb-4">
                          <h6 className="mb-3">
                            Resumen por Tipo de Solicitud
                          </h6>
                          <Row>
                            {Object.entries(facturacion.facturas_por_tipo).map(
                              ([tipo, datos]) => (
                                <Col key={tipo} md={6} lg={4}>
                                  <Card className="border">
                                    <CardBody>
                                      <h6 className="card-title mb-2">
                                        {tipo}
                                      </h6>
                                      <div className="d-flex justify-content-between">
                                        <span>Cantidad:</span>
                                        <span className="fw-medium">
                                          {datos.cantidad}
                                        </span>
                                      </div>
                                      <div className="d-flex justify-content-between">
                                        <span>Monto Total:</span>
                                        <span className="fw-medium">
                                          Bs.{" "}
                                          {datos.monto_total.toLocaleString(
                                            "es-VE",
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )}
                                        </span>
                                      </div>
                                    </CardBody>
                                  </Card>
                                </Col>
                              )
                            )}
                          </Row>
                        </div>
                      )}

                      {facturacion?.detalles ? (
                        <TableContainer
                          columns={columns}
                          data={facturacion?.detalles || []}
                          isGlobalFilter={true}
                          isAddUserList={false}
                          customPageSize={30}
                          className="custom-header-css"
                          divClass="table-responsive table-card mb-3"
                          tableClass="align-middle table-nowrap"
                          theadClass="table-light text-muted"
                          SearchPlaceholder="Buscar facturas..."
                        />
                      ) : (
                        <div className="text-center my-5">
                          <p>No hay datos disponibles</p>
                          <pre>{JSON.stringify(facturacion, null, 2)}</pre>{" "}
                          {/* Para debug */}
                        </div>
                      )}
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ReporteFacturacion;
