import React, { useState, useEffect } from "react";
import { Col, Row, Input, Label, Form, Spinner, Alert } from "reactstrap";
import {
  createUser,
  resetUsuarioFlag,
  getFarmacias,
} from "../../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { createSelector } from "reselect";
import { Navigate } from "react-router-dom";
import { loginSuccess } from "../../../slices/auth/login/reducer";

const CrearUsuario = () => {
  const dispatch = useDispatch();
  const selectLayoutState = (state) => state;
  const [perfil, setPerfil] = useState("");
  const usuarioPageData = createSelector(selectLayoutState, (state) => ({
    usuario: state.Usuarios.usuario,
    error: state.Usuarios.error,
    loading: state.Usuarios.loading,
    errorMsg: state.Usuarios.errorMsg,
    colorMsg: state.Usuarios.colorMsg,
    exito: state.Usuarios.exito,
  }));

  const { usuario, exito, error, loading, errorMsg, colorMsg } =
    useSelector(usuarioPageData);
  const farmaciasPageData = createSelector(selectLayoutState, (state) => ({
    farmacias: state.Farmacias.farmacias,
  }));
  const { farmacias } = useSelector(farmaciasPageData);

  useEffect(() => {
    if (errorMsg) {
      setTimeout(() => {
        dispatch(resetUsuarioFlag());
        setLoadingx(false);
      }, 2000);
    }
  }, [dispatch, errorMsg]);

  useEffect(() => {
    if (exito) {
      setTimeout(() => {
        setRedireccionar(true);
      }, 3000);
    }
  }, [dispatch, exito]);

  useEffect(() => {
    if (!farmacias || farmacias.length === 0) {
      dispatch(getFarmacias());
    } else {
      setFarmaciasLoaded(true);
    }
  }, [dispatch, farmacias]);

  useEffect(() => {
    if (perfil !== "Farmabien" && perfil !== "Delivery") {
      setFarmacia(null);
    }
  }, [perfil]);

  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [correo, setCorreo] = useState("");
  const [telefono, setTelefono] = useState("");
  const [usuarioRol, setUsuarioRol] = useState("");
  const [activo, setActivo] = useState(false);
  const [redireccionar, setRedireccionar] = useState(false);
  const [loadingx, setLoadingx] = useState(false);

  const [contrasena, setContrasena] = useState("");
  const [farmacia, setFarmacia] = useState(null);
  const [farmaciasLoaded, setFarmaciasLoaded] = useState(false);
  const opcionesPerfil = ["Administrador", "MyHealth", "Farmabien", "Delivery"];
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(null);

  // Custom password validation function
  const validatePassword = (password) => {
    const errors = [];

    if (password.length < 8) {
      errors.push("La contraseña debe tener al menos 8 caracteres.");
    }

    if (!/[a-z]/.test(password)) {
      errors.push("La contraseña debe contener al menos una letra minúscula.");
    }

    if (!/[A-Z]/.test(password)) {
      errors.push("La contraseña debe contener al menos una letra mayúscula.");
    }

    if (!/\d/.test(password)) {
      errors.push("La contraseña debe contener al menos un número.");
    }

    if (!/[!@#$%^&*()_+\-=\]{};':"\\|,.<>?]/.test(password)) {
      errors.push("La contraseña debe contener al menos un símbolo especial.");
    }

    return errors.length > 0 ? errors : null;
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setContrasena(newPassword);
    const validationErrors = validatePassword(newPassword);
    setPasswordError(validationErrors);
  };

  // Add this function near your other handlers
  const handleSubmit = (event) => {
    event.preventDefault();
    
    // Validate required fields
    if (!nombre || !apellido || !correo || !telefono || !usuarioRol || 
        !contrasena || !perfil || (["Farmabien", "Delivery"].includes(perfil) && !farmacia)) {
      return;
    }
    
    // Don't submit if password validation fails
    if (passwordError) {
      return;
    }
     
    const userData = {
      nombre,
      apellido,
      correo,
      telefono,
      usuario: usuarioRol,
      contrasena,
      perfil,
      activo,
      farmacia_id: (perfil === "Farmabien" || perfil === "Delivery") ? farmacia : null
    };
    
    dispatch(createUser(userData));
    setLoadingx(true);
  };

  return (
    <>
      {redireccionar && <Navigate to="/usuarios" replace />}

      {!farmaciasLoaded ? (
        <div className="text-center">
          <Spinner size="sm" color="primary" />
          Cargando farmacias...
        </div>
      ) : (
        <React.Fragment>
          {errorMsg && errorMsg ? (
            <Alert color={colorMsg}> {error} </Alert>
          ) : null}

          <h5 className="fw-semibold text-uppercase mb-3">Crear Cliente</h5>

          <Form onSubmit={handleSubmit}>
            <Row lg={12} className="p-3">
              <Col xxl={4} md={4}>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="nombre">
                    Nombre <span className="text-danger">*</span>
                  </Label>

                  <Input
                    type="text"
                    className="form-control"
                    id="nombre"
                    name="nombre"
                    required
                    minLength={3}
                    maxLength={50}
                    value={nombre}
                    onChange={(e) => setNombre(e.target.value)}
                  />
                </div>
              </Col>
              <Col xxl={4} md={4}>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="apellido">
                    Apellido <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="apellido"
                    name="apellido"
                    required
                    minLength={3}
                    maxLength={50}
                    value={apellido}
                    onChange={(e) => setApellido(e.target.value)}
                  />
                </div>
              </Col>
              <Col xxl={4} md={4}>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="correo">
                    Correo Electrónico <span className="text-danger">*</span>
                  </Label>

                  <Input
                    type="email"
                    className="form-control"
                    id="correo"
                    name="correo"
                    required
                    value={correo}
                    onChange={(e) => setCorreo(e.target.value)}
                  />
                </div>
              </Col>
              <Col xxl={4} md={4}>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="telefono">
                    Teléfono <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="number"
                    className="form-control"
                    id="telefono"
                    name="telefono"
                    required
                    minLength={13}
                    maxLength={13}
                    value={telefono}
                    onChange={(e) => setTelefono(e.target.value)}
                  />
                </div>
              </Col>
              <Col xxl={4} md={4}>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="usuario">
                    Usuario <span className="text-danger">*</span>
                  </Label>

                  <Input
                    type="text"
                    className="form-control"
                    id="usuario"
                    name="usuario"
                    minLength={5}
                    required
                    maxLength={50}
                    value={usuarioRol}
                    onChange={(e) => setUsuarioRol(e.target.value)}
                  />
                </div>
              </Col>

              <Col xxl={4} md={4}>
              <div className="mb-3 position-relative">
              <Label className="form-label" htmlFor="contrasena">
                Contraseña <span className="text-danger">*</span>
              </Label>


                <div className="position-relative">
                  <Input
                    type={showPassword ? "text" : "password"}
                    className={`form-control pr-5 ${
                      passwordError ? "is-invalid" : ""
                    }`}
                    id="contrasena"
                    name="contrasena"
                    required
                    minLength={8}
                    maxLength={12}
                    value={contrasena}
                    onChange={handlePasswordChange}
                  />
                  
                  <button
                    type="button"
                    className="btn btn-link position-absolute end-0 top-0 text-decoration-none"
                    onClick={() => setShowPassword(!showPassword)}
                    style={{ height: '100%', zIndex: 10 }}
                  >
                    <i className={`ri-eye${showPassword ? '-off' : ''}-line`}></i>
                  </button>
                
                  {passwordError && (
                    <div className="invalid-feedback">
                      <ul>
                        {passwordError.map((error, index) => (
                          <li key={index}>{error}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              </Col>

              <Col xxl={4} md={4}>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="perfil">
                    Perfil <span className="text-danger">*</span>
                  </Label>

                  <Select
                    onChange={(selectedOption) => {
                      setPerfil(selectedOption.value);
                    }}
                    options={opcionesPerfil.map((item) => ({
                      value: item,
                      label: item,
                    }))}
                    required
                    id="perfil"
                    className="js-example-basic-single mb-0"
                    name="perfil"
                  />
                </div>
              </Col>

              <Col xxl={4} md={4}>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="activo">
                    Activo <span className="text-danger">*</span>
                  </Label>

                  <Select
                    onChange={(selectedOption) => {
                      setActivo(selectedOption.value);
                    }}
                    options={[
                      { value: true, label: "Si" },
                      { value: false, label: "No" },
                    ]}
                    required
                    id="activo"
                    className="js-example-basic-single mb-0"
                    name="activo"
                  />
                </div>
              </Col>

              <Col xxl={4} md={4}>
                {(perfil === "Farmabien" || perfil === "Delivery") && (
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="activo">
                      Farmacia <span className="text-danger">*</span>
                    </Label>

                    {!farmaciasLoaded ? (
                      <Spinner size="sm" color="primary" />
                    ) : farmacias.length === 0 ? (
                      <p>No hay farmacias disponibles</p>
                    ) : (
                      <Select
                        onChange={(selectedOption) => {
                          setFarmacia(selectedOption.value);
                        }}
                        options={farmacias[0]?.map((farmacia) => ({
                          value: farmacia.id,
                          label: farmacia.nombre,
                        }))}
                        required
                        id="farmacia"
                        className="js-example-basic-single mb-0"
                        name="farmacia"
                      />
                    )}
                  </div>
                )}
              </Col>
            </Row>

            <Row className="align-items-end">
              <Col lg={12}>
                <div className="mb-3 text-end">
                  <button
                    type="submit"
                    className="btn btn-success btn-label right ms-auto"
                    disabled={loadingx || passwordError}
                  >
                    {loadingx ? (
                      <Spinner size="sm" className="me-2">
                        {" "}
                        Loading...{" "}
                      </Spinner>
                    ) : null}
                    <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                    Crear Usuario
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </React.Fragment>
      )}
    </>
  );
};

export default CrearUsuario;
