import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Row,
  Accordion,
  AccordionItem,
  Collapse,
} from "reactstrap";

import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { Autocomplete } from "@react-google-maps/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  editSolicitud,
  getFarmacias,
  createMessage,
} from "../../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import MapComponent from "./MapComponent";

const InformacionDelivery = ({ solicitud, farmacias }) => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchBox, setSearchBox] = useState(null);
  const [ubicacion, setUbicacion] = useState(null);
  const [map, setMap] = useState(null);
  const [geocoder, setGeocoder] = useState(null);
  const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_APIKEYGOOGLEMAPS;
  const [nuevaDireccion, setNuevaDireccion] = useState({
    direccion: solicitud.direccion || "",
    coordenadas: solicitud.coordenadas || null,
  });
  const mapContainerStyle = {
    width: "100%",
    height: "400px",
  };
  const [center, setCenter] = useState({
    lat: 10.4806, // Coordenadas centrales de Venezuela
    lng: -66.9036,
  });
  const [zoom, setZoom] = useState(6); // Añadimos estado para el zoom
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  useEffect(() => {
    try {
      if (farmacias && farmacias.length === 0) {
        setUbicacion(null);
      } else {
        const farmacia = farmacias.find(
          (farmacia) => farmacia.codigo_myh === solicitud.codigoProveedor
        );

        if (farmacia) {
          setUbicacion(farmacia.direccion.split(","));
        } else {
          setUbicacion(null);
        }
      }
    } catch (error) {
      console.error("Error en useEffect:", error);
    }
  }, [solicitud, farmacias]);

  useEffect(() => {
    if (solicitud.coordenadas) {
      setCenter({
        lat: solicitud.coordenadas[0],
        lng: solicitud.coordenadas[1],
      });
      setZoom(15);
      setNuevaDireccion({
        direccion: solicitud.direccion,
        coordenadas: solicitud.coordenadas,
      });
    }
  }, [solicitud]);

  const toggle = () => setModal(!modal);

  const handleDireccionChange = (e) => {
    const { name, value } = e.target;
    setNuevaDireccion((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit2 = (direccion, coordenadas) => {
    try {
      const now = new Date();
      const creado = new Date(
        now.getTime() - now.getTimezoneOffset() * 60000
      ).toISOString();

      const comentario = {
        tipo: "Enviado",
        mensaje: "Actualización de la Dirección de Entrega: " + direccion,
        estatus: true,
        adjunto: null,
        creado: creado,
      };

      const updateSolicitud = {
        ...solicitud,
        direccion: direccion,
        coordenadas: coordenadas,
        observaciones: "Dirección de delivery actualizada",
        porcentaje_avance: 50,
        comentarios: solicitud.comentarios
          ? [
              ...(Array.isArray(solicitud.comentarios)
                ? solicitud.comentarios
                : []),
              comentario,
            ]
          : [comentario],
      };

      const message = {
        IdentificadorEvento: solicitud.identificador,
        Tipo: "Mensaje",
        Mensaje: "Actualización de la Dirección de Entrega: " + direccion,
        Adjunto: "",
        CodigoProveedor: solicitud.codigoProveedor,
      };

      dispatch(createMessage(message));
      dispatch(editSolicitud(updateSolicitud));

      toast.success("Dirección actualizada exitosamente", {});

      toggle();
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error al actualizar la dirección: " + error.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const now = new Date();
      const creado = new Date(
        now.getTime() - now.getTimezoneOffset() * 60000
      ).toISOString();

      const comentario = {
        tipo: "Enviado",
        mensaje:
          "Actualización de la Dirección de Entrega: " +
          nuevaDireccion.direccion,
        estatus: true,
        adjunto: null,
        creado: creado,
      };

      const updateSolicitud = {
        ...solicitud,
        direccion: nuevaDireccion.direccion,
        coordenadas: nuevaDireccion.coordenadas,
        observaciones: "Dirección de delivery actualizada",
        porcentaje_avance: 50,
        comentarios: solicitud.comentarios
          ? [
              ...(Array.isArray(solicitud.comentarios)
                ? solicitud.comentarios
                : []),
              comentario,
            ]
          : [comentario],
      };

      const message = {
        IdentificadorEvento: solicitud.identificador,
        Tipo: "Mensaje",
        Mensaje:
          "Actualización de la Dirección de Entrega: " +
          nuevaDireccion.direccion,
        Adjunto: "",
        CodigoProveedor: solicitud.codigoProveedor,
      };

      dispatch(createMessage(message));
      dispatch(editSolicitud(updateSolicitud));

      toast.success("Dirección actualizada exitosamente", {});

      toggle();
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error al actualizar la dirección: " + error.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleMapClick = async (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    // Verificar si la ubicación está dentro de Venezuela
    if (lat >= 0.7 && lat <= 12.5 && lng >= -73.4 && lng <= -59.8) {
      try {
        const response = await geocoder.geocode({
          location: { lat, lng },
        });

        if (response.results[0]) {
          setNuevaDireccion({
            direccion: response.results[0].formatted_address,
            coordenadas: [lat, lng],
          });

          setCenter({
            lat: lat,
            lng: lng,
          });

          setZoom(15);
        } else {
          toast.error("No se pudo obtener la dirección para esta ubicación", {
            position: "top-right",
            autoClose: 3000,
          });
        }
      } catch (error) {
        toast.error("Error al obtener la dirección", {
          position: "top-right",
          autoClose: 3000,
        });
      }
    } else {
      toast.error("Por favor, seleccione una ubicación dentro de Venezuela", {
        position: "top-right",
        autoClose: 3000,
      });
    }
  };

  const [borderCol1, setborderCol1] = useState(true);
  const [borderCol2, setborderCol2] = useState(false);

  const t_borderCol1 = () => {
    setborderCol1(!borderCol1);
    setborderCol2(false);
  };

  const t_borderCol2 = () => {
    setborderCol2(!borderCol2);
    setborderCol1(false);
  };

  // Función para determinar si se puede editar la dirección
  const canEditAddress = () => {
    return (
      solicitud.estatus !== "Cancelada" &&
      solicitud.estatus !== "Entregado" &&
      solicitud.estatus !== "Por Renovar"
    );
  };

  return (
    <React.Fragment>
      <LoadScript
        googleMapsApiKey={GOOGLE_MAPS_API_KEY}
        libraries={["places"]}
        onLoad={() => setIsScriptLoaded(true)}
      >
        <Row>
          <Col lg={6}>
            <Card className="delivery-info-card">
              <CardHeader>
                <div className="d-flex align-items-center">
                  <i className="ri-truck-line me-1 text-primary me-2 fs-20"></i>
                  <h5 className="card-title mb-0">
                    Información para el Delivery
                  </h5>
                </div>
              </CardHeader>

              <CardBody>
                <div className="table-responsive table-card">
                  <Table className="table-borderless align-middle mb-0">
                    <tbody>
                      <tr>
                        <td className="fw-medium" width="40%">
                          <i className="ri-user-line text-muted me-2"></i>Recibe
                        </td>
                        <td id="d-date" className="text-break">
                          {solicitud.recibe || "-"}
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-medium">
                          <i className="ri-phone-line text-muted me-2"></i>
                          Teléfono
                        </td>
                        <td id="d-date">
                          {solicitud.telefono ? (
                            <a
                              href={`tel:${solicitud.telefono}`}
                              className="text-primary"
                            >
                              {solicitud.telefono}
                            </a>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-medium">
                          <i className="ri-map-pin-line text-muted me-2"></i>
                          Estado
                        </td>
                        <td id="d-date">{ubicacion || "-"}</td>
                      </tr>
                      <tr>
                        <td className="fw-medium">
                          <i className="ri-road-map-line text-muted me-2"></i>
                          Dirección
                        </td>
                        <td id="d-date" className="text-break">
                          {solicitud.direccion || "-"}
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-medium">
                          <i className="ri-map-2-line text-muted me-2"></i>
                          Coordenadas
                        </td>
                        <td id="d-date">
                          {solicitud.coordenadas ? (
                            <a
                              href={`https://www.google.com/maps/search/?api=1&query=${solicitud.coordenadas[0]},${solicitud.coordenadas[1]}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="btn btn-sm btn-soft-primary"
                            >
                              <i className="ri-map-pin-line me-1"></i>
                              {`${solicitud.coordenadas[0].toFixed(
                                6
                              )}, ${solicitud.coordenadas[1].toFixed(6)}`}
                            </a>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-medium">
                          <i className="ri-file-text-line text-muted me-2"></i>
                          Observaciones
                        </td>
                        <td id="d-date" className="text-break">
                          {solicitud.observaciones || "-"}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>

                {solicitud.coordenadas && isScriptLoaded ? (
                  <div className="map-preview mt-4">
                    <h6 className="mb-2">Vista previa de ubicación</h6>
                    <div
                      className="border rounded overflow-hidden"
                      style={{ height: "200px" }}
                    >
                      <GoogleMap
                        mapContainerStyle={{ width: "100%", height: "100%" }}
                        center={{
                          lat: solicitud.coordenadas[0],
                          lng: solicitud.coordenadas[1],
                        }}
                        zoom={15}
                      >
                        <Marker
                          position={{
                            lat: solicitud.coordenadas[0],
                            lng: solicitud.coordenadas[1],
                          }}
                        />
                      </GoogleMap>
                    </div>
                  </div>
                ) : (
                  <div className="mt-4">
                    <div className="alert alert-warning">
                      <div className="d-flex align-items-center">
                        <i className="ri-map-pin-line fs-20 me-2"></i>
                        <div>
                          <h6 className="mb-1">No hay ubicación registrada</h6>
                          <p className="mb-0">
                            Debe usar <strong>"Actualizar Dirección de Entrega"</strong> para poder continuar.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
          <Col lg={6}>
            <Card className="update-address-card">
              <CardHeader>
                <div className="d-flex align-items-center">
                  <i className="ri-truck-line me-1 text-primary me-2 fs-20"></i>
                  <h5 className="card-title mb-0">Actualizar Dirección de Entrega</h5>
                </div>
              </CardHeader>

              <CardBody>
                <div className="address-options">
                  <div className="d-flex mb-3">
                    <button
                      className={`btn ${
                        borderCol1 ? "btn-primary" : "btn-light"
                      } me-2 flex-grow-1`}
                      onClick={t_borderCol1}
                      type="button"
                    >
                      <i className="ri-map-pin-line me-1"></i> Usar mapa
                    </button>
                    <button
                      className={`btn ${
                        borderCol2 ? "btn-primary" : "btn-light"
                      } flex-grow-1`}
                      onClick={t_borderCol2}
                      type="button"
                    >
                      <i className="ri-compass-3-line me-1"></i> Usar coordenadas
                    </button>
                  </div>

                  <div className="address-content p-3 border rounded">
                    {borderCol1 && (
                      <div className="map-option">
                        <p className="text-muted mb-3">
                          Seleccione una ubicación en el mapa para actualizar la
                          dirección de entrega.
                        </p>
                        {!canEditAddress() ? (
                          <div className="alert alert-info">
                            <i className="ri-information-line me-1"></i>
                            No se puede modificar la dirección en el estado
                            actual.
                          </div>
                        ) : (
                          <Button
                            color="primary"
                            className="w-100"
                            onClick={toggle}
                          >
                            <i className="ri-map-pin-add-line me-1"></i> Abrir
                            mapa
                          </Button>
                        )}
                      </div>
                    )}

                    {borderCol2 && (
                      <div className="coordinates-option">
                        {!canEditAddress() ? (
                          <div className="alert alert-info">
                            <i className="ri-information-line me-1"></i>
                            No se puede modificar la dirección en el estado
                            actual.
                          </div>
                        ) : (
                          <MapComponent handleSubmit2={handleSubmit2} />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Modal
          isOpen={modal}
          toggle={toggle}
          size="xl"
          className="address-update-modal"
        >
          <Form onSubmit={handleSubmit}>
            <ModalHeader toggle={toggle} className="bg-light">
              <i className="ri-map-pin-line me-2 text-primary"></i>
              Editar Dirección de Entrega
            </ModalHeader>
            <ModalBody>
              <div className="alert alert-info mb-3">
                <i className="ri-information-line me-2"></i>
                Seleccione una ubicación en el mapa o busque una dirección para
                actualizar la información de entrega.
              </div>

              <Row>
                <Col md={12} className="mb-3">
                  {isScriptLoaded && (
                    <>
                      <FormGroup className="mb-3 search-box">
                        <Label>
                          <i className="ri-search-line me-1"></i>
                          Buscar dirección
                        </Label>
                        <Autocomplete
                          onLoad={(autocomplete) => {
                            setSearchBox(autocomplete);
                            autocomplete.setComponentRestrictions({
                              country: ["ve"],
                            });
                            autocomplete.setTypes(["address"]);
                          }}
                          onPlaceChanged={() => {
                            const place = searchBox.getPlace();
                            if (place.geometry) {
                              const lat = place.geometry.location.lat();
                              const lng = place.geometry.location.lng();

                              // Verificar si la ubicación está dentro de Venezuela
                              if (
                                lat >= 0.7 &&
                                lat <= 12.5 &&
                                lng >= -73.4 &&
                                lng <= -59.8
                              ) {
                                setNuevaDireccion({
                                  direccion: place.formatted_address,
                                  coordenadas: [lat, lng],
                                });

                                setCenter({
                                  lat: lat,
                                  lng: lng,
                                });

                                setZoom(15);
                              } else {
                                toast.error(
                                  "Por favor, seleccione una ubicación dentro de Venezuela",
                                  {
                                    position: "top-right",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                  }
                                );
                              }
                            }
                          }}
                          options={{
                            componentRestrictions: { country: "ve" },
                            types: ["geocode", "establishment"],
                            fields: [
                              "formatted_address",
                              "geometry",
                              "name",
                              "place_id",
                            ],
                            strictBounds: true,
                            bounds: {
                              north: 12.5,
                              south: 0.7,
                              east: -59.8,
                              west: -73.4,
                            },
                          }}
                        >
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Buscar dirección en Venezuela..."
                          />
                        </Autocomplete>
                        <small className="text-muted">
                          Escriba una dirección y seleccione una opción del menú
                          desplegable
                        </small>
                      </FormGroup>

                      <div className="map-container border rounded overflow-hidden mb-3">
                        <GoogleMap
                          mapContainerStyle={mapContainerStyle}
                          center={center}
                          zoom={zoom}
                          onClick={handleMapClick}
                          onLoad={(map) => {
                            setMap(map);
                            setGeocoder(new window.google.maps.Geocoder());
                          }}
                        >
                          {nuevaDireccion.coordenadas && (
                            <Marker
                              position={{
                                lat: nuevaDireccion.coordenadas[0],
                                lng: nuevaDireccion.coordenadas[1],
                              }}
                            />
                          )}
                        </GoogleMap>
                      </div>
                    </>
                  )}

                  <div className="address-preview p-3 border rounded mb-3">
                    <h6 className="mb-2">Dirección seleccionada</h6>
                    <p className="mb-1">
                      <strong>Dirección:</strong>{" "}
                      {nuevaDireccion.direccion || "No seleccionada"}
                    </p>
                    <p className="mb-0">
                      <strong>Coordenadas:</strong>{" "}
                      {nuevaDireccion.coordenadas
                        ? `${nuevaDireccion.coordenadas[0].toFixed(
                            6
                          )}, ${nuevaDireccion.coordenadas[1].toFixed(6)}`
                        : "No seleccionadas"}
                    </p>
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="light" onClick={toggle}>
                Cancelar
              </Button>{" "}
              <Button
                color="primary"
                type="submit"
                disabled={
                  isSubmitting ||
                  !nuevaDireccion.direccion ||
                  !nuevaDireccion.coordenadas
                }
              >
                {isSubmitting ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Actualizando...
                  </>
                ) : (
                  <>Guardar cambios</>
                )}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </LoadScript>
    </React.Fragment>
  );
};

export default InformacionDelivery;
