import React from "react";
import { Col } from "reactstrap";
import Solicitud from "./Solicitud";
import Comentarios from "./Comentarios";
const TicketDetails = ({ solicitud }) => {
  return (
    <React.Fragment>
      <Col xxl={3}>
        <Solicitud solicitud={solicitud} />
        <Comentarios solicitud={solicitud} />
      </Col>
    </React.Fragment>
  );
};

export default TicketDetails;
