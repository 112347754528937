import axios from "axios";
import SecureStorage from "./secure_storage";
import AuthNotifications from "./auth_notifications";
import { addCsrfHeader } from "./csrf_helper";

// Definir la URL base para las solicitudes API
const API_BASE_URL = process.env.REACT_APP_API_URL;

// Definir la clase APIClient
export class APIClient {
  constructor() {
    this.client = axios.create({
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type": "application/json",
      },
    });

    // Configurar interceptor para añadir token de autenticación a todas las solicitudes
    this.client.interceptors.request.use(
      (config) => {
        const token = SecureStorage.getAccessToken();
        if (token) {
          config.headers["Authorization"] = `Bearer ${token}`;
/*           console.log("Añadiendo token de autorización a la solicitud:", {
            url: config.url,
            method: config.method,
            hasToken: !!token
          }); */
        }
        
        // Añadir token CSRF para métodos que modifican datos
        if (['post', 'put', 'delete', 'patch'].includes(config.method)) {
          config.headers = addCsrfHeader(config.headers);
        }
        
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    // Configurar interceptor para manejar errores de autenticación
    this.client.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest = error.config;
        
        // Si es un error 401 (no autorizado) y no es una solicitud de refresh token
        if (error.response && error.response.status === 401 && 
            !originalRequest._retry && 
            !originalRequest.url.includes('/auth/refresh') && 
            !originalRequest.url.includes('/auth/token')) {
          
          //console.log("Interceptando error 401, intentando refresh token...");
          originalRequest._retry = true;
          
          try {
            // Intentar refrescar el token
            const refreshResult = await refreshAuthToken();
            
            if (refreshResult.success) {
              // Si el refresh fue exitoso, actualizar el token en la solicitud original
              const token = SecureStorage.getAccessToken();
              originalRequest.headers["Authorization"] = `Bearer ${token}`;
              
              //console.log("Token refrescado exitosamente, reintentando solicitud original");
              
              // Reintentar la solicitud original con el nuevo token
              return this.client(originalRequest);
            } else {
              // Si el refresh falló, redirigir al login
              console.error("Refresh token falló, redirigiendo a login:", refreshResult.message);
              
              // Notificar al usuario
              AuthNotifications.notify(
                AuthNotifications.EVENTS.SESSION_EXPIRED,
                AuthNotifications.TYPES.ERROR,
                "Tu sesión ha expirado. Por favor, inicia sesión nuevamente."
              );
              
              // Limpiar tokens
              SecureStorage.clearAuthTokens();
              
              // Redirigir a login después de un breve retraso
              setTimeout(() => {
                window.location.href = "/login";
              }, 1000);
              
              return Promise.reject(new Error("Sesión expirada"));
            }
          } catch (refreshError) {
            console.error("Error al intentar refrescar el token:", refreshError);
            return Promise.reject(refreshError);
          }
        }
        
        // Manejar errores CSRF (403 Forbidden)
        if (error.response && error.response.status === 403 && 
            error.response.data && error.response.data.csrf_error) {
          console.error("Error CSRF detectado, regenerando token...");
          
          // Notificar al usuario
          AuthNotifications.notify(
            AuthNotifications.EVENTS.SECURITY_ERROR,
            AuthNotifications.TYPES.ERROR,
            "Error de seguridad. Por favor, recarga la página e intenta nuevamente."
          );
          
          return Promise.reject(new Error("Error de seguridad CSRF"));
        }
        
        return Promise.reject(error);
      }
    );
  }

  /**
   * Método para realizar peticiones GET
   * @param {string} url - URL del endpoint
   * @param {Object} params - Parámetros de la petición
   * @returns {Promise} - Promesa con la respuesta
   */
  get = async (url, params) => {
    try {
      return await this.client.get(url, { params });
    } catch (error) {
      return this.handleError(error);
    }
  };

  /**
   * Método para realizar peticiones POST
   * @param {string} url - URL del endpoint
   * @param {Object} data - Datos a enviar
   * @param {Object} config - Configuración adicional
   * @returns {Promise} - Promesa con la respuesta
   */
  post = async (url, data, config = {}) => {
    try {
      return await this.client.post(url, data, config);
    } catch (error) {
      return this.handleError(error);
    }
  };

  /**
   * Método para realizar peticiones PUT
   * @param {string} url - URL del endpoint
   * @param {Object} data - Datos a enviar
   * @param {Object} config - Configuración adicional
   * @returns {Promise} - Promesa con la respuesta
   */
  put = async (url, data, config = {}) => {
    try {
      return await this.client.put(url, data, config);
    } catch (error) {
      return this.handleError(error);
    }
  };

  /**
   * Método para realizar peticiones DELETE
   * @param {string} url - URL del endpoint
   * @param {Object} config - Configuración adicional
   * @returns {Promise} - Promesa con la respuesta
   */
  delete = async (url, config = {}) => {
    try {
      return await this.client.delete(url, config);
    } catch (error) {
      return this.handleError(error);
    }
  };


/**
 * Método para realizar peticiones GET y obtener un Blob
 * @param {string} url - URL del endpoint
 * @param {Object} config - Configuración adicional
 * @returns {Promise<Blob>} - Promesa con el Blob de la respuesta
 */
getBlob = async (url, config = {}) => {
  try {
      const response = await this.client.get(url, {
          ...config,
          responseType: 'blob',
          headers: {
              Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              ...config.headers
          }
      });

      // Validación mejorada
      if (!response.data || !(response.data instanceof Blob)) {
          throw new Error('Respuesta no contiene un Blob válido');
      }

      return response.data;
      
  } catch (error) {
      console.error('[API getBlob Error]', error);
      return this.handleError(error);
  }
};

  /**
   * Método para manejar errores de las peticiones
   * @param {Object} error - Error de la petición
   * @returns {Promise} - Promesa rechazada con el error
   */
  handleError = (error) => {
    // Extraer mensaje de error para evitar pasar el objeto completo
    let errorMessage = "Error desconocido en la petición";
    
    if (error.response) {
      // Error de respuesta del servidor
      errorMessage = `Error ${error.response.status}: ${error.response.statusText || 'Error en la respuesta del servidor'}`;
    } else if (error.request) {
      // Error de solicitud (no se recibió respuesta)
      errorMessage = "No se recibió respuesta del servidor";
    } else if (error.message) {
      // Error en la configuración de la solicitud
      errorMessage = error.message;
    }
    
    const simplifiedError = new Error(errorMessage);
    simplifiedError.status = error.response ? error.response.status : null;
    simplifiedError.originalError = error;
    
    return Promise.reject(simplifiedError);
  };
}

// Crear instancia de la API
const api = new APIClient();

// Exportar métodos para uso directo
export const { get, post, put, delete: del } = api;

/**
 * Método para establecer el token de autorización en los headers
 * @param {string} token - Token de autorización
 */
export const setAuthorization = (token) => {
  axios.defaults.headers.common["Authorization"] = token ? `Bearer ${token}` : "";
};

/**
 * Método para refrescar el token de autorización
 * @returns {Promise} - Promesa con el resultado del refresh
 */
export const refreshAuthToken = async () => {
  try {
    const refreshToken = SecureStorage.getRefreshToken();
    
    if (!refreshToken) {
      AuthNotifications.notify(
        AuthNotifications.EVENTS.REFRESH_TOKEN_FAILED,
        AuthNotifications.TYPES.ERROR,
        "No hay refresh token disponible"
      );
      return { success: false, message: "No hay refresh token disponible" };
    }
    
    //console.log("Intentando refrescar token con refresh_token");
    
    // Crear objeto JSON para enviar el refresh token
    const jsonData = {
      refresh_token: refreshToken
    };
    
    // Configuración específica para JSON
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    
    try {
      // Intentar hacer la petición de refresh token
      const response = await axios.post(`${API_BASE_URL}/auth/refresh`, jsonData, config);
      
      /* console.log("Respuesta de refresh token recibida:", {
        status: response.status,
        hasAccessToken: !!response.data?.access_token
      }); */
      
      if (response.data && response.data.access_token) {
        // Guardar el nuevo token
        SecureStorage.setAccessToken(response.data.access_token);
        
        // Si también devuelve un nuevo refresh token, guardarlo
        if (response.data.refresh_token) {
          SecureStorage.setRefreshToken(response.data.refresh_token);
        }
        
        // Calcular y guardar la expiración
        if (response.data.expires_in) {
          const expiryTime = new Date().getTime() + response.data.expires_in * 1000;
          SecureStorage.setTokenExpiry(expiryTime);
        }
        
        AuthNotifications.notify(
          AuthNotifications.EVENTS.REFRESH_TOKEN_SUCCESS,
          AuthNotifications.TYPES.SUCCESS,
          "Token refrescado exitosamente"
        );
        
        return { success: true };
      } else {
        throw new Error("Respuesta de refresh token inválida");
      }
    } catch (error) {
      console.error("Error en refresh token:", error);
      
      // Manejar errores específicos
      if (error.response) {
        const status = error.response.status;
        
        if (status === 404) {
          AuthNotifications.notify(
            AuthNotifications.EVENTS.REFRESH_TOKEN_FAILED,
            AuthNotifications.TYPES.ERROR,
            "El endpoint de refresh token no está implementado en el servidor"
          );
          
          return { 
            success: false, 
            message: "El endpoint de refresh token no está implementado en el servidor (404 Not Found)",
            suggestion: "Verifica que el endpoint /auth/refresh esté correctamente configurado en el backend"
          };
        } else if (status === 401) {
          AuthNotifications.notify(
            AuthNotifications.EVENTS.REFRESH_TOKEN_FAILED,
            AuthNotifications.TYPES.ERROR,
            "Refresh token inválido o expirado"
          );
          
          return { 
            success: false, 
            message: "Refresh token inválido o expirado (401 Unauthorized)",
            suggestion: "Inicia sesión nuevamente para obtener un nuevo token"
          };
        }
        
        console.error("Detalles del error de respuesta:", {
          status: error.response.status,
          statusText: error.response.statusText,
          data: error.response.data
        });
      } else if (error.request) {
        console.error("No se recibió respuesta del servidor:", error.request);
      }
      
      // Para otros errores
      AuthNotifications.notify(
        AuthNotifications.EVENTS.REFRESH_TOKEN_FAILED,
        AuthNotifications.TYPES.ERROR,
        `Error al refrescar token: ${error.message}`
      );
      
      return { 
        success: false, 
        message: `Error al refrescar token: ${error.message}` 
      };
    }
  } catch (error) {
    console.error("Error inesperado en refresh token:", error);
    
    // Capturar cualquier error inesperado
    const errorMessage = error.message || "Error desconocido al refrescar token";
    
    AuthNotifications.notify(
      AuthNotifications.EVENTS.REFRESH_TOKEN_FAILED,
      AuthNotifications.TYPES.ERROR,
      errorMessage
    );
    
    return { 
      success: false, 
      message: errorMessage
    };
  }
};


// Obtener notificaciones no leídas
export const getNotificacionesNoLeidas = () => {
  return get(`${API_BASE_URL}/solicitud/notificaciones/no-leidas`);
};

// Marcar notificación como leída
export const marcarNotificacionLeida = (notificacionId) => {
  return put(`${API_BASE_URL}/solicitud/notificaciones/${notificacionId}/marcar-leida`);
};

// Marcar todas las notificaciones como leídas
export const marcarTodasNotificacionesLeidas = () => {
  return put(`${API_BASE_URL}/solicitud/notificaciones/marcar-todas-leidas`);
};

// Añadir esta función al archivo api_helper.js
export const getTasaDolar = () => {
  return get(`${API_BASE_URL}/tasadolar/tasa-dolar`);
};

export default api;
