import {
  getFarmacias as gets,
  getFarmacia as get,
  deleteFarmacia as eliminar,
  editFarmacia as editar,
  createFarmacia as crear,
} from "../../../helpers/backend_helper";
import {
  apiError,
  farmaciasSuccess,
  farmaciaSuccess,
  farmaciaDeleteSuccess,
  reset_farmacia_flag,
} from "./reducer";

/**
 * Asynchronous thunk action to fetch farmacias data.
 *
 * This function dispatches either a success action with the fetched data
 * or an error action if the request fails.
 *
 * @returns {Function} A thunk function that accepts `dispatch` as an argument.
 */
export const getFarmacias = () => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = gets();
    }
    var data = await response;
    if (data) {
      // Extract only the serializable parts of the response
      const serializableData = {
        data: data.data,
        message: data.message,
        status: data.status,
      };

      dispatch(farmaciasSuccess(serializableData));
    }
  } catch (error) {
    dispatch(apiError(error.message));
  }
};

/**
 * Deletes a farmacia by its ID.
 *
 * This thunk action creator dispatches the appropriate actions based on the
 * success or failure of the delete operation. It uses JWT authentication if
 * specified in the environment variables.
 *
 * @param {string} id - The ID of the farmacia to delete.
 * @returns {Function} A thunk function that accepts `dispatch` as an argument.
 */
export const deleteFarmacia = (id) => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = eliminar(id);
    }
    var data = await response;
    if (data) {
      // Extract only the serializable parts of the response
      const serializableData = {
        data: data.data,
        message: data.message,
        status: data.status,
      };
      dispatch(farmaciaDeleteSuccess(serializableData));
    }
  } catch (error) {
    dispatch(apiError(error.message));
  }
};

/**
 * Asynchronous thunk action to edit a farmacia.
 *
 * @param {Object} farmacia - The farmacia object to be edited.
 * @returns {Function} A thunk function that takes dispatch as an argument.
 * @throws Will dispatch an apiError action if the request fails.
 */
export const editFarmacia = (farmacia) => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = editar(farmacia);
    }
    var data = await response;
    if (data) {
      // Extract only the serializable parts of the response
      const serializableData = {
        data: data.data,
        message: data.message,
        status: data.status,
      };

      dispatch(farmaciaSuccess(serializableData));
    }
  } catch (error) {
    dispatch(apiError(error.message));
  }
};

/**
 * Asynchronous thunk action to create a new farmacia.
 *
 * @param {Object} farmacia - The farmacia object to be created.
 * @returns {Function} A thunk function that dispatches actions based on the API response.
 * @throws Will dispatch an apiError action if the API call fails.
 */
export const createFarmacia = (farmacia) => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = crear(farmacia);
    }
    var data = await response;

    if (data) {
      // Extract only the serializable parts of the response
      const serializableData = {
        data: data.data,
        message: data.message,
        status: data.status,
      };
      dispatch(farmaciaSuccess(serializableData));
    }
  } catch (error) {
    dispatch(apiError(error.message));
  }
};

/**
 * Asynchronous thunk action to fetch a farmacia by ID.
 *
 * @param {string} id - The ID of the farmacia to fetch.
 * @returns {Function} A thunk function that dispatches actions based on the API response.
 *
 * @async
 * @function getFarmacia
 * @param {Function} dispatch - The Redux dispatch function.
 * @throws Will dispatch an apiError action if the API call fails.
 */
export const getFarmacia = (id) => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = get(id);
    }
    var data = await response;

    if (data) {
      // Extract only the serializable parts of the response
      const serializableData = {
        data: data.data,
        message: data.message,
        status: data.status,
      };
      dispatch(farmaciaSuccess(serializableData));
    }
  } catch (error) {
    console.error("Error en getFarmacia:", error);
    dispatch(apiError(error.message));
  }
};

/**
 * Asynchronous thunk action to reset the farmacias flag.
 *
 * This function dispatches the `reset_farmacia_flag` action and returns the response.
 * If an error occurs during the dispatch, it catches the error and dispatches the `apiError` action.
 *
 * @returns {Promise} The response from the `reset_farmacia_flag` action.
 */
export const resetFarmaciasFlag = () => async (dispatch) => {
  try {
    const response = dispatch(reset_farmacia_flag());
    return response;
  } catch (error) {
    dispatch(apiError(error.message));
  }
};
