import React from "react";
import { Spinner } from "reactstrap";
import { Container, Row } from "reactstrap";

const Page404 = (props) => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <div className="d-flex justify-content-center mx-2 mt-2">
              <Spinner color="warning"> Loading... </Spinner>
            </div>

            <p className="text-center mt-4">Sorry, Page not Found 😭</p>
          </Row>
          <Row></Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Page404;
