import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Widget from "./Widgets";
import BestSellingProducts from "./BestSellingProducts";
import RecentActivity from "./RecentActivity";
import RecentOrders from "./RecentOrders";
/* import Revenue from "./Revenue"; */
import SalesByLocations from "./SalesByLocations";
import Section from "./Section";
import StoreVisits from "./StoreVisits";
import TopSellers from "./TopSellers";
import Grafica from "./Grafica";
import { DatosSesion } from "../../Components/Common/DatosSesion";
import { useNavigate } from "react-router-dom";

// Versión limpia - 2025-03-02 19:16
const DashboardEcommerce = () => {
  document.title = "Dashboard | Farme";
  
  const nombreUsuario = DatosSesion("nombre") || "";
  const apellidoUsuario = DatosSesion("apellido") || "";
  const farmaciaId = DatosSesion("farmacia") || null;
  const perfil = DatosSesion("perfil") || "";
  const navigate = useNavigate();

  
  useEffect(() => {
    // Redirigir a los usuarios con perfil Delivery a la página de motorizado
    if (perfil.trim() === "Delivery") {
      navigate("/motorizado/");
    }
  }, [perfil, navigate]);
  
  const nombre = `${nombreUsuario} ${apellidoUsuario}`.trim();
  const [rightColumn, setRightColumn] = useState(true);
  const toggleRightColumn = () => {
    setRightColumn(!rightColumn);
  };

  // Si el perfil es Delivery, no renderizar el dashboard
  if (perfil.trim() === "Delivery") {
    return null;
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <Section
                  nombre={nombre}
                  farmaciaId={farmaciaId}
                  rightColumn={rightColumn}
                  toggleRightColumn={toggleRightColumn}
                />
                <Row>
                  <Widget />
                </Row>
                {/* <Row>
                  <Grafica />
                </Row>
                <Row>
                  <Col xl={8}>
                    <RecentOrders />
                  </Col>
                  <Col xl={4}>
                    <TopSellers />
                  </Col>
                </Row> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardEcommerce;
