import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner
} from "reactstrap";

/**
 * Componente para el modal de cancelación de entrega
 * @param {Object} props - Propiedades del componente
 * @returns {JSX.Element} - Componente de modal de cancelación
 */
const CancelModal = ({
  isOpen,
  toggle,
  solicitud,
  onCancel,
  onClose,
  onNotify,
  loading = false
}) => {
  const [motivo, setMotivo] = useState("");
  const [error, setError] = useState("");

  // Lista de motivos de cancelación
  const motivosList = [
    "Cliente ausente",
    "Dirección incorrecta",
    "Cliente rechaza pedido",
    "Zona insegura",
    "Problemas con el producto",
    "Otro"
  ];

  // Manejar la cancelación
  const handleCancel = () => {
    if (!motivo) {
      setError("Por favor, seleccione un motivo de cancelación");
      return;
    }

    // Crear el mensaje para Siscotel
    const message = {
      IdentificadorEvento: solicitud.identificador,
      Tipo: "Mensaje",
      Mensaje: "Entrega cancelada por: " + motivo + " la misma sera reprogramada",
      Adjunto: "",
      CodigoProveedor: solicitud.codigoProveedor,
    };

    onNotify(message);

    // Asegurarse de pasar el ID correcto
    onCancel({
      id: solicitud?._id || solicitud?.id,
      motivo
    });
  };

  // Limpiar el estado al cerrar el modal
  const handleClose = () => {
    setMotivo("");
    setError("");
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered size="md" className="delivery-cancel-modal">
      <ModalHeader toggle={toggle} className="bg-light border-bottom">
        <div className="d-flex align-items-center">
          <i className="ri-error-warning-line text-warning fs-3 me-2"></i>
          <div>
            <h5 className="modal-title mb-0">Cancelar Entrega</h5>
            {solicitud && (
              <small className="text-muted">
                Solicitud #{solicitud.identificador}
              </small>
            )}
          </div>
        </div>
      </ModalHeader>
      <ModalBody className="p-4">
        {error && (
          <div className="alert alert-danger">
            <i className="ri-error-warning-line me-2"></i>
            {error}
          </div>
        )}
        <div className="mb-0">
          <Label className="form-label fw-semibold">
            <i className="ri-message-2-line me-1"></i>
            Motivo de cancelación
          </Label>
          <Input
            type="select"
            value={motivo}
            onChange={(e) => setMotivo(e.target.value)}
            className="form-select bg-light border"
            invalid={error && !motivo}
          >
            <option value="">Seleccione un motivo</option>
            {motivosList.map((m) => (
              <option key={m} value={m}>
                {m}
              </option>
            ))}
          </Input>
        </div>
      </ModalBody>
      <ModalFooter className="border-top bg-light p-3">
        <Button color="light" onClick={handleClose} disabled={loading}>
          <i className="ri-close-line align-bottom me-1"></i>
          Volver
        </Button>
        <Button
          color="danger"
          onClick={handleCancel}
          disabled={!motivo || loading}
        >
          {loading ? (
            <>
              <Spinner size="sm" className="me-1" /> Procesando...
            </>
          ) : (
            <>
              <i className="ri-close-circle-line align-bottom me-1"></i>
              Cancelar Entrega
            </>
          )}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CancelModal;