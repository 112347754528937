//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/_firebase_helper";
import { post } from "../../../helpers/api_helper";
import * as url from "../../../helpers/url_helper";

// action
import { profileSuccess, profileError, resetProfileFlagChange } from "./reducer";

const fireBaseBackend = getFirebaseBackend();

// Implementar la función postJwtProfile
const postJwtProfile = (data) => {
  return post(url.POST_EDIT_JWT_PROFILE, data);
};

export const editProfile = (user) => async (dispatch) => {
    try {
        let response;

        if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {

            response = postJwtProfile(
                {
                    username: user.username,
                    idx: user.idx,
                }
            );

        } 

        const data = await response;

        if (data) {
            dispatch(profileSuccess(data));
        }

    } catch (error) {
        dispatch(profileError(error));
    }
};

export const resetProfileFlag = () => {
    try {
        const response = resetProfileFlagChange();
        return response;
    } catch (error) {
        return error;
    }
};