import ReactDOM from "react-dom";
import React, { useEffect, useState } from "react";
import { createSelector } from "reselect";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  editSolicitud,
  createEventResult,
  createMessage,
  addComentario,
  addFacturacion
} from "../../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Row,
  Input,
  Label,
  Button,
  Form,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";

import { getTasaDolar } from "../../../helpers/api_helper";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginImageResize from "filepond-plugin-image-resize";

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileEncode,
  FilePondPluginFileValidateType,
  FilePondPluginImageTransform,
  FilePondPluginImageResize
);

const compressImage = async (base64String) => {
  try {
    // Crear una imagen desde el base64
    const img = new Image();

    // Crear una promesa para manejar la carga de la imagen
    const imageLoadPromise = new Promise((resolve, reject) => {
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = base64String;
    });

    // Esperar a que la imagen cargue
    const loadedImg = await imageLoadPromise;

    // Crear un canvas para la compresión
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    // Calcular las dimensiones máximas (por ejemplo, 800px de ancho máximo)
    const MAX_WIDTH = 800;
    const scaleFactor = MAX_WIDTH / loadedImg.width;
    canvas.width = MAX_WIDTH;
    canvas.height = loadedImg.height * scaleFactor;

    // Dibujar la imagen en el canvas con las nuevas dimensiones
    ctx.drawImage(loadedImg, 0, 0, canvas.width, canvas.height);

    // Convertir el canvas a base64 con compresión
    const compressedBase64 = canvas.toDataURL("image/jpeg", 0.7);

    return compressedBase64;
  } catch (error) {
    console.error("Error en la compresión de imagen:", error);
    throw new Error(`Error al comprimir la imagen: ${error.message}`);
  }
};

const cleanBase64 = (base64String) => {
  if (!base64String) return "";
  return base64String.split(",")[1] || base64String;
};

const Facturacion = ({ solicitud }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [editingFacturaIndex, setEditingFacturaIndex] = useState(null);
  const [monto, setMonto] = useState(0);
  const [files, setFiles] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [facturaImageUrl, setFacturaImageUrl] = useState("");
  const [showFacturacion, setShowFacturacion] = useState(null);
  const [fechaFacturacion, setFechaFacturacion] = useState(null);
  const [numeroFactura, setNumeroFactura] = useState(null);
  const [montoTotal, setMontoTotal] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [archivoAdjunto, setArchivoAdjunto] = useState(null);
  const [loadingx, setLoadingx] = useState(false);
  const [shouldProcess, setShouldProcess] = useState(false);
  const [tasaDolar, setTasaDolar] = useState(null);
  const [loadingTasa, setLoadingTasa] = useState(false);

  const selectLayoutState = (state) => state;
  const pageData = createSelector(selectLayoutState, (state) => ({
    siscotel: state.Siscotel.siscotel,
    error: state.Siscotel.error,
    loading: state.Siscotel.loading,
    errorMsg: state.Siscotel.errorMsg,
    colorMsg: state.Siscotel.colorMsg,
  }));

  const { siscotel, error, loading, errorMsg, colorMsg } =
    useSelector(pageData);

  // Cargar la tasa del dólar al montar el componente
  useEffect(() => {
    const fetchTasaDolar = async () => {
      setLoadingTasa(true);
      try {
        const response = await getTasaDolar();
        if (response.status === 200 && response.data) {
          setTasaDolar(response.data);
        }
      } catch (error) {
        console.error("Error al obtener tasa del dólar:", error);
      }
      setLoadingTasa(false);
    };

    fetchTasaDolar();
  }, []);

  useEffect(() => {
    if (siscotel && siscotel.status == 200 && shouldProcess && files) {
      const procesarFacturacion = async () => {
        // Inmediatamente establecer shouldProcess a false para prevenir múltiples ejecuciones
        setShouldProcess(false);

        try {
          const now = new Date();
          const creado = new Date(
            now.getTime() - now.getTimezoneOffset() * 60000
          ).toISOString();

          // Procesar el archivo adjunto si existe
          let facturaData = {
            fecha: fechaFacturacion,
            numeroFactura: numeroFactura,
            montoTotal: parseFloat(montoTotal.replace(",", ".")),
            monedaFactura: "B",
            montoDolar:
              parseFloat(montoTotal.replace(",", ".")) / tasaDolar?.precio,
          };

          // Si hay un archivo nuevo, procesarlo

          if (files.length > 0) {
            const file = files[0];
            const fileData = file.getFileEncodeDataURL();

            if (!fileData) {
              throw new Error("No se pudieron obtener los datos del archivo");
            }

            if (file.fileType === "application/pdf") {
              facturaData.adjunto = archivoAdjunto;
            } else {
              // Para imágenes, usa la compresión existente
              const compressedData = await compressImage(archivoAdjunto);
              facturaData.adjunto = compressedData;
            }
          }

          // Crear el array de facturación
          let facturacionArray = [];

          // Si ya existe facturación previa, la incluimos
          if (solicitud.facturacion && Array.isArray(solicitud.facturacion)) {
            facturacionArray = [...solicitud.facturacion];
          }

          if (isEditing && editingFacturaIndex !== null) {
            // Modo edición: actualizar la factura existente
            facturacionArray[editingFacturaIndex] = {
              ...facturacionArray[editingFacturaIndex],
              ...facturaData,
              adjunto:
                facturaData.adjunto ||
                facturacionArray[editingFacturaIndex].adjunto,
            };
          } else {
            // Modo creación: agregar nueva factura
            facturacionArray.push(facturaData);
          }

          const comentario = {
            tipo: "Enviado",
            mensaje: isEditing
              ? "Factura actualizada"
              : "Nueva factura agregada a la espera de los datos para el Delivery",
            estatus: true,
            adjunto: null,
            creado: creado,
          };
         

          const message = {
            IdentificadorEvento: solicitud.identificador,
            Tipo: "Mensaje",
            Mensaje: comentario.mensaje,
            Adjunto: "",
            CodigoProveedor: solicitud.codigoProveedor,
          };

          // Enviar las actualizaciones secuencialmente en lugar de en paralelo
          // Primero enviar el mensaje
          await dispatch(createMessage(message));
          
          // Luego actualizar la facturación
          await dispatch(addFacturacion({ 
            identificador: String(solicitud.identificador), 
            facturacionArray 
          }));
          
          // Finalmente actualizar el comentario
          await dispatch(addComentario({ 
            identificador: String(solicitud.identificador), 
            comentario 
          }));

          resetForm();

          toast.success("Facturación registrada exitosamente", {});

          setLoadingx(false);
        } catch (error) {
          console.error("❌ Error en el proceso de edición:", error);
          setLoadingx(false);
          setShouldProcess(false);
        }
      };

      procesarFacturacion();
    }
  }, [dispatch, siscotel]);

  useEffect(() => {
    if (solicitud) {
      setIsLoading(false);
      setMonto(solicitud.montoCobertura);
      if (solicitud.facturacion) {
        setShowFacturacion(true);
      }
    }
  }, [solicitud]);

  const handleFechaFacturacion = (event) => {
    const { value } = event.target;
    setFechaFacturacion(value.trim());
  };

  const handleNumeroFactura = (event) => {
    const { value } = event.target;
    setNumeroFactura(value.trim());
  };

  const handleMontoTotal = (e) => {
    const value = e.target.value;
    
    // Solo permitir números, una coma y hasta 2 decimales
    if (!/^[0-9]*,?[0-9]{0,2}$/.test(value) && value !== "") {
      return;
    }

    // Convertir el valor ingresado a número (reemplazando la coma por punto)
    const numberValue = value.replace(",", ".");
    const montoIngresado = parseFloat(numberValue) || 0;

    // Calcular el monto máximo permitido (equivalencia en Bs)
    const montoMaximo = parseFloat(monto) * (tasaDolar?.precio || 0);

    if (montoIngresado > montoMaximo) {  
      setFormErrors(prev => ({
        ...prev,
        montoTotal: `El monto no puede exceder ${montoMaximo.toFixed(2).replace(".", ",")} Bs`
      }));
    } else {
      setFormErrors(prev => ({
        ...prev,
        montoTotal: null
      }));
    }

    setMontoTotal(value);
  };

  // Agregar un nuevo estado para manejar los errores de validación
  const [formErrors, setFormErrors] = useState({
    fechaFacturacion: false,
    numeroFactura: false,
    montoTotal: false,
    files: false,
  });

  // Función de validación del formulario
  const validateForm = () => {
    const errors = {
      fechaFacturacion: !fechaFacturacion || fechaFacturacion.trim() === "",
      numeroFactura: !numeroFactura || numeroFactura.trim() === "",
      montoTotal: !montoTotal || montoTotal.trim() === "",
      files: !files || (Array.isArray(files) && !files.length && !archivoAdjunto)
    };

    setFormErrors(errors);

    // Mostrar mensaje de error si hay campos inválidos
    if (Object.values(errors).some((error) => error)) {
      setErrorMessage(
        "Por favor, complete todos los campos requeridos"
      );
      return false;
    }

    return true;
  };

  const handleSubmitFacturaion = async () => {
    // Validar el formulario
    if (!validateForm()) {
      return;
    }

    // Solo proceder si no está ya en proceso
    if (!loadingx && !shouldProcess) {
      setLoadingx(true);
      setShouldProcess(true);
    }

    let miFactura = null;

    try {
      if (files && files.length > 0) {
        const file = files[0];
        const fileData = file.getFileEncodeDataURL();

        if (!fileData) {
          throw new Error("No se pudieron obtener los datos del archivo");
        }

        if (file.fileType === "application/pdf") {
          // Para PDFs, usa directamente el fileData sin comprimir
          setArchivoAdjunto(fileData);
          miFactura = fileData;
        } else {
          // Para imágenes, usa la compresión existente
          try {
            const compressedData = await compressImage(fileData);
            setArchivoAdjunto(compressedData);
            miFactura = compressedData;
          } catch (compressionError) {
            console.error("Error en la compresión:", compressionError);
            throw compressionError;
          }
        }
      }

      // Continuar con el proceso de facturación...
      const formatearFecha = (fecha) => {
        if (!fecha) return null;
        const [year, month, day] = fecha.split("-");
        return `${day}-${month}-${year}`;
      };

      const facturacion = {
        IdentificadorEvento: solicitud.identificador,
        TipoAccion:
          solicitud.tipo === "Aguda"
            ? isEditing
              ? "Modificar"
              : "Crear"
            : `${new Date(fechaFacturacion)
                .toLocaleDateString("es", { month: "2-digit", year: "numeric" })
                .replace("/", "-")}`,
        CodigoDiagnostico: solicitud.codigoDiagnosticoFarmacologico || "",
        SubCodigoDiagnostico: solicitud.codigoSubdiagnosticoFarmacologico || "",
        Procedimiento: "Servicio de medicamentos",
        FechaEvento: formatearFecha(fechaFacturacion),
        MontoFactura: String(montoTotal).replace(".", ","),
        MonedaFactura: "B",
        Factura: miFactura ? cleanBase64(miFactura) : "",
        NumeroFactura: numeroFactura,
        CodigoProveedor: solicitud.codigoProveedor || "",
      };

      const response = await dispatch(createEventResult(facturacion));
    } catch (error) {
      console.error("❌ Error en el proceso:", {
        mensaje: error.message,
        stack: error.stack,
      });
      setLoadingx(false);
    }
  };

  const resetForm = () => {
    setFechaFacturacion("");
    setNumeroFactura("");
    setMontoTotal("");
    setFiles([]);
    setIsEditing(false);
    setEditingFacturaIndex(null);
    setShowFacturacion(true);
    setFormErrors({
      fechaFacturacion: false,
      numeroFactura: false,
      montoTotal: false,
      files: false,
    });
  };

  const editarSolicitudFacturacion = (index) => {
    setIsEditing(true);
    setEditingFacturaIndex(index);

    if (solicitud.facturacion && solicitud.facturacion[index]) {
      const facturaSeleccionada = solicitud.facturacion[index];

      // Asegurarse de que todos los valores sean válidos
      if (
        !facturaSeleccionada.fecha ||
        !facturaSeleccionada.numeroFactura ||
        !facturaSeleccionada.montoTotal
      ) {
        console.error("Datos de factura inválidos");
        return;
      }

      // Formatear la fecha para el input type="date"
      const fecha = facturaSeleccionada.fecha
        ? facturaSeleccionada.fecha.split("T")[0]
        : "";

      setFechaFacturacion(fecha);
      setNumeroFactura(facturaSeleccionada.numeroFactura);
      setMontoTotal(String(facturaSeleccionada.montoTotal).replace(".", ","));
      setFiles([]);
    }

    setShowFacturacion(false);
  };

  const openFacturaModal = (fileData) => {
    // Detectar si es un PDF basado en el header del base64
    const isPDF =
      fileData.includes("data:application/pdf") ||
      fileData.startsWith("data:application/pdf") ||
      fileData.includes("JVBERi0"); // Signature de PDF en base64

    if (isPDF) {
      // Abrir PDF en nueva pestaña
      const pdfWindow = window.open();
      pdfWindow.document.write(
        `<iframe width='100%' height='100%' src='${fileData}'></iframe>`
      );
    } else {
      // Para imágenes, usar el modal existente
      if (!fileData.startsWith("data:")) {
        fileData = `data:image/jpeg;base64,${base64Data}`;
      }
      setFacturaImageUrl(fileData);
      setModalIsOpen(true);
    }
  };

  const FacturaModal = ({ isOpen, toggle, imageUrl }) => {
    const isPDF =
      imageUrl &&
      (imageUrl.includes("data:application/pdf") ||
        imageUrl.startsWith("data:application/pdf") ||
        imageUrl.includes("JVBERi0"));

    return (
      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>Factura</ModalHeader>
        <ModalBody>
          {imageUrl ? (
            isPDF ? (
              <iframe
                src={imageUrl}
                style={{ width: "100%", height: "500px" }}
                title="PDF Viewer"
              />
            ) : (
              <img
                src={imageUrl}
                alt="Factura"
                style={{ width: "100%" }}
                onError={(e) => {
                  console.error("Error al cargar la imagen:", e);
                  e.target.onerror = null;
                  e.target.src = "ruta/a/imagen/por/defecto.png";
                }}
              />
            )
          ) : (
            <p>No se pudo cargar el documento.</p>
          )}
        </ModalBody>
      </Modal>
    );
  };

  const FacturacionTable = ({ solicitud, onEdit, onOpenFactura }) => {
    if (
      !solicitud ||
      !solicitud.facturacion ||
      solicitud.facturacion.length === 0
    ) {
      return (
        <div className="text-center p-4 bg-light rounded">
          <i className="ri-file-list-3-line text-muted font-size-24 mb-2"></i>
          <p className="text-muted mb-3">
            No hay información de facturación disponible.
          </p>
          {solicitud?.tipo === "Cronica" &&
            isValidSolicitud() &&
            !hasEntryThisMonth() && (
              <Button
                color="primary"
                className="btn-sm"
                onClick={() => setShowFacturacion(false)}
              >
                <i className="ri-add-line me-1"></i> Agregar Factura
              </Button>
            )}
        </div>
      );
    }

    return (
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h5 className="card-title mb-0">Historial de Facturación</h5>

            {solicitud?.estatus !== "Cancelada" &&
              solicitud?.tipo === "Cronica" &&
              isValidSolicitud() &&
              !hasEntryThisMonth() && (
                <Button
                  color="primary"
                  size="sm"
                  className="d-flex align-items-center"
                  onClick={() => {
                    setIsEditing(false);
                    setShowFacturacion(false);
                  }}
                >
                  <i className="ri-add-line me-1"></i> Agregar Nueva Factura
                </Button>
              )}
          </div>

          <div className="table-responsive">
            <table className="table table-centered table-hover mb-0">
              <thead className="table-light">
                <tr>
                  <th scope="col">Fecha</th>
                  <th scope="col"># Factura</th>
                  <th scope="col">Total (Bs.)</th>
                  <th scope="col">Total ($)</th>
                  <th scope="col">Factura</th>
                  <th scope="col" className="text-center">
                    Acción
                  </th>
                </tr>
              </thead>
              <tbody>
                {solicitud.facturacion.map((factura, index) => (
                  <tr key={index}>
                    <td>
                      {factura.fecha ? factura.fecha.split("T")[0] : "N/A"}
                    </td>
                    <td>
                      <span className="fw-medium">
                        {factura.numeroFactura || "N/A"}
                      </span>
                    </td>
                    <td>
                      <span className="text-primary fw-medium">
                        {factura.montoTotal
                          ? new Intl.NumberFormat("es-VE", {
                              style: "decimal",
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                              useGrouping: true,
                            }).format(parseFloat(factura.montoTotal))
                          : "N/A"}
                      </span>
                    </td>
                    <td>
                      <span className="text-primary fw-medium">
                        {factura.montoDolar
                          ? new Intl.NumberFormat("es-VE", {
                              style: "decimal",
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                              useGrouping: true,
                            }).format(parseFloat(factura.montoDolar))
                          : "N/A"}
                      </span>
                    </td>
                    <td>
                      {factura.adjunto ? (
                        <Button
                          color="link"
                          className="p-0 text-info"
                          onClick={(e) => {
                            e.preventDefault();
                            onOpenFactura(factura.adjunto);
                          }}
                        >
                          <i
                            className={`me-1 ${
                              factura.adjunto.includes(
                                "data:application/pdf"
                              ) || factura.adjunto.includes("JVBERi0")
                                ? "ri-file-pdf-line"
                                : "ri-image-line"
                            }`}
                          ></i>
                          {factura.adjunto.includes("data:application/pdf") ||
                          factura.adjunto.includes("JVBERi0")
                            ? "Ver PDF"
                            : "Ver Factura"}
                        </Button>
                      ) : (
                        <span className="text-muted">No disponible</span>
                      )}
                    </td>
                    <td className="text-center">
                      {solicitud.estatus === "Cancelado" ||
                      solicitud.estatus === "Entregado" ? (
                        <span className="text-muted">-</span>
                      ) : (
                        <Button
                          color="soft-primary"
                          size="sm"
                          className="btn-icon rounded-circle"
                          onClick={() => onEdit(index)}
                        >
                          <i className="ri-pencil-line"></i>
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  const isValidSolicitud = () => {
    // Para solicitudes Agudas
    if (solicitud.tipo === "Aguda") {
      return solicitud.montoCobertura > 0;
    }

    // Para solicitudes Crónicas (mantener la lógica existente)
    if (solicitud.estatus !== "Nueva Solicitud") return false;
    if (!solicitud.cronico?.fechaVigencia) return false;

    const fechaVigencia = new Date(solicitud.cronico.fechaVigencia);
    const today = new Date();

    return (
      fechaVigencia.getFullYear() > today.getFullYear() ||
      (fechaVigencia.getFullYear() === today.getFullYear() &&
        fechaVigencia.getMonth() >= today.getMonth())
    );
  };

  const hasEntryThisMonth = () => {
    // Para solicitudes Agudas no aplicamos esta validación
    if (solicitud.tipo === "Aguda") {
      return false;
    }

    // Mantener la lógica existente para Crónicas
    if (!solicitud.cronico?.historialEntregas?.length) return false;
    if (!isValidSolicitud()) return true;

    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();

    return solicitud.facturacion?.some((factura) => {
      const facturaDate = new Date(factura.fecha);
      return (
        facturaDate.getMonth() === currentMonth &&
        facturaDate.getFullYear() === currentYear
      );
    });
  };

  const getNextAvailableDate = () => {
    const today = new Date();
    const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
    return nextMonth.toLocaleDateString("es-ES", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const getStatusMessage = () => {
    // Para solicitudes Agudas
    if (solicitud.tipo === "Aguda") {
      if (solicitud.montoCobertura <= 0) {
        return {
          title: "Solicitud no disponible",
          message: "Esta solicitud no tiene monto de cobertura asignado.",
        };
      }
      return null;
    }

    // Mantener la lógica existente para Crónicas
    if (solicitud.estatus !== "Nueva Solicitud") {
      return {
        title: "Solicitud no disponible",
        message: "Esta solicitud no está en estado 'Nueva Solicitud'.",
      };
    }

    if (!solicitud.cronico?.fechaVigencia) {
      return {
        title: "Solicitud sin fecha de vigencia",
        message: "Esta solicitud no tiene una fecha de vigencia establecida.",
      };
    }

    const fechaVigencia = new Date(solicitud.cronico.fechaVigencia);
    if (fechaVigencia < new Date()) {
      return {
        title: "Solicitud vencida",
        message: `Esta solicitud venció el ${fechaVigencia.toLocaleDateString(
          "es-ES",
          {
            year: "numeric",
            month: "long",
            day: "numeric",
          }
        )}.`,
      };
    }

    if (hasEntryThisMonth()) {
      return {
        title: "Registro mensual completado",
        message: `Ya se ha registrado una factura para este mes.
                  La próxima facturación estará disponible a partir del ${getNextAvailableDate()}.`,
      };
    }

    return null;
  };

  //principal
  return (
    <React.Fragment>
      {!showFacturacion ? (
        <Card className="border-0 shadow-sm">
          <CardHeader>
            <div className="d-flex align-items-center">
              <i className="ri-information-line me-1 text-primary me-2 fs-20"></i>
              <h5 className="card-title mb-0">
                {isEditing ? "Editar Facturación" : "Nueva Facturación"}
              </h5>
            </div>
          </CardHeader>

          <CardBody className="p-4">
            <Form id="facturacion-form" className="form-steps">
              {getStatusMessage() && (
                <div
                  className={`alert ${
                    !isValidSolicitud() ? "alert-warning" : "alert-info"
                  } d-flex align-items-center`}
                  role="alert"
                >
                  <i
                    className={`me-2 fs-18 ${
                      !isValidSolicitud()
                        ? "ri-error-warning-line"
                        : "ri-information-line"
                    }`}
                  ></i>
                  <div>
                    <h6 className="alert-heading mb-1">
                      {getStatusMessage().title}
                    </h6>
                    <p className="mb-0 small">{getStatusMessage().message}</p>
                  </div>
                </div>
              )}

              {((solicitud.tipo === "Aguda" && solicitud.montoCobertura > 0) ||
                (solicitud.tipo === "Cronica" &&
                  isValidSolicitud() &&
                  !hasEntryThisMonth()) ||
                isEditing) && (
                <div className="bg-light p-4 rounded mb-4">
                  <Row>
                    <Col lg={4} md={6} className="mb-3">
                      <Label
                        className="form-label fw-medium"
                        htmlFor="FechaFacturacion"
                      >
                        Fecha <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="date"
                        className={`form-control ${
                          formErrors.fechaFacturacion ? "is-invalid" : ""
                        }`}
                        id="FechaFacturacion"
                        value={fechaFacturacion || ""}
                        onChange={handleFechaFacturacion}
                        required
                      />
                      {formErrors.fechaFacturacion && (
                        <div className="invalid-feedback">
                          Este campo es requerido
                        </div>
                      )}
                    </Col>

                    <Col lg={4} md={6} className="mb-3">
                      <Label className="form-label fw-medium" htmlFor="factura">
                        # Factura <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className={`form-control ${
                          formErrors.numeroFactura ? "is-invalid" : ""
                        }`}
                        id="factura"
                        value={numeroFactura || ""}
                        onChange={handleNumeroFactura}
                        required
                      />
                      {formErrors.numeroFactura && (
                        <div className="invalid-feedback">
                          Este campo es requerido
                        </div>
                      )}
                    </Col>

                    <Col lg={4} md={6} className="mb-3">
                      <Label className="form-label fw-medium" htmlFor="total">
                        Monto Total (Bs) <span className="text-danger">*</span>
                      </Label>
                      <div className="input-group">
                        <span className="input-group-text">Bs</span>
                        <Input
                          type="text"
                          className={`form-control ${
                            formErrors.montoTotal ? "is-invalid" : ""
                          }`}
                          id="total"
                          value={montoTotal || ""}
                          onChange={(e) => {
                            setErrorMessage("");
                            handleMontoTotal(e);
                          }}
                          onBlur={(e) => {
                            if (montoTotal) {
                              const numberValue = montoTotal.replace(",", ".");
                              if (!isNaN(numberValue)) {
                                const formattedValue = parseFloat(numberValue)
                                  .toFixed(2)
                                  .replace(".", ",");
                                setMontoTotal(formattedValue);
                              }
                            }
                          }}
                          placeholder="0,00"
                          required
                        />
                        {formErrors.montoTotal && (
                          <div className="invalid-feedback">
                            {formErrors.montoTotal}
                          </div>
                        )}
                      </div>
                    </Col>

                    <Col lg={12} className="mb-3">
                      <Label className="form-label fw-medium">
                        Adjuntar Factura <span className="text-danger">*</span>
                      </Label>
                      <div
                        className={
                          formErrors.files ? "border border-danger rounded" : ""
                        }
                      >
                        <FilePond
                          files={files}
                          onupdatefiles={setFiles}
                          allowMultiple={false}
                          maxFiles={1}
                          name="files"
                          labelIdle='<i class="ri-upload-cloud-line fs-24 mb-2"></i><br/>Arrastra o <span class="filepond--label-action text-primary">selecciona</span> un archivo'
                          className="filepond filepond-input-multiple"
                          allowFileEncode={true}
                          acceptedFileTypes={["application/pdf", "image/*"]}
                          labelFileTypeNotAllowed="Solo se permiten imágenes y PDFs"
                          fileValidateTypeLabelExpectedTypes="Seleccione una imagen o PDF"
                          maxFileSize="2MB"
                          credits={false}
                          allowImageTransform={true}
                          imageTransformOutputQuality={80}
                          imageResizeTargetWidth={1200}
                          imageResizeMode="contain"
                          imageResizeUpscale={false}
                          stylePanelLayout="compact"
                          styleLoadIndicatorPosition="center bottom"
                          styleProgressIndicatorPosition="right bottom"
                          styleButtonRemoveItemPosition="left bottom"
                          styleButtonProcessItemPosition="right bottom"
                          allowBrowse={true}
                          allowCamera={true}
                          fileValidateTypeDetectType={(source, type) => new Promise((resolve, reject) => {
                            // Para archivos de cámara, el tipo puede ser incorrecto
                            if (source instanceof File) {
                              if (source.type.includes('image/')) {
                                resolve(source.type);
                              } else if (source.type === 'application/pdf') {
                                resolve('application/pdf');
                              }
                            }
                            resolve(type);
                          })}
                          onaddfile={(error, file) => {
                            if (error) {
                              console.error("Error al agregar archivo:", error);
                              setFormErrors({
                                ...formErrors,
                                files: "Error al procesar el archivo",
                              });
                              return;
                            }
                            setFormErrors({
                              ...formErrors,
                              files: null,
                            });
                          }}
                          onerror={(error) => {
                            console.error("Error FilePond:", error);
                            setFormErrors({
                              ...formErrors,
                              files: "Error al procesar el archivo",
                            });
                          }}
                        />
                      </div>
                      {formErrors.files && (
                        <div className="text-danger small mt-1">
                          <i className="ri-error-warning-line me-1"></i>
                          Debe adjuntar una factura
                        </div>
                      )}
                      <div className="text-muted small mt-1">
                        <i className="ri-information-line me-1"></i>
                        Formatos aceptados: Imágenes y PDFs. Tamaño máximo: 2MB
                      </div>
                    </Col>
                  </Row>

                  <div className="d-flex flex-column flex-md-row justify-content-between align-items-start mt-4">
                    <div className="d-flex flex-column w-100 mb-3 mb-md-0">
                      <div className="d-flex align-items-center mb-3">
                        <div className="badge bg-light-subtle text-primary p-2 me-2">
                          <i className="ri-money-dollar-circle-line fs-18"></i>
                        </div>
                        <div>
                          <span className="text-muted small">
                            Monto de Cobertura
                          </span>
                          <h6 className="mb-0">
                            {new Intl.NumberFormat("es-VE", {
                              style: "decimal",
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                              useGrouping: true,
                            }).format(parseFloat(monto))}{" "}
                            USD
                          </h6>
                        </div>
                      </div>

                      {tasaDolar && (
                        <div className="d-flex align-items-center ps-5">
                          <div className="border-start ps-3">
                            <div className="d-flex align-items-center flex-wrap mb-1">
                              <span className="text-muted me-2">
                                Tasa del día:
                              </span>
                              <span className="fw-medium">
                                {tasaDolar.precio.toFixed(2)} Bs/USD
                              </span>
                              <span className="badge bg-soft-success text-success ms-2 small">
                                {tasaDolar.ultima_actualizacion}
                              </span>
                            </div>
                            <div className="d-flex align-items-center">
                              <span className="text-muted me-2">
                                Equivalente:
                              </span>
                              <span className="fw-medium text-success">
                                {new Intl.NumberFormat("es-VE", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                  useGrouping: true,
                                }).format(parseFloat(monto) * tasaDolar.precio)}{" "}
                                Bs
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    {solicitud.estatus !== "Cancelado" && (
                      <div className="d-flex gap-2 mt-2 mt-md-0">
                        {isEditing && (
                          <Button
                            color="light"
                            className="d-flex align-items-center"
                            onClick={() => {
                              resetForm();
                              setShowFacturacion(true);
                            }}
                          >
                            <i className="ri-close-line me-1"></i>
                            Cancelar
                          </Button>
                        )}
                        <Button
                          color="success"
                          className="d-flex align-items-center"
                          disabled={loadingx}
                          onClick={handleSubmitFacturaion}
                        >
                          {loadingx ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm me-2"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              Registrando...
                            </>
                          ) : (
                            <>
                              <i className="ri-save-line me-1"></i>
                              {isEditing ? "Actualizar" : "Registrar"}{" "}
                              Facturación
                            </>
                          )}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Form>
          </CardBody>
        </Card>
      ) : (
        <Card className="border-0 shadow-sm">
          <CardHeader>
            <div className="d-flex align-items-center">
              <i className="ri-check-double-line me-1 text-primary me-2 fs-20"></i>
              <h5 className="card-title mb-0">Información de Facturación:</h5>
            </div>
          </CardHeader>

          <CardBody className="p-4">
            <FacturacionTable
              solicitud={solicitud}
              onEdit={editarSolicitudFacturacion}
              onOpenFactura={openFacturaModal}
            />
          </CardBody>
        </Card>
      )}

      <FacturaModal
        isOpen={modalIsOpen}
        toggle={() => setModalIsOpen(false)}
        imageUrl={facturaImageUrl}
      />
    </React.Fragment>
  );
};

export default Facturacion;
